import React, { useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
import * as Yup from "yup";
import {
  Grid,
  MenuItem,
  Slider,
  Stack,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import RequestQuoteIcon from "@mui/icons-material/RequestQuoteTwoTone";

import {
  loanDetailsSelector,
  updateLoanDetails,
  saveLoanDetails,
  assetSelector,
  saveAsset,
  updateAsset,
} from "../../store/slices/applicationFormSlice";
import regex from "src/utils/regex";
import checkAlphabetRestrict from "src/utils/checkAlphabetRestrict";
import {
  NumericFormatCustom,
  currencyMaskedValue,
} from "src/utils/currencyMaskFormat";
import { userSelector } from "src/store/slices/userSlice";

const dollarStringOptions = {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
};

const LoanDetails = React.memo(({ applicationType }) => {
  const dispatch = useDispatch();

  const user = useSelector(userSelector)
  const loanDetails = useSelector(loanDetailsSelector);
  const asset = useSelector(assetSelector);

  const {
    _id,
    balloon = 0,
    tradeIn = null,
    payout = null,
    deposit = null,
    term,
    brokerage,
    originationFee,
  } = loanDetails;

  const { ageOfAsset = "", ageOfAssetAtEnd, purpose, assetValue } = asset;

  const [fieldErrors, setFieldErrors] = useState({
    deposit: "",
    tradeIn: "",
    payout: "",
    assetValue: "",
    purpose: "",
  });

  const validationSchema = Yup.object({
    deposit: Yup.string()
      .optional()
      .matches(
        regex.allowOnlyNumber,
        "Only numbers and and decimal points allowed."
      )
      .matches(regex.allowNumberAndDecimal, {
        message: "Number must be to up 7 digit and up to 2 decimal places",
      })
      .max(10, "Maximum of 7 digits"),
    tradeIn: Yup.string()
      .optional()
      .matches(
        regex.allowOnlyNumber,
        "Only numbers and and decimal points allowed."
      )
      .matches(regex.allowNumberAndDecimal, {
        message: "Number must be to up 7 digit and up to 2 decimal places",
      })
      .max(10, "Maximum of 7 digits"),
    payout: Yup.string()
      .optional()
      .matches(
        regex.allowOnlyNumber,
        "Only numbers and and decimal points allowed."
      )
      .matches(regex.allowNumberAndDecimal, {
        message: "Number must be to up 7 digit and up to 2 decimal places",
      })
      .max(10, "Maximum of 7 digits"),
    assetValue: Yup.string()
      .optional()
      .matches(
        regex.allowOnlyNumber,
        "Only numbers and and decimal points allowed."
      )
      .matches(regex.allowNumberAndDecimal, {
        message: "Number must be to up 7 digit and up to 2 decimal places",
      })
      .max(10, "Maximum of 7 digits"),
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };

  const [allValues, setAllValue] = useState({
    deposit: deposit || "",
    balloonValue: balloon || "",
    termValue: term,
    tradeIn: tradeIn || "",
    payout: payout || "",
    purpose: purpose,
    assetValue: assetValue || "",
    brokerage: brokerage || 0,
    originationFee: originationFee || 0,
  });

  const handle = {
    onChangeField: async (e, name) => {
      let isValid = true;
      console.log(e, name)

      if (e === "") return setAllValue({ ...allValues, [name]: e });

      if (
        name === "deposit" ||
        name === "tradeIn" ||
        name === "payout" ||
        name === "assetValue"
      ) {
        const splitString = e?.split(".");
        isValid =
          (e === "" || regex.allowOnlyNumber.test(e)) &&
          e?.length <= 10 &&
          splitString[1]?.length
            ? splitString[1]?.length <= 2
            : e[7] !== "."
            ? e?.length <= 7
            : e?.length <= 10;
      }

      if (!isValid) {
        await validateField(name, e);
        return;
      }

      setAllValue({ ...allValues, [name]: e });
      await validateField(name, e);
    },
    loanDetailsFn: (value) => {
      dispatch(saveLoanDetails(value));
      dispatch(updateLoanDetails({ _id, ...value }));
    },
    termValueFn: () => {
      const termInYears = allValues?.termValue / 12;
      const ageOfAssetMoment = moment(ageOfAsset, "YYYY");
      const endOfTermYear = moment().add(termInYears, "year");
      const ageOfAssetAtEnd = endOfTermYear.diff(ageOfAssetMoment, "years");
      handle.apiCallFn(ageOfAssetAtEnd);
    },
    apiCallFn: (ageOfAssetAtEnd) => {
      Promise.all([
        dispatch(saveLoanDetails({ term: allValues?.termValue })),
        dispatch(updateAsset({ _id: asset._id, ageOfAssetAtEnd })),
        dispatch(updateLoanDetails({ _id, term: allValues?.termValue })),
      ]);
    },
    blurFn: async (fieldName, value) => {
      try {
        if (fieldErrors[fieldName] === "")
          handle.loanDetailsFn({
            [fieldName]: value,
          });
      } catch (error) {
        const newErrors = {};
        error.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
        setFieldErrors(newErrors);
      }
    },
    blurFnPersonalType: (fieldName, value) => {
      try {
        if (fieldErrors[fieldName] === "")
          dispatch(saveAsset({ [fieldName]: value }));
        if (_id)
          dispatch(
            updateAsset({
              ...asset,
              [fieldName]: value,
            })
          );
      } catch (error) {
        const newErrors = {};
        error.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
        setFieldErrors(newErrors);
      }
    },
  };

  return (
    <Grid
      container
      style={{
        margin: "0 0 30px",
        padding: "0 0 20px",
        borderBottom: "1px solid rgba(0,0,0,0.12)",
      }}
    >
      <Grid container xs={12} sm={12} md={2}>
        <RequestQuoteIcon style={{ marginRight: "10px" }} />
        <Typography>Loan Details</Typography>
      </Grid>

      <Grid
        conatiner
        xs={12}
        sm={12}
        md={10}
        style={{
          padding: "0 0 0 10px",
        }}
      >
        {(applicationType === "commercial" ||
          applicationType === "consumer") && (
          <Grid container item style={{ margin: "0 0 10px" }}>
            <Grid container item spacing={1}>
              <Grid item sm={4} xs={12}>
                <Stack direction="row">
                  <TextField
                    fullWidth
                    id="deposit"
                    label="Deposit amount"
                    variant="filled"
                    type="text"
                    size="small"
                    name="deposit"
                    value={allValues?.deposit}
                    error={fieldErrors?.deposit}
                    helperText={fieldErrors?.deposit}
                    onChange={(event) =>
                      handle.onChangeField(event?.target?.value, "deposit")
                    }
                    onBlur={(e) =>
                      handle.blurFn(
                        "deposit",
                        currencyMaskedValue(e.target.value)
                      )
                    }
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      style: {
                        borderTopRightRadius: 0,
                      },
                    }}
                  />
                </Stack>
              </Grid>

              <Grid item md={4} sm={3}>
                <TextField
                  fullWidth
                  id="tradeIn"
                  variant="filled"
                  type="text"
                  name="tradeIn"
                  label="Trade in amount"
                  size="small"
                  value={allValues?.tradeIn}
                  error={fieldErrors?.tradeIn}
                  helperText={fieldErrors?.tradeIn}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                  onChange={(event) =>
                    handle.onChangeField(event?.target?.value, "tradeIn")
                  }
                  onBlur={(e) =>
                    handle.blurFn(
                      "tradeIn",
                      currencyMaskedValue(e.target.value)
                    )
                  }
                />
              </Grid>
              <Grid item md={4} sm={3}>
                <TextField
                  fullWidth
                  id="payout"
                  variant="filled"
                  type="text"
                  name="payout"
                  label="Payout amount"
                  size="small"
                  value={allValues?.payout}
                  error={fieldErrors?.payout}
                  helperText={fieldErrors?.payout}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                  onChange={(event) =>
                    handle.onChangeField(event?.target?.value, "payout")
                  }
                  onBlur={(e) =>
                    handle.blurFn("payout", currencyMaskedValue(e.target.value))
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid container item xs={12} style={{ margin: "0 0 10px" }}>
          <Grid container item spacing={2}>
            {(applicationType === "commercial" ||
              applicationType === "consumer") && (
              <Fragment>
                <Grid item sm={4} xs={12}>
                  <Stack direction="column">
                    <Typography variant="caption">Balloon</Typography>
                    <Box style={{ padding: "0 7px" }}>
                      <Slider
                        color="primary"
                        size="large"
                        marks={[
                          { value: 0, label: 0 },
                          { value: 10, label: 10 },
                          { value: 20, label: 20 },
                          { value: 30, label: 30 },
                          { value: 40, label: 40 },
                        ]}
                        step={5}
                        min={0}
                        max={40}
                        value={allValues?.balloonValue}
                        name="balloonValue"
                        onChange={(event) =>
                          handle.onChangeField(
                            event?.target?.value,
                            "balloonValue"
                          )
                        }
                        onChangeCommitted={() =>
                          handle.loanDetailsFn({
                            balloon: allValues?.balloonValue,
                          })
                        }
                        aria-label="Default"
                        valueLabelDisplay="auto"
                      />
                    </Box>
                  </Stack>
                </Grid>
              </Fragment>
            )}

            {applicationType === "personal" && (
              <Fragment>
                <Grid item md={4} sm={4}>
                  <TextField
                    id="assetValue"
                    fullWidth
                    variant="filled"
                    type="text"
                    name="assetValue"
                    label="Loan amount"
                    size="small"
                    value={allValues?.assetValue}
                    error={fieldErrors?.assetValue}
                    helperText={fieldErrors?.assetValue}
                    onChange={(event) =>
                      handle.onChangeField(event?.target?.value, "assetValue")
                    }
                    onBlur={(e) =>
                      handle.blurFnPersonalType("assetValue", e.target.value)
                    }
                  />
                </Grid>

                <Grid item md={4} sm={4}>
                  <TextField
                    fullWidth
                    variant="filled"
                    style={{ background: "#ffffff" }}
                    select
                    value={allValues?.purpose}
                    label="Loan purpose"
                    size="small"
                    name="purpose"
                    onChange={(event) =>
                      handle.onChangeField(event?.target?.value, "purpose")
                    }
                    onBlur={(e) =>
                      handle.blurFnPersonalType("purpose", e.target.value)
                    }
                  >
                    {[
                      "Debt Consolidation",
                      "Home improvements",
                      "Medical & Dental",
                      "Household furnishings",
                      "Education",
                      "Travel",
                      "Wedding",
                      "Mortgage Cost Funding",
                      "Other Personal Use",
                    ].map((purpose, i) => (
                      <MenuItem value={purpose}>{purpose}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Fragment>
            )}

            <Grid item sm={4} xs={12}>
              <Stack direction="column">
                <Typography variant="caption">
                  Repayment term (in months)
                </Typography>
                <Box style={{ padding: "0 7px" }}>
                  <Slider
                    color="primary"
                    size="large"
                    marks={[
                      { value: 12, label: 12 },
                      { value: 24, label: 24 },
                      { value: 36, label: 36 },
                      { value: 48, label: 48 },
                      { value: 60, label: 60 },
                      { value: 72, label: 72 },
                      { value: 84, label: 84 },
                    ]}
                    step={12}
                    min={12}
                    max={84}
                    value={parseInt(allValues?.termValue)}
                    name="termValue"
                    onChange={(event) =>
                      handle.onChangeField(event?.target?.value, "termValue")
                    }
                    onChangeCommitted={() => handle.termValueFn()}
                    defaultValue={60}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                  />
                </Box>
              </Stack>
            </Grid>
            {/* </Grid>
        </Grid>
        <Grid container item xs={12} style={{ margin: "0 0 10px" }}>
          <Grid container item spacing={2} alignItems="center"> */}

            {(applicationType === "commercial" && user?.userType === "master") && (
              <Fragment>
                <Grid item sm={4} xs={12}>
                  <Stack direction="column">
                    <Typography variant="caption">
                      Brokerage {allValues?.brokerage}% |{" "}
                      {(
                        assetValue || 0 * (allValues?.brokerage / 100)
                      ).toLocaleString("en-US", dollarStringOptions)}
                    </Typography>
                    <Box style={{ padding: "0 7px" }}>
                      <Slider
                        color="primary"
                        size="large"
                        marks={[
                          { value: 0, label: 0 },
                          { value: 2, label: "2" },
                          { value: 4, label: "4" },
                          { value: 6, label: "6" },
                          { value: 8, label: "8" },
                          { value: 10, label: "10" },
                          { value: 12, label: "12" },
                        ]}
                        step={1}
                        min={0}
                        max={12}
                        value={allValues?.brokerage}
                        name="brokerage"
                        onChange={(event) =>
                          handle.onChangeField(
                            event?.target?.value,
                            "brokerage"
                          )
                        }
                        onChangeCommitted={() =>
                          handle.loanDetailsFn({
                            brokerage: allValues?.brokerage,
                          })
                        }
                        aria-label="Default"
                        valueLabelDisplay="auto"
                      />
                    </Box>
                  </Stack>
                  {/* <TextField
                    id="originationFee"
                    fullWidth
                    variant="filled"
                    type="text"
                    name="originationFee"
                    label="Brokerage (%)"
                    size="small"
                    value={allValues?.brokerageAdjustment}
                    // error={allValues?.brokerageAdjustment}
                    // helperText={fieldErrors?.originationFee}
                    onChange={(event) =>
                      handle.onChangeField(event?.target?.value, "brokerageAdjustment")
                    }
                    onBlur={(e) =>
                      handle.loanDetailsFn({
                        brokerageAdjustment: allValues?.brokerageAdjustment,
                      })
                    }
                  /> */}
                </Grid>
              </Fragment>
            )}
            <Grid item sm={4} xs={12}>
              {/* <TextField
                id="originationFee"
                fullWidth
                variant="filled"
                type="text"
                name="originationFee"
                label="Origination fee"
                size="small"
                value={allValues?.originationFee || "Lender max"}
                error={fieldErrors?.originationFee}
                helperText={fieldErrors?.originationFee}`
                onChange={(event) =>
                  handle.onChangeField(event?.target?.value, "originationFee")
                }
                onBlur={(e) =>
                  handle.blurFnPersonalType("originationFee", e.target.value)
                }
              /> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default LoanDetails;
