import criteria from "src/utils/criteria";
import { typeOfSaleList } from "src/constants.js";

const { DEALER_SALE } = typeOfSaleList;

const {
  hasDeposit,
  depositBetween,
  ageOfAssetGreaterThan,
  ageOfAssetBetween,
  livingArrangements,
  termIsGreaterThan,
  typeOfSale,
  assetValueBetween,
  assetType,
  ageOfAssetLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  timeInBusinessBetween,
  gstRegistrationGreaterThan,
  ageOfAssetAtEndLessThan,
  creditScoreGreaterThan,
  compound,
} = criteria;

export default {
  date: "13 Jul 2023",
  lender: "Volkswagen Financial Services",
  type: "commercial",
  logoURL:
    "https://assets.financeable.com.au/lender-logos/Volkswagen_logo_2019.svg",
  actions: [],
  lenderCredentials: [
    {
      label: "Email",
      attribute: "email",
    },
    {
      label: "User Name",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
    {
      label: "Merchant ID",
      attribute: "organisationId",
    },
    {
      label: "User ID",
      attribute: "brokerId",
    },
    {
      label: "Api Key",
      attribute: "apiKey",
    },
  ],
  requiredFields: {
    entity: [
      "abn",
      // "addresses[0].streetAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
    ],
    customer: [
      "phoneNumbers[0].number",
      "emails[0].address",
      "firstName",
      "lastName",
      "dateOfBirth",
      // "addresses[0].streetAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "IDNumber",
      "IDCardNumber",
      // "emails[0].address",
      // "phoneNumbers[0].number",
    ],
    loanDetails: ["rate"],
    asset: [
      "assetType",
      "typeOfSale",
      "ageOfAsset",
      "glassesMake",
      "glassesModel",
      "glassesVariant",
      "assetValue",
    ],
  },
  fees: [
    {
      name: "Document fee",
      value: 450,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Origination fee",
      value: 975,
      capitalised: true,
      frequency: "upfront",
    },
  ],
  brokerage: [
    {
      value: 3.4,
      max: 3.4,
      valueType: "percentage",
      criteria: [assetValueBetween(10000, 200001)],
    },
  ],
  loading: [
    // {
    //   name: "Non property owner",
    //   value: 2,
    //   valueType: "percentage",
    //   criteria: [livingArrangements(["Renting", "Boarding", "Living w/ parents"]),],
    // },
    {
      name: "LVR <70%",
      value: -0.65,
      valueType: "percentage",
      criteria: [hasDeposit(35)],
    },
    {
      name: "LVR 70-80%",
      value: -0.5,
      valueType: "percentage",
      criteria: [depositBetween(30, 34.99999999)],
    },
    {
      name: "LVR 80-90%",
      value: -0.3,
      valueType: "percentage",
      criteria: [depositBetween(20, 29.99999999)],
    },
    {
      name: "LVR 90-110%",
      value: -0.2,
      valueType: "percentage",
      criteria: [depositBetween(10, 19.99999999)],
    },
  ],
  info: [
    `Applications >100K will require comparable credit for low doc applications`,
    `For ride share - new and demo vehicles only. Deposit required to bring loan down to 90% advance. 48 month max term.`,
    `Replacement allowed for upto $250K - A rated account completed in last 12 months`,
    `Professionals package $200K for registered architects, accountants (CA/CPA), dentists, doctors, engineers, lawyers/barristers/solicitors, pharmacists, veterinarians`,
    `Asset types - MV<4.5T allowed (Does not include commercial use trucks)`,
    `Full financials - 2 years financials, ATO portal (Integrated client account) and Income Tax account`,
  ],
  productTiers: [
    {
      name: "New start",
      rate: 10.67,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 100000),
        timeInBusinessBetween(3, 24),
        gstRegistrationGreaterThan(0),
        ageOfAssetLessThan(13),
        hasDeposit(20),
        ageOfAssetAtEndLessThan(13),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        typeOfSale([DEALER_SALE]),
        // livingArrangements(["Property owner"]),
        // creditScoreGreaterThan(400)
      ],
    },
    {
      name: "New start",
      rate: 10.96,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 100000),
        timeInBusinessBetween(3, 24),
        gstRegistrationGreaterThan(0),
        ageOfAssetBetween(13, 23),
        ageOfAssetAtEndLessThan(13),
        hasDeposit(20),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        typeOfSale([DEALER_SALE]),
        // livingArrangements(["Property owner"]),
        // creditScoreGreaterThan(400)
      ],
    },
    {
      name: "New start",
      rate: 11.36,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 100000),
        timeInBusinessBetween(3, 24),
        gstRegistrationGreaterThan(0),
        ageOfAssetGreaterThan(23),
        ageOfAssetAtEndLessThan(13),
        hasDeposit(20),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        typeOfSale([DEALER_SALE]),
        // livingArrangements(["Property owner"]),
        // creditScoreGreaterThan(400)
      ],
    },

    {
      name: "Low doc",
      rate: 10.96,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 100000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(0),
        ageOfAssetLessThan(13),
        ageOfAssetAtEndLessThan(13),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        typeOfSale([DEALER_SALE]),
        // livingArrangements(["Property owner"]),
        // creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Low doc",
      rate: 11.26,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 100000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(0),
        ageOfAssetBetween(13, 23),
        ageOfAssetAtEndLessThan(13),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        typeOfSale([DEALER_SALE]),
        // livingArrangements(["Property owner"]),
        // creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Low doc",
      rate: 11.65,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 100000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(0),
        ageOfAssetGreaterThan(23),
        ageOfAssetAtEndLessThan(13),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        typeOfSale([DEALER_SALE]),
        // livingArrangements(["Property owner"]),
        // creditScoreGreaterThan(400)
      ],
    },

    {
      name: "Low doc",
      rate: 10.96,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 200000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(0),
        ageOfAssetLessThan(13),
        ageOfAssetAtEndLessThan(13),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        livingArrangements(["Property owner"]),
        typeOfSale([DEALER_SALE]),
        // creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Low doc",
      rate: 11.26,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 200000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(0),
        ageOfAssetBetween(13, 23),
        ageOfAssetAtEndLessThan(13),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        livingArrangements(["Property owner"]),
        typeOfSale([DEALER_SALE]),
        // creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Low doc",
      rate: 11.65,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 200000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(0),
        ageOfAssetGreaterThan(23),
        ageOfAssetAtEndLessThan(13),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        livingArrangements(["Property owner"]),
        typeOfSale([DEALER_SALE]),
        // creditScoreGreaterThan(400)
      ],
    },

    {
      name: "Full doc",
      rate: 10.96,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(3),
        gstRegistrationGreaterThan(0),
        ageOfAssetLessThan(13),
        ageOfAssetAtEndLessThan(13),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        typeOfSale([DEALER_SALE]),
        // livingArrangements(["Property owner"]),
        // creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Full doc",
      rate: 11.26,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(3),
        gstRegistrationGreaterThan(0),
        ageOfAssetBetween(13, 23),
        ageOfAssetAtEndLessThan(13),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        typeOfSale([DEALER_SALE]),
        // livingArrangements(["Property owner"]),
        // creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Full doc",
      rate: 11.65,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(3),
        gstRegistrationGreaterThan(0),
        ageOfAssetGreaterThan(23),
        ageOfAssetAtEndLessThan(13),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        typeOfSale([DEALER_SALE]),
        // livingArrangements(["Property owner"]),
        // creditScoreGreaterThan(400)
      ],
    },
  ],
};
