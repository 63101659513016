import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Grid, MenuItem, Select, Typography } from "@mui/material";
import { updateUserMember } from "src/store/slices/userSlice";
import { getAllUsers } from "src/store/slices/usersSlice";

export default function StatusCell({params, updateApplicationStatus}) {
  const dispatch = useDispatch();
  const [value, setValue] = useState(params.row.status)

  console.log({ params })

  useEffect(() => {
    if (params.row.status === "pendingApproval") return setValue("Pending")
    setValue(params.row.status)
  }, [params.row.status])

  const handleUserStatusUpdate = async event => {
    setValue(event.target.value)
    await dispatch(updateUserMember({
      userId: params.row.id,
      status: event.target.value
    })).unwrap()
    updateApplicationStatus({ _id: params.row.id, status: event.target.value })
  }

  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={value || ""}
      // label="Status"
      // placeholder="Status"
      // onClick={params.api.}
      onBlur={async event => {
        // setValue(event.target.value)
        // await dispatch(
        //   getAllUsers({
        //     userId: params.row.id,
        //     cursor: params.row.cursor
        //   })).unwrap()
        // setValue(params.row.status)
      }}
      onChange={handleUserStatusUpdate}
    >
      <MenuItem value="Pending">Pending</MenuItem>
      <MenuItem value="Approved">Approved</MenuItem>
      <MenuItem value="Declined">Declined</MenuItem>
      <MenuItem value="Suspended">Suspended</MenuItem>
    </Select>
  );
}
