import React, { useEffect, useState } from "react";
import { CardContent, Typography } from "@mui/material";
import { compareAsc, format } from "date-fns";
import { orderBy } from "lodash";
import Timeline from "@mui/lab/Timeline";
import ActivityLogSidebarGroup from "./ActivityLogSidebarGroup";

function createGroups(inputArray) {
  const orArr = [];
  let curArr = [];

  const newArr = [...inputArray].sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );
  let lastType = newArr?.[0]?.activityType;
  newArr.forEach((obj, index) => {
    const currentType = obj.activityType;
    if (currentType != lastType) {
      lastType = currentType;
      orArr.push([...curArr]);
      curArr = [];
    }
    curArr.push(obj);
    if (index + 1 == newArr.length) {
      orArr.push([...curArr]);
    }
  });

  return orArr;
}

const ActivityLogSidebar = React.memo(({ activityList, openActivity }) => {
  const [expanded, setExpanded] = useState(null);
  const [logGroups, setLogGroups] = useState([]);

  useEffect(() => {
    if (Array.isArray(activityList)) {
      setLogGroups(createGroups(activityList));
    }
  }, [activityList]);

  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  const FormattedDate = (date) => {
    const currentDate = new Date(date);
    const formattedDate = format(currentDate, "hh:mm a");
    return <p>{formattedDate}</p>;
  };

  return (
    <>
      <CardContent style={{overflow: ""}}>
        <Typography style={{ marginBottom: 10 }} fontWeight={600}>
          Activity Log
        </Typography>
        <Timeline sx={{ px: 0 }}>
          {logGroups.length > 0 &&
            logGroups.map((activity, index) => (
              <ActivityLogSidebarGroup
                key={"ActivityGroup" + index}
                logs={activity}
                maxLength={activity?.length}
                expanded={expanded}
                handleToggle={handleToggle}
              />
            ))}
        </Timeline>
      </CardContent>
    </>
  );
});

export default ActivityLogSidebar;

// <Stack spacing={1} divider={<Divider></Divider>}>
//   {orderBy(activityList, ["updatedAt"], ["asc"]).map((activity) => (
//     <>
//       <Typography style={{ fontSize: 12 }} fontWeight={600}>
//         {activity.createdAt === activity.updatedAt
//           ? "Created"
//           : "Updated"}{" "}
//         {activity.type
//           .replace(/([A-Z])/g, " $1")
//           // uppercase the first character
//           .replace(/^./, function (str) {
//             return str.toUpperCase();
//           })}
//       </Typography>
//       <Typography style={{ fontSize: 12 }} fontWeight={600}>
//         Date: {activity.updatedAt}
//       </Typography>
//       <Typography style={{ fontSize: 12 }}>
//         Data: {JSON.stringify(activity, null, 2)}
//       </Typography>
//     </>
//   ))}
// </Stack>
