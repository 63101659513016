import React from "react";

import {
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
} from "@mui/material";
import { sendDisclosure } from "src/store/slices/applicationFormSlice";
import { useDispatch } from "react-redux";

const ConnectiveSendDisclosure = ({
  productDetails,
  application,
  handleOpenDisclosureDialog,
  handleCloseDisclosureDialog,
  disclosureFields,
  setDisclosureFields,
  openDisclosureDialog,
}) => {
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <Dialog open={openDisclosureDialog} onClose={handleCloseDisclosureDialog}>
        <DialogTitle>Send Proposal Disclosure Statement</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please review and update the details below.
          </DialogContentText>
          <Stack direction="column">
            <TextField
              margin="dense"
              size="small"
              label="Lender Name"
              disabled
              variant="outlined"
              value={productDetails?.lender}
            />
            <TextField
              autoFocus
              margin="dense"
              size="small"
              label="Repayment Amount"
              variant="outlined"
              value={disclosureFields.repayments}
              onChange={(e) => {
                setDisclosureFields({
                  ...disclosureFields,
                  repayments: e.target.value,
                });
              }}
            />

            <TextField
              margin="dense"
              size="small"
              label="Interest Rate"
              variant="outlined"
              value={disclosureFields.rate}
              onChange={(e) => {
                setDisclosureFields({
                  ...disclosureFields,
                  rate: e.target.value,
                });
              }}
            />

            <TextField
              margin="dense"
              size="small"
              id="rate"
              label="Effective Rate"
              variant="outlined"
              value={disclosureFields.effectiveRate}
              onChange={(e) => {
                setDisclosureFields({
                  ...disclosureFields,
                  effectiveRate: e.target.value,
                });
              }}
            />
            <TextField
              margin="dense"
              size="small"
              label="Establsihment Fee"
              variant="outlined"
              value={disclosureFields.establsihmentFee}
              onChange={(e) => {
                setDisclosureFields({
                  ...disclosureFields,
                  establsihmentFee: e.target.value,
                });
              }}
            />

            <TextField
              margin="dense"
              size="small"
              label="Doc Fee"
              variant="outlined"
              value={disclosureFields.applicationFee}
              onChange={(e) => {
                setDisclosureFields({
                  ...disclosureFields,
                  applicationFee: e.target.value,
                });
              }}
            />
            <TextField
              margin="dense"
              size="small"
              label="Origination fee"
              variant="outlined"
              value={disclosureFields.originationFee}
              onChange={(e) => {
                setDisclosureFields({
                  ...disclosureFields,
                  originationFee: e.target.value,
                });
              }}
            />
            <TextField
              margin="dense"
              size="small"
              label="Referer Fee"
              variant="outlined"
              value={disclosureFields.referralFee}
              onChange={(e) => {
                setDisclosureFields({
                  ...disclosureFields,
                  referralFee: e.target.value,
                });
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDisclosureDialog}>Cancel</Button>
          <Button
            onClick={() => {
              handleCloseDisclosureDialog();
              dispatch(
                sendDisclosure({
                  ...disclosureFields,
                  ...{
                    applicationId: application._id,
                    lenderType: productDetails?.lender,
                  },
                })
              );
            }}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ConnectiveSendDisclosure;
