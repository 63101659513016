import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as Yup from "yup";
import regex from "src/utils/regex";
import {
  createFinanceItem,
  createIncome,
  deleteFinanceItem,
  deleteIncome,
  updateIncome,
  updateIncomeList,
} from "../../store/slices/applicationFormSlice";
import InputAdornment from "@mui/material/InputAdornment";
import _ from "lodash";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import FinanceItem from "./FinanceItem";
import { frequencyForIncomeList, incomeTypesList } from "src/constants";
import {
  NumericFormatCustom,
  currencyMaskedValue,
} from "src/utils/currencyMaskFormat";

export default function Income({ income, customerId, applicationId }) {
  const dispatch = useDispatch();

  const [newIncomeItemName, setNewIncomeItemName] = useState("");

  const [fieldErrors, setFieldErrors] = useState({
    income: "",
    amount: "",
  });

  const assetState = useSelector(({ assert }) => assert);
  const incomeList = assetState?.incomeListData || [];

  const validationSchema = Yup.object({
    // income: Yup.array().of(
    // Yup.object().shape({
    // income: Yup.string()
    //   .optional()
    //   .matches(
    //     regex.fanciableItemNameRegex,
    //     "Only letters, dashes (-), forward slashes (/), commas (,), and spaces are allowed."
    //   )
    //   .max(25, "Maximum of 25 characters"),
    amount: Yup.string()
      .optional()
      .matches(regex.allowOnlyNumber, "Only numbers allowed.")
      .max(10, "Maximum of 7 digits"),
    // })
    // ),
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };

  const onAddNewIncome = () => {
    const data = {
      incomeType: "",
      frequency: "",
      amount: "",
      application: applicationId,
      customer: customerId,
    };

    dispatch(createIncome(data));
  };

  const removeIncome = (Element) => {
    const data = {
      incomeId: Element._id,
      application: Element.application,
      customer: Element.customer,
    };
    delete data._id;

    dispatch(deleteIncome(data));
  };

  const handle = {
    onBlur: (name, value, newID) => {
      const temp = [...income];

      temp.map((ele) => {
        // if (!ele._id) {
        //   console.log("ele", ele);
        // }
        if (ele._id === newID) {
          let updatedAssetData = {
            ...ele,
            [name]: value,
            incomeId: newID,
            application: applicationId,
            customer: customerId,
          };
          delete updatedAssetData._id;

          dispatch(updateIncome(updatedAssetData));
          return updatedAssetData;
        } else {
          return ele;
        }
      });
    },
    onChange: (name, value, newID) => {
      const temp = [...income];

      const updateIncome = temp.map((ele) => {
        if (!ele._id) {
          const dataWithOutId = {
            ...ele,
            [name]: value,
            application: applicationId,
            customer: customerId,
          };
          dispatch(createIncome(dataWithOutId));
        }
        if (ele._id === newID) {
          const updatedIncomeData = {
            ...ele,
            [name]: value,
          };
          // validateField(name, value);
          return updatedIncomeData;
        } else {
          return ele;
        }
      });
      dispatch(updateIncomeList(updateIncome));
    },
    income: (update) => {
      dispatch(updateIncome(update));
    },
    deleteIncome: async (del_data) => {
      await dispatch(
        deleteFinanceItem({
          data: { incomeItemId: del_data._id, incomeId: income._id },
          endpoint: "income",
        })
      ).unwrap();
    },
    createIncomeItem: async () => {
      try {
        if (fieldErrors["income"] === "") {
          await dispatch(
            createFinanceItem({
              data: {
                name: _.camelCase(newIncomeItemName),
                label: newIncomeItemName,
                incomeId: income._id,
              },
              endpoint: "income",
            })
          ).unwrap();
          setNewIncomeItemName("");
        }
      } catch (error) {
        const newErrors = {};
        error.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
        setFieldErrors(newErrors);
      }
    },
  };

  return (
    <>
      <Typography
        variant="subtitle2"
        sx={{ fontWeight: "bold" }}
        style={{ marginBottom: "5px", marginTop: "20px" }}
      >
        Income
      </Typography>
      {income?.map((ele, index) => {
        return (
          <Grid
            container
            spacing={1}
            style={{ marginBottom: "20px" }}
            key={ele?._id}
          >
            <Grid container item xs={10} spacing={1}>
              <Grid item xs={3}>
                <FormControl fullWidth variant="filled">
                  <InputLabel id="years-at-label">Income type</InputLabel>
                  <Select
                    size="small"
                    labelId="years-at-label"
                    id="demo-simple-select"
                    value={ele?.incomeType}
                    label="Income type"
                    onChange={(event) => {
                      handle.onChange(
                        "incomeType",
                        event.target.value,
                        ele?._id
                      );
                    }}
                    onBlur={() =>
                      handle.onBlur("incomeType", ele?.incomeType, ele?._id)
                    }
                  >
                    {incomeTypesList.map((i) => (
                      <MenuItem value={i.value}>{i.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  // type="tel"
                  type="text"
                  inputProps={{ min: 0 }}
                  label="Income amount"
                  variant="filled"
                  style={{ width: "100%" }}
                  value={ele?.amount}
                  // error={`${fieldErrors?.amount}${ele?._id}`}
                  // helperText={`${fieldErrors?.amount}${ele?._id}`}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                  onChange={(event) =>
                    handle.onChange("amount", event.target.value, ele?._id)
                  }
                  onBlur={() =>
                    handle.onBlur(
                      "amount",
                      currencyMaskedValue(ele?.amount),
                      ele?._id
                    )
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth variant="filled">
                  <InputLabel id="years-at-label">Income frequency</InputLabel>
                  <Select
                    size="small"
                    labelId="years-at-label"
                    id="demo-simple-select"
                    value={ele?.frequency}
                    label="Income frequency"
                    onChange={(event) => {
                      handle.onChange(
                        "frequency",
                        event.target.value,
                        ele?._id
                      );
                    }}
                    onBlur={() =>
                      handle.onBlur("frequency", ele?.frequency, ele?._id)
                    }
                  >
                    {frequencyForIncomeList.map((i) => (
                      <MenuItem value={i.value}>{i.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {income.length !== 0 && index !== 0 && (
                <Grid item xs={1}>
                  <Button
                    onClick={() => removeIncome(ele)}
                    variant="outlined"
                    size="small"
                    style={{ marginTop: "10px" }}
                    // style={{ marginLeft: "5px" }}
                  >
                    Remove
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <div>
          <Button
            onClick={() => onAddNewIncome()}
            variant="outlined"
            size="small"
            disabled={
              !income[0]?.incomeType &&
              !income[0]?.frequency &&
              !income[0]?.amount
                ? true
                : false
            }
          >
            Add income
          </Button>
        </div>
      </Grid>
    </>
  );
}

//  <Grid
//   container
//   item
//   xs={3}
//   spacing={0}
//   style={{ alignContent: "flex-start" }}
// >
//   <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>
//     Income (monthly)
//   </Typography>

//   {/* <Grid item sm={12}> */}
//   <Stack spacing={1}>
//     {income?.items?.map((incomeItem) => (
//       <FinanceItem
//         item={incomeItem}
//         handleUpdate={()=> handle.income()}
//         handleDelete={()=> handle.deleteIncome(incomeItem)}
//       />
//     ))}

//     <TextField
//       id="outlined-basic"
//       type="text"
//       size="small"
//       name={income}
//       label="Add income"
//       variant="outlined"
//       onChange={async (event) => {
//         let isValid = true;
//         isValid = regex.fanciableItemNameRegex.test(event.target.value) && event.target.value?.length <= 25;
//         if (!isValid) {
//           await validateField('income', event.target.value);
//           return;
//         } else {
//           setNewIncomeItemName(event.target.value)
//           await validateField("income", event.target.value);
//         }
//       }}
//       value={newIncomeItemName}
//       error={fieldErrors?.income}
//       helperText={fieldErrors?.income}
//       placeholder="Income name"
//       InputProps={{
//         endAdornment: (
//           <InputAdornment position="end">
//             <IconButton
//               onClick={(event) => {
//                 if (newIncomeItemName) {
//                   handle.createIncomeItem(event);
//                 }
//               }}
//               style={{
//                 background: newIncomeItemName ? "#616161" : "none",
//                 color: newIncomeItemName ? "#fff" : "rgba(0, 0, 0, 0.6)",
//                 height: "30px",
//               }}
//               edge="end"
//             >
//               +
//             </IconButton>
//           </InputAdornment>
//         ),
//       }}
//     />
//   </Stack>
// </Grid>
