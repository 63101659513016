import { typeOfSaleList } from "src/constants";
import criteria from "src/utils/criteria";

const {
  livingArrangements,
  termIsLessThan,
  typeOfSale,
  hasDeposit,
  assetValueBetween,
  assetType,
  ageOfAssetGreaterThan,
  ageOfAssetLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  gstRegistrationGreaterThan,
  ageOfAssetAtEndLessThan,
  compound,
  creditScoreGreaterThan
} = criteria;


function propertyOwnerLiteFullDoc(l) {
  return l.map(product => {

    return {
      ...product,
      
      criteria: [
        ...product.criteria,
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Property owner"]),
      ]
    }
  })
}

function nonPropertyOwnerLiteFullDoc(l) {
  return l.map(product => {

    return {
      ...product,
      productType: "Light doc",
      criteria: [
        ...product.criteria,
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        hasDeposit(20),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
      ]
    }
  })
}

export default [
  ...propertyOwnerLiteFullDoc(baseLiteDoc()),
  ...nonPropertyOwnerLiteFullDoc(nonPropLiteDoc()),
  ...propertyOwnerLiteFullDoc(baseFullDoc()),
  ...nonPropertyOwnerLiteFullDoc(baseFullDoc()),
]

function baseLiteDoc() {
  return [
    {
      name: "Motor vehicle",
      rate: 7.99,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(4999, 250000),
        ageOfAssetAtEndLessThan(21),
        assetType([
          "Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)", "Classic car"
        ]),
        creditScoreGreaterThan(600)
      ],
    },

    {
      name: "Motor vehicle",
      rate: 9.99,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(4999, 250000),
        ageOfAssetAtEndLessThan(21),
        assetType([
          "Classic car"
        ]),
        creditScoreGreaterThan(600)
      ],
    },

    {
      name: "Primary asset",
      rate: 8.79,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(4999, 350000),
        ageOfAssetAtEndLessThan(21),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Trailers and Caravans",
          "Mini Buses & Route Buses (<25 seats)",
        ]),
        creditScoreGreaterThan(550)
      ],
    },

    {
      name: "Primary mover asset",
      rate: 8.99,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(4999, 350000),
        ageOfAssetAtEndLessThan(21),
        assetType([
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
        ]),
        creditScoreGreaterThan(550)
      ],
    },

    {
      name: "Secondary asset",
      rate: 11.39,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(4999, 175000),
        ageOfAssetAtEndLessThan(21),
        assetType([
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment"
        ]),
        creditScoreGreaterThan(600)
      ],
    },

    {
      name: "Tertiary asset",
      rate: 13.39,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(4999, 125000),
        ageOfAssetAtEndLessThan(21),
        assetType([
          "IT, Computer & Audio Equipment",
          "Office Equipment",
        ]),

        creditScoreGreaterThan(600)
      ],
    },
  ]
}

function nonPropLiteDoc() {
  return [
    {
      name: "Motor vehicle",
      rate: 7.99,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(4999, 250000),
        ageOfAssetAtEndLessThan(21),
        assetType([
          "Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)", "Classic car"
        ]),
        creditScoreGreaterThan(600)
      ],
    },

    {
      name: "Motor vehicle",
      rate: 9.99,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(4999, 250000),
        ageOfAssetAtEndLessThan(21),
        assetType([
          "Classic car"
        ]),
        creditScoreGreaterThan(600)
      ],
    },

    {
      name: "Primary asset",
      rate: 8.79,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(4999, 250000),
        ageOfAssetAtEndLessThan(21),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Trailers and Caravans",
          "Mini Buses & Route Buses (<25 seats)",
        ]),
        creditScoreGreaterThan(550)
      ],
    },

    {
      name: "Primary mover asset",
      rate: 8.99,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(4999, 250000),
        ageOfAssetAtEndLessThan(21),
        assetType([
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
        ]),
        creditScoreGreaterThan(550)
      ],
    },

    {
      name: "Secondary asset",
      rate: 11.39,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(4999, 175000),
        ageOfAssetAtEndLessThan(21),
        assetType([
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment"
        ]),
        creditScoreGreaterThan(600)
      ],
    },

    {
      name: "Tertiary asset",
      rate: 13.39,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(4999, 125000),
        ageOfAssetAtEndLessThan(21),
        assetType([
          "IT, Computer & Audio Equipment",
          "Office Equipment",
        ]),

        creditScoreGreaterThan(600)
      ],
    },
  ]
}

function baseFullDoc() {
  return [
    {
      name: "Motor vehicle",
      rate: 7.99,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(4999, 450000),
        ageOfAssetAtEndLessThan(21),
        assetType([
          "Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)", "Classic car"
        ]),
        creditScoreGreaterThan(600)
      ],
    },

    {
      name: "Motor vehicle",
      rate: 9.99,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(4999, 450000),
        ageOfAssetAtEndLessThan(21),
        assetType([
          "Classic car"
        ]),
        creditScoreGreaterThan(600)
      ],
    },

    {
      name: "Primary asset",
      productType: "Full doc",
      rate: 8.79,
      criteria: [
        loanAmountBetween(4999, 450000),
        ageOfAssetAtEndLessThan(21),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Trailers and Caravans",
          "Mini Buses & Route Buses (<25 seats)",
        ]),
        creditScoreGreaterThan(550)
      ],
    },

    {
      name: "Prime mover asset",
      productType: "Full doc",
      rate: 8.99,
      criteria: [
        loanAmountBetween(4999, 450000),
        ageOfAssetAtEndLessThan(21),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Trailers and Caravans",
          "Mini Buses & Route Buses (<25 seats)",
        ]),
        creditScoreGreaterThan(550)
      ],
    },

    {
      name: "Secondary asset",
      productType: "Full doc",
      rate: 11.39,
      criteria: [
        loanAmountBetween(4999, 450000),
        ageOfAssetAtEndLessThan(21),
        assetType([
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment"
        ]),
        creditScoreGreaterThan(600)
      ],
    },

    {
      name: "Tertiary asset",
      productType: "Full doc",
      rate: 13.39,
      criteria: [
        loanAmountBetween(4999, 450000),
        ageOfAssetAtEndLessThan(21),
        assetType([
          "IT, Computer & Audio Equipment",
          "Office Equipment",
        ]),

        creditScoreGreaterThan(600)
      ],
    },
  ]
}