const checkAlphabetRestrict = (evt, name) => {
  let checkValid =
    evt.charCode === 0 ||
    (evt.charCode >= 48 && evt.charCode <= 57) ||
    (evt.charCode == 46 &&
      document.getElementById(name).value.indexOf(".") < 0);
  if (!checkValid) {
    evt.preventDefault();
    return false;
  }
  return checkValid;
};

export default checkAlphabetRestrict;
