import _ from "lodash";
import { Grid, Typography, Box, Divider } from "@mui/material";

import SettingsSidebar from "src/layouts/SettingsSidebar";
import Lenders from "./lenders/Lender";
import { useParams } from "react-router-dom";
import Profile from "./profile/UserDetails";
import UserDetails from "./profile/UserDetails";

export default function UserProfile(params) {
  const { path } = useParams();

  return (
    <>
      <Grid
        container
        item
        height="100%"
        width="100%"
        spacing={3}
        // style={{ padding: "30px 50px 100px" }}
        // justifyContent="center"
        // alignItems="center"
        direction="row"
      >
        <Grid
          container
          item
          xs={12}
          sm={3}
          md={2}
          lg={2}
          width="100%"
          // sm={10}
          // md={10}
          // xl={8}
          // height="100vh"
          justifyContent="start"
          alignItems="start"
          direction="column"
          // textAlign="center"
          // style={{ marginTop: "64px" }}
        >
          <SettingsSidebar {...{ path }} />
        </Grid>
        {path === "general" && (
          <Grid
            container
            item
            xs={12}
            sm={9}
            md={9}
            lg={8}
            width="100%"
            // sm={10}
            // md={10}
            // xl={8}
            // height="100vh"
            justifyContent="start"
            alignItems="start"
            direction="column"
            // textAlign="center"
            // style={{ marginTop: "64px" }}
          >
            <UserDetails />
          </Grid>
        )}

        <Grid
          container
          item
          xs={12}
          sm={9}
          md={9}
          lg={8}
          width="100%"
          // sm={10}
          // md={10}
          // xl={8}
          // height="100vh"
          justifyContent="start"
          alignItems="start"
          direction="column"
          // textAlign="center"
          // style={{ marginTop: "64px" }}
        >
          {path === "lenders" && <Lenders />}
        </Grid>
      </Grid>
    </>
  );
}
