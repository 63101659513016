import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {  MenuItem, Select } from "@mui/material";
import { updateApplication } from "src/store/slices/applicationFormSlice";

export default function StatusCell({params, updateApplicationStatus}) {

  const dispatch = useDispatch();
  const [value, setValue] = useState(params.row.status)

  useEffect(() => { setValue(params.row.status) }, [params.row.status])

  const handleStatusUpdate = async event => {
    setValue(event.target.value)
    await dispatch(updateApplication({ _id: params.row.id, status: event.target.value })).unwrap()
    updateApplicationStatus({ _id: params.row.id, status: event.target.value })
  }

  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={value}
      onChange={handleStatusUpdate}
    >
      <MenuItem value="Workshop">Workshop</MenuItem>
      <MenuItem value="Application Completed Pending Info">Application Completed Pending Info</MenuItem>
      <MenuItem value="Lodge Pending Decision">Lodge Pending Decision</MenuItem>
      <MenuItem value="Approved In Progress">Approved In Progress</MenuItem>
      <MenuItem value="Docs Issued">Docs Issued</MenuItem>
      <MenuItem value="Settlement Pending Info">Settlement Pending Info</MenuItem>
      <MenuItem value="Settlement Complete">Settlement Complete</MenuItem>
    </Select >
  );
}
