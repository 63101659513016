import criteria from "src/utils/criteria";

const {
  livingArrangements,
  termIsGreaterThan,
  typeOfSale,
  hasDeposit,
  assetValueBetween,
  assetType,
  ageOfAssetGreaterThan,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessLessThan,
  timeInBusinessGreaterThan,
  gstRegistrationGreaterThan,
  compound,
  creditScoreGreaterThan,
  noDeposit,
} = criteria;

export default {
  date: "30 June 2023",
  lender: "ScotPac",
  type: "commercial",
  logoURL:
    "https://assets.financeable.com.au/lender-logos%2Fscotpac-square.gif",
  actions: ["upload"],
  lenderCredentials: [
    {
      label: "Email",
      attribute: "email",
    },
    {
      label: "User Name",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
    {
      label: "Merchant ID",
      attribute: "organisationId",
    },
    {
      label: "User ID",
      attribute: "brokerId",
    },
    {
      label: "Api Key",
      attribute: "apiKey",
    },
  ],
  requiredFields: {
    entity: [
      "abn",
      // "addresses[0].streetAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
    ],
    customer: [
      "phoneNumbers[0].number",
      "emails[0].address",
      "firstName",
      "lastName",
      "dateOfBirth",
      // "addresses[0].streetAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "IDNumber",
      "IDCardNumber",
      // "emails[0].address",
      // "phoneNumbers[0].number",
    ],
    loanDetails: ["rate"],
    asset: [
      "assetType",
      "typeOfSale",
      "ageOfAsset",
      "glassesMake",
      "glassesModel",
      "glassesVariant",
      "assetValue",
    ],
  },
  email: "afapps@scotpac.com.au",
  fees: [
    {
      name: "Authorised supplier fee",
      value: 600,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale("Private sale")],
    },
  ],
  brokerage: [
    {
      value: 4,
      max: 4,
      valueType: "percentage",
      criteria: [assetValueBetween(0, 1000000)],
    },
  ],
  loading: [
    // {
    //   name: "Non property owner",
    //   value: 2,
    //   valueType: "percentage",
    //   criteria: [livingArrangements(["Renting", "Boarding", "Living w/ parents"]),],
    // },
    {
      name: "Private sale primary asset",
      value: 1,
      valueType: "percentage",
      criteria: [
        typeOfSale("Private sale"),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Trailers and Caravans",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
        ]),
      ],
    },
    {
      name: "Private sale secondary asset",
      value: 2,
      valueType: "percentage",
      criteria: [
        typeOfSale("Private sale"),
        assetType([
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Solar",
        ]),
      ],
    },
    // {
    //   name: "Term >48",
    //   value: 0.20,
    //   valueType: "percentage",
    //   criteria: [termIsGreaterThan(48)],
    // },
    {
      name: "Time in business <24 months",
      value: 2,
      valueType: "percentage",
      criteria: [timeInBusinessLessThan(24)],
    },
    {
      name: "Asset age >10 years",
      value: 2,
      valueType: "percentage",
      criteria: [ageOfAssetGreaterThan(10)],
    },
  ],
  info: [
    "Loans under 30,000 require rates to be approved by credit",
    "ATO debts accepted for full doc submission",
  ],
  productTiers: [
    {
      name: "Fast doc",
      rate: 10.75,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(30000, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(21),
        livingArrangements(["Property owner"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Trailers and Caravans",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
        ]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Fast doc",
      rate: 10.75,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(30000, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(21),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(10),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Trailers and Caravans",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
        ]),
        creditScoreGreaterThan(622),
      ],
    },
    {
      name: "Fast doc",
      rate: 10.75,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(30000, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(21),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        noDeposit(0),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Trailers and Caravans",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
        ]),
        creditScoreGreaterThan(750),
      ],
    },

    {
      name: "Fast doc",
      rate: 12.35,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(30000, 75000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(21),
        livingArrangements(["Property owner"]),
        assetType([
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
        ]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Fast doc",
      rate: 12.35,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(30000, 75000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(21),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(10),
        assetType([
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
        ]),
        creditScoreGreaterThan(622),
      ],
    },
    {
      name: "Fast doc",
      rate: 12.35,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(30000, 75000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(21),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        noDeposit(0),
        assetType([
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
        ]),
        creditScoreGreaterThan(750),
      ],
    },

    {
      name: "Fast doc",
      rate: 12.35,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(30000, 75000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(11),
        livingArrangements(["Property owner"]),
        assetType([
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Solar",
        ]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Fast doc",
      rate: 12.35,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(30000, 75000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(11),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(10),
        assetType([
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Solar",
        ]),
        creditScoreGreaterThan(622),
      ],
    },
    {
      name: "Fast doc",
      rate: 12.35,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(30000, 75000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(11),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        noDeposit(0),
        assetType([
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Solar",
        ]),
        creditScoreGreaterThan(750),
      ],
    },

    {
      name: "Full doc",
      rate: 10.75,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(30000, 1000000),
        timeInBusinessGreaterThan(11),
        gstRegistrationGreaterThan(11),
        ageOfAssetAtEndLessThan(26),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Trailers and Caravans",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
        ]),
        livingArrangements(["Property owner"]),
        creditScoreGreaterThan(550),
      ],
    },

    {
      name: "Full doc",
      rate: 12.35,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(30000, 1000000),
        timeInBusinessGreaterThan(11),
        gstRegistrationGreaterThan(11),
        ageOfAssetAtEndLessThan(11),
        assetType([
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Solar",
        ]),
        livingArrangements(["Property owner"]),
        creditScoreGreaterThan(550),
      ],
    },

    {
      name: "Full doc",
      rate: 12.35,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(30000, 1000000),
        timeInBusinessGreaterThan(11),
        gstRegistrationGreaterThan(11),
        assetType([
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
        ]),
        livingArrangements(["Property owner"]),
        creditScoreGreaterThan(550),
      ],
    },
  ],
};
