import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import BusinessTwoToneIcon from "@mui/icons-material/BusinessTwoTone";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import { ERRORS_MESSAGE } from "../../constants";
import {
  applicationSelector,
  createEntity,
  entitySelector,
  saveValue,
  userDetailsSelector,
} from "../../store/slices/applicationFormSlice";
import { getBusinessByABN, getBusinessByName } from "../../services/abr";
import { isDigitsOnly } from "../../utils/isDigitsOnly";
import AutoSearchHOC from "../HOC/AutoSearchHOC";

const AddedEntity = React.memo(({ fieldsErrors, setFieldsErrors }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const entity = useSelector(entitySelector);
  const application = useSelector(applicationSelector);
  const { gettingEntityDetails } = useSelector(userDetailsSelector);

  const [businessSearchValue, setBusinessSearchValue] = useState({
    searchValueBusiness: "",
    businessSearchInputText: "",
  });
  const [loadingBusinessSearch, setLoadingBusinessSearch] = useState("");
  const [entityOptions, setEntityOptions] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({
    business_search: "",
    turnoverValue: "",
    industryType: "",
  });

  useEffect(() => {
    debounced(businessSearchValue?.businessSearchInputText);
    return () => debounced.cancel();
  }, [businessSearchValue?.searchValueBusiness, businessSearchValue?.businessSearchInputText]);

  useEffect(() => {
    if (!gettingEntityDetails) {
      setBusinessSearchValue({
        searchValueBusiness: "",
        businessSearchInputText: "",
      });
    }
  }, [gettingEntityDetails]);

  const handle = {
    entityDetailsFn: async (details) => {
      dispatch(saveValue({ gettingEntityDetails: true }));
      setFieldErrors({ business_search: "" });
      const abrData = await getBusinessByABN(details.Abn);

      if (abrData.EntityName !== entity?.entityName) {
        dispatch(createEntity({ abrData, applicationId: application._id })).then((res) => {
          if (res?.payload?.data?.data) {
            // enqueueSnackbar(SUCCESS_MESSAGE.applicationCreateSuccessMsg, {
            //   variant: "success",
            //   autoHideDuration: 5000,
            // });
          } else {
            enqueueSnackbar(res?.payload?.status_message || ERRORS_MESSAGE.fetch_error_msg, {
              variant: "error",
              autoHideDuration: 5000,
            });
          }
        });
        setFieldErrors({
          business_search: "",
          turnoverValue: "",
          industryType: "",
        });
      } else {
        dispatch(saveValue({ gettingEntityDetails: false }));
      }
    },
  };

  const debounced = debounce(async (inputValue) => {
    if (inputValue) {
      setLoadingBusinessSearch(true);
      if (isDigitsOnly.test(inputValue)) {
        const businessByABN = await getBusinessByABN(inputValue);
        setLoadingBusinessSearch(false);
        if (businessByABN) {
          setEntityOptions([businessByABN]);
          return;
        }
      }

      const businesses = await getBusinessByName(inputValue);
      setLoadingBusinessSearch(false);
      if (businesses?.Names?.length > 0) {
        setEntityOptions(businesses.Names);
      } else {
        setEntityOptions([
          {
            Name: "No results found",
          },
        ]);
      }
    }
  }, 500);

  return (
    <Grid
      container
      style={{
        margin: "0 0 30px",
        padding: "0 0 20px",
        borderBottom: "1px solid rgba(0,0,0,0.12)",
      }}
    >
      <Grid container xs={12} sm={12} md={2}>
        <BusinessTwoToneIcon style={{ marginRight: "10px" }} />
        <Typography>{"Add Entity"}</Typography>
      </Grid>

      <Grid
        container
        xs={12}
        sm={12}
        md={10}
        style={{
          padding: "0 0 0 10px",
        }}
      >
        <Grid item lg={9} md={12} sm={12}>
          <Typography
            fontWeight={600}
            // variant="subtitle2"
            // sx={{ fontWeight: "bold" }}
            style={{ marginBottom: "5px" }}
          >
            Add another entity
          </Typography>
          <AutoSearchHOC
            setBusinessSearchValue={setBusinessSearchValue}
            setEntityOptions={setEntityOptions}
            application={application?.entities?.length}
            loadingBusinessSearch={loadingBusinessSearch}
            entityOptions={entityOptions}
            updatedSearch={handle.entityDetailsFn}
            businessSearchValue={businessSearchValue}
            fieldErrors={fieldErrors}
            setFieldErrors={setFieldErrors}
          />
          {fieldsErrors && application?.entities?.length === 0 && (
            <Typography
              style={{
                marginTop: "5px",
                marginLeft: "14px",
                color: "#d32f2f",
                fontSize: "0.75rem",
                fontWeight: "400",
              }}
            >
              Please select entity
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
});

export default AddedEntity;
