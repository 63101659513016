import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import Assets from "./Assets";
import Liabilities from "./Liabilities";
import Income from "./Income";
import Expenses from "./Expenses";
import { useDispatch } from "react-redux";
import { clearUpFinances } from "src/store/slices/applicationFormSlice";

const CustomerPersonalFinances = React.memo(
  ({ customer, applicationType, applicationId }) => {
    const dispatch = useDispatch();

    const handleCleanUpField = () => {
      dispatch(clearUpFinances());
    };

    return (
      <React.Fragment>
        <Grid
          container
          item
          xs={12}
          // spacing={3}
          style={{
            margin: "0 0 30px 0",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            paddingBottom: "30px",
          }}
        >
          <Grid item md={12} sm={12}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography fontWeight={600}>Personal Finances</Typography>
              {/* <Button onClick={handleCleanUpField} variant="outlined">
              Remove all field data
            </Button> */}
            </div>
          </Grid>

          <Grid container item xs={12}>
            <Assets
              assets={customer.customerAssets}
              customerId={customer._id}
              applicationId={applicationId}
            />
            <Liabilities
              liabilities={customer.liabilities}
              customerId={customer._id}
              applicationId={applicationId}
            />

            {(applicationType === "consumer" ||
              applicationType === "personal") && (
                          <React.Fragment>
              <Income
                income={customer.income}
                customerId={customer._id}
                applicationId={applicationId}
              />

              <Expenses
                expenses={customer.expenses}
                customerId={customer._id}
                applicationId={applicationId}
              />
            </React.Fragment>
)}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
);

export default CustomerPersonalFinances;
