import criteria from "src/utils/criteria";

const {
  livingArrangements,
  noDeposit,
  hasDeposit,
  assetValueBetween,
  assetType,
  ageOfAssetGreaterThan,
  ageOfAssetLessThan,
  ageOfAssetBetween,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  gstRegistrationGreaterThan,
  creditScoreGreaterThan,
} = criteria;

export default {
  date: "01st Feb 2024",
  lender: "Macquarie",
  type: "commercial",
  logoURL:
    "https://assets.financeable.com.au/lender-logos%2Fmacquarie_logo.gif",
  actions: [],
  lenderCredentials: [
    {
      label: "Email",
      attribute: "email",
    },
    {
      label: "User Name",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
    {
      label: "Merchant ID",
      attribute: "organisationId",
    },
    {
      label: "User ID",
      attribute: "brokerId",
    },
    {
      label: "Api Key",
      attribute: "apiKey",
    },
  ],
  requiredFields: {
    entity: [
      "abn",
      // "addresses[0].streetAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
    ],
    customer: [
      "phoneNumbers[0].number",
      "emails[0].address",
      "firstName",
      "lastName",
      "dateOfBirth",
      // "addresses[0].streetAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "IDNumber",
      "IDCardNumber",
      // "emails[0].address",
      // "phoneNumbers[0].number",
    ],
    loanDetails: ["rate"],
    asset: [
      "assetType",
      "typeOfSale",
      "ageOfAsset",
      "glassesMake",
      "glassesModel",
      "glassesVariant",
      "assetValue",
    ],
  },
  fees: [
    {
      name: "Document fee",
      value: 350,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Montly fee",
      value: 8.25,
      capitalised: false,
      frequency: "monthly",
    },
  ],
  brokerage: [
    {
      value: 3,
      max: 3,
      valueType: "percentage",
      criteria: [assetValueBetween(10000, 250000)],
    },
  ],
  loading: [],
  info: [
    `Credit reference from existing loan or home loan statement required. NIL adverse on credit file.`,
    `130% max LVR (Private Sales 110%)`,
    `$100,000 Maximum net amount financed within a 6 month period of the settlement date (or approval date if not settled)`,
    `$200,000 max NAF (Existing customer with current account including non property owner) to be eligible: 
      Must have a minimum of 24 months history, with gaps of no more than 6 months 
      between contracts and; Must have current exposure or have finalised a contract no more than 6 months prior to 
      submission `,
    `$250,000 max NAF (Luxury motor vehicles - new/demo Ferrari, Maserati, McLaren, Porsche, Bentley, 
      Lamborghini, Tesla Motor Vehicles and Mercedes)`,
    `Maximum exposure $500,000`,
  ],
  productTiers: [
    {
      name: "Express",
      rate: 6.55,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 100000),
        timeInBusinessGreaterThan(11),
        gstRegistrationGreaterThan(11),
        ageOfAssetLessThan(12),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Property owner"]),
        hasDeposit(20),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Express",
      rate: 7.25,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 100000),
        timeInBusinessGreaterThan(11),
        gstRegistrationGreaterThan(11),
        ageOfAssetBetween(11, 37),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Property owner"]),
        hasDeposit(20),
        creditScoreGreaterThan(500),
      ],
    },
    // {
    //   name: "Express",
    //   rate: 8.5,
    //   productType: "Low doc",
    //   criteria: [
    //     loanAmountBetween(10000, 100000),
    //     timeInBusinessGreaterThan(11),
    //     gstRegistrationGreaterThan(11),
    //     ageOfAssetGreaterThan(36),
    //     assetType([
    //       "Motor Vehicle (<4.5 tonnes)",
    //       "Electric Motor Vehicle (<4.5 tonnes)",
    //     ]),
    //     livingArrangements(["Property owner"]),
    //     hasDeposit(20),
    //     ageOfAssetAtEndLessThan(13),
    //     creditScoreGreaterThan(500),
    //   ],
    // },

    // {
    //   name: "Express",
    //   rate: 8.2,
    //   productType: "Low doc",
    //   criteria: [
    //     loanAmountBetween(10000, 100000),
    //     timeInBusinessGreaterThan(11),
    //     gstRegistrationGreaterThan(11),
    //     ageOfAssetLessThan(12),
    //     assetType([
    //       "Motor Vehicle (<4.5 tonnes)",
    //       "Electric Motor Vehicle (<4.5 tonnes)",
    //     ]),
    //     ageOfAssetAtEndLessThan(13),
    //     livingArrangements(["Property owner"]),
    //     hasDeposit(10),
    //     creditScoreGreaterThan(500),
    //   ],
    // },
    // {
    //   name: "Express",
    //   rate: 8.75,
    //   productType: "Low doc",
    //   criteria: [
    //     loanAmountBetween(10000, 100000),
    //     timeInBusinessGreaterThan(11),
    //     gstRegistrationGreaterThan(11),
    //     ageOfAssetBetween(11, 37),
    //     assetType([
    //       "Motor Vehicle (<4.5 tonnes)",
    //       "Electric Motor Vehicle (<4.5 tonnes)",
    //     ]),
    //     ageOfAssetAtEndLessThan(13),
    //     livingArrangements(["Property owner"]),
    //     hasDeposit(10),
    //     creditScoreGreaterThan(500),
    //   ],
    // },
    // {
    //   name: "Express",
    //   rate: 9.05,
    //   productType: "Low doc",
    //   criteria: [
    //     loanAmountBetween(10000, 100000),
    //     timeInBusinessGreaterThan(11),
    //     gstRegistrationGreaterThan(11),
    //     ageOfAssetGreaterThan(36),
    //     assetType([
    //       "Motor Vehicle (<4.5 tonnes)",
    //       "Electric Motor Vehicle (<4.5 tonnes)",
    //     ]),
    //     livingArrangements(["Property owner"]),
    //     hasDeposit(10),
    //     ageOfAssetAtEndLessThan(13),
    //     creditScoreGreaterThan(500),
    //   ],
    // },
    {
      name: "Express",
      rate: 6.85,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 100000),
        timeInBusinessGreaterThan(11),
        gstRegistrationGreaterThan(11),
        ageOfAssetLessThan(12),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Property owner"]),
        noDeposit(0),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Express",
      rate: 7.55,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 100000),
        timeInBusinessGreaterThan(11),
        gstRegistrationGreaterThan(11),
        ageOfAssetBetween(11, 37),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Property owner"]),
        noDeposit(0),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Express",
      rate: 9.35,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 100000),
        timeInBusinessGreaterThan(11),
        gstRegistrationGreaterThan(11),
        ageOfAssetGreaterThan(36),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Property owner"]),
        noDeposit(0),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Express",
      rate: 7.40,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(10000, 100000),
        timeInBusinessGreaterThan(11),
        gstRegistrationGreaterThan(11),
        ageOfAssetLessThan(12),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Express",
      rate: 8.10,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(10000, 100000),
        timeInBusinessGreaterThan(11),
        gstRegistrationGreaterThan(11),
        ageOfAssetBetween(11, 37),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        creditScoreGreaterThan(500),
      ],
    },
    // {
    //   name: "Express",
    //   rate: 9.8,
    //   productType: "Full doc",
    //   criteria: [
    //     loanAmountBetween(10000, 100000),
    //     timeInBusinessGreaterThan(11),
    //     gstRegistrationGreaterThan(11),
    //     ageOfAssetGreaterThan(36),
    //     assetType([
    //       "Motor Vehicle (<4.5 tonnes)",
    //       "Electric Motor Vehicle (<4.5 tonnes)",
    //     ]),
    //     ageOfAssetAtEndLessThan(13),
    //     livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
    //     hasDeposit(20),
    //     creditScoreGreaterThan(500),
    //   ],
    // },

    // {
    //   name: "Express",
    //   rate: 8.9,
    //   productType: "Full doc",
    //   criteria: [
    //     loanAmountBetween(10000, 100000),
    //     timeInBusinessGreaterThan(11),
    //     gstRegistrationGreaterThan(11),
    //     ageOfAssetLessThan(12),
    //     assetType([
    //       "Motor Vehicle (<4.5 tonnes)",
    //       "Electric Motor Vehicle (<4.5 tonnes)",
    //     ]),
    //     ageOfAssetAtEndLessThan(13),
    //     livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
    //     hasDeposit(10),
    //     creditScoreGreaterThan(500),
    //   ],
    // },
    // {
    //   name: "Express",
    //   rate: 9.85,
    //   productType: "Full doc",
    //   criteria: [
    //     loanAmountBetween(10000, 100000),
    //     timeInBusinessGreaterThan(11),
    //     gstRegistrationGreaterThan(11),
    //     ageOfAssetBetween(11, 37),
    //     assetType([
    //       "Motor Vehicle (<4.5 tonnes)",
    //       "Electric Motor Vehicle (<4.5 tonnes)",
    //     ]),
    //     ageOfAssetAtEndLessThan(13),
    //     livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
    //     hasDeposit(10),
    //     creditScoreGreaterThan(500),
    //   ],
    // },
    // {
    //   name: "Express",
    //   rate: 10.4,
    //   productType: "Full doc",
    //   criteria: [
    //     loanAmountBetween(10000, 100000),
    //     timeInBusinessGreaterThan(11),
    //     gstRegistrationGreaterThan(11),
    //     ageOfAssetGreaterThan(36),
    //     assetType([
    //       "Motor Vehicle (<4.5 tonnes)",
    //       "Electric Motor Vehicle (<4.5 tonnes)",
    //     ]),
    //     ageOfAssetAtEndLessThan(13),
    //     livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
    //     hasDeposit(10),
    //     creditScoreGreaterThan(500),
    //   ],
    // },

    {
      name: "Express",
      rate: 7.85,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(10000, 100000),
        timeInBusinessGreaterThan(11),
        gstRegistrationGreaterThan(11),
        ageOfAssetLessThan(12),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        noDeposit(0),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Express",
      rate: 8.55,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(10000, 100000),
        timeInBusinessGreaterThan(11),
        gstRegistrationGreaterThan(11),
        ageOfAssetBetween(11, 37),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        noDeposit(0),
        creditScoreGreaterThan(500),
      ],
    },
    // {
    //   name: "Express",
    //   rate: 11.15,
    //   productType: "Full doc",
    //   criteria: [
    //     loanAmountBetween(10000, 100000),
    //     timeInBusinessGreaterThan(11),
    //     gstRegistrationGreaterThan(11),
    //     ageOfAssetGreaterThan(36),
    //     assetType([
    //       "Motor Vehicle (<4.5 tonnes)",
    //       "Electric Motor Vehicle (<4.5 tonnes)",
    //     ]),
    //     ageOfAssetAtEndLessThan(13),
    //     livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
    //     noDeposit(0),
    //     creditScoreGreaterThan(500),
    //   ],
    // },
    {
      name: "Express",
      rate: 8.2,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 200000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetLessThan(12),
        assetType(["Commercial (Small to Medium) Vehicles (<12 Tonnes)"]),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Property owner"]),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Express",
      rate: 8.75,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 200000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetBetween(11, 37),
        assetType(["Commercial (Small to Medium) Vehicles (<12 Tonnes)"]),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Property owner"]),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Express",
      rate: 9.05,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 200000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetGreaterThan(36),
        assetType(["Commercial (Small to Medium) Vehicles (<12 Tonnes)"]),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Property owner"]),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Express",
      rate: 8.90,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(10000, 200000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetLessThan(12),
        assetType(["Commercial (Small to Medium) Vehicles (<12 Tonnes)"]),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Express",
      rate: 9.85,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(10000, 200000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetBetween(11, 37),
        assetType(["Commercial (Small to Medium) Vehicles (<12 Tonnes)"]),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Express",
      rate: 10.4,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(10000, 200000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetGreaterThan(36),
        assetType(["Commercial (Small to Medium) Vehicles (<12 Tonnes)"]),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        creditScoreGreaterThan(500),
      ],
    },
  ],
};
