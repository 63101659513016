import React, { useEffect, useState, useMemo } from "react";
import { debounce, get, isEqual, merge, orderBy, uniqWith } from "lodash";
import { Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import {
  applicationSelector,
  assetSelector,
  loanDetailsSelector,
  userDetailsSelector,
  entitySelector,
  customersSelector,
  saveValue as saveApplicationValue,
  getCurrentProductLenderId,
} from "src/store/slices/applicationFormSlice";

import commercialProducts from "src/products/commercial";
import consumerProducts from "src/products/consumer";

import calculateRepayments from "src/utils/calculateRepayments";
import {
  evaluateCriteria,
  calculateLoading,
  calculateFees,
  calculateExtras,
} from "src/utils/evaluateCriteria";
import getMonthlyRate from "src/utils/getMonthlyRate";

import ProductSelectorFilterBy from "./ProductSelectorFilterBy";
import Product from "./Product";
import {
  consumerLenderList,
  commercialLenderList,
  productTypes,
  productLenderFilterKey,
  APPLICATION_TYPES,
  REACT_APP_ALLOWED_LENDER_LIST,
} from "src/constants";
import ProductSelectorOrderBy from "./ProductSelectorOrderBy";

import calculateComparisonRate from "src/utils/calculateComparisonRate";
import { userSelector } from "src/store/slices/userSlice";
import { saveValue } from "src/store/slices/applicationFormSlice";
import CustomSnackBar from "../customComponents/CustomSnackBar";
import VerifyDialog from "../customComponents/VerifyDialog";
import { getLocalStorage, setLocalStorage } from "src/utils/localstorage";
import { glassesAssetsSearchableList } from "../Asset";
import calculateEffectiveRate from "src/utils/calculateEffectiveRate";

const ProductSelector = React.memo(({ onShowRequired, lenderData }) => {
  const dispatch = useDispatch();

  const [fitFilterValue, setFitFilterValue] = useState("3 criteria off");

  const { open, verifiedDialogBoxIsOpen } = useSelector(userDetailsSelector);
  const application = useSelector(applicationSelector);
  const isCommercial = application.applicationType === "commercial";
  const asset = useSelector(assetSelector);
  const loanDetails = useSelector(loanDetailsSelector);
  const entity = useSelector(entitySelector);
  const customers = useSelector(customersSelector);
  const user = useSelector(userSelector);
  const introducerDeclarationAgree = application?.introducerDeclarationAgree;
  const criteriaFilterListDefault = isCommercial
    ? [
      "ageOfAsset",
      "ageOfAssetAtEnd",
      "assetType",
      "deposit",
      "gst",
      "livingArrangements",
      "loanAmount",
      "score",
      "timeInBusiness",
      "typeOfSale",
    ]
    : [
      "ageOfAsset",
      "ageOfAssetAtEnd",
      "assetType",
      "dateOfBirth",
      "deposit",
      "employmentType",
      "livingArrangements",
      "loanAmount",
      "score",
      "timeOfEmployment",
      "typeOfSale",
    ];

  const [criteriaFilterValue, setCriteriaFilterValue] = useState([]);
  const [criteriaFilterList, setCriteriaFilterList] = useState(
    criteriaFilterListDefault
  );

  const products = structuredClone(lenderData?.data?.getAllLendersData?.docs ?? []);
  const lenderList = structuredClone(lenderData?.data?.getAllLendersData?.docs ?? []).map((lender) => {
    const { lender: lenderName } = lender;
    return lenderName;
  });
  const filteredProducts = products.flat().filter((product) => REACT_APP_ALLOWED_LENDER_LIST.includes(product.lender));
  const filteredLenderList = lenderList.flat().filter((lender) => REACT_APP_ALLOWED_LENDER_LIST.includes(lender));
  // const products = setProducts(application.applicationType);
  // const lenderList = setLenders(application.applicationType);

  const {
    assetValue = 0,
    ageOfAsset = "",
    assetType = "",
    typeOfSale,
    make,
    model,
    glassesMake,
    glassesModel,
    glassesVariant,
  } = useSelector(assetSelector);

  const {
    balloon = 0,
    term = 0,
    tradeIn = 0,
    payout = 0,
    deposit = 0,
    brokerage = 0,
    rateAdjustment = 0,
    purpose,
    loanAmount: loanAmountSaved,
  } = useSelector(loanDetailsSelector);

  const [openReferModal, setOpenReferModal] = useState(false);

  const [productsMap, setProductsMap] = useState([]);
  const [orderByParameters, setOrderByParameters] = useState([]);
  const [orderByParametersList, setOrderByParametersList] = useState([]);
  // const [orderByParametersList, setOrderByParametersList] = useState([
  //   ["fit", "rate"],
  //   ["asc", "asc"],
  // ]);

  const [filterByParameters, setFilterByParameters] = useState(filteredLenderList); // lenderList

  const [filterByProductType, setFilterByProductType] = useState(productTypes);

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  function handleFilterByProductType(event) {
    setFilterByProductType(event.target.value);
  }

  function handleFilterByFit(event) {
    setFitFilterValue(event.target.value);
  }

  function handleFilterByCriteria(event) {

    if (user?.status === "new") {
      if (event.target.value === "assetType") {
        setCriteriaFilterValue([event.target.value]);
        if (user?.walkthroughIndex < 6) {
          dispatch(saveValue({ walkthroughIndex: 6 }))
          // mixpanel.track(`Demo: Step 7 - Credit score`)
        }
        return
      }
    }
    setCriteriaFilterValue(event.target.value);
  }

  function filterByFit(fit, filterValue) {
    if (parseInt(filterValue) == 100 && fit === 0) return true
    if (filterValue === "Show all") return true
    if (fit <= parseInt(filterValue) && parseInt(filterValue) !== 100) return true
  }

  useEffect(() => {
    window.addEventListener("resize", handle.setDimension);
    return () => {
      window.removeEventListener("resize", handle.setDimension);
    };
  }, [screenSize]);

  // const lenderSettingsNamesList = user?.lenderSettings?.map((setting) => {
  //   if (setting?.lenderEmail) {
  //     return setting?.lender;
  //   }
  // });

  // const lenderSettingsNames = lenderSettingsNamesList?.filter(
  //   (setting) => setting !== undefined && setting !== null
  // );

  const handle = {
    setDimension: () => {
      getDimension({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight,
      });
    },
    loanAmountCommercial: (fees, netAssetValue, brokerageAmount) => netAssetValue + brokerageAmount + fees,
    loanAmountConsumer: (fees, netAssetValue) => netAssetValue + fees,
    evaluateProducts: () => {
      const customerProfile = customers?.[0]?.customerProfile;
      const creditProfile = customers?.[0]?.creditProfile;
      const employer = customers?.[0]?.employers?.[0];

      const productList = filteredProducts
        ?.flatMap((product) => {
          return product?.productTiers?.map((tier) => {
            if (!tier) return null;

            const defaultBrokerage = calculateExtras(product.brokerage, {
              asset,
              loanDetails,
              entity: entity?.[0],
            })[0];

            const newLoanDetails = {
              loanAmount: assetValue - deposit,
              term,
              deposit,
              balloon,
            };

            const FEESLIST = calculateFees(product.fees, {
              asset,
              loanDetails: newLoanDetails,
              entity: entity?.[0],
              customer: customers?.[0],
              customerProfile,
              creditProfile,
              employer,
            });

            const feesList = FEESLIST.map((fee) => {
              const feeValue = fee.value * 1;
              if (fee.calc === "percent") {
                const newFeeValue = (feeValue / 100) * newLoanDetails.loanAmount;
                return { ...fee, value: newFeeValue };
              }
              // if (fee.name === "Origination fee")
              //   return {
              //     ...fee,
              //     value: originationFee < feeValue ? parseFloat(originationFee) : feeValue,
              //   };
              return fee;
            });

            const financeFeesTotal = FEESLIST.reduce((accum, fee) => {
              // if (fee.percentage) {
              //   return (fee.value / 100) * newLoanDetails.loanAmount + accum;
              // }
              // if (fee.frequency === "monthly") return accum;
              // Upfront: false || Financed: true || Monthly: false
              if (fee.frequency === "Financed") return fee.value + accum;
              return accum;
            }, 0);

            const monthlyFees = feesList.find((fee) => fee.frequency === "monthly");
            const feesTotal = feesList.reduce((accum, fee) => {
              let feeValue = fee.value * 1;
              if (fee.percentage) {
                return (fee.value / 100) * newLoanDetails.loanAmount + accum;
              }
              if (fee.frequency === "monthly") return accum;
              if (fee.capitalised) return feeValue + accum;
              return accum;
            }, 0);

            const netAssetValue = assetValue * 1 - deposit * 1 - tradeIn * 1 + payout * 1;
            const adjustedBrokerage = brokerage * 1;

            let finalBrokerage = adjustedBrokerage > defaultBrokerage?.max ? defaultBrokerage?.max : adjustedBrokerage;
            if (!isCommercial) {
              if (rateAdjustment === -2) finalBrokerage = 0;
              if (rateAdjustment === -1.5) finalBrokerage = defaultBrokerage?.value * 0.25;
              if (rateAdjustment === -1) finalBrokerage = defaultBrokerage?.value * 0.5;
              if (rateAdjustment === -0.5) finalBrokerage = defaultBrokerage?.value * 0.75;
              if (rateAdjustment === 0) finalBrokerage = defaultBrokerage?.value;
            }
            const brokerageAmount = netAssetValue * (finalBrokerage / 100);

            // let loanAmount = isCommercial
            //   ? handle.loanAmountCommercial(feesTotal, netAssetValue, brokerageAmount)
            //   : handle.loanAmountConsumer(feesTotal, netAssetValue);

            // We just need financed fees only
            let loanAmount = isCommercial
              ? handle.loanAmountCommercial(financeFeesTotal, netAssetValue, brokerageAmount)
              : handle.loanAmountConsumer(financeFeesTotal, netAssetValue);

            if (application.applicationType === APPLICATION_TYPES.PERSONAL) loanAmount = loanAmountSaved;

            const loadingList = calculateLoading(product.loading, {
              asset,
              loanDetails: {
                ...loanDetails,
                ...{
                  loanAmount,
                  brokerageRate: adjustedBrokerage,
                  deposit: 100 - ((assetValue - deposit) / assetValue) * 100,
                },
              },
              entity: entity?.[0],
              customerProfile,
            });

            const loadingTotal = loadingList.reduce(
              (accum, item) => (item.result === true ? item.value + accum : accum),
              0,
            );

            let RATE;
            if (Array.isArray(tier.rate)) {
              const scoreCriteria = tier.criteria.find((criteria) => criteria.attribute === "score");
              if (
                scoreCriteria &&
                creditProfile?.score >= scoreCriteria.value.min &&
                creditProfile?.score < scoreCriteria.value.max
              ) {
                const percentage =
                  ((creditProfile.score - scoreCriteria.value.max) /
                    (scoreCriteria.value.max - scoreCriteria.value.min)) *
                  100 +
                  100;
                RATE = (percentage / 100) * (tier.rate[1] - tier.rate[0]) + tier.rate[0];
              } else {
                RATE = tier.rate[0];
              }
            } else {
              RATE = tier.rate;
            }

            RATE = RATE + rateAdjustment + loadingTotal;
            const quoteDetails = handle.calculateQuote(loanAmount, balloon, term, RATE, netAssetValue);
            const quoteWithNoBalloon = handle.calculateQuote(loanAmount, 0, term, RATE, netAssetValue);

            // const balloonAmount = (balloon / 100) * assetValue;
            const balloonAmount = (balloon / 100) * netAssetValue;
            const presentValue = balloonAmount / Math.pow(1 + RATE, term);
            const trueLoanAmount = loanAmount - presentValue;

            // const comparisonRateRaw = calculateComparisonRate(
            //   term,
            //   -quoteWithNoBalloon.repayments,
            //   trueLoanAmount - feesTotal - (isCommercial ? brokerageAmount : 0),
            //   0, // (balloon / 100) * assetValue
            //   isCommercial ? 1 : 0,
            //   RATE / 100,
            // );

            const comparisonRateRaw = calculateEffectiveRate(
              term,
              quoteDetails.repayments,
              -(trueLoanAmount - feesTotal - (isCommercial ? brokerageAmount : 0)),
              balloonAmount,
              isCommercial ? 1 : 0,
              RATE / 100,
            );

            let effectiveRate = calculateEffectiveRate(
              term,
              quoteDetails.repayments,
              -(netAssetValue + financeFeesTotal),
              balloonAmount,
              isCommercial ? 1 : 0,
              RATE / 100,
            );

            if (effectiveRate < RATE) {
              effectiveRate = RATE;
            }

            // const comparisonRate = comparisonRateRaw * 12 * 100;
            let comparisonRate = comparisonRateRaw;

            if (comparisonRate < RATE) {
              comparisonRate = RATE;
            }
            // const compoundingYears = 12
            // const newRate = RATE / 100
            // const compoundRate = newRate/compoundingYears
            // const effectiveRate = (Math.pow(1 + compoundRate, compoundingYears) - 1) * 100

            const LOAN_DETAILS = {
              ...loanDetails,
              ...{
                loanAmount,
                repayments: quoteDetails.repayments,
                deposit: 100 - ((assetValue - deposit) / assetValue) * 100,
              },
            };

            // ================================================================================================
            // For Logic Reference

            const requiredFields = get(product, "requiredFields");

            //remove fields for angle
            if (application.applicationType !== APPLICATION_TYPES.PERSONAL) {
              if (!glassesAssetsSearchableList.includes(application.asset.assetType)) {
                let indexGlassMake = requiredFields?.asset?.indexOf("glassesMake");
                if (indexGlassMake !== -1) {
                  requiredFields?.asset?.splice(indexGlassMake, 1);
                  requiredFields?.asset?.push("make");
                }
                let indexGlassModel = requiredFields?.asset?.indexOf("glassesModel");
                if (indexGlassModel !== -1) {
                  requiredFields?.asset?.splice(indexGlassModel, 1);
                  requiredFields?.asset?.push("model");
                }
                let indexGlassVariant = requiredFields?.asset?.indexOf("glassesVariant");
                if (indexGlassVariant !== -1) {
                  requiredFields?.asset?.splice(indexGlassVariant, 1);
                }
              } else {
                let indexGlassMake = requiredFields?.asset?.indexOf("make");
                if (indexGlassMake !== -1) {
                  requiredFields?.asset?.splice(indexGlassMake, 1);
                  requiredFields?.asset?.push("glassesMake");
                }
                let indexGlassModel = requiredFields?.asset?.indexOf("model");
                if (indexGlassModel !== -1) {
                  requiredFields?.asset?.splice(indexGlassModel, 1);
                  requiredFields?.asset?.push("glassesModel");
                }
                let indexGlassVariant = requiredFields?.asset?.indexOf("variant");
                if (indexGlassVariant !== -1) {
                  requiredFields?.asset?.splice(indexGlassVariant, 1);
                  requiredFields?.asset?.push("glassesVariant");
                } else {
                  let indexGlassVarian = requiredFields?.asset?.indexOf("glassesVariant");
                  if (indexGlassVarian === -1) {
                    requiredFields?.asset?.push("glassesVariant");
                  }
                }
              }
            }

            const missingFields = {};
            if (
              (application.applicationType === APPLICATION_TYPES.CONSUMER ||
                application.applicationType === APPLICATION_TYPES.COMMERCIAL) &&
              requiredFields?.asset
            ) {
              missingFields.asset = requiredFields?.asset?.filter((field) => !get(application.asset, field));
              // if glasses key then change it to non glasses key
              if (missingFields.asset.includes("glassesModel")) {
                missingFields.asset.splice(missingFields.asset.indexOf("glassesModel"), 1);
                missingFields?.asset.push("model");
              }
              if (missingFields.asset.includes("glassesMake")) {
                missingFields.asset.splice(missingFields.asset.indexOf("glassesMake"), 1);
                missingFields?.asset.push("make");
              }
              if (missingFields.asset.includes("glassesVariant")) {
                missingFields.asset.splice(missingFields.asset.indexOf("glassesVariant"), 1);
                if (product.lender !== "Pepper") {
                  missingFields?.asset.push("variant");
                }
              }
            }

            if (requiredFields?.entity) {
              missingFields.entity = requiredFields?.entity?.filter(
                (field) => !field?.includes("address") && !get(application?.entities?.[0], field)
              );
            }

            //remove monthAt and yearsAt if city is not null validation
            //  if (requiredFields?.customer) {
            //   if (!get(application?.customers?.[0], "addresses[0].city")) {
            //     const filterField = ["addresses[0].monthsAt", "addresses[0].yearsAt"];
            //     requiredFields.customer = requiredFields?.customer.filter((field) => !filterField.includes(field));
            //   }
            // }

            if (requiredFields?.customer) {
              missingFields.customer = requiredFields?.customer?.filter(
                (field) => {
                  const filterField = ["addresses[0].monthsAt", "addresses[0].yearsAt"];
                  if (filterField.includes(field) && (get(application?.customers?.[0], field) >= 0)) return
                  if (field === "emails?.slice(-1)[0].address") {
                    const isEmailExit = application?.customers?.[0].emails?.slice(-1)[0]?.address
                    if (isEmailExit) return
                  }

                  if (field === "phoneNumbers?.slice(-1)[0].number") {
                    const isNumExit = application?.customers?.[0].phoneNumbers?.slice(-1)[0]?.number
                    if (isNumExit) return
                  }
                  return !get(application?.customers?.[0], field);
                }
              );
            }

            if (requiredFields?.agreement) {
              missingFields.agreement = requiredFields?.agreement?.filter(
                (field) => !application?.introducerDeclarationAgree
              );
            }
            // if (requiredFields?.loanDetails) {
            //   missingFields.loanDetails = requiredFields?.loanDetails?.filter(
            //     (field) => !get(loanDetails?.rate, field)
            //   );
            // }

            // if (!value || value === undefined) return;

            // const findFirstName = application.customers.filter((el) => !el.firstName);
            // // console.log("findFirstName", findFirstName);
            // let errorMsg = error;
            // console.log("application?.entities?.length", application?.entities?.length);
            // if (
            //   application?.entities?.length >= 0 &&
            //   !error.includes("entities[i].abn")
            // ) {
            //   console.log("===");
            //   errorMsg.push("entities[i].abn");
            //   setError(errorMsg);
            //   return;
            // }
            // if (findFirstName.length <= 0 && !error.includes("customer[i].firstName")) {
            //   errorMsg.push("customer[i].firstName");
            //   setError(errorMsg);
            //   return;
            // }

            // Map product details with application data to check required field data are available or not
            // console.log("application", application);
            let requiredFieldsLength,
              missingFieldsCustomer,
              missingFieldsEntity,
              missingFieldsLength,
              missingFieldsAgreement;
            if (isCommercial) {
              requiredFieldsLength = product?.requiredFields?.asset?.concat(
                product?.requiredFields?.entity,
                product?.requiredFields?.customer
                // product?.requiredFields?.loanDetails
              );

              missingFieldsCustomer = missingFields?.customer?.flat();
              missingFieldsEntity = missingFields?.entity?.flat();
              missingFieldsLength = missingFields?.asset?.concat(
                missingFieldsCustomer,
                missingFieldsEntity
              );
            } else {
              requiredFieldsLength = product?.requiredFields?.asset?.concat(
                product?.requiredFields?.customer,
                product?.requiredFields?.agreement
                // product?.requiredFields?.loanDetails
              );
              missingFieldsCustomer = missingFields?.customer?.flat();
              missingFieldsAgreement = missingFields?.agreement?.flat();
              missingFieldsLength = missingFields?.asset?.concat(
                missingFieldsCustomer,
                missingFieldsAgreement
              );
            }

            const requiredFieldsData = {
              totalLength: requiredFieldsLength?.length || 0,
              availableDataLength:
                requiredFieldsLength?.length - missingFieldsLength?.length || 0,
              remainingDataFields: missingFields, // put all the validation message in this array to show on hover
            };

            // ================================================================================================

            const productDetails = {
              lender: product.lender,
              productName: tier.name,
              name: tier.name,
              logo: product.logoURL,
              lenderCredentials: product?.lenderCredentials,
              requiredFieldsData: requiredFieldsData,
            };

            const criteriaResults = evaluateCriteria(tier.criteria, {
              asset,
              loanDetails: LOAN_DETAILS,
              entity: entity?.[0],
              customer: customers?.[0],
              customerProfile,
              creditProfile,
              employer,
            }).filter((result) => {
              if (!result.criteria) return result;
              if (result.criteria.every((criteria) => criteria.result))
                return result;
              if (
                !result.criteria.some((criteria) => criteria.result) &&
                result.criteria.some(
                  (item) => result.primary === item.attribute
                )
              ) {
                return result.criteria.find(
                  (item) => result.primary === item.attribute
                );
              }
              return result;
            });

            const overallResult = criteriaResults.reduce(
              (count, item) => (item?.result ? count + 1 : count),
              0
            );

            function tierInfo() {
              return tier?.info?.[0] ? tier?.info : [];
            }

            const financeAmount = application?.applicationType === APPLICATION_TYPES.PERSONAL ? loanDetails?.loanAmount : 1 * netAssetValue;

            const finalLoanAmount = application?.applicationType === APPLICATION_TYPES.PERSONAL
              ? loanDetails?.loanAmount
              : quoteDetails.repayments * term

            return _.merge(productDetails, {
              effectiveRate: effectiveRate,
              comparisonRate: comparisonRate,
              financeAmount: financeAmount,
              loanAmount: finalLoanAmount,
              repayments: (quoteDetails.repayments || 0) + (monthlyFees?.value || 0),
              brokerage: finalBrokerage,
              brokerageAmount: brokerageAmount,
              monthlyFees,
              fees: feesList,
              feesTotal,
              financeFeesTotal,
              loading: { loadingList, loadingTotal },
              criteriaResults,
              fit: criteriaResults.length - overallResult,
              criteria: `${overallResult}/${criteriaResults.length}`,
              qualification: criteriaResults.length - overallResult,
              productType: tier.productType,
              labels: [tier.productType],
              // rate: (RATE + loadingTotal) * 1,
              rate: RATE,
              info: [...tierInfo(), ...(product?.info || [])],
              date: product.date,
              isCommercial,
              actions: product.actions,
            });
          });
        })
        .filter(Boolean);

      const list = productList
        .flat()
        // .filter((product) => product.repayments > 0)
        .filter((product) => filterByParameters?.includes(product?.lender))
        // .filter((product) =>
        //   lenderSettingsNames
        //     ? lenderSettingsNames?.includes(product?.lender)
        //     : product
        // )
        .filter((product) => filterByProductType?.includes(product?.productType))
        .filter((product) => handle.filterByCriteriaFn(product, criteriaFilterValue));

      const groupedList = list.reduce((acc, product) => {
        acc[product.lender] = acc[product.lender] || [];
        acc[product.lender].push(product);
        return acc;
      }, {});

      const prioritisedList = Object.values(groupedList).map(
        (products) =>
          products.sort((a, b) => {
            if (a.fit !== b.fit) return a.fit - b.fit;
            return a.rate - b.rate;
          })[0],
      );

      setProductsMap(orderBy(prioritisedList, ...orderByParametersList));
    },
    filterByCriteriaFn: (product, criteriaFilterValue) => {
      const criteriaResults = product.criteriaResults.map((result) => {
        if (result.result || result.result === undefined) return result.attribute;
        return result.result;
      });
      const matches = Array.isArray(criteriaFilterValue)
        ? criteriaFilterValue.map((criteria) => criteriaResults.includes(criteria))
        : [criteriaResults.includes(criteriaFilterValue)];

      return matches.every((i) => i);
    },
    calculateQuote: (loanAmount, balloon, term, rate, netAssetValue) => {
      const monthlyRate = getMonthlyRate(rate);
      const repayments = calculateRepayments(
        monthlyRate,
        term,
        -loanAmount,
        // (balloon / 100) * assetValue,
        (balloon / 100) * netAssetValue,
        isCommercial ? 1 : 0,
      );

      return {
        repayments: Number(repayments.toFixed(2)),
      };
    },
    orderByFn: (event) => {
      // const targetValue = event.target.value;
      const targetValue = event;
      const params = targetValue.map((i) => i[0]);
      // const order = targetValue.map((i) => i[1]);

      if (user.status === "new" && params[0] === "rate") {
        // setOrderByParameters([params[0]]);
        // setOrderByParametersList([params, order]);
        handle.setFilterToLocalStore("orderBy", [params[0]]);
        return dispatch(saveValue({ walkthroughIndex: 3 }));
      }

      // setOrderByParameters(targetValue);
      // setOrderByParametersList([params, order]);
      handle.setFilterToLocalStore("orderBy", targetValue);
    },
    filterByFn: (event) => {
      // setFilterByParameters(event.target.value);
      handle.setFilterToLocalStore("filterBy", event.target.value);
    },
    filterByProductTypeFn: (event) => {
      // setFilterByProductType(event.target.value);
      handle.setFilterToLocalStore("filterByProductType", event.target.value);
    },
    filterByFitFn(event) {
      // setFitFilterValue(event.target.value);
      handle.setFilterToLocalStore("filterByFit", event.target.value);
    },
    filterByCriteria: (event) => {
      if (user?.status === "new") {
        if (event.target.value === "assetType") {
          // setCriteriaFilterValue([event.target.value]);
          handle.setFilterToLocalStore("filterByCriteria", [
            event.target.value,
          ]);
          if (user?.walkthroughIndex < 6) {
            dispatch(saveValue({ walkthroughIndex: 6 }));
          }
          return;
        }
      }
      // setCriteriaFilterValue(event.target.value);
      handle.setFilterToLocalStore("filterByCriteria", event.target.value);
    },
    setFilterToLocalStore: (key, filteredValue) => {
      const getProductFilter = getLocalStorage(productLenderFilterKey);

      if (!getProductFilter) {
        setLocalStorage(productLenderFilterKey, null);
      }

      let filteredData = {
        orderBy: orderByParameters,
        orderByList: orderByParametersList,
        filterBy: filterByParameters,
        filterByProductType: filterByProductType,
        filterByFit: fitFilterValue,
        filterByCriteria: criteriaFilterValue,
      };

      switch (key) {
        case "orderBy":
          const uniqOrderBy = uniqWith(filteredValue, isEqual);

          setOrderByParameters(uniqOrderBy);
          filteredData.orderBy = uniqOrderBy;

          // Update setOrderByList
          // ======================================================================
          const targetValue = filteredValue;
          const params = targetValue.map((i) => i[0]);
          const order = targetValue.map((i) => i[1]);

          if (user.status === "new" && params[0] === "rate") {
            setOrderByParametersList([params, order]);
            filteredData.orderByList = [params, order];
            return dispatch(saveValue({ walkthroughIndex: 3 }));
          }
          setOrderByParametersList([params, order]);
          filteredData.orderByList = [params, order];
          // ======================================================================
          break;
        case "orderByList":
          setOrderByParametersList(filteredValue);
          filteredData.orderByList = filteredValue;
          break;
        case "filterBy":
          setFilterByParameters(filteredValue);
          filteredData.filterBy = filteredValue;
          break;
        case "filterByProductType":
          setFilterByProductType(filteredValue);
          filteredData.filterByProductType = filteredValue;
          break;
        case "filterByFit":
          setFitFilterValue(filteredValue);
          filteredData.filterByFit = filteredValue;
          break;
        case "filterByCriteria":
          setCriteriaFilterValue(filteredValue);
          filteredData.filterByCriteria = filteredValue;
          break;

        default:
          break;
      }

      const data = {
        ...getProductFilter,
        [application._id]: filteredData,
      };

      setLocalStorage(productLenderFilterKey, data);
    },
  };

  useEffect(() => {
    handle.evaluateProducts();
  }, [
    customers,
    introducerDeclarationAgree,
    brokerage,
    assetType,
    ageOfAsset,
    balloon,
    term,
    entity,
    orderByParameters,
    filterByParameters,
    filterByProductType,
    fitFilterValue,
    typeOfSale,
    criteriaFilterValue,
    assetValue,
    deposit,
    tradeIn,
    payout,
    glassesMake,
    glassesModel,
    glassesVariant,
    make,
    model,
    asset,
    loanAmountSaved,
    purpose,
    rateAdjustment
  ]);

  // const debounced = debounce(handle.evaluateProducts, 500);

  // useEffect(() => {
  //   debounced();
  // }, [assetValue]);

  useEffect(() => {
    // setOrderByParametersList([
    //   ["fit", "repayments"],
    //   ["asc", "asc"],
    // ]);

    // Update Filter list
    const getProductFilter = getLocalStorage(productLenderFilterKey);

    if (getProductFilter) {
      const isFilterData = getProductFilter[application._id];
      if (isFilterData) {
        const {
          orderBy,
          orderByList,
          filterBy,
          filterByProductType,
          filterByFit,
          filterByCriteria,
        } = getProductFilter[application._id];

        setOrderByParameters(orderBy);
        setOrderByParametersList(orderByList);
        setFilterByParameters(filterBy);
        setFilterByProductType(filterByProductType);
        setFitFilterValue(filterByFit);
        setCriteriaFilterValue(filterByCriteria);
        return;
      }
    }
  }, [application]);

  const productsList = useMemo(
    () =>
      productsMap
        ?.slice(0, 50)
        .map((productDetails, i) => (
          <Product
            key={
              productDetails.lender +
              productDetails.productType +
              productDetails.loanAmount +
              productDetails.rate
            }
            productDetails={productDetails}
            user={user}
            application={application}
            screenSize={screenSize}
            onShowRequired={onShowRequired}
          />
        )),
    [productsMap]
  );

  return (
    <React.Fragment>
      <Stack
        direction="row"
        style={{
          width: "100%",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        {open && (
          <Stack direction="row">
            <ProductSelectorFilterBy
              list={filteredLenderList} // lenderList
              handleFilterBy={handle.filterByFn}
              value={filterByParameters}
              title="Lenders"
              multiple
              screenSize={screenSize}
            />
            <ProductSelectorFilterBy
              list={productTypes}
              handleFilterBy={handle.filterByProductTypeFn}
              value={filterByProductType}
              title="Product type"
              multiple
              screenSize={screenSize}
            />
            <ProductSelectorFilterBy
              list={criteriaFilterList}
              handleFilterBy={handle.filterByCriteria}
              value={criteriaFilterValue}
              title="Must match"
              multiple
              screenSize={screenSize}
            />
          </Stack>
        )}
        <ProductSelectorOrderBy
          handleOrderBy={handle.orderByFn}
          value={orderByParameters}
          screenSize={screenSize}
        />
      </Stack>

      <Stack>{productsList}</Stack>

      {!productsMap?.[0] && (
        <Stack
          justifyContent="center"
          alignItems="center"
          style={{ height: "200px" }}
        >
          <Typography>No matching products</Typography>
        </Stack>
      )}
      <VerifyDialog
        isOpen={verifiedDialogBoxIsOpen}
        title={"Lender verification"}
        message={"Lender verify Two-factor authentication code"}
      />
    </React.Fragment>
  );
});

function setProducts(applicationType) {
  if (applicationType === "consumer") return consumerProducts;
  // if (applicationType === "personal") return personalLoanProducts;

  return commercialProducts;
}

function setLenders(applicationType) {
  if (applicationType === "consumer") return consumerLenderList;
  // if (applicationType === "personal") return personalLoanLenderList;

  return commercialLenderList;
}

export default ProductSelector;
