import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import regex from "src/utils/regex";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  createFinanceItem,
  deleteFinanceItem,
  createAssets,
  deleteAssets,
  updateAssetList,
  updateAssets,
} from "../../store/slices/applicationFormSlice";
import _ from "lodash";
import { assetTypesList } from "src/constants";
import {
  NumericFormatCustom,
  currencyMaskedValue,
} from "src/utils/currencyMaskFormat";

export default function Assets({ assets, customerId, applicationId }) {
  const dispatch = useDispatch();
  const [newAssetName, setNewAssetName] = useState("");
  const [fieldErrors, setFieldErrors] = useState({
    asset: "",
  });

  const assetState = useSelector(({ assert }) => assert);

  const assetList = assetState?.assetListData || [];

  const validationSchema = Yup.object({
    asset: Yup.string()
      .optional()
      .matches(
        regex.fanciableItemNameRegex,
        "Only letters, dashes (-), forward slashes (/), commas (,), and spaces are allowed."
      )
      .max(25, "Maximum of 25 characters"),
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };

  // useEffect(() => {
  //   if (!assets.length) {
  //     onAddNewAssetType();
  //     return;
  //   }
  // }, []);

  const onAddNewAssetType = () => {
    const data = {
      assetType: "",
      value: "",
      description: "",
      application: applicationId,
      customer: customerId,
    };
    dispatch(createAssets(data));
  };

  const removeAssetType = (Element) => {
    const data = {
      assetId: Element._id,
      application: Element.application,
      customer: Element.customer,
    };
    delete data._id;

    dispatch(deleteAssets(data));
  };

  const handle = {
    onBlur: (name, value, newID) => {
      const temp = [...assets];

      temp.map((ele) => {
        // if (!ele._id) {
        //   console.log("ele", ele);
        // }
        if (ele._id === newID) {
          let updatedAssetData = {
            ...ele,
            [name]: value,
            assetId: newID,
            application: applicationId,
            customer: customerId,
          };
          delete updatedAssetData._id;

          dispatch(updateAssets(updatedAssetData));
          return updatedAssetData;
        } else {
          return ele;
        }
      });
    },

    onChange: (name, value, newID) => {
      const temp = [...assets];

      const updateAssetType = temp.map((ele) => {
        if (!ele._id) {
          const dataWithOutId = {
            ...ele,
            [name]: value,
            application: applicationId,
            customer: customerId,
          };
          dispatch(createAssets(dataWithOutId));
        }
        if (ele._id === newID) {
          const updatedAssetsItem = {
            ...ele,
            [name]: value,
          };
          return updatedAssetsItem;
        } else {
          return ele;
        }
      });
      dispatch(updateAssetList(updateAssetType));
    },

    assets: (update) => {
      dispatch(updateAssets({ ...update, assetsId: assets._id }));
    },
    deleteAsset: async (del_data) => {
      await dispatch(
        deleteFinanceItem({
          data: { assetId: del_data._id, assetsId: assets._id },
          endpoint: "customer-assets",
        })
      ).unwrap();
    },
    createAsset: async () => {
      try {
        if (fieldErrors["asset"] === "") {
          await dispatch(
            createFinanceItem({
              data: {
                name: _.camelCase(newAssetName),
                label: newAssetName,
                assetsId: assets._id,
              },
              endpoint: "customer-assets",
            })
          ).unwrap();
          setNewAssetName("");
        }
      } catch (error) {
        const newErrors = {};
        error.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
        setFieldErrors(newErrors);
      }
    },
  };

  return (
    <>
      <Typography
        variant="subtitle2"
        sx={{ fontWeight: "bold" }}
        style={{ marginBottom: "5px", marginTop: "20px" }}
      >
        Assets
      </Typography>
      {assets?.map((ele, index) => {
        return (
          <Grid
            container
            spacing={1}
            alignItems="center"
            style={{ marginBottom: "20px" }}
            key={ele?._id}
          >
            <Grid item xs={3}>
              <FormControl fullWidth variant="filled">
                <InputLabel id="years-at-label">Asset Type</InputLabel>
                <Select
                  size="small"
                  labelId="years-at-label"
                  id="demo-simple-select"
                  value={ele?.assetType}
                  label="Asset Type"
                  // IconComponent={() =>
                  //   ele?.assetType !== "" ? (
                  //     <ClearIcon
                  //       sx={{ backgroundColor: "initial" }}
                  //       onClick={() => {
                  //         handle.onChange("assetType", "", ele?.id);
                  //       }}
                  //     />
                  //   ) : (
                  //     <ArrowDropDownIcon sx={{ backgroundColor: "initial" }} />
                  //   )
                  // }
                  onChange={(event) => {
                    handle.onChange("assetType", event.target.value, ele?._id);
                  }}
                  onBlur={(event) =>
                    handle.onBlur("assetType", ele?.assetType, ele?._id)
                  }
                >
                  {assetTypesList.map((i, index) => (
                    <MenuItem key={index} value={i.value}>{i.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <TextField
                size="small"
                id="outlined-basic"
                type="text"
                label="Value"
                variant="filled"
                value={ele?.value}
                InputProps={{
                  inputComponent: NumericFormatCustom,
                }}
                onChange={(event) =>
                  handle.onChange("value", event.target.value, ele?._id)
                }
                onBlur={(event) =>
                  handle.onBlur(
                    "value",
                    currencyMaskedValue(ele?.value),
                    ele?._id
                  )
                }
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                size="small"
                id="outlined-basic"
                type="text"
                label="Description"
                variant="filled"
                style={{ width: "100%" }}
                value={ele?.description}
                onChange={(event) =>
                  handle.onChange("description", event?.target?.value, ele?._id)
                }
                onBlur={(event) =>
                  handle.onBlur("description", ele?.description, ele?._id)
                }
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            {assets.length !== 0 && index !== 0 && (
              <Grid item xs={1}>
                <Button
                  size="small"
                  onClick={() => removeAssetType(ele)}
                  variant="outlined"
                  // style={{ marginTop: "10px" }}
                >
                  Remove
                </Button>
              </Grid>
            )}
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <div>
          <Button
            size="small"
            onClick={() => onAddNewAssetType()}
            variant="outlined"
            disabled={
              !assets[0]?.assetType &&
              !assets[0]?.value &&
              !assets[0]?.description
                ? true
                : false
            }
          >
            Add asset
          </Button>
        </div>
      </Grid>

      {/* <Grid item sm={12}> */}
      {/* <Stack spacing={1} direction="row"> */}

      {/* <div>dd</div>
        <div>dd</div> */}
      {/* {assets?.items?.map((asset) => (
        <FinanceItem
          item={asset}
          handleUpdate={() => handle.assets()}
          handleDelete={() => handle.deleteAsset(asset)}
        />
      ))} */}
      {/* <TextField
          id="outlined-basic"
          type="text"
          size="small"
          name="asset"
          label="Add asset"
          variant="outlined"
          onChange={async (event) => {
            let isValid = true;
            isValid =
              regex.fanciableItemNameRegex.test(event.target.value) &&
              event.target.value?.length <= 25;
            if (!isValid) {
              await validateField("asset", event.target.value);
              return;
            } else {
              setNewAssetName(event.target.value);
              await validateField("asset", event.target.value);
            }
          }}
          value={newAssetName}
          error={fieldErrors?.asset}
          helperText={fieldErrors?.asset}
          placeholder="Asset name"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={(event) => {
                    if (newAssetName) {
                      handle.createAsset(event);
                    }
                  }}
                  style={{
                    background: newAssetName ? "#616161" : "none",
                    color: newAssetName ? "#fff" : "rgba(0, 0, 0, 0.6)",
                    height: "30px",
                  }}
                  edge="end"
                >
                  +
                </IconButton>
              </InputAdornment>
            ),
          }}
        /> */}
      {/* </Stack> */}
    </>
  );
}