import { useStat } from "react";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import { userSelector } from "../../store/slices/userSlice";

import { useState } from "react";
import { saveValue } from "src/store/slices/organisationSlice";
import { useDispatch } from "react-redux";
import GoogleAddress from "../Utils/GoogleAddress";

export default function Organisation() {
  const dispatch = useDispatch()
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [aggregationGroup, setAggregationGroup] = useState("");

  function handleCreateAddress(value) {
    console.log("Address", value)
    dispatch(saveValue({ address: value.formatted_address }))
  }

  const [businessFieldErrors, setBusinessFieldErrors] = useState({
    address: "",
  });

  const handleDeleteAddress = (newAddressValue) => {
    if (newAddressValue) {
      console.log("newAddressValue", newAddressValue)
      dispatch(saveValue({ address: '' }))
    }
  };


  return (
    <>
      <Grid
        container
        xs={11}
        sm={8}
        md={6}
        lg={6}
        justifyContent="center"
        alignItems="center"
        direction="column"
        textAlign="center"
      // style={{ padding: "10vh 100px", marginTop: "10vh" }}
      >

        <TextField
          fullWidth
          // id="outlined-basic"
          type="text"
          name="businessName"
          label="Business name*"
          // autoComplete="first-name"
          variant="filled"
          // variant="outlined"
          size="small"
          value={name}
          inputProps={{ maxLength: 24 }}
          onChange={(event) => setName(event.target.value)}
          style={{ margin: "0 0 10px 0" }}
          // placeholder="John"
          // error={firstNameError}
          // helperText={firstNameErrorText}
          onBlur={() => dispatch(saveValue({ name }))}

        />

        {/* <TextField
          fullWidth
          // id="outlined-basic"
          type="text"
          name="businessEmail"
          label="Email"
          // autoComplete="first-name"
          variant="filled"
          // variant="outlined"
          size="small"
          value={email}
          inputProps={{ maxLength: 24 }}
          onChange={(event) => setEmail(event.target.value)}
          style={{ margin: "0 0 10px 0" }}
          // placeholder="John"
          // error={firstNameError}
          // helperText={firstNameErrorText}
          onBlur={() => dispatch(saveValue({ email }))}
        />

        <TextField
          fullWidth
          // id="outlined-basic"
          type="text"
          name="businessPhoneNumber"
          label="Phone Number"
          // autoComplete="first-name"
          variant="filled"
          // variant="outlined"
          size="small"
          value={phoneNumber}
          inputProps={{ maxLength: 24 }}
          onChange={(event) => setPhoneNumber(event.target.value)}
          style={{ margin: "0 0 10px 0" }}
          // placeholder="John"
          // error={firstNameError}
          // helperText={firstNameErrorText}
          onBlur={() => dispatch(saveValue({ phoneNumber }))}

        /> */}

        {/* <GoogleMaps
          style={{ margin: "0 0 10px 0", width: "100%" }}
          handleCreateAddress={handleCreateAddress}
        // address={employer?.address}
        /> */}

        <GoogleAddress
          style={{ margin: "0 0 10px 0", width: "100%" }}
          userFieldErrors={businessFieldErrors}
          setUserFieldErrors={setBusinessFieldErrors}
          handleCreateAddress={handleCreateAddress}
          // handleUpdateAddress={handleUpdateAddress}
          handleRemoveAddress={handleDeleteAddress}
          // address={""}
          isRequired={true}
        />

        <TextField
          fullWidth
          // id="outlined-basic"
          type="text"
          name="aggregationGroup"
          label="Aggregation group"
          // autoComplete="first-name"
          variant="filled"
          // variant="outlined"
          size="small"
          value={aggregationGroup}
          inputProps={{ maxLength: 100 }}
          onChange={(event) => setAggregationGroup(event.target.value)}
          style={{ margin: "0 0 10px 0" }}
          // placeholder="John"
          // error={firstNameError}
          // helperText={firstNameErrorText}
          onBlur={() => dispatch(saveValue({ aggregationGroup }))}

        />
      </Grid>
    </>
  );
}