import criteria from "src/utils/criteria";
import motorMisc from "./motorMisc";
import motorBike from "./motorbike";
import ATVMowersTractors from "./ATVMowersTractors";
import JetskiPWC from "./JetskiPWC";
import trailers from "./trailers";
import horseFloat from "./horseFloat";

const { loanAmountBetween, assetValueBetween } = criteria;

export default {
  date: "01 Jul 2023",
  lender: "NOW",
  type: "consumer",
  logoURL:
    "https://assets.financeable.com.au/lender-logos%2FNOWFinance-Logo.svg",
  actions: [],
  lenderCredentials: [
    {
      label: "Email",
      attribute: "email",
    },
    {
      label: "User Name",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
    {
      label: "Merchant ID",
      attribute: "organisationId",
    },
    {
      label: "User ID",
      attribute: "brokerId",
    },
    {
      label: "Api Key",
      attribute: "apiKey",
    },
  ],
  requiredFields: {
    asset: [
      "assetType",
      "typeOfSale",
      "ageOfAsset",
      "glassesMake",
      "glassesModel",
      "glassesVariant",
      "assetValue",
    ],
    customer: [
      "firstName",
      "lastName",
      "dateOfBirth",
      "phoneNumbers[0].number",
      "emails[0].address",
      // "addresses[0].street",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "IDNumber",
      "IDCardNumber",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "employers[0].entityName",
      "employers[0].timeOfEmployment",
      "employers[0].employmentType",
    ],
    agreement: ["introducerDeclarationAgree"],
  },
  fees: [
    {
      name: "Origination fee",
      value: 350,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(3000, 4999)],
    },
    {
      name: "Origination fee",
      value: 550,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(5000, 7999)],
    },
    {
      name: "Origination fee",
      value: 880,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(8000, 9999)],
    },
    {
      name: "Origination fee",
      value: 990,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(10000, 19999)],
    },
    {
      name: "Origination fee",
      value: 1650,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(20000, 50000)],
    },
    {
      name: "Origination fee",
      value: 1650,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(50000, 150001)],
    },
  ],
  brokerage: [],
  loading: [],
  info: [
    "No lender fees",
    `New =<12 months build date / Demo = <24 months build date <5000kms.`,
    `Asset backed customers with credit score <600 are subject to tier 3 LVR and max lend`,
  ],
  productTiers: [
    ...motorMisc,
    ...motorBike,
    ...ATVMowersTractors,
    ...JetskiPWC,
    ...trailers,
    ...horseFloat,
  ],
};
