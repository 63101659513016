import { useState, useEffect, useMemo } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import _ from "lodash";
import { Grid, Typography, TextField, Stack, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from '@mui/material/Button';

import { useDispatch, useSelector } from "react-redux";
import {
    registerUser,
    saveValue,
    sendEnquiry,
    userSelector,
    verifyEmail,
} from "../../store/slices/userSlice";

import regex from "../../utils/regex";
import { useImperativeHandle } from "react";
import RadioButtonGroup from "../Utils/RadioButtonGroup";

function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

export default function UserDetails({ ref }) {
    const dispatch = useDispatch();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [firstNameErrorText, setFirstNameErrorText] = useState(" ");
    const [lastNameErrortext, setLastNameErrorText] = useState(" ");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState(" ");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [phoneNumberErrorText, setPhoneNumberErrorText] = useState("");
    const [canSubmit, setCanSubmit] = useState(false);
    const [previousAccreditation, setPreviousAccreditation] = useState("");
    const [yearsOfLendingExperience, setYearsOfLendingExperience] = useState("");
    const [representativeOfACLHolder, setRepresentativeOfACLHolder] = useState("");
    const [employeeOfACLHolder, setEmployeeOfACLHolder] = useState("");
    const [directorOfACLHolder, setDirectorOfACLHolder] = useState("");
    const [done, setDone] = useState(false);

    const { emailInUse, isCreatingAccount, createAccountSuccess } =
        useSelector(userSelector);

    async function handleRegistration() {
        // dispatch(saveValue({ isCreatingAccount: true }));
        // await dispatch(
        //   registerUser({
        //     firstName,
        //     lastName,
        //     email,
        //   })
        // ).unwrap();
    }

    // useImperativeHandle(ref, () => ({
    //   handleRegistration() {
    //     handleRegistration()
    //   }
    // }))

    function handleSaveValue(value) {
        dispatch(saveValue(value))
    }

    function handleFirstName(event) {
        setFirstName(event.target.value);
        dispatch(saveValue({ firstName: event.target.value }))

        if (regex.name.test(event.target.value)) {
            setFirstNameError(false);

            return setFirstNameErrorText(" ");
        }

        setFirstNameError(true);
        setFirstNameErrorText(
            "Name can contain only letters, dashes (-) or spaces."
        );
    }

    function handleLastName(event) {
        setLastName(event.target.value);
        dispatch(saveValue({ lastName: event.target.value }))

        if (regex.name.test(event.target.value)) {
            setLastNameError(false);

            return setLastNameErrorText(" ");
        }

        setLastNameError(true);
        setLastNameErrorText(
            "Name can contain only letters, dashes (-) or spaces."
        );
    }

    function handleEmail(event) {
        setEmail(event.target.value.trim());
        dispatch(saveValue({ email: event.target.value.trim() }))

        if (regex.email.test(event.target.value.toLowerCase())) {
            setEmailError(false);
            dispatch(saveValue({ emailInUse: false }));
            return setEmailErrorText(" ");
        }

        setEmailError(true);
        setEmailErrorText("Invalid email");
    }

    function handlePhoneNumber(event) {
        setPhoneNumber(event.target.value.trim());
        dispatch(saveValue({ phoneNumber: event.target.value.trim() }))

        if (regex.email.test(event.target.value.toLowerCase())) {
            setPhoneNumberError(false);
            return setEmailErrorText(" ");
        }

        setPhoneNumberError(true);
        setPhoneNumberErrorText("Invalid phone");
    }


    async function handleCheckEmail() {
        await dispatch(verifyEmail(email)).unwrap();
    }

    useEffect(() => {
        if (emailInUse) {
            setEmailError(true);
            return setEmailErrorText("Email address already in use.");
        }

        setEmailError(false);
        return setEmailErrorText(" ");
    }, [emailInUse]);

    useEffect(() => {
        if (
            firstName &&
            lastName &&
            email &&
            !firstNameError &&
            !lastNameError &&
            !emailError
        )
            return setCanSubmit(true);

        setCanSubmit(false);
    }, [
        firstName,
        lastName,
        email,
        firstNameError,
        lastNameError,
        emailError,
    ]);

    // useEffect(() => {
    //   console.log({ createAccountSuccess });
    //   if (hasOrganisation) return navigate("/applications");
    //   if (createAccountSuccess) navigate("/setup/organisation");
    // }, [createAccountSuccess]);

    // useEffect(() => {
    //   console.log("query.get(oid)", query.get("oid"))
    //   if (query.get("oid")) setHasOrganisation(query.get("oid"));
    // });

    return (
        <Grid
            container
            item
            // height="100%"
            // width="100%"
            style={{ padding: "30px 0 100px" }}
            justifyContent="center"
            alignItems="center"
            direction="row-reverse"
        >
            <Grid container
                item md={8}
                justifyContent="center"
                alignItems="center"
                direction="column"
            >
                <Grid
                    item
                    style={{
                        textAlign: "center",
                        margin: "0 0 30px 0"
                    }}
                >
                    <Typography
                        variant="h4"
                        fontWeight="800"
                        style={{
                            letterSpacing: "-1.8px",
                            margin: "0 0 10px 0"
                        }}
                    >
                        Tandemm
                    </Typography>
                    <Typography variant="h6">
                        Thank you for your interest in the Tandemm Platform.

                    </Typography>
                    <Typography>
                        Please fill in the details below and we will be in touch with
                        instructions on how to register.
                    </Typography>
                </Grid>
                {/* <Grid conatiner item > */}

                {!done && <Grid item
                    container
                    direction="column"
                    justifyContent="center"

                    style={{
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0 0 10px 0"
                    }}>
                    <Grid
                        container
                        // item
                        xs={11}
                        sm={8}
                        md={6}
                        xl={6}
                        // height="100vh"
                        justifyContent="center"
                        alignItems="center"
                        direction="column"
                        textAlign="center"
                    // spacing={3}
                    >
                        <TextField
                            fullWidth
                            // id="outlined-basic"
                            type="text"
                            name="firstName"
                            label="First name"
                            autoComplete="first-name"
                            variant="filled"
                            // variant="outlined"
                            size="small"
                            value={firstName}
                            inputProps={{ maxLength: 24 }}
                            onChange={handleFirstName}
                            margin="dense"
                            // placeholder="John"
                            error={firstNameError}
                            // helperText={firstNameErrorText}
                            // onBlur={() => {}}
                            style={{ margin: "0 0 10px 0" }}
                        />

                        <TextField
                            fullWidth
                            // id="outlined-basic"
                            type="text"
                            name="lastName"
                            label="Last name"
                            autoComplete="last-name"
                            variant="filled"
                            // variant="outlined"
                            size="small"
                            value={lastName}
                            inputProps={{ maxLength: 24 }}
                            onChange={handleLastName}
                            margin="dense"
                            // placeholder="Smith"
                            // helperText={lastNameErrortext}
                            error={lastNameError}
                            // onBlur={() => {}}
                            style={{ margin: "0 0 10px 0" }}
                        />

                        <TextField
                            fullWidth
                            // id="outlined-basic"
                            type="email"
                            name="email"
                            label="Email"
                            autoComplete="email"
                            variant="filled"
                            // variant="outlined"
                            size="small"
                            value={email}
                            onChange={handleEmail}
                            margin="dense"
                            // helperText={emailErrorText}
                            error={emailError}
                            onBlur={handleCheckEmail}
                            inputProps={{ maxLength: 50 }}
                            placeholder="name@email.com"
                            style={{ margin: "0 0 30px 0" }}
                        />
                        <Grid item>
                            <Button
                                variant="contained"
                                onClick={async () => {
                                    await dispatch(sendEnquiry({ firstName, lastName, email })).unwrap()
                                    setDone(true)
                                }
                                }
                            >Send Enquiry</Button>
                        </Grid>
                    </Grid >
                </Grid>
                }
                {done && <Typography>Your enquiry was sent. Thank you.</Typography>}
            </Grid>
        </Grid>
    );
}
