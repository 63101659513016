import axios from "axios";

export const headers = (token) => {
  if (token) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": token,
    };
  }

  return {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
};

export const reusableFetch = async (url, method, data, rejectWithValue) => {
  const token = localStorage.token;
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL}/${url}`,
      method: method,
      headers: headers(token),
      data: data ? data : "",
    });
    return response; // Assuming the relevant data is returned from the API
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.setItem("tokenExpired", true);
      localStorage.removeItem("token");
      window.location.reload();
    }
    return rejectWithValue(error.response ? error.response.data : error);
  }
};

export const reusableWithoutTokenFetch = async (
  url,
  method,
  data,
  rejectWithValue
) => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL}/${url}`,
      method: method,
      headers: headers(),
      data: data ? data : "",
    });
    return response; // Assuming the relevant data is returned from the API
  } catch (error) {
    if (error.response.status === 401) {
      if (error.response.status === 401) {
        localStorage.setItem("tokenExpired", true);
        localStorage.removeItem("token");
        window.location.reload();
      }
    }
    return rejectWithValue(error.response ? error.response.data : error);
  }
};

export const reusableFormDataFetch = async (
  url,
  method,
  formData,
  rejectWithValue
) => {
  const token = localStorage.token;
  const formDataHeaders = headers(token);
  formDataHeaders[
    "Content-Type"
  ] = `multipart/form-data; boundary=${formData._boundary}`;
  delete formDataHeaders.Accept;

  try {
    if (formData) {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/${url}`,
        method: method,
        headers: formDataHeaders,
        data: formData,
      });
      return response; // Assuming the relevant data is returned from the API
    }
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.setItem("tokenExpired", true);
      localStorage.removeItem("token");
      window.location.reload();
    }
    return rejectWithValue(error.response ? error.response.data : error);
  }
};
