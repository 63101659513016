import criteria from "src/utils/criteria";
import { livingArrangementsTypes, typeOfSaleList } from "src/constants";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } =
  livingArrangementsTypes;

const {
  nonGST,
  typeOfSale,
  hasDeposit,
  assetValueBetween,
  assetValueGreaterThan,
  assetType,
  ageOfAssetAtEndGreaterThan,
  ageOfAssetAtEndLessThan,
  ageOfAssetAtEndBetween,
  ageOfAssetBetween,
  ageOfAssetGreaterThan,
  loanAmountLessThan,
  loanAmountBetween,
  timeInBusinessBetween,
  timeInBusinessLessThan,
  timeInBusinessGreaterThan,
  gstRegistrationLessThan,
  gstRegistrationGreaterThan,
  gstRegistrationBetween,
  creditScoreGreaterThan,
  livingArrangements,
  termIsLessThan,
  creditScoreLessThan,
  brokerageIsEqualTo,
} = criteria;

const auto = [
  "Motor Vehicle (<4.5 tonnes)",
  "Electric Motor Vehicle (<4.5 tonnes)",
];
const primary = [
  "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
  "Commercial (Heavy) Vehicles (>12 Tonnes)",
  "Earth Moving & Construction (wheeled or tracked)",
  "Agricultural & Landscaping Equipment (wheeled or tracked)",
  "Trailers and Caravans",
  "Warehouse Equipment (wheeled or tracked)",
  "Medical Equipment",
  "Mini Buses & Route Buses (<25 seats)",
];

const secondary = ["Industrial Plant & Printing", "Manufacturing Equipment"];

const tertiary = [
  "IT, Computer & Audio Equipment",
  "Office Equipment",
  "Office Furniture",
  "Restaurant & Catering Equipment",
  "Health & Beauty Equipment",
  "Solar",
];

function base(l) {
  return l.map((product) => {
    return {
      ...product,
      productType: "Low doc",
      criteria: [
        ...product.criteria,
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(0),
        livingArrangements([PROPERTY_OWNER]),
        termIsLessThan(61),
      ],
    };
  });
}

export default {
  date: "08th Jan 2024",
  lender: "Captial Finance",
  productName: "STREAMLINE",
  type: "commercial",
  logoURL: "https://assets.financeable.com.au/lender-logos/capital-finance.svg",
  actions: ["email"],
  lenderCredentials: [
    {
      label: "Email",
      attribute: "email",
    },
    {
      label: "User Name",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
    {
      label: "Merchant ID",
      attribute: "organisationId",
    },
    {
      label: "User ID",
      attribute: "brokerId",
    },
    {
      label: "Api Key",
      attribute: "apiKey",
    },
  ],
  requiredFields: {
    entity: [
      "abn",
      // "addresses[0].streetAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
    ],
    customer: [
      "phoneNumbers[0].number",
      "emails[0].address",
      "firstName",
      "lastName",
      "dateOfBirth",
      // "addresses[0].streetAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "IDNumber",
      "IDCardNumber",
      // "emails[0].address",
      // "phoneNumbers[0].number",
    ],
    loanDetails: ["rate"],
    asset: [
      "assetType",
      "typeOfSale",
      "ageOfAsset",
      "glassesMake",
      "glassesModel",
      "glassesVariant",
      "assetValue",
    ],
  },
  email: "nrd@capital-finance.com.au",
  fees: [
    {
      name: "Document fee - Private sale",
      value: 695,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale("Private sale")],
    },
    {
      name: "Document fee",
      value: 495,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale("Authorised seller")],
    },
  ],
  brokerage: [
    {
      value: 4,
      max: 8,
      criteria: [assetValueBetween(5000, 50000)],
    },
    {
      value: 4,
      max: 6,
      criteria: [assetValueBetween(50000, 150000)],
    },
  ],
  loading: [
    {
      name: "Asset age 4-6 years",
      value: 0.25,
      valueType: "percentage",
      criteria: [assetType([...auto, ...primary]), ageOfAssetBetween(48, 72)],
    },
    {
      name: "Asset age >6 years",
      value: 0.75,
      valueType: "percentage",
      criteria: [assetType([...auto, ...primary]), ageOfAssetGreaterThan(72)],
    },
    // {
    //   name: "Asset age at end >15 yrs",
    //   value: 2,
    //   valueType: "percentage",
    //   criteria: [
    //     assetType([
    //       ...auto,
    //       ...primary
    //     ]),
    //     ageOfAssetAtEndGreaterThan(15),
    //   ],
    // },
    {
      name: "Private sale",
      value: 0.5,
      capitalised: true,
      valueType: "percentage",
      criteria: [typeOfSale(typeOfSaleList.PRIVATE_SALE)],
    },
    {
      name: "Brokerage 6% for <$50000",
      value: 0.5,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(6), assetValueBetween(5000, 49999.99)],
    },
    {
      name: "Brokerage 7% for <$50000",
      value: 1,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(7), assetValueBetween(5000, 49999.99)],
    },
    {
      name: "Brokerage 8% for <$50000",
      value: 1.5,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(8), assetValueBetween(5000, 49999.99)],
    },

    {
      name: "Brokerage 5% for >$50000",
      value: 0.5,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(5), assetValueGreaterThan(49999.99)],
    },
    {
      name: "Brokerage 6% for >$50000",
      value: 1,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(6), assetValueGreaterThan(49999.99)],
    },
  ],
  info: [
    `Existing customer - Max Loan amount $150K for motor vehicles and Primary Assets. Max $100K for Secondary Assets. Max $75K for Tertiary AssetsExisting customer limits`,
    `New customer - Max loan amount $100K for Motor Vehicles. Max $55K for Primary, Secondary and Tertiary assets`,
  ],
  productTiers: [...base(rates())],
};

function rates() {
  return [
    {
      name: "Auto",
      rate: 7.9,
      criteria: [
        loanAmountBetween(15000, 34999.99),
        assetType(auto),
        ageOfAssetAtEndLessThan(15),
      ],
    },
    {
      name: "Auto",
      rate: 7.7,
      criteria: [
        loanAmountBetween(35000, 150000),
        assetType(auto),
        ageOfAssetAtEndLessThan(15),
      ],
    },

    {
      name: "Primary",
      rate: 12.5,
      criteria: [
        loanAmountBetween(5000, 9999.99),
        assetType(primary),
        ageOfAssetAtEndLessThan(15),
      ],
    },
    {
      name: "Primary",
      rate: 9.3,
      criteria: [
        loanAmountBetween(10000, 20000),
        assetType(primary),
        ageOfAssetAtEndLessThan(15),
      ],
    },
    {
      name: "Primary",
      rate: 7.95,
      criteria: [
        loanAmountBetween(20000, 55000),
        assetType(primary),
        ageOfAssetAtEndLessThan(15),
      ],
    },
    {
      name: "Primary",
      rate: 7.2,
      criteria: [
        loanAmountBetween(55000, 150000),
        assetType(primary),
        ageOfAssetAtEndLessThan(15),
      ],
    },

    {
      name: "Secondary",
      rate: 13.0,
      criteria: [
        loanAmountBetween(5000, 10000),
        assetType(secondary),
        ageOfAssetAtEndLessThan(5),
      ],
    },
    {
      name: "Secondary",
      rate: 9.75,
      criteria: [
        loanAmountBetween(10000, 20000),
        assetType(secondary),
        ageOfAssetAtEndLessThan(5),
      ],
    },
    {
      name: "Secondary",
      rate: 8.5,
      criteria: [
        loanAmountBetween(20000, 55000),
        assetType(secondary),
        ageOfAssetAtEndLessThan(5),
      ],
    },
    {
      name: "Secondary",
      rate: 8.05,
      criteria: [
        loanAmountBetween(55000, 150000),
        assetType(secondary),
        ageOfAssetAtEndLessThan(5),
      ],
    },

    {
      name: "Tertiary",
      rate: 14.0,
      criteria: [
        loanAmountBetween(5000, 10000),
        assetType(tertiary),
        ageOfAssetAtEndLessThan(5),
      ],
    },
    {
      name: "Tertiary",
      rate: 10.25,
      criteria: [
        loanAmountBetween(10000, 20000),
        assetType(tertiary),
        ageOfAssetAtEndLessThan(5),
      ],
    },
    {
      name: "Tertiary",
      rate: 9.5,
      criteria: [
        loanAmountBetween(20000, 55000),
        assetType(tertiary),
        ageOfAssetAtEndLessThan(5),
      ],
    },
    {
      name: "Tertiary",
      rate: 9.45,
      criteria: [
        loanAmountBetween(55000, 150000),
        assetType(tertiary),
        ageOfAssetAtEndLessThan(5),
      ],
    },
  ];
}
