import { useState } from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Box,
  Divider,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  TextField,
  CardActionArea,
  CardMedia,
  Stack,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { commercialLenderList, consumerLenderList } from "src/constants";
import commercialProducts from "src/products/commercial";
import consumerProducts from "src/products/consumer";
import LenderFields from "./LenderFields";
import { useDispatch, useSelector } from "react-redux";
import {
  createLenderSetting,
  deleteLenderSetting,
} from "src/store/slices/userSlice";
import { useRef } from "react";

const lendersList = [
  ...new Map(
    [...commercialProducts, ...consumerProducts].map((l) => [l.lender, l])
  ).values(),
];

const lenderNamesList = lendersList.map((l) => l.lender);

export default function SettingsSidebar() {
  const userData = useSelector(({ user }) => user);
  const { _id, lenderSettings } = userData;

  const dispatch = useDispatch();
  const lenderFieldRef = useRef();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchedLender, setSearchedLender] = useState("");
  const [expand, setExpand] = useState({});
  const [filteredLenderOption, setFilteredLenderOption] = useState([]);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    handle.createLenderListFilter();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const lenderSettingsNamesList = lenderSettings?.map(
    (setting) => setting?.lender
  );

  const filterLenderDetail = () => {
    const filteredLenderList = lendersList?.filter((lender) => {
      return !lenderSettingsNamesList?.includes(lender.lender);
    });
    const lenderSettingOption = filteredLenderList.sort((a, b) => {
      if (a.lender.toLowerCase() === b.lender.toLowerCase()) {
        return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
      }
      return a.lender.toLowerCase() > b.lender.toLowerCase() ? 1 : -1;
    });
    return lenderSettingOption;
  };

  const lenderSettingsWithLogos = lenderSettings?.map((setting) => {
    const logo = lendersList?.find(
      (lender) => lender.lender === setting.lender
    );

    return { logo: logo?.logoURL, ...setting };
  });

  const handle = {
    createLenderListFilter: () => {
      const filterData = filterLenderDetail();

      setFilteredLenderOption(filterData);
    },
    searchOnChange: (event, value, reason) => {
      if (reason === "clear") {
        setSearchTerm("");
        return;
      }

      setSearchTerm(value?.lender);
      setSearchedLender(value?.lender);
    },
    deleteLenderSetting: () => {
      const deleteLenderItemData = {
        user: _id || "",
        lenderSettings: lenderSettings[0]._id,
      };
      dispatch(deleteLenderSetting(deleteLenderItemData));
    },
  };

  return (
    <>
      <Box width="100%">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack>
            <Typography variant="h4" style={{ marginBottom: "0px" }}>
              Lenders
            </Typography>
            <Typography style={{ marginBottom: "10px", fontSize: "13px" }}>
              Configure your settings for each lender such as their email
              address, broker credentials and more.
            </Typography>
          </Stack>
          <Button variant="contained" style={{}} onClick={handleClickOpen}>
            Add lender
          </Button>
        </Stack>
        <Divider sx={{ margin: "0 0 20px 0" }} />
        <Stack direction="column"></Stack>

        {lenderSettingsWithLogos
          // .filter((lender) => {
          // console.log(lender?.lender?.includes(searchTerm));
          //   if (
          //     searchTerm.length > 0 &&
          //     lender.lender
          //       .toLocaleLowerCase()
          //       .includes(searchTerm.toLocaleLowerCase())
          //   )
          //     return lender;

          //   if (searchTerm.length < 1) return lender;
          // })
          // .sort((a, b) => {
          //   const nameA = a.lender.toUpperCase(); // ignore upper and lowercase
          //   const nameB = b.lender.toUpperCase(); // ignore upper and lowercase

          //   if (nameA < nameB) {
          //     return -1;
          //   }
          //   if (nameA > nameB) {
          //     return 1;
          //   }

          //   // names must be equal
          //   return 0;
          // })
          ?.map((lenderSetting, index) => (
            <Card
              sx={{ minWidth: 275, margin: "0 0 10px 0" }}
              key={lenderSetting.lender}
              style={{
                transition: "all 0.3s ease-in-out",
                // height: 78,
                // height: expand[lender.lender] ? "300px" : "auto"
              }}
              elevation={1}
            >
              <CardContent
                // onClick={() => navigate(`${lender.lender}`)}

                sx={{ padding: "20px 20px !important" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Stack
                    direction="row"
                    // spacing={1}
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ width: "100%", margin: "0" }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      // style={{ margin: "0 0 10px 0" }}
                    >
                      <Box display="flex" alignItems="center">
                        <img
                          height="30px"
                          component="img"
                          sx={{ height: 20 }}
                          src={lenderSetting.logo}
                          style={{ maxWidth: 75, height: "auto" }}
                          // alt="green iguana"
                        />
                      </Box>
                      <Typography
                        style={{ marginLeft: 30 }}
                        sx={{
                          fontSize: 14,
                          fontWeight: 700,
                          margin: 0,
                          padding: 0,
                          lineHeight: 1.75,
                        }}
                        color="text.secondary"
                      >
                        {lenderSetting.lender}
                      </Typography>
                    </Stack>
                    <Stack
                      gap={1}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          handle.deleteLenderSetting();
                        }}
                      >
                        Delete Configure
                      </Button> */}
                      <Button
                        variant="outlined"
                        size="small"
                        // onClick={() => setExpand({ [lender.lender]: false })}
                        onClick={() => {
                          // handle.createLenderSetting(lender.lender);
                          setExpand({
                            [lenderSetting.lender]:
                              !expand[lenderSetting.lender],
                          });
                        }}
                      >
                        {expand[lenderSetting.lender] ? "Save" : "Edit"}
                      </Button>
                    </Stack>
                  </Stack>
                  {expand[lenderSetting.lender] && (
                    <Stack
                      style={{ width: "100%" }}
                      direction="column"
                      alignItems="center"
                      justifyContent="start"
                    >
                      <LenderFields
                        // {...{ lender }}
                        lenderSetting={lenderSetting}
                        // lenderCreatedData={lenderCreatedData}
                      />
                    </Stack>
                  )}
                </Box>
              </CardContent>
            </Card>
          ))}
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add lender</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */}
          <Autocomplete
            fullWidth
            // open
            sx={{ margin: "0 0 20px 0" }}
            options={filteredLenderOption || []}
            value={searchTerm.lender}
            onChange={handle.searchOnChange}
            getOptionLabel={(lenderItem) => lenderItem?.lender || ""}
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                label="Search lender"
                variant="filled"
                // onChange={(event) => setSearchTerm(event.target.value)}
              />
            )}
          />
          <LenderFields
            ref={lenderFieldRef}
            lender={searchedLender}
            handleClose={handleClose}
          />
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Cancel</Button> */}
          <Button onClick={handleClose}>Close</Button>
          <Button
            onClick={() => {
              lenderFieldRef.current.handleCreateLenderSetting();
            }}
          >
            Add lender
          </Button>
        </DialogActions>
      </Dialog>

      {/* <CardActions>
                  <Button size="small">Learn More</Button>
              </CardActions> */}
    </>
  );
}
