import pepper from "./pepper";
import plenti from "./plenti";
import now from "./now";
import wisr from "./wisr"
import liberty from "./liberty";
import money3 from "./money3";
import macquarie from "./macquarie";
import moneyplace from "./moneyplace";
import greenlight from "./greenlight";
import financeOne from "./financeOne";
import acl from "./acl"
import apf from "./apf"
import carStart from "./carStart";

export default [
    acl,
    apf,
    carStart,
    financeOne,
    greenlight,
    liberty,
    macquarie,
    money3,
    moneyplace,
    now,
    pepper,
    plenti,
    wisr,
];
