import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { debounce } from "lodash";
import LicencePassportToggle from "./LicencePassportToggle";
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import {
  saveCustomerProfile,
  updateCustomer,
} from "src/store/slices/applicationFormSlice";
import moment from "moment";
import { DateField } from "@mui/x-date-pickers";
import InputMask from "react-input-mask";
import RadioButtonGroup from "../Utils/RadioButtonGroup";
import regex from "src/utils/regex";
import { customerTitleList, field_required_message, ID_TYPE, validCustomerTitle } from "src/constants";

const CustomerPersonalDetails = React.memo(
  ({
    customer,
    applicationId,
    key,
    fieldErrors = {},
    setFieldErrors,
    customerId,
  }) => {
    const dispatch = useDispatch();
    const [allValues, setAllValue] = useState({
      customerTitle: customer?.customerTitle || "",
      firstName: customer?.firstName || "",
      middleNames: customer?.middleNames || "",
      lastName: customer?.lastName || "",
      IDNumber: customer?.IDNumber || "",
      IDCardNumber: customer.IDCardNumber || "",
      dateOfBirth: customer?.dateOfBirth || "",
      residencyStatusValue: customer?.customerProfile?.residencyStatus,
      maritalStatusValue: customer?.customerProfile?.maritalStatus,
      IDExpiryDate: customer?.IDExpiryDate || "",
      IDType: customer?.IDType || ID_TYPE.LICENCE
    });

    // const [fieldErrors, setFieldErrors] = useState({
    //   customerTitle: "",
    //   firstName: "",
    //   middleNames: "",
    //   lastName: "",
    //   IDNumber: "",
    //   dateOfBirth: "",
    //   IDCardNumber: "",
    // });

    const validationSchema = Yup.object({
      customerTitle: Yup.string()
        .oneOf(validCustomerTitle, "Please enter a valid title")
        .required("Title is required"),
      firstName: Yup.string()
        .required(field_required_message.firstName)
        .matches(regex.nameRegex, "Only letters and ' - characters allowed.")
        .min(2, "Maximum of 2 characters")
        .max(50, "Maximum of 50 characters"),
      middleNames: Yup.string()
        .optional()
        .matches(regex.nameRegex, "Only letters and ' - characters allowed.")
        .max(50, "Maximum of 50 characters"),
      lastName: Yup.string()
        .required(field_required_message.lastName)
        .matches(regex.nameRegex, "Only letters and ' - characters allowed.")
        .min(2, "Maximum of 2 characters")
        .max(50, "Maximum of 50 characters"),
      IDNumber: Yup.string()
        .optional()
        .matches(
          regex.drivingPassportRegex,
          "Only alphanumeric, space and - characters allowed."
        )
        .min(6, "Maximum of 6 characters")
        .max(15, "Maximum of 15 characters"),
      dateOfBirth: Yup.string()
        .typeError("Please enter a valid date")
        .matches(
          regex.customerDateOfBirthRegexOptional,
          "Invalid date,please enter a valid date"
        )
        .optional()
        .max(new Date(), "Date is not select in the future"),
      IDCardNumber: Yup.string()
        .optional()
        .min(6, "Maximum of 7 characters")
        .max(15, "Maximum of 16 characters"),
    });

    const validateField = async (fieldName, value) => {
      try {
        await validationSchema.validateAt(fieldName, { [fieldName]: value });
        setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
      } catch (error) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: error.message,
        }));
      }
    };

    const handle = {
      onChangeField: async (e, name) => {
        let isValid = true;

        if (
          name === "firstName" ||
          name === "middleNames" ||
          name === "lastName"
        ) {
          isValid = regex.nameRegex.test(e) && e?.length <= 50;
        } else if (name === "IDNumber") {
          isValid = regex.drivingPassportRegex.test(e) && e?.length <= 15;
        } else if (name === "IDCardNumber") {
          isValid = e?.length <= 16;
        }
        if (!isValid) {
          await validateField(name, e);
          return;
        }

        setAllValue({ ...allValues, [name]: e });
        await validateField(name, e);
      },
      onChangeRadio: (value, name) => {
        setAllValue({ ...allValues, [name]: value });
      },
      handleSaveCustomer: (data) => {
        dispatch(
          updateCustomer({
            customerId: customer._id,
            data: {
              application: applicationId,
              ...data,
            },
          })
        );
      },
      handleResidencyStatus: (value) => {
        dispatch(
          saveCustomerProfile({
            residencyStatus: value,
            customer: customer._id,
          })
        );
      },
      handleMaritalStatus: (value) => {
        dispatch(
          saveCustomerProfile({
            maritalStatus: value,
            customer: customer._id,
          })
        );
      },
      handleLicenseNumber: () =>
        customer
          ? customer?.documents?.find((doc) => {
            return doc.details?.licence_number
              ? doc.details?.licence_number
              : "";
          })?.details?.licence_number
          : "",
      handleSaveIDType: (value) => {
        handle.handleSaveCustomer({
          IDType: value,
        });
      },
      blurFn: (fieldName, value) => {
        try {
          let fieldErr = fieldErrors[fieldName];
          if (fieldName == "middleNames") {
            let isValid = regex.nameOptionalRegex.test(value);
            if (isValid) {
              fieldErr = "";
            } else {
              fieldErr = "Only letters and ' - characters allowed.";
            }
          } else if (fieldName == "IDNumber") {
            let isValid = regex.drivingPassportRegexOptional.test(value);
            if (isValid) {
              fieldErr = "";
            } else {
              fieldErr = "Only alphanumeric, space and - characters allowed.";
            }
          }
          // else if(fieldName == "dateOfBirth"){
          //   let isValid = regex.dateOfBirthRegexOptional.test(value)
          //   if(isValid){
          //     fieldErr =""
          //   }else{
          //     fieldErr = "Only alphanumeric, space and - characters allowed.";
          //   }
          // }
          setFieldErrors((pre) => {
            return {
              ...pre,
              [fieldName]: fieldErr,
            };
          });
          if (fieldErr === "") {
            handle.handleSaveCustomer({
              [fieldName]:
                fieldName === "dateOfBirth"
                  ? dayjs(value, "DD-MM-YYYY").format("DD-MM-YYYY")
                  : value,
            });
          }
        } catch (error) {
          const newErrors = {};
          error.inner.forEach((validationError) => {
            newErrors[validationError.path] = validationError.message;
          });
          setFieldErrors(newErrors);
        }
      },
    };

    return (
      <React.Fragment>
        <Grid
          style={{
            margin: "0 0 30px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            paddingBottom: "30px",
          }}
        >
          <Grid item md={12} sm={12} style={{ margin: "0 0 10px" }}>
            <Typography id="input-slider" fontWeight={600}>
              Personal details
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Stack spacing={1} direction="row" style={{ margin: "0 0 10px", width: '100%' }}>
              <Grid item md={2} sm={2}>
                <FormControl fullWidth variant="filled">
                  <InputLabel id="customer-title">Title*</InputLabel>
                  <Select
                    size="small"
                    labelId="customer-title"
                    id="demo-simple-select"
                    value={allValues?.customerTitle}
                    label="Title"
                    onChange={(event) => {
                      handle.onChangeField(event?.target?.value, "customerTitle")
                    }}
                    onBlur={(event) =>
                      handle.blurFn("customerTitle", event.target.value)
                    }
                    error={customerId === customer?._id && fieldErrors?.customerTitle}
                  >
                    {customerTitleList.map((i) => (
                      <MenuItem value={i.value}>{i.label}</MenuItem>
                    ))}
                  </Select>
                  {customerId === customer?._id && fieldErrors?.customerTitle && (
                    <FormHelperText
                      sx={{ color: "#d32f2f" }}
                    >
                      {fieldErrors.customerTitle}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item md={4} sm={4}>
                <TextField
                  fullWidth
                  required
                  id="outlined-basic"
                  type="text"
                  name="firstName"
                  label="First name"
                  variant="filled"
                  size="small"
                  value={allValues?.firstName}
                  error={customerId === customer?._id && fieldErrors?.firstName}
                  helperText={
                    customerId === customer?._id && fieldErrors?.firstName
                  }
                  onChange={(event) =>
                    handle.onChangeField(event?.target?.value, "firstName")
                  }
                  onBlur={(e) => handle.blurFn("firstName", e.target.value)}
                />
              </Grid>

              <Grid item md={4} sm={4}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  type="text"
                  name="middleNames"
                  label="Other given names"
                  variant="filled"
                  size="small"
                  value={allValues?.middleNames}
                  error={fieldErrors?.middleNames}
                  helperText={fieldErrors?.middleNames}
                  onChange={(event) =>
                    handle.onChangeField(event?.target?.value, "middleNames")
                  }
                  onBlur={(e) => handle.blurFn("middleNames", e.target.value)}
                />
              </Grid>

              <Grid item md={4} sm={4}>
                <TextField
                  fullWidth
                  required
                  id="outlined-basic"
                  type="text"
                  name="lastName"
                  label="Last name"
                  variant="filled"
                  size="small"
                  value={allValues?.lastName}
                  error={customerId === customer?._id && fieldErrors?.lastName}
                  helperText={
                    customerId === customer?._id && fieldErrors?.lastName
                  }
                  onChange={(event) =>
                    handle.onChangeField(event?.target?.value, "lastName")
                  }
                  onBlur={(e) => handle.blurFn("lastName", e.target.value)}
                />
              </Grid>
            </Stack>

            {/* <Grid item md={12} sm={12}>
            <Typography id="input-slider" gutterBottom>
            ID Number
            </Typography>
            </Grid> */}
            <Stack spacing={1} direction="row" style={{ margin: "0 0 10px", width: '100%' }}>
              <Grid item md={6} sm={6}>
                <InputMask
                  mask="99-99-9999"
                  value={allValues?.dateOfBirth || null}
                  disabled={false}
                  maskChar={null}
                  onChange={(event) => {
                    handle.onChangeField(
                      event?.target.value || null,
                      "dateOfBirth"
                    );
                  }}
                  onBlur={(e) => handle.blurFn("dateOfBirth", e.target.value)}
                >
                  {() => (
                    <TextField
                      label="Date of birth"
                      size="small"
                      fullWidth
                      placeholder="DD-MM-YYYY"
                      variant="filled"
                      name="dateOfBirth"
                      style={{ background: "#ffffff" }}
                      error={
                        customerId === customer?._id && fieldErrors?.dateOfBirth
                          ? true
                          : false
                      }
                      helperText={
                        customerId === customer?._id && fieldErrors?.dateOfBirth
                      }
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  type="text"
                  name="IDNumber"
                  label={allValues?.IDType === ID_TYPE.LICENCE ? 'Driver licence' : 'Passport no'}
                  variant="filled"
                  size="small"
                  value={allValues?.IDNumber}
                  error={customerId === customer?._id && fieldErrors?.IDNumber}
                  helperText={
                    customerId === customer?._id && fieldErrors?.IDNumber
                  }
                  onChange={(event) =>
                    handle.onChangeField(event.target.value, "IDNumber")
                  }
                  onBlur={(e) => handle.blurFn("IDNumber", e.target.value)}
                />
                <LicencePassportToggle allValues={allValues} setAllValue={setAllValue} handleSaveIDType={handle.handleSaveIDType} />
              </Grid>
              <Grid item xs={12} sm={4}>
                {/* <TextField
                  // required
                  fullWidth
                  id="outlined-basic"
                  type="text"
                  name={`IDCardNumber_${customer._id}`}
                  label={(allValues?.IDType === ID_TYPE.LICENCE ? ID_TYPE.LICENCE : ID_TYPE.PASSPORT) + " Card No"}
                  variant="filled"
                  size="small"
                  value={allValues?.IDCardNumber}
                  error={fieldErrors[`IDCardNumber_${customer._id}`]}
                  helperText={fieldErrors[`IDCardNumber_${customer._id}`]}
                  onChange={(event) => handle.onChangeField(event.target.value, `IDCardNumber_${customer._id}`)}
                  onBlur={(e) => handle.blurFn(`IDCardNumber_${customer._id}`, e.target.value)}
                /> */}
                <TextField
                  // required
                  fullWidth
                  id="outlined-basic"
                  type="text"
                  name="IDCardNumber"
                  label={(allValues?.IDType === ID_TYPE.LICENCE ? ID_TYPE.LICENCE : ID_TYPE.PASSPORT) + " Card No"}
                  variant="filled"
                  size="small"
                  value={allValues?.IDCardNumber}
                  error={
                    customerId === customer?._id && fieldErrors?.IDCardNumber
                  }
                  helperText={
                    customerId === customer?._id && fieldErrors?.IDCardNumber
                  }
                  onChange={(event) =>
                    handle.onChangeField(event.target.value, "IDCardNumber")
                  }
                  onBlur={(e) => handle.blurFn("IDCardNumber", e.target.value)}
                />
              </Grid>
            </Stack>
            <Grid container spacing={1}>
              <Grid item sm={9} md={8} lg={8}>
                <Stack>
                  <Typography variant="subtitle2">Residency status</Typography>
                  <RadioButtonGroup
                    required
                    name={"residencyStatusValue"}
                    value={allValues?.residencyStatusValue}
                    handleValue={(value) => {
                      handle.onChangeRadio(value, "residencyStatusValue");
                      handle.handleResidencyStatus(value);
                    }}
                    optionStyle={{
                      width: "50%",
                      padding: "8px",
                      minHeight: "20px",
                    }}
                    options={[
                      { icon: "House", label: "Permanent Resident" },
                      { icon: "HomeWork", label: "New Zealand Citizen" },
                      { icon: "House", label: "Australian Citizen" },
                      { icon: "House", label: "Student Visa" },
                      { icon: "HomeWork", label: "Working Visa" },
                    ]}
                  />
                </Stack>
              </Grid>
              <Grid item sm={3} md={4} lg={4}>
                <Typography variant="subtitle2">Marital status</Typography>
                <RadioButtonGroup
                  required
                  value={allValues?.maritalStatusValue}
                  name={"maritalStatusValue"}
                  handleValue={(value) => {
                    handle.onChangeRadio(value, "maritalStatusValue");
                    handle.handleMaritalStatus(value);
                  }}
                  options={[
                    { icon: "House", label: "Married/Defacto" },
                    { icon: "HomeWork", label: "Single/Widowed" },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
);

export default CustomerPersonalDetails;
