import { typeOfSaleList, employmentTypes } from "src/constants";
import criteria from "src/utils/criteria";
import perm from "./perm";
import temp from "./temp";
import benefits from "./benefits";

const { SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL, BENEFITS } =
  employmentTypes;

const { typeOfSale, assetType, employmentType } = criteria;

export default {
  date: "31 Aug 2023",
  lender: "Australian Premier Finance",
  type: "consumer",
  logoURL: "https://assets.financeable.com.au/lender-logos/APL.png",
  actions: ["email"],
  lenderCredentials: [
    {
      label: "Email",
      attribute: "email",
    },
    {
      label: "User Name",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
    {
      label: "Merchant ID",
      attribute: "organisationId",
    },
    {
      label: "User ID",
      attribute: "brokerId",
    },
    {
      label: "Api Key",
      attribute: "apiKey",
    },
  ],
  requiredFields: {
    asset: [
      "assetType",
      "typeOfSale",
      "ageOfAsset",
      "glassesMake",
      "glassesModel",
      "glassesVariant",
      "assetValue",
    ],
    customer: [
      "firstName",
      "lastName",
      "dateOfBirth",
      "phoneNumbers[0].number",
      "emails[0].address",
      //   "addresses[0].street",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "IDNumber",
      "IDCardNumber",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "employers[0].entityName",
      "employers[0].timeOfEmployment",
      "employers[0].employmentType",
    ],
    agreement: ["introducerDeclarationAgree"],
  },
  fees: [
    {
      name: "Establishment fee",
      value: 1290,
      capitalised: true,
      frequency: "upfront",
      criteria: [
        typeOfSale(typeOfSaleList.DEALER_SALE),
        assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ],
    },
    {
      name: "Origination fee",
      value: 990,
      capitalised: true,
      frequency: "upfront",
      criteria: [
        employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
      ],
    },
    {
      name: "Monthly account fee",
      value: 14.0,
      capitalised: false,
      frequency: "monthly",
    },
    // {
    //   name: "Risk fee",
    //   value: 2,
    //   calc: "percent",
    //   capitalised: true,
    //   frequency: "upfront",
    //   criteria: [creditScoreGreaterThan(600)],
    // },
    // {
    //   name: "Risk fee",
    //   value: 3,
    //   calc: "percent",
    //   capitalised: true,
    //   frequency: "upfront",
    //   criteria: [creditScoreBetween(550, 600)],
    // },
    // {
    //   name: "Risk fee",
    //   value: 4,
    //   calc: "percent",
    //   capitalised: true,
    //   frequency: "upfront",
    //   criteria: [creditScoreBetween(500, 550)],
    // },
    // {
    //   name: "Risk fee",
    //   value: 4.5,
    //   calc: "percent",
    //   capitalised: true,
    //   frequency: "upfront",
    //   criteria: [creditScoreBetween(100, 499)],
    // },
  ],
  brokerage: [],
  loading: [
    // {
    //   name: "Age of asset at start 36-95 mths",
    //   value: 3,
    //   valueType: "percentage",
    //   criteria: [ageOfAssetBetween(36, 95)],
    // },
    // {
    //   name: "Age of asset at start >95 mths",
    //   value: 3,
    //   valueType: "percentage",
    //   criteria: [ageOfAssetGreaterThan(95)],
    // },
  ],
  info: [
    `All income types accepted - can be 100 % benefits only`,
    `BENEFITS ONLY - Maximum lend of $10,000 only`,
    `CASUAL ONLY – 6 months or more continual employment MAX LEND $20,000.00(plus add ons) & subject to assessment`,
    `EXCLUSIONS:`,
    `- Bankruptcy Undischarged`,
    `- Finance defaults < 6 months`,
    `- >3 active payday and BNPLs`,
  ],
  productTiers: [...perm, ...temp, ...benefits],
};
