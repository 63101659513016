import { typeOfSaleList } from "src/constants";
import criteria from "src/utils/criteria";

import lowDocTierOne from "./lowDocTierOne";
import lowDocTierTwo from "./lowDocTierTwo";
import lowDocTierThree from "./lowDocTierThree";
import lightDoc from "./lightDoc";
import fullDoc from "./fullDoc";

const {
  livingArrangements,
  termIsLessThan,
  termIsGreaterThan,
  typeOfSale,
  hasDeposit,
  assetValueBetween,
  assetType,
  ageOfAssetGreaterThan,
  ageOfAssetLessThan,
  ageOfAssetBetween,
  ageOfAssetAtEndLessThan,
  loanAmountLessThan,
  loanAmountBetween,
  timeInBusinessLessThan,
  timeInBusinessGreaterThan,
  gstRegistrationLessThan,
  gstRegistrationGreaterThan,
  compound,
  creditScoreGreaterThan,
  brokerageIsBetween,
  brokerageIsEqualTo,
} = criteria;

export default {
  date: "23 August 2023",
  lender: "Dynamoney",
  type: "commercial",
  logoURL: "https://assets.financeable.com.au/lender-logos/dynamoney-logo.svg",
  actions: ["upload"],
  lenderCredentials: [
    {
      label: "Email",
      attribute: "email",
    },
    {
      label: "User Name",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
    {
      label: "Merchant ID",
      attribute: "organisationId",
    },
    {
      label: "User ID",
      attribute: "brokerId",
    },
    {
      label: "Api Key",
      attribute: "apiKey",
    },
  ],
  requiredFields: {
    entity: [
      "abn",
      // "addresses[0].streetAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
    ],
    customer: [
      "phoneNumbers[0].number",
      "emails[0].address",
      "firstName",
      "lastName",
      "dateOfBirth",
      // "addresses[0].streetAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "IDNumber",
      "IDCardNumber",
      // "emails[0].address",
      // "phoneNumbers[0].number",
    ],
    loanDetails: ["rate"],
    asset: [
      "assetType",
      "typeOfSale",
      "ageOfAsset",
      "glassesMake",
      "glassesModel",
      "glassesVariant",
      "assetValue",
    ],
  },
  fees: [
    {
      name: "Private sale fee",
      value: 695,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(typeOfSaleList.PRIVATE_SALE)],
    },
    {
      name: "Authorised dealer sale fee",
      value: 495,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(typeOfSaleList.DEALER_SALE)],
    },
    {
      name: "Origination fee",
      value: 900,
      capitalised: true,
      frequency: "upfront",
    },
  ],
  brokerage: [
    {
      value: 4,
      max: 8,
      valueType: "percentage",
      criteria: [assetValueBetween(2000, 2000000)],
    },
  ],
  loading: [
    {
      name: "Non property owner",
      value: 1,
      valueType: "percentage",
      criteria: [
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
      ],
    },
    {
      name: "Private sale",
      value: 1,
      valueType: "percentage",
      criteria: [typeOfSale("Private sale")],
    },
    {
      name: "Loan amount <10000",
      value: 2,
      valueType: "percentage",
      criteria: [loanAmountLessThan(10000)],
    },
    {
      name: "Time in business/GST <24 months",
      value: 2,
      valueType: "percentage",
      criteria: [timeInBusinessLessThan(24), gstRegistrationLessThan(24)],
    },
    {
      name: "Term >60 months",
      value: 1,
      valueType: "percentage",
      criteria: [termIsGreaterThan(60)],
    },
    {
      name: "Term <36 months",
      value: 0.5,
      valueType: "percentage",
      criteria: [termIsLessThan(36)],
    },
    {
      name: "Brokerage 6%",
      value: 0.5,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(6)],
    },
    {
      name: "Brokerage 7%",
      value: 1,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(7)],
    },
    {
      name: "Brokerage 8%",
      value: 1.5,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(8)],
    },
  ],
  info: [
    `Fitness, Rideshare & Beauty Industry (Medical Professionals/Registered nurses considers as an exception)`,
    `Year terms only for wheeled & tracked assets with Max EOT age 15 years`,
  ],
  productTiers: [
    ...lowDocTierOne,
    ...lowDocTierTwo,
    ...lowDocTierThree,
    ...lightDoc,
    ...fullDoc,
  ],
};
