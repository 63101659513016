import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
// import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import {
  updateNotes,
  applicationSelector,
  getApplication,
} from "src/store/slices/applicationFormSlice";
import { Box, Link, Stack, Tooltip, Typography, } from "@mui/material";
// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import ApplicationProgress from "./ApplicationProgress"
import StyledTextarea from "./ApplicationNotes";
import { startCase, union } from "lodash";

// const rawContent = {
//   blocks: [
//     {
//       data: {},
//       depth: 0,
//       entityRanges: [],
//       inlineStyleRanges: [],
//       key: "2vm6d",
//       text: "Type your notes here...",
//       type: "normal",
//       color: "#bbbbbb",
//     },
//   ],
//   entityMap: {},
// };

const assetDetailsItems = [
  "ageOfAsset",
  "assetType",
  "assetValue",
  "typeOfSale",
  "make",
  "model"
]

const assetDetailsItemsGlasses = [
  "ageOfAsset",
  "assetType",
  "assetValue",
  "typeOfSale",
  "glassesMake",
  "glassesModel",
]

let employerDetailsItems = [
  "timeOfEmployment",
]

let customerDetailsItems = [
  "firstName",
  "lastName",
  "dateOfBirth",
  "emails",
  "phoneNumbers",
  "addresses",
  "yearsAt",
  "monthsAt",
  "livingArrangements",
  "IDNumber"
]

const primaryCustomerDetailsItemsCommercial = [
  "customerAssets",
  "liabilities",
  "privacy",
]

const primaryCustomerDetailsItemsConsumer = [
  "customerAssets",
  "liabilities",
  "income",
  "expenses",
  "employers",
  "privacy",
]

const EditorNotes = React.memo(({ handleApplicationComplete }) => {

  const dispatch = useDispatch();
  const application = useSelector(applicationSelector);
  const { applicationType } = application

  const primaryCustomerDetailsItems = applicationType === "commercial" ? primaryCustomerDetailsItemsCommercial : primaryCustomerDetailsItemsConsumer
  const primaryCustomerDetailsItemsCount = primaryCustomerDetailsItems.length + customerDetailsItems.length

  const notes = application?.notes?.value;

  const [noteValue, setNoteValue] = React.useState("")

  // const [editorState, setEditorState] = React.useState(
  //   EditorState.createWithContent(convertFromRaw(notes || rawContent))
  // );

  // const [rawText, setRawText] = React.useState(0);
  const [applicationProgress, setApplicationProgress] = React.useState(0);
  const [assetDetailsCompleteList, setAssetDetailsCompleteList] = React.useState();

  function handleAssetItemsProgress() {
    let progress = 0
    let complete = []
    let assetList = application?.asset?.model ||
      application?.asset?.make ? assetDetailsItems : assetDetailsItemsGlasses
    for (let [key, value] of Object.entries(application?.asset)) {

      if (assetList.includes(key) && value && value !== "") {
        progress++
        complete.push(key)
      }
    }
    // setAssetDetailsCompleteList(complete)

    complete = assetDetailsItems?.reduce((acc, val) => {
      // console.log(acc)
      if (complete?.includes(val)) {
        acc.push(<Typography style={{ textDecoration: "line-through" }}>{startCase(val)}</Typography >)
        return acc
      }
      acc.push(<Typography>{startCase(val)}</Typography >)
      return acc
    }, [])

    // console.log({ progress, complete })

    return { progress, complete }
  }

  function handleCustomerItemsProgress(i) {
    let progress = 0
    let complete = []
    let items = []

    if (i === 0) items = union(customerDetailsItems, primaryCustomerDetailsItems)
    else items = customerDetailsItems

    // console.log({ items })

    for (let [key, value] of Object.entries(application?.customers?.[i] || {})) {
      // console.log({ progress })
      // console.log({ key, value })
      if ((items.includes(key) && value && value !== "") || key === "documents" || key === "customerProfile") {
        // console.log({ key })
        if (key === "emails") {
          // console.log({ key })

          if (application?.customers?.[i]?.emails[0]) {
            progress++
            complete.push(key)
            continue
          }
          continue
        }
        if (key === "phoneNumbers") {
          if (application?.customers?.[i]?.phoneNumbers[0]) {
            progress++
            complete.push(key)
            continue
          }
          continue
        }
        if (key === "addresses") {
          if (application?.customers?.[i]?.addresses.length > 0) {
            progress++
            complete.push(key)

            if (application?.customers?.[i]?.addresses?.[0]?.yearsAt || application?.customers?.[i]?.addresses?.[0]?.yearsAt === 0) {
              progress++
              complete.push("yearsAt")
            }
            if (application?.customers?.[i]?.addresses?.[0]?.monthsAt || application?.customers?.[i]?.addresses?.[0]?.monthsAt === 0) {
              progress++
              complete.push("monthsAt")
            }

            continue
          }
          continue
        }

        if (applicationType === "consumer" && key === "employers") {
          if (application?.customers?.[i]?.employers?.[0]) {
            progress++
            complete.push("employers")
            continue
          }
          continue
        }
        // console.log(key === "documents")
        if (key === "documents") {
          const hasPrivacy = application?.customers?.[i]?.documents?.length > 0 &&
            application?.customers?.[i]?.documents
              ?.filter((doc) => doc.name === "Privacy agreement")[0]

          // console.log({ i })

          if (i === 0) {
            // console.log("primary")
            if (hasPrivacy) {
              progress++
              complete.push("privacy")

            }

            // console.log("bankStatements")
            // const hasBankStatements = (application?.customers?.[i]?.documents?.length > 0 &&
            //   application?.customers?.[i]?.documents
            //     ?.filter((doc) => doc.name === "Bank statements")[0]) ||
            //   application?.customers?.[i]?.creditProfile?.bankstatementsLinkSent ||
            //   application?.customers?.[i]?.creditProfile?.bankstatementsDocID

            // // console.log({ hasBankStatements })

            // if (hasBankStatements) {
            //   progress++
            //   complete.push("bankStatements")

            // }
          }

          continue
        }

        if (key === "customerProfile") {
          // console.log("customerProfile")

          if (application?.customers?.[i]?.customerProfile?.livingArrangements) {
            progress++
            complete.push("livingArrangements")
            continue
          }
          continue
        }

        if (value.items) {
          const hasValue = value.items.reduce((acc, val) => {
            return val.value ? true : acc
          }, false)

          // console.log({ hasValue })

          if (hasValue) {
            progress++
            complete.push(key)
            continue
          }
          continue
        }

        if (items.includes(key) && value && value !== "") {
          progress++
          complete.push(key)
        }

        continue
      }
    }
    // console.log({ complete })
    const completeItems = items?.reduce((acc, val) => {
      // console.log(val)
      if (complete?.includes(val)) {

        if (val === "addresses") val = "address"
        if (val === "emails") val = "email"
        if (val === "phoneNumbers") val = "phoneNumber"

        acc.push(<Typography style={{ textDecoration: "line-through" }}>{startCase(val)}</Typography >)
        return acc
      }

      if (val === "addresses") val = "address"
      if (val === "emails") val = "email"
      if (val === "phoneNumbers") val = "phoneNumber"

      acc.push(<Typography>{startCase(val)}</Typography >)
      return acc
    }, [])

    // console.log({ progress, complete })

    return { progress, complete: completeItems }
  }

  function handleEmployerItemsProgress(i) {
    let progress = 0
    let complete = []
    let items = employerDetailsItems || []

    if (applicationType === "commercial") return

    for (let [key, value] of Object.entries(application?.customers?.[i] || {})) {

      if (applicationType === "consumer" && key === "employers") {
        let totalTimeOfEmployment = 0
        // for (let [employerKey, employerValue] of Object.entries(application?.customers?.[i]?.employers || {})) {
        for (let employer of application?.customers?.[i]?.employers) {
          if (employer?.timeOfEmployment || employer?.timeOfEmployment === 0) {
            totalTimeOfEmployment += +employer?.timeOfEmployment

            if (totalTimeOfEmployment >= 24) {
              progress++
              complete.push("timeOfEmployment")
              continue
            }
          }
        }

        if (value.items) {
          const hasValue = value.items.reduce((acc, val) => {
            return val.value ? true : acc
          }, false)

          if (hasValue) {
            progress++
            complete.push(key)
            continue
          }
          continue
        }

        if (items.includes(key) && value && value !== "") {
          progress++
          complete.push(key)
        }

        continue
      }
    }
    const completeItems = items?.reduce((acc, val) => {
      if (complete?.includes(val)) {

        if (val === "timeOfEmployment") val = "Time Of Employment > 24"
        // acc.push(<Typography style={{ textDecoration: "line-through" }}>{startCase(val)}</Typography >)
        acc.push(<Typography style={{ textDecoration: "line-through" }}>{val}</Typography >)
        return acc
      }

      if (val === "timeOfEmployment") val = "Time Of Employment > 24"
      // acc.push(<Typography>{startCase(val)}</Typography >)
      acc.push(<Typography>{val}</Typography >)
      return acc
    }, [])

    return { progress, complete: completeItems }
  }

  // const onChange = (newState) => {
  //   if (JSON.stringify(editorState) !== JSON.stringify(newState)) {
  //     setRawText(newState);
  //     setEditorState(newState);
  //   }
  // };

  // const debounced = debounce(async () => {
  //   const blocks = convertToRaw(editorState.getCurrentContent());
  //   dispatch(
  //     updateNotes({
  //       value: blocks,
  //       application: application._id,
  //       _id: notes?._id,
  //     })
  //   );
  // }, 3000);

  // React.useEffect(() => {
  //   if (rawText !== 0) debounced(editorState);
  //   return () => debounced.cancel();
  // }, [rawText, editorState]);

  React.useEffect(() => {
    const assetProgress = handleAssetItemsProgress()?.progress
    const entityProgress = application?.entities?.[0] ? 1 : 0
    const customersProgress = application?.customers?.map((val, i) => handleCustomerItemsProgress(i)?.progress)
    const customersProgressTotal = customersProgress?.reduce((acc, val) => acc + val, 0)
    const employerProgress = application?.customers?.map((val, i) => handleEmployerItemsProgress(i)?.progress)
    const employerProgressTotal = employerProgress?.reduce((acc, val) => acc + val, 0)
    // console.log(primaryCustomerDetailsItems.length, application?.customers?.length, customerDetailsItems.length)
    const totalRequiredItems = (applicationType === "commercial" ? 1 : 0)
      + assetDetailsItems.length
      + primaryCustomerDetailsItems.length
      + (applicationType !== "commercial" ? employerDetailsItems?.length : 0)
      + (application?.customers?.length * customerDetailsItems.length)

    // console.log({ assetProgress, entityProgress, customersProgress, customersProgressTotal })
    const totalProgress = assetProgress + entityProgress + customersProgressTotal + (applicationType !== "commercial" ? employerProgressTotal : 0)
    setApplicationProgress(isNaN(parseInt(totalProgress / totalRequiredItems * 100)) ? 0 : parseInt(totalProgress / totalRequiredItems * 100))

    // console.log({ totalProgress, totalRequiredItems })
    handleApplicationComplete(totalProgress / totalRequiredItems >= 1)
  }, [application]);


  React.useEffect(() => {
    if (notes?.blocks) {
      setNoteValue("")

    } else {
      setNoteValue(notes || "")
    }
  }, [notes]);

  const handle = {
    onChange: (event, key) => {
      setNoteValue(event.target.value)
    },
    onBlur: (event, key) => {
      dispatch(
        updateNotes({
          value: event.target.value,
          application: application._id,
          _id: application.notes?._id,
        })
      );
    }
  }

  return (
    <Box style={{ marginTop: "20px", padding: "0 15px 20px 15px" }}>
      <Stack alignContent="column">
        <Typography variant="subtitle" style={{ marginBottom: "20px" }}><strong>Application ID:</strong> {application.humanId}</Typography>
      </Stack>
      <Stack alignContent="column" style={{
        // fontWeight: 600,
        marginBottom: "20px"
      }}>
        <Typography
          variant="subtitle"
          style={{
            fontWeight: 700,
            // marginBottom: "20px"
          }}>
          Application progress:
        </Typography>
        <Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <ApplicationProgress value={applicationProgress} />
            {/* <Typography
                variant="subtitle"
                style={{
                  // fontWeight: 600,
                  // marginBottom: "20px"
                }}>
                10%
              </Typography> */}
            <Typography
              variant="subtitle"
              style={{
                // fontWeight: 600,
                marginLeft: "10px"
              }}>
              {applicationProgress}%
            </Typography>
          </Stack>
          <Stack direction="column" alignItems="baseline">
            <Typography>Items remaining:</Typography>
            <Tooltip arrow title={handleAssetItemsProgress()?.complete} placement="right">
              <Link
                // href="#assetDetails"
                underline="hover"
                style={{ cursor: "pointer", textDecoration: handleAssetItemsProgress()?.progress === 6 && "line-through" }}
                onClick={() => {
                  if (document.getElementById("assetDetails")) document.getElementById("assetDetails").scrollIntoView({ behavior: 'smooth', block: 'start' })
                }}
              >
                Asset details: {handleAssetItemsProgress()?.progress}/6
              </Link>
            </Tooltip>
            {applicationType === "commercial" && <Link
              // href="#addEntity"
              underline="hover"
              style={{ cursor: "pointer", textDecoration: application?.entities?.[0] && "line-through" }}
              onClick={() => {
                if (document.getElementById("addEntity")) document.getElementById("addEntity").scrollIntoView({ behavior: 'smooth', block: 'start' })
              }}
            >
              {application?.entities?.[0] ? "Entity" : "Add entity"}</Link>
            }
            {application?.customers?.[0] ?
              application?.customers?.map((customer, i) => <>
                <Tooltip arrow title={handleCustomerItemsProgress(i)?.complete} placement="right">
                  <Link
                    // href="#"
                    underline="hover"
                    style={{
                      cursor: "pointer",
                      textDecoration:
                        handleCustomerItemsProgress(i).progress / (i === 0 ? primaryCustomerDetailsItemsCount : customerDetailsItems.length) === 1 && "line-through"
                    }}
                    onClick={() => {
                      if (document.getElementById(`applicant-${customer.firstName}-${customer.lastName}`)) document.getElementById(`applicant-${customer.firstName}-${customer.lastName}`).scrollIntoView({ behavior: 'smooth', block: 'start' })
                    }}
                  >
                    Guarantor {i + 1} details: {handleCustomerItemsProgress(i).progress}/{i === 0 ? primaryCustomerDetailsItemsCount : customerDetailsItems.length}
                  </Link>
                </Tooltip>
                {applicationType === "consumer" &&
                  // <Link
                  //   // href="#addEntity"
                  //   underline="hover"
                  //   style={{ cursor: "pointer", textDecoration: application?.customers?.[i].employers?.[0] && "line-through" }}
                  //   onClick={(e) => {
                  //     e.preventDefault();
                  //     if (document.getElementById("addEmployer")) document.getElementById("addEmployer").scrollIntoView({ behavior: 'smooth', block: 'start' })
                  //   }}
                  // >
                  //   {application?.customers?.[i].employers?.[0] ? "Employer" : "Add employer"}
                  //   </Link>

                  <Tooltip arrow title={handleEmployerItemsProgress(i)?.complete} placement="right">
                    <Link
                      // href="#"
                      underline="hover"
                      style={{
                        cursor: "pointer",
                        textDecoration:
                          handleEmployerItemsProgress(i).progress / (employerDetailsItems?.length) === 1 && "line-through"
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        if (document.getElementById("addEmployer")) document.getElementById("addEmployer").scrollIntoView({ behavior: 'smooth', block: 'start' })
                      }}
                    >
                      Guarantor {i + 1} employer details: {handleEmployerItemsProgress(i).progress}/{employerDetailsItems?.length}
                    </Link>
                  </Tooltip>
                }
              </>
              ) :
              <Link
                // href="#addGuarantor"
                underline="hover"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (document.getElementById("addGuarantor")) document.getElementById("addGuarantor").scrollIntoView({ behavior: 'smooth', block: 'start' })
                }}
              >
                Add a guarantor
              </Link>
            }
          </Stack>
        </Stack>
      </Stack>
      {/* <div style={{ marginTop: "64px" }}> */}
      <Typography style={{ fontWeight: 700, marginBottom: "5px" }}> Application Notes:</Typography>
      <Box
        style={{ border: "1px solid rgba(0, 0, 0, 0.12)", padding: "5px" }}
      >
        {/* <Editor
          toolbarHidden
          onBlur={() => {
            const blocks = convertToRaw(editorState.getCurrentContent());
            dispatch(
              updateNotes({
                value: blocks,
                application: application._id,
                _id: notes?._id,
              })
            );
          }}
          editorState={editorState}
          onEditorStateChange={onChange}
        /> */}

        <StyledTextarea handle={handle} value={noteValue} minH={"30em"} />

      </Box>
    </Box>
  );
});

export default EditorNotes;