import React from "react";

import {
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
} from "@mui/material";
import { sendDisclosure } from "src/store/slices/applicationFormSlice";
import { useDispatch } from "react-redux";

const BmaSendDisclosure = ({
    productDetails,
    application,
    handleOpenDisclosureDialog,
    handleCloseDisclosureDialog,
    disclosureFields,
    setDisclosureFields,
    openDisclosureDialog,
  }) => {
    const dispatch = useDispatch();
  return (
 <React.Fragment>
    <Dialog
      open={openDisclosureDialog}
      onClose={handleCloseDisclosureDialog}
    >
      <DialogTitle>Send Proposal Disclosure Statement</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please review and update the details below.
        </DialogContentText>
        <Stack
          direction="column"
        >
          <TextField
            autoFocus
            margin="dense"
            size="small"
            label="Indicative rate"

            variant="outlined"
            value={disclosureFields.rate}
            onChange={(e) => {
              setDisclosureFields({
                ...disclosureFields,
                rate: e.target.value,
              })
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            size="small"
            label="Indicative monthly repayment"

            variant="outlined"
            value={disclosureFields.repayments}
            onChange={(e) => {
              setDisclosureFields({
                ...disclosureFields,
                repayments: e.target.value,
              })
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            size="small"
            label="Origination fee"

            variant="outlined"
            value={disclosureFields.originationFee}
            onChange={(e) => {
              setDisclosureFields({
                ...disclosureFields,
                originationFee: e.target.value,
              })
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            size="small"
            label="Inspection fee"

            variant="outlined"
            value={disclosureFields.inspectionFee}
            onChange={(e) => {
              setDisclosureFields({
                ...disclosureFields,
                inspectionFee: e.target.value,
              })
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            size="small"
            label="Application fee"

            variant="outlined"
            value={disclosureFields.applicationFee}
            onChange={(e) => {
              setDisclosureFields({
                ...disclosureFields,
                applicationFee: e.target.value,
              })
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            size="small"
            id="rate"
            label="Asset registration fee"

            variant="outlined"
            value={disclosureFields.registrationFee}
              onChange={(e) => {
                setDisclosureFields({
                  ...disclosureFields,
                  registrationFee: e.target.value,
                })
              }}
          />
          <TextField
            autoFocus
            margin="dense"
            size="small"
            label="Lender monthly account keeping fee"

            variant="outlined"
            value={disclosureFields.accountFee}
            onChange={(e) => {
              setDisclosureFields({
                ...disclosureFields,
                accountFee: e.target.value,
              })
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            size="small"
            label="Referral fee"
            variant="outlined"
            value={disclosureFields.referralFee}
            onChange={(e) => {
              setDisclosureFields({
                ...disclosureFields,
                referralFee: e.target.value,
              })
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            size="small"
            label="Referrer"
            variant="outlined"
            value={disclosureFields.referrer}
            onChange={(e) => {
              setDisclosureFields({
                ...disclosureFields,
                referrer: e.target.value,
              })
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDisclosureDialog}>Cancel</Button>
        <Button onClick={() => {
          handleCloseDisclosureDialog()
          dispatch(sendDisclosure({
            ...disclosureFields, ...{
              applicationId: application._id,
              lenderType: productDetails?.lender,
            }
          }))
        }}>Send</Button>
      </DialogActions>
    </Dialog>
  </React.Fragment>
  
  )
}

export default BmaSendDisclosure