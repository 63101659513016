import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Box,
  TextField,
  FormControl,
  Typography,
  Divider,
  Stack,
} from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateLenderSetting,
  createLenderSetting,
} from "src/store/slices/userSlice";

export default forwardRef(({ lenderSetting, lender, handleClose }, ref) => {
  const userData = useSelector(({ user }) => user);
  const { _id } = userData;

  const [lenderSettingState, setLenderSettingState] = useState({
    lender: lenderSetting?.lender || lender || "",
    lenderEmail: lenderSetting?.lenderEmail || "",
    username: lenderSetting?.username || "",
    password: lenderSetting?.password || "",
    brokerId: lenderSetting?.brokerId || "",
    organisationId: lenderSetting?.organisationId || "",
    apiKey: lenderSetting?.apiKey || "",
    user: lenderSetting?.user || _id || "",
  });

  const dispatch = useDispatch();
  const primaryTypographyProps = {
    // color: 'primary',
    fontWeight: "400",
    fontSize: "13px",
    // variant: 'body2',
  };

  useEffect(() => {
    setLenderSettingState({
      ...lenderSettingState,
      lender: lender,
    });
  }, [lender]);

  const handle = {
    onChange: (value, name) => {
      setLenderSettingState({
        ...lenderSettingState,
        [name]: value,
      });
    },
    onBlur: (value, name) => {
      if (lenderSetting?._id) {
        console.log("Updating");
        dispatch(
          updateLenderSetting({
            _id: lenderSetting?._id,
            ...lenderSettingState,
            [name]: value,
          })
        );
      }
      setLenderSettingState({
        ...lenderSettingState,
        [name]: value,
      });
    },
    createNewLenderSetting: () => {
      console.log("Creating");
      if (!lenderSettingState.lender) {
        console.log("failed");

        return;
      }

      dispatch(createLenderSetting(lenderSettingState)).then(({ payload }) => {
        if (payload.data) {
          handleClose();
        }
      });
    },
  };

  useImperativeHandle(ref, () => ({
    handleCreateLenderSetting() {
      handle.createNewLenderSetting();
    },
  }));

  return (
    <>
      <Stack spacing={3} sx={{ width: "100%", fontSize: "10px" }}>
        <Stack>
          <Typography variant="h5" style={{ margin: "0 0 5px 0" }}>
            Email
          </Typography>
          <Typography
            variant="body2"
            style={{ fontSize: "13px", margin: "0 0 10px 0" }}
          >
            Setup the email for submission.
          </Typography>
          <Divider style={{ margin: "0 0 20px 0" }} />
          <TextField
            label="Lender email address"
            variant="filled"
            value={lenderSettingState?.lenderEmail}
            type="email"
            onChange={(event) =>
              handle.onChange(event.target.value, "lenderEmail")
            }
            onBlur={(event) => handle.onBlur(event.target.value, "lenderEmail")}
            size="small"
            style={{ margin: "0 0 5px 0" }}
          />
          <Typography style={{ fontSize: "12px", margin: "0 0 3px 0" }}>
            The email address you use to submit applications to{" "}
            {lenderSettingState?.lender || "the lender"}.
          </Typography>
        </Stack>
        <Divider style={{ margin: "20px 0 10px 0" }} />
        <Stack>
          <Typography variant="h5" style={{ margin: "0 0 5px 0" }}>
            Broker Portal Credentials
          </Typography>
          <Typography
            variant="body2"
            style={{ fontSize: "13px", margin: "0 0 10px 0" }}
          >
            Setup your credentials for a lender's broker portal to automatically
            upload applications.
          </Typography>
          <Divider style={{ margin: "0 0 20px 0" }} />
          <TextField
            label="Email/Username"
            variant="filled"
            value={lenderSettingState?.username}
            onChange={(event) =>
              handle.onChange(event.target.value, "username")
            }
            onBlur={(event) => handle.onBlur(event.target.value, "username")}
            size="small"
            style={{ margin: "0 0 5px 0" }}
          />
          <TextField
            label="Password"
            variant="filled"
            type="password"
            value={lenderSettingState?.password}
            onChange={(event) =>
              handle.onChange(event.target.value, "password")
            }
            onBlur={(event) => handle.onBlur(event.target.value, "password")}
            size="small"
            style={{ margin: "0 0 5px 0" }}
          />
          <Typography style={{ fontSize: "12px", margin: "0 0 3px 0" }}>
            Your credentials are encrypted and stored securely with bank level
            security.
          </Typography>
        </Stack>
        <Divider style={{ margin: "20px 0 10px 0" }} />
        <Stack>
          <Typography variant="h5" style={{ margin: "0 0 5px 0" }}>
            API Credentials
          </Typography>
          <Typography
            variant="body2"
            style={{ fontSize: "13px", margin: "0 0 10px 0" }}
          >
            Setup your API credentials for automatic quoting, submission and
            more with the lender.
          </Typography>
          <Divider style={{ margin: "0 0 20px 0" }} />
          <TextField
            label="Broker ID"
            value={lenderSettingState?.brokerId}
            onChange={(event) =>
              handle.onChange(event.target.value, "brokerId")
            }
            onBlur={(event) => handle.onBlur(event.target.value, "brokerId")}
            variant="filled"
            size="small"
            style={{ margin: "0 0 3px 0" }}
          /><Typography style={{ fontSize: "12px", margin: "0 0 10px 0" }}>
            Your ID given to you by {lenderSettingState?.lender || "the lender"}.
          </Typography>
          <TextField
            label="Partner/Merchant ID"
            variant="filled"
            value={lenderSettingState?.organisationId}
            onChange={(event) =>
              handle.onChange(event.target.value, "organisationId")
            }
            onBlur={(event) =>
              handle.onBlur(event.target.value, "organisationId")
            }
            size="small"
            style={{ margin: "0 0 3px 0" }}
          />
          <Typography style={{ fontSize: "12px", margin: "0 0 10px 0" }}>
            The ID of your organisation assigned by {lenderSettingState?.lender || "the lender"}.
          </Typography>
          <TextField
            label="API Key"
            variant="filled"
            value={lenderSettingState?.apiKey}
            onChange={(event) =>
              handle.onChange(event.target.value, "apiKey")
            }
            onBlur={(event) =>
              handle.onBlur(event.target.value, "apiKey")
            }
            size="small"
            style={{ margin: "0 0 3px 0" }}
          />
          <Typography style={{ fontSize: "12px", margin: "0 0 10px 0" }}>
            Your unique identifier provided by {lenderSettingState?.lender || "the lender"}.
          </Typography>
        </Stack>
        <Divider style={{ margin: "20px 0 10px 0" }} />
      </Stack>
    </>
  );
});
