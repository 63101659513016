import React, { useCallback, useState } from "react";
import { DataGrid, GridCellModes, useGridApiRef } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  applicationsSelector,
  getAllApplications,
  saveValue,
} from "src/store/slices/applicationsSlice";
import _, { debounce } from "lodash";
import { useEffect } from "react";
import { userSelector } from "src/store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Typography,
} from "@mui/material";
import StatusCell from "src/components/Application/StatusCell";
import DataGridTable from "../dataGridTable/DataGridTable";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { getApplicationTab } from "src/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "61ch",
  },
}));

const dollarStringOptions = {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
};

const brokerColumns = [
  // { field: "id", headerName: "ID", width: 90 },
  {
    field: "humanId",
    headerName: "ID",
    type: "string",
    width: 80,
    editable: false,
  },
  {
    field: "startDate",
    headerName: "Date",
    type: "string",
    width: 80,
    editable: false,
    renderCell: (params) => (
      <div>
        <Typography style={{ fontSize: "14px" }}>
          {params.value.date}
        </Typography>
        <Typography style={{ fontSize: "11px", color: "#999" }}>
          {params.value.time}
        </Typography>
      </div>
    ),
  },
  {
    field: "applicationType",
    headerName: "Loan type",
    type: "string",
    width: 120,
    // editable: true,
  },
  // {
  //   field: "time",
  //   headerName: "Time",
  //   type: "string",
  //   width: 100,
  //   // editable: true,
  // },

  {
    field: "status",
    headerName: "Status",
    width: 100,
    // editable: true,
  },
  // {
  //   field: "entityName",
  //   headerName: "Entity",
  //   width: 250,
  //   // editable: true,
  // },
  // {
  //   field: "firstName",
  //   headerName: "First name",
  //   width: 150,
  //   // editable: true,
  // },
  // {
  //   field: "lastName",
  //   headerName: "Last name",
  //   width: 150,
  //   // editable: true,
  // },
  {
    field: "customer",
    headerName: "Applicant",
    width: 220,
    editable: false,
    renderCell: (params) => (
      <div>
        <Typography style={{ fontSize: "14px", textWrap: "initial" }}>
          {params.value}
        </Typography>
      </div>
    ),
  },
  {
    field: "assetType",
    headerName: "Asset",
    type: "string",
    width: 200,
    editable: false,
    renderCell: (params) => (
      <div>
        <Typography style={{ fontSize: "14px", textWrap: "initial" }}>
          {params.value}
        </Typography>
      </div>
    ),
  },
  // {
  //   field: "assetValue",
  //   headerName: "Asset value",
  //   type: "string",
  //   width: 110,
  //   editable: true,
  // },
  {
    field: "financeAmount",
    headerName: "Finance amount",
    type: "string",
    width: 110,
    editable: false,
  },
];


export default function ApplicationsList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = React.useState("");
  const apiRef = useGridApiRef()
  const user = useSelector(userSelector);
  const { applications, page, isLoading } = useSelector(applicationsSelector);

  const classes = useStyles();
  const [rows, setRows] = useState([]);

  const [params, setParams] = useState({ offset: 1 });

  const handle = {
    getAllApplicationsFn: (userId, cursor, search) => {
      dispatch(getAllApplications({ userId, cursor, search, requestedList: getApplicationTab.application })); // isQuoteList: false
    },
    handleApplicationSerch: (searchTerm) => {
      (searchTerm.length >= 2 || searchTerm.length <= 0) &&
        handle.getAllApplicationsFn(user, params?.offset, searchTerm);
    },
  };

  useEffect(() => {
    if (params?.offset) {
      handle.getAllApplicationsFn(user, params?.offset, searchTerm);
      dispatch(saveValue({ page: params?.offset }));
    }
  }, [params?.offset]);

  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  const [rowCountState, setRowCountState] = useState(
    applications?.totalDocs || 0
  );

  useEffect(() => {
    setRowCountState(
      (prevRowCountState) => applications?.totalDocs ?? prevRowCountState
    );
  }, [applications?.totalDocs]);

  // useEffect(() => {
  //   if (user) handle.getAllApplicationsFn(user, page || 1, searchTerm);
  // }, [user]);

  useEffect(() => {
    const transformApplication = (doc) => {
      const assetValueCheck = doc?.asset?.assetValue
        ? _.get(doc, "asset.assetValue").toLocaleString(
          "en-US",
          dollarStringOptions
        )
        : "No Value";

      const date = new Date(_.get(doc, "createdAt"));
      const entityName = _.get(doc, "entities[0].entityName");
      const firstName = _.get(doc, "customers[0].firstName", "No");
      const lastName = _.get(doc, "customers[0].lastName", "customer");
      const assetType = _.get(doc, "asset.assetType", "Type not entered");
      const assetValue = assetValueCheck;
      const financeAmount = _.get(doc, "asset.assetValue", 0) -
        _.get(doc, "loanDetails.deposit", 0) -
        _.get(doc, "loanDetails.tradeIn", 0) +
        _.get(doc, "loanDetails.payout", 0)

      const capitalizedStatus =
        _.get(doc, "status", "").charAt(0).toUpperCase() +
        _.get(doc, "status", "").slice(1);
      const capitalizedApplicationType =
        _.get(doc, "applicationType", "").charAt(0).toUpperCase() +
        _.get(doc, "applicationType", "").slice(1);

      return _.pickBy(
        {
          humanId: _.get(doc, "humanId"),
          customer: entityName ? entityName : `${firstName} ${lastName}`,
          applicationType: capitalizedApplicationType,
          assetType,
          assetValue,
          loanAmount: _.get(doc, "loanDetails.loanAmount"),
          financeAmount: financeAmount ? financeAmount.toLocaleString("en-US", dollarStringOptions) : 'Not entered',
          term: _.get(doc, "loanDetails.term"),
          id: _.get(doc, "_id"),
          startDate: {
            date: moment(date).format("D-M-YY"),
            time: moment(date).format("h:mm a"),
          },
          broker: {
            firstName: _.get(doc, "user.firstName"),
            lastName: _.get(doc, "user.lastName"),
            org: _.get(doc, "user.organisation[0].name"),
          },
          status: capitalizedStatus,
        },
        _.identity
      );
    };

    const transformedRows = _.map(
      _.get(applications, "docs"),
      transformApplication
    );
    setRows(transformedRows);
    // time: moment(date).format("h:mm a"),
  }, [applications]);


  const debounced = useCallback(
    debounce(handle.handleApplicationSerch, 500),
    []
  );

  const [cellModesModel, setCellModesModel] = useState({});

  const handleCellClick = React.useCallback((params, event) => {
    // console.log({ params })
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if (!event.currentTarget.contains(event.target)) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {},
            ),
          }),
          {},
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {},
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = React.useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const updateApplicationStatus = ({_id, status}) => {
    const updatedRows = rows.map((row) =>
      row.id === _id ? { ...row, status: status } : row
    );
    setRows(updatedRows);
  };

  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      const updatedRows = rows.map((row) =>
        row.id === id ? { ...row, [field]: value } : row
      );
      setRows(updatedRows);
    },
    [rows]
  );


  const masterViewColumns = [
    // { field: "id", headerName: "ID", width: 90 },
    {
      field: "humanId",
      headerName: "ID",
      type: "string",
      width: 90,
      editable: false,
    },
    {
      field: "startDate",
      headerName: "Date",
      type: "string",
      width: 80,
      editable: false,
      renderCell: (params) => (
        <div>
          <Typography style={{ fontSize: "14px" }}>
            {params.value.date}
          </Typography>
          <Typography style={{ fontSize: "11px", color: "#999" }}>
            {params.value.time}
          </Typography>
        </div>
      ),
    },
    // {
    //   field: "time",
    //   headerName: "Time",
    //   type: "string",
    //   width: 80,
    //   editable: false,
    // },
    {
      field: "status",
      headerName: "Status",
      // editable: true,
      // type: 'singleSelect',
      // valueOptions: ['draft', 'Wire transfer', 'Cash'],
      width: 300,
      editable: true,
      renderEditCell: (params) => (
        <StatusCell params={params} updateApplicationStatus={updateApplicationStatus} />
      ),
    },
    {
      field: "applicationType",
      headerName: "Loan type",
      type: "string",
      width: 110,
      editable: false,
    },
    {
      field: "broker",
      headerName: "Broker",
      type: "string",
      width: 120,
      editable: false,
      renderCell: (params) => (
        <div>
          <Typography style={{ fontSize: "14px" }}>
            {params.value.firstName} {params.value.lastName}
          </Typography>
          <Typography style={{ fontSize: "11px", color: "#999" }}>
            {params.value.org}
          </Typography>
        </div>
      ),
    },
    {
      field: "customer",
      headerName: "Applicant",
      width: 220,
      editable: false,
      renderCell: (params) => (
        <div>
          <Typography style={{ fontSize: "14px", textWrap: "initial" }}>
            {params.value}
          </Typography>
        </div>
      ),
    },
    {
      field: "assetType",
      headerName: "Asset type",
      type: "string",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <div>
          <Typography style={{ fontSize: "14px", textWrap: "initial" }}>
            {params.value}
          </Typography>
        </div>
      ),
    },
    {
      field: "financeAmount",
      headerName: "Finance amount",
      type: "string",
      width: 150,
      editable: false,
    },
    {
      field: "cursor",
      headerName: "Cursor",
      type: "string",
      width: 0,
      editable: false,
      hide: true
    },
  
    // {
    //   field: "entityName",
    //   headerName: "Entity",
    //   width: 250,
    //   // editable: true,
    // },
    // {
    //   field: "firstName",
    //   headerName: "First name",
    //   width: 150,
    //   // editable: true,
    // },
    // {
    //   field: "lastName",
    //   headerName: "Last name",
    //   width: 150,
    //   // editable: true,
    // },
  
    // {
    //   field: "term",
    //   headerName: "Term",
    //   type: "string",
    //   width: 110,
    //   editable: true,
    // },
    // {
    //   field: "serviceFees",
    //   headerName: "3rd Party fees",
    //   type: "string",
    //   width: 110,
    //   editable: true,
    // },
  ];

  
  return (
    <Grid
      container
      item
      direction="column"
      spacing={0}
      style={{ padding: "20px" }}
    // style={{ marginTop: "64px", }}
    >
      <Grid
        item
        style={{
          borderBottom: "1px solid rgba(224, 224, 224, 1)",
          display: "flex",
          alignItems: "center",
          columnGap: "40px",
        }}
      >
        <Typography
          variant="h1"
          style={{
            fontSize: "24px",
            fontWeight: 800,
            letterSpacing: "-0.5px",
          }}
        >
          Applications
        </Typography>
        <div className={classes.root}>
          <FormControl className={clsx(classes.margin, classes.textField)}>
            <Input
              className="input-border-bottom"
              id="application-search"
              autoComplete={false}
              variant="filled"
              placeholder="Search…"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                debounced(e.target.value, 1000);
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              endAdornment={
                searchTerm && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setSearchTerm("");
                        searchTerm !== "" && debounced("", 1000);
                      }}
                      color="secondary"
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
          </FormControl>
        </div>
      </Grid>

      <Grid item>
        <DataGridTable
           onCellClick={(params, event) => {
            // event.defaultMuiPrevented = true;
            if (params.field !== "status") return navigate(`/application/${params.id}`);
            handleCellClick(params, event);
          }}
          onRowClick={() => {}}
          data={rows || []}
          columns={user.userType === "master" ? masterViewColumns : brokerColumns}
          rowsPerPageOptions={[10]}
          pageSize={10}
          rowCount={rowCountState}
          params={params}
          setParams={setParams}
          page={page}
          isLoading={isLoading}
          onCellEditStop={handleCellEditCommit}
        />

        {/* <DataGrid
          autoHeight
          autoPageSize
          onCellClick={(params, event) => {
            event.defaultMuiPrevented = true;
          }}
          onRowClick={(event) => {
            console.log(event);
            navigate(`/application/${event.id}`);
          }}
          rows={rows || []}
          columns={masterViewColumns}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          style={{ background: "#ffffff", border: "none" }}
          pagination
          pageSize={10}
          rowCount={rowCountState}
          paginationMode="server"
          onPageChange={handle.pageChange}
          page={page - 1 || 0}
          loading={isLoading}
          sx={{
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
              width: "7px",
              height: "7px",
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
              backgroundColor: "#999",
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
              background: "#616161",
            },
          }}
        /> */}
      </Grid>
    </Grid>
  );
}
