import { livingArrangementsTypes } from "src/constants";
import criteria from "src/utils/criteria";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } =
  livingArrangementsTypes;

const {
  livingArrangements,
  termIsGreaterThan,
  termIsLessThanOrEqualTo,
  typeOfSale,
  assetValueBetween,
  assetType,
  ageOfAssetGreaterThan,
  ageOfAssetBetween,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  ageOfAssetAtEndGreaterThan,
  gstRegistrationGreaterThan,
  creditScoreGreaterThan,
  compound,
} = criteria;

const tertiaryAssets = [
  "IT, Computer & Audio Equipment",
  "Office Equipment",
  "Restaurant & Catering Equipment",
  "Office Furniture",
  "Health & Beauty Equipment",
  "Solar",
  "Other",
];

export default {
  date: "24 Apr 2023",
  lender: "Shift",
  type: "commercial",
  logoURL: "https://assets.financeable.com.au/lender-logos/shift.png",
  actions: [],
  lenderCredentials: [
    {
      label: "Email",
      attribute: "email",
    },
    {
      label: "User Name",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
    {
      label: "Merchant ID",
      attribute: "organisationId",
    },
    {
      label: "User ID",
      attribute: "brokerId",
    },
    {
      label: "Api Key",
      attribute: "apiKey",
    },
  ],
  requiredFields: {
    entity: [
      "abn",
      // "addresses[0].streetAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
    ],
    customer: [
      "phoneNumbers[0].number",
      "emails[0].address",
      "firstName",
      "lastName",
      "dateOfBirth",
      // "addresses[0].streetAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "IDNumber",
      "IDCardNumber",
      // "emails[0].address",
      // "phoneNumbers[0].number",
    ],
    loanDetails: ["rate"],
    asset: [
      "assetType",
      "typeOfSale",
      "ageOfAsset",
      "glassesMake",
      "glassesModel",
      "glassesVariant",
      "assetValue",
    ],
  },
  fees: [
    {
      name: "Document fee",
      value: 499,
      capitalised: true,
      frequency: "upfront",
    },
  ],
  brokerage: [
    {
      value: 0,
      valueType: "percentage",
      criteria: [assetValueBetween(0, 2500000)],
    },
  ],
  loading: [],
  info: [`Minimun turnover $250,000/year`],
  productTiers: general(tertiary(baseTertiary())),
};

function general(l) {
  return l.map((product) => {
    return {
      ...product,
      criteria: [
        ...product.criteria,
        termIsLessThanOrEqualTo(60),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        creditScoreGreaterThan(600),
      ],
    };
  });
}

function tertiary(l) {
  return l.map((product) => {
    return {
      ...product,
      criteria: [...product.criteria, assetType(tertiaryAssets)],
    };
  });
}

function baseTertiary() {
  return [
    {
      name: "Equipment",
      rate: 15.95,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      ],
    },
    {
      name: "Equipment",
      rate: 15.95,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(5000, 500000),
        livingArrangements([PROPERTY_OWNER]),
      ],
    },
    {
      name: "Equipment",
      rate: 15.95,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(500000, 1000000),
        livingArrangements([PROPERTY_OWNER]),
      ],
    },
  ];
}
