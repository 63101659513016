import criteria from "src/utils/criteria";
import { livingArrangementsTypes, typeOfSaleList } from "src/constants";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } =
  livingArrangementsTypes;
const {
  DEALER_SALE,
  PRIVATE_SALE,
  BALLOON_RESIDUAL_REFINANCE,
  MID_TERM_REFINANCE,
  SALE_BUYBACK_EQUITY,
} = typeOfSaleList;

const {
  livingArrangements,
  termIsGreaterThan,
  termIsLessThanOrEqualTo,
  typeOfSale,
  hasDeposit,
  assetValueBetween,
  assetType,
  ageOfAssetGreaterThan,
  ageOfAssetLessThan,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  gstRegistrationGreaterThan,
  compound,
  ageOfAssetBetween,
  creditScoreGreaterThan,
} = criteria;

export default {
  date: "20 Feb 2024",
  lender: "Pepper",
  type: "commercial",
  logoURL: "https://assets.financeable.com.au/lender-logos%2FPepper_Money.gif",
  actions: ["upload"],
  lenderCredentials: [
    {
      label: "Email",
      attribute: "email",
    },
    {
      label: "User Name",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
    {
      label: "Merchant ID",
      attribute: "organisationId",
    },
    {
      label: "User ID",
      attribute: "brokerId",
    },
    {
      label: "Api Key",
      attribute: "apiKey",
    },
  ],
  requiredFields: {
    entity: [
      "abn",
      // "addresses[0].streetAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
    ],
    customer: [
      "phoneNumbers[0].number",
      "emails[0].address",
      "firstName",
      "lastName",
      "dateOfBirth",
      // "addresses[0].streetAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "IDNumber",
      "IDCardNumber",
      // "emails[0].address",
      // "phoneNumbers[0].number",
    ],
    loanDetails: ["rate"],
    asset: [
      "assetType",
      "typeOfSale",
      "ageOfAsset",
      "glassesMake",
      "glassesModel",
      "glassesVariant",
      "assetValue",
    ],
  },
  fees: [
    {
      name: "Document fee",
      value: 499,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale("Authorised seller")],
    },
    {
      name: "Private sale fee",
      value: 600,
      capitalised: true,
      frequency: "upfront",
      criteria: [
        typeOfSale("Private sale"),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Motorbikes, ATVs",
          "Trailers and Caravans",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Industrial Plant & Printing",
          "Manufacturing Equipment",
          "Medical Equipment",
        ]),
      ],
    },
    {
      name: "Origination fee",
      value: 1500,
      capitalised: true,
      frequency: "upfront",
    },
  ],
  brokerage: [
    {
      value: 4,
      max: 8,
      valueType: "percentage",
      criteria: [assetValueBetween(0, 250000)],
    },
  ],
  loading: [
    // {
    //   name: "Non property owner",
    //   value: 2,
    //   valueType: "percentage",
    //   criteria: [livingArrangements(["Renting", "Boarding", "Living w/ parents"]),],
    // },
    {
      name: "Private sale",
      value: 0.5,
      valueType: "percentage",
      criteria: [typeOfSale("Private sale")],
    },
    {
      name: "Term >60-84 months",
      value: 0.5,
      valueType: "percentage",
      criteria: [termIsGreaterThan(61)],
    },
  ],
  info: [
    `Defaults: Telco/Utility <$1000`,
    `Private Sales - maximum NAF for all Primary & Secondary Assets $100,000 plus fees and brokerage`,
    `No private sales available for Tertiary Assets`,
    `If new GST - Bank Statements may be required by Lender`,
    `Exposure for ABN >5 & GST >3 up to $150,000 within the first 6 months, 
     maximum of $300,000 within any 12 month period, maximum aggregate exposure up to $450,000`,
    `Exposure for ABN >2 & GST >1 up to $100,000 within thefirst 12 months, maximum aggregate exposure up to $200,000 after the first 12 months`,
    `Exposure for ABN >2 & GST >0 maximum aggregate up to $80,000 after the first 12 months`,
  ],
  productTiers: [
    {
      name: "Primary asset",
      rate: 8.29,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(60),
        gstRegistrationGreaterThan(36),
       ageOfAssetBetween(0, 36),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        loanAmountBetween(150000, 250000),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
        ]),
       
        typeOfSale([DEALER_SALE, BALLOON_RESIDUAL_REFINANCE]),
      ],
    },
    {
      name: "Primary asset",
      rate: 8.29,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(60),
        gstRegistrationGreaterThan(36),
       ageOfAssetBetween(0, 36),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        loanAmountBetween(5000, 150000),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
        ]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },
    {
      name: "Sub Primary asset",
      rate: 8.29,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(60),
        gstRegistrationGreaterThan(36),
       ageOfAssetBetween(0, 36),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        loanAmountBetween(5000, 110000),
        assetType(["Trailers and Caravans"]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },
    {
      name: "Primary asset",
      rate: 8.29,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(60),
        gstRegistrationGreaterThan(36),
       ageOfAssetBetween(0, 36),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        loanAmountBetween(5000, 55000),
        assetType(["Motorbikes, ATVs"]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },

    {
      name: "Primary asset",
      rate: 9.69,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(60),
        gstRegistrationGreaterThan(36),
        ageOfAssetGreaterThan(36),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        loanAmountBetween(150000, 250000),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
        ]),
       
        typeOfSale(DEALER_SALE, BALLOON_RESIDUAL_REFINANCE),
      ],
    },
    {
      name: "Primary asset",
      rate: 9.69,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(60),
        gstRegistrationGreaterThan(36),
        ageOfAssetGreaterThan(36),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        loanAmountBetween(5000, 150000),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
        ]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },
    {
      name: "Sub Primary asset",
      rate: 9.69,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(60),
        gstRegistrationGreaterThan(36),
        ageOfAssetGreaterThan(36),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        loanAmountBetween(5000, 110000),
        assetType(["Trailers and Caravans"]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },
    {
      name: "Mid Primary asset",
      rate: 9.69,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(60),
        gstRegistrationGreaterThan(36),
        ageOfAssetGreaterThan(36),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        loanAmountBetween(5000, 55000),
        assetType(["Motorbikes, ATVs"]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },


    {
      name: "Primary asset",
      rate: 10.39,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
       ageOfAssetBetween(0, 36),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        loanAmountBetween(5000, 150000),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
        ]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },
    {
      name: "Sub Primary asset",
      rate: 10.39,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
       ageOfAssetBetween(0, 36),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        loanAmountBetween(5000, 110000),
        assetType(["Trailers and Caravans"]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },
    {
      name: "Mid Primary asset",
      rate: 10.39,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
       ageOfAssetBetween(0, 36),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        loanAmountBetween(5000, 55000),
        assetType(["Motorbikes, ATVs"]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },

    {
      name: "Primary asset",
      rate: 10.39,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
       ageOfAssetBetween(0, 36),
        ageOfAssetAtEndLessThan(16),
        loanAmountBetween(5000, 150000),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
        ]),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },
    {
      name: "Sub Primary asset",
      rate: 10.39,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
       ageOfAssetBetween(0, 36),
        ageOfAssetAtEndLessThan(16),
        loanAmountBetween(5000, 110000),
        assetType(["Trailers and Caravans"]),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },
    {
      name: "Mid Primary asset",
      rate: 10.39,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
       ageOfAssetBetween(0, 36),
        ageOfAssetAtEndLessThan(16),
        loanAmountBetween(5000, 55000),
        assetType(["Motorbikes, ATVs"]),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },

    {
      name: "Primary asset",
      rate: 11.59,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetGreaterThan(36),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        loanAmountBetween(5000, 150000),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
        ]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },
    {
      name: "Sub Primary asset",
      rate: 11.59,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetGreaterThan(36),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        loanAmountBetween(5000, 110000),
        assetType(["Trailers and Caravans"]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },
    {
      name: "Mid Primary asset",
      rate: 11.59,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetGreaterThan(36),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        loanAmountBetween(5000, 55000),
        assetType(["Motorbikes, ATVs"]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },

    {
      name: "Primary asset",
      rate: 11.59,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetGreaterThan(36),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        loanAmountBetween(5000, 150000),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
        ]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },
    {
      name: "Sub Primary asset",
      rate: 11.59,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetGreaterThan(36),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        loanAmountBetween(5000, 110000),
        assetType(["Trailers and Caravans"]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },
    {
      name: "Mid Primary asset",
      rate: 11.59,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetGreaterThan(36),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        loanAmountBetween(5000, 55000),
        assetType(["Motorbikes, ATVs"]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },


    {
      name: "Primary asset",
      rate: 12.19,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 40000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(0),
       ageOfAssetGreaterThan(0),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
        ]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },
    {
      name: "Mid Primary asset",
      rate: 12.19,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 40000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(0),
       ageOfAssetBetween(0, 36),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType(["Motorbikes, ATVs"]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },

    {
      name: "Primary asset",
      rate: 12.19,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 40000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(0),
       ageOfAssetBetween(0, 36),
        ageOfAssetAtEndLessThan(16),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        hasDeposit(20),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
        ]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },
    {
      name: "Mid Primary asset",
      rate: 12.19,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 40000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(0),
       ageOfAssetBetween(0, 36),
        ageOfAssetAtEndLessThan(16),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        hasDeposit(20),
        assetType(["Motorbikes, ATVs"]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },

    {
      name: "Primary asset",
      rate: 13.99,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 40000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(0),
        ageOfAssetGreaterThan(36),
        ageOfAssetAtEndLessThan(16),

        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
        ]),
        livingArrangements([PROPERTY_OWNER]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },
    {
      name: "Mid Primary asset",
      rate: 13.99,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 40000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(0),
        ageOfAssetGreaterThan(36),
        ageOfAssetAtEndLessThan(16),
        assetType(["Motorbikes, ATVs"]),
        livingArrangements([PROPERTY_OWNER]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },
    {
      name: "Primary asset",
      rate: 13.99,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 40000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(0),
        ageOfAssetGreaterThan(36),
        ageOfAssetAtEndLessThan(16),

        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
        ]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        hasDeposit(20),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },
    {
      name: "Mid Primary asset",
      rate: 13.99,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 40000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(0),
        ageOfAssetGreaterThan(36),
        ageOfAssetAtEndLessThan(16),
        assetType(["Motorbikes, ATVs"]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        hasDeposit(20),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },

    

    {
      name: "Primary asset",
      rate: 8.29,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(60),
        gstRegistrationGreaterThan(36),
        ageOfAssetGreaterThan(0),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        loanAmountBetween(150000, 250000),
        assetType([
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
        ]),
       
        typeOfSale(DEALER_SALE, BALLOON_RESIDUAL_REFINANCE),
      ],
    },
    {
      name: "Primary asset",
      rate: 8.29,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(60),
        gstRegistrationGreaterThan(36),
        ageOfAssetGreaterThan(0),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        loanAmountBetween(5000, 150000),
        assetType([
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
        ]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },

    

    {
      name: "Primary asset",
      rate: 10.39,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
       ageOfAssetBetween(0, 36),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        loanAmountBetween(5000, 150000),
        assetType([
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
        ]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },
    
    {
      name: "Primary asset",
      rate: 10.39,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
       ageOfAssetBetween(0, 36),
        ageOfAssetAtEndLessThan(16),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        hasDeposit(20),
        loanAmountBetween(5000, 150000),
        assetType([
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
        ]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },
    
    {
      name: "Primary asset",
      rate: 12.19,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(0),
        ageOfAssetGreaterThan(0),
        ageOfAssetAtEndLessThan(16),

        hasDeposit(10),
        loanAmountBetween(5000, 150000),
        assetType([
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
        ]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },

    {
      name: "Secondary asset",
      rate: 11.19,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(60),
        gstRegistrationGreaterThan(36),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        termIsLessThanOrEqualTo(60),
        assetType([
          "Industrial Plant & Printing",
          "Manufacturing Equipment",
          "Medical Equipment",
        ]),
       
        typeOfSale(DEALER_SALE, BALLOON_RESIDUAL_REFINANCE),
      ],
    },
    {
      name: "Secondary asset",
      rate: 11.19,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(60),
        gstRegistrationGreaterThan(36),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        termIsLessThanOrEqualTo(60),
        assetType([
          "Industrial Plant & Printing",
          "Manufacturing Equipment",
          "Medical Equipment",
        ]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },

    {
      name: "Secondary asset",
      rate: 12.29,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(100000, 150000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetAtEndLessThan(16),
        termIsLessThanOrEqualTo(60),
        livingArrangements(["Property owner"]),
        assetType([
          "Industrial Plant & Printing",
          "Manufacturing Equipment",
          "Medical Equipment",
        ]),
       
        typeOfSale(DEALER_SALE, BALLOON_RESIDUAL_REFINANCE),
      ],
    },
    {
      name: "Secondary asset",
      rate: 12.29,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetAtEndLessThan(16),
        termIsLessThanOrEqualTo(60),
        livingArrangements(["Property owner"]),
        assetType([
          "Industrial Plant & Printing",
          "Manufacturing Equipment",
          "Medical Equipment",
        ]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },

    {
      name: "Secondary asset",
      rate: 12.29,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(100000, 150000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetAtEndLessThan(16),
        termIsLessThanOrEqualTo(60),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType([
          "Industrial Plant & Printing",
          "Manufacturing Equipment",
          "Medical Equipment",
        ]),
       
        typeOfSale(DEALER_SALE, BALLOON_RESIDUAL_REFINANCE),
      ],
    },
    {
      name: "Secondary asset",
      rate: 12.29,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetAtEndLessThan(16),
        termIsLessThanOrEqualTo(60),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType([
          "Industrial Plant & Printing",
          "Manufacturing Equipment",
          "Medical Equipment",
        ]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },

    {
      name: "Tertiary asset",
      rate: 11.19,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 75000),
        timeInBusinessGreaterThan(60),
        gstRegistrationGreaterThan(36),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        termIsLessThanOrEqualTo(60),
        assetType([
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Health & Beauty Equipment",
          "Solar",
        ]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },
    {
      name: "Secondary & Tertiary asset",
      rate: 12.29,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 55000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetAtEndLessThan(16),
        termIsLessThanOrEqualTo(60),
        livingArrangements(["Property owner"]),
        assetType([
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Health & Beauty Equipment",
          "Solar",
        ]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },
    {
      name: "Secondary & Tertiary asset",
      rate: 12.29,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 55000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetAtEndLessThan(16),
        termIsLessThanOrEqualTo(60),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType([
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Health & Beauty Equipment",
          "Solar",
        ]),
       
        typeOfSale([
          DEALER_SALE,
          BALLOON_RESIDUAL_REFINANCE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          SALE_BUYBACK_EQUITY,
        ]),
      ],
    },
  ],
};
