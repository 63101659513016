import { uniq } from "lodash";
import commercialProducts from "../products/commercial";
import consumerProducts from "../products/consumer";
// import personalProducts from "../products/personal";

export const getAllLenderList = ({ isKeyOnly }) => {
  let lendersList = [];

  // Commercial
  const commercialLendersList = isKeyOnly
    ? [...new Map([...commercialProducts].map((l) => [l.lender, l])).keys()]
    : [...new Map([...commercialProducts].map((l) => [l.lender, l])).values()]

  lendersList.push(...commercialLendersList)

  // Consumer
  const consumerLendersList = isKeyOnly
    ? [...new Map([...consumerProducts].map((l) => [l.lender, l])).keys()]
    : [...new Map([...consumerProducts].map((l) => [l.lender, l])).values()]

  lendersList.push(...consumerLendersList)

  // Personal
  // const personalLendersList = isKeyOnly
  // ? [...new Map([...personalProducts].map((l) => [l.lender, l])).keys()]
  // : [...new Map([...personalProducts].map((l) => [l.lender, l])).values()]

  // lendersList.push(...personalLendersList)

  const uniqueList = uniq(lendersList);

  return uniqueList

}