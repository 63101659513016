import React from "react";
// import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, TextField } from "@mui/material";
import { typeOfSaleList } from "../../../constants";
import {
  updateAsset,
  assetSelector,
  saveAsset,
} from "../../../store/slices/applicationFormSlice";


const TypeOfSale = () => {
  const dispatch = useDispatch();
  // const { applicationId } = useParams();
  const { typeOfSale, _id } = useSelector(assetSelector);

  function handleUpdateAssetDetails(event) {
    // handleMixpanelQuoteEvent(applicationId, "type of sale", event.target.value)
    const dataToBeSave = {
      typeOfSale: event.target.value,
      ...(event.target.value === "Private sale" && { condition: "Used" })
    }

    dispatch(saveAsset(dataToBeSave))

    if (_id) {
      dispatch(updateAsset({
        _id,
        ...dataToBeSave
      }));
    }
  };

  return (
    <TextField
      fullWidth
      variant="filled"
      style={{ background: "#ffffff" }}
      select
      value={typeOfSale || ""}
      label="Type of sale"
      size="small"
      onChange={handleUpdateAssetDetails}
    >
      {Object.keys(typeOfSaleList).map((item, i) => {
        if (i === 0) return { label: typeOfSaleList[item], default: true }
        return { label: typeOfSaleList[item] }
      }).map((asset, i) => (
        <MenuItem default={asset.default} value={asset.label} key={i}>
          {asset.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default TypeOfSale