import { livingArrangementsTypes, employmentTypes } from "src/constants";
import criteria from "src/utils/criteria";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } = livingArrangementsTypes
const { FULL_TIME, PART_TIME, CASUAL, CONTRACT, SELF_EMPLOYED } = employmentTypes

const {
    termIsGreaterThan,
    typeOfSale,
    assetValueBetween,
    assetType,
    ageOfAssetGreaterThan,
    ageOfAssetLessThan,
    loanAmountBetween,
    creditScoreGreaterThan,
    livingArrangements,
    employmentType,
    compound,
    timeOfEmploymentGreaterThan,
    ageOfAssetBetween,
} = criteria;

export default [
    {
        name: "B Tier - Motor Vehicle New/Demo",
        rate: 12.24,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(5000, 150000),
            ageOfAssetLessThan(12),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            assetType([
                "Motor Vehicle", "Electric Motor Vehicle"
            ]),
            creditScoreGreaterThan(600)
        ],
    },
    {
        name: "B Tier - Motor Vehicle 12-71 months old",
        rate: 12.94,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(5000, 150000),
            ageOfAssetBetween(12, 71),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            assetType([
                "Motor Vehicle", "Electric Motor Vehicle"
            ]),
            creditScoreGreaterThan(600)
        ],
    },
    {
        name: "B Tier - Motor Vehicle 71-119 months old",
        rate: 14.04,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(5000, 150000),
            ageOfAssetBetween(71, 119),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            assetType([
                "Motor Vehicle", "Electric Motor Vehicle"
            ]),
            creditScoreGreaterThan(600)
        ],
    },
    {
        name: "B Tier - Motor Vehicle >119 months old",
        rate: 15.04,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(5000, 150000),
            ageOfAssetGreaterThan(119),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            assetType([
                "Motor Vehicle", "Electric Motor Vehicle"
            ]),
            creditScoreGreaterThan(600)
        ],
    },

    {
        name: "B Tier - Caravan New/Demo",
        rate: 12.24,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(5000, 110000),
            ageOfAssetLessThan(12),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            assetType([
                "Caravan"
            ]),
            creditScoreGreaterThan(600)
        ],
    },
    {
        name: "B Tier - Caravan 12-71 months old",
        rate: 13.04,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(5000, 110000),
            ageOfAssetBetween(12, 71),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            assetType([
                "Caravan"
            ]),
            creditScoreGreaterThan(600)
        ],
    },
    {
        name: "B Tier - Caravan 71-119 months old",
        rate: 14.44,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(5000, 110000),
            ageOfAssetBetween(71, 119),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            assetType([
                "Caravan"
            ]),
            creditScoreGreaterThan(600)
        ],
    },
    {
        name: "B Tier - Caravan >119 months old",
        rate: 15.74,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(5000, 110000),
            ageOfAssetGreaterThan(119),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            assetType([
                "Caravan"
            ]),
            creditScoreGreaterThan(600)
        ],
    },
    {
        name: "B Tier - Recreational/Marine/Misc. New/Demo",
        rate: 13.04,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(5000, 100000),
            ageOfAssetLessThan(12),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            assetType([
                "Motorbike",
                "Trailered boat",
                "Jetski/PWC",
                "ATV/Off-Road Bike",
                "Tractor",
                "Horse float",
                "Ride on mower"
            ]),
            creditScoreGreaterThan(600)
        ],
    },
    {
        name: "B Tier - Bikes/Recreational/Marine/Misc. 12-71 months old",
        rate: 13.64,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(5000, 100000),
            ageOfAssetBetween(12, 71),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            assetType([
                "Motorbike",
                "Trailered boat",
                "Jetski/PWC",
                "ATV/Off-Road Bike",
                "Tractor",
                "Horse float",
                "Ride on mower"
            ]),
            creditScoreGreaterThan(600)
        ],
    },
    {
        name: "B Tier - Bikes/Recreational/Marine/Misc. 71-119 months old",
        rate: 15.14,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(5000, 100000),
            ageOfAssetBetween(71, 119),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            assetType([
                "Motorbike",
                "Trailered boat",
                "Jetski/PWC",
                "ATV/Off-Road Bike",
                "Tractor",
                "Horse float",
                "Ride on mower"
            ]),
            creditScoreGreaterThan(600)
        ],
    },
    {
        name: "B Tier - Bikes/Recreational/Marine/Misc. >119 months old",
        rate: 16.44,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(5000, 100000),
            ageOfAssetGreaterThan(119),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            assetType([
                "Motorbike",
                "Trailered boat",
                "Jetski/PWC",
                "ATV/Off-Road Bike",
                "Tractor",
                "Horse float",
                "Ride on mower"
            ]),
            creditScoreGreaterThan(600)
        ],
    },
]