import { typeOfSaleList, livingArrangementsTypes } from "src/constants";
import criteria from "src/utils/criteria";

const { PROPERTY_OWNER } = livingArrangementsTypes

const {
  livingArrangements,
  nonGST,
  typeOfSale,
  hasDeposit,
  assetValueBetween,
  assetType,
  ageOfAssetGreaterThan,
  ageOfAssetLessThan,
  ageOfAssetBetween,
  loanAmountBetween,
  timeInBusinessBetween,
  timeInBusinessGreaterThan,
  gstRegistrationGreaterThan,
  gstRegistrationBetween,
  compound,
  creditScoreGreaterThan,
  ageOfAssetAtEndLessThan
} = criteria;

function isGSTPropertyOwner(b) {
  return b.map(product => {

    return {
      ...product,
      criteria: [
        ...product.criteria,
        loanAmountBetween(5000, 50000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(0),
        livingArrangements([PROPERTY_OWNER]),
      ]
    }
  })
}

function noGSTPropertyOwner(b) {
  return b.map(product => {

    return {
      ...product,
      criteria: [
        ...product.criteria,
        loanAmountBetween(5000, 35000),
        timeInBusinessGreaterThan(12),
        nonGST,
        livingArrangements([PROPERTY_OWNER]),
      ]
    }
  })
}

// function isGSTNonPropertyOwner(b) {
//   return b.map(product => {

//     return {
//       ...product,
//       criteria: [
//         ...product.criteria,
//         timeInBusinessGreaterThan(12),
//         gstRegistrationGreaterThan(0),
//         hasDeposit(20),
//         livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
//       ]
//     }
//   })
// }

function noGSTNonPropertyOwner(b) {
  return b.map(product => {

    return {
      ...product,
      criteria: [
        ...product.criteria,
        loanAmountBetween(5000, 35000),
        timeInBusinessGreaterThan(12),
        nonGST,
        hasDeposit(10),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
      ]
    }
  })
}

export default [
  ...isGSTPropertyOwner(base()),
  ...noGSTPropertyOwner(base()),
  // ...isGSTNonPropertyOwner(base()),
  ...noGSTNonPropertyOwner(base())
]

function base() {
  return [
    {
      name: "Commercial Motor",
      rate: 15.95,
      productType: "Adverse",
      criteria: [
        ageOfAssetLessThan(12),
        assetType([
          "Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        ageOfAssetAtEndLessThan(21),
        creditScoreGreaterThan(400),
      ],
    },
    {
      name: "Commercial Motor",
      rate: 16.45,
      productType: "Adverse",
      criteria: [
        ageOfAssetBetween(12, 48),
        assetType([
          "Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        ageOfAssetAtEndLessThan(21),
        creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Commercial Motor",
      rate: 16.95,
      productType: "Adverse",
      criteria: [
        ageOfAssetBetween(48, 120),
        assetType([
          "Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        ageOfAssetAtEndLessThan(21),
        creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Commercial Motor",
      rate: 17.45,
      productType: "Adverse",
      criteria: [
        ageOfAssetGreaterThan(120),
        assetType([
          "Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        ageOfAssetAtEndLessThan(21),
        creditScoreGreaterThan(400)
      ],
    },
  ]
}