import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Slider,
  Autocomplete,
} from "@mui/material";
import GoogleAddressSearch from "../Utils/GoogleAddressSearch";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import regex from "src/utils/regex";
import {
  updateEntity,
  saveValue,
  userDetailsSelector,
  saveEmployer,
  createEmployer,
  addAddress,
  deleteAddress,
  updateAddress,
  removeEmployer,
} from "../../store/slices/applicationFormSlice";

import { getBusinessByABN, getBusinessByName } from "../../services/abr";
import readableABNString from "../../utils/readableABNString";
import readableACNString from "../../utils/readableACNString";

import { isDigitsOnly } from "src/utils/isDigitsOnly";
import { LoadingButton } from "@mui/lab";
import { width } from "@mui/system";
import convertToOrdinal from "src/utils/convertToOrdinal";
import RadioButtonGroup from "../Utils/RadioButtonGroup";
import { useMemo } from "react";
import PhoneNumber from "./PhoneNumber";
import AutoCompleteLoader from "../Application/AutoCompleteLoader";
import { occupationListOptions } from "src/utils/OccupationList";
import { employerIndustryListOptions } from "src/utils/employerIndustryList";

const Employer = React.memo(({ customer, employer, index, customerId, applicationId }) => {
  const dispatch = useDispatch();

  const { gettingEntityDetails } = useSelector(userDetailsSelector);

  const [loadingBusinessSearch, setLoadingBusinessSearch] = useState("");
  const [entityOptions, setEntityOptions] = useState([]);

  const [searchValue, setSearchValue] = useState({
    businessSearchValue: "",
    businessSearchInputText: "",
  });

  const [allValues, setAllValue] = useState({
    entityNameValue: employer?.entityName,
    employmentTypeValue: employer?.employmentType,
    timeOfEmploymentValue: employer.timeOfEmployment,
    occupationValue: employer?.occupation,
    managerValue: employer?.manager,
    phoneNumberValue: employer.phoneNumber,
    industryValue: employer.industry,
  });

  const validationSchema = Yup.object({
    occupationValue: Yup.string()
      .required("Occupation field is required")
      .matches(regex.occupationRegex, "Only letters and ' - characters allowed.")
      .min(2, "Maximum of 2 characters")
      .max(100, "Maximum of 100 characters"),
    industryValue: Yup.string()
      .required("Industry field is required")
      .min(2, "Maximum of 2 characters")
      .max(50, "Maximum of 50 characters"),
    managerValue: Yup.string()
      .required("Manager name is required")
      .matches(regex.customerNameRegex, "Only letters, space and characters allowed.")
      .min(2, "Maximum of 2 characters")
      .max(50, "Maximum of 50 characters"),
    phoneNumberValue: Yup.string()
      .required("number field is required")
      .matches(regex.phoneNumber, "Enter a valid Australian landline number."),
  });

  const [fieldErrors, setFieldErrors] = useState({
    occupationValue: "",
    industryValue: "",
    managerValue: "",
    phoneNumberValue: "",
    supplierPhone: "",
    supplierEmail: "",
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };

  const handle = {
    onChangeField: async (e, name) => {
      let isValid = true;

      if (name === "occupationValue") {
        isValid = regex.nameRegex.test(e) && e?.length <= 50;
      } else if ( name === "managerValue") {
        isValid = regex.customerNameRegex.test(e) && e?.length <= 50; // nameRegex
      } else if (name === "phoneNumberValue") {
        // isValid = e === "" || regex.addressRegex.test(e);
        isValid = e === "" || regex.phoneNumber.test(e);
      }

      if (!isValid) {
        await validateField(name, e);
        return;
      } else {
        setAllValue({ ...allValues, [name]: e });
        await validateField(name, e);
      }
    },
    onChangeRadio: (value, name) => {
      setAllValue({ ...allValues, [name]: value });
    },
    commonEventHandle: (value, name) => {
      dispatch(
        saveEmployer({
          [name]: value,
          employerId: employer._id,
          customer: customer._id,
        })
      );
    },
    addAddress: (address) => {
      console.log("update address", address);
      if (address) {
        dispatch(
          createEmployer({
            employerId: employer._id,
            customer: customer._id,
            address: address,
          })
        );
      }
    },
  };

  const handleAddAddress = (newAddressValue) => {
    if (newAddressValue) {
      dispatch(
        addAddress({
          employerId: employer._id,
          address: newAddressValue,
          applicationId,
          customerId: customer._id,
          isManualAddress: false,
          isEmployerAddress: true
        }),
      );
    }
  };

  const handleDeleteAddress = (newAddressValue) => {
    if (newAddressValue) {
      // if (REACT_APP_PLATFORM_NAME === PLATFORM_NAME.CONNECTIVE) {
        // For connective we just have update employer address only
        handleUpdateAddress({
          _id: newAddressValue?._id,
          address: null
        })
        return
      // } else {
      //   dispatch(
      //     deleteAddress({
      //       employerId: employer._id,
      //       addressId: newAddressValue?._id,
      //       customerId: customer._id,
      //       applicationId,
      //       isEmployerAddress: true
      //     }),
      //   );
      // }
    }
  };

  const handleUpdateAddress = (newAddressValue) => {
    if (newAddressValue) {
      dispatch(updateAddress({
        ...newAddressValue,
        applicationId,
        customerId: customer._id,
        employerId: employer._id,
        isEmployerAddress: true
      }));
    }
  };

  const [ABREntityDetails, setABREntityDetails] = useState({});
  const [newBusinessValue, setNewBusinessValue] = useState("");

  // const parseTimeInBusiness = moment(entity?.timeInBusiness, "DD-MM-YYYY");

  const handleEntity = (update) => {
    // console.log("update phoneNumber");
    dispatch(updateEntity(update));
  };

  const debounced = useMemo(() =>
    debounce(async (inputValue) => {
      // console.log("inputValue", inputValue);
      if (inputValue) {
        setLoadingBusinessSearch(true);
        if (isDigitsOnly.test(inputValue)) {
          const businessByABN = await getBusinessByABN(inputValue);
          setLoadingBusinessSearch(false);
          if (businessByABN.Abn.length > 1) {
            return setEntityOptions([businessByABN]);
          }
        }

        const businesses = await getBusinessByName(inputValue);
        if (businesses?.Names?.length > 0) {
          setLoadingBusinessSearch(false);
          return setEntityOptions(
            businesses.Names.map((i) => {
              // return { label: `${i.Name} ${i.Abn}`, Abn: i.Abn, value: i };
              return i;
            })
          );
        }

        setEntityOptions([
          {
            Name: "No results found",
          },
        ]);
      }
    }, 500)
  );

  const handleEntityDetails = async (details) => {
    dispatch(saveValue({ gettingEntityDetails: true }));
    console.log("details", details);
    const abrData = await getBusinessByABN(details.Abn);
    console.log("ABREntityDetails", abrData);
    setABREntityDetails(abrData);
    console.log(abrData.EntityName !== employer?.entityName);
    console.log(abrData.EntityName, employer?.entityName);

    if (abrData.EntityName !== employer?.entityName) {
      // await dispatch(getCompanyProfile({ ACN: abrData.Acn })).unwrap();
      dispatch(createEmployer({ abrData, customerId: customer._id }));
    } else {
      dispatch(saveValue({ gettingEntityDetails: false }));
    }
  };

  useEffect(() => {
    debounced(searchValue?.businessSearchInputText);
    return () => debounced.cancel();
  }, [searchValue?.businessSearchValue, searchValue?.businessSearchInputText]);

  useEffect(() => {
    if (!gettingEntityDetails) {
      setSearchValue({
        businessSearchValue: "",
        businessSearchInputText: "",
      });
    }
  }, [gettingEntityDetails]);

  return (
    <>
      <Grid
        style={{
          margin: "0 0 30px 0",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          paddingBottom: "30px",
        }}
      >
        {employer?.entityName ? (
          <Typography style={{ marginBottom: "20px" }}>
            {`Employer ${++index}: ${employer?.entityName}`}{" "}
          </Typography>
        ) : (
          <Grid item md={7} sm={6}>
            <Grid item md={12} sm={12} style={{ margin: "0 0 20px" }}>
              <Typography fontWeight={600}>Employer details</Typography>
            </Grid>
            <TextField
              fullWidth
              // id="outlined-basic"
              type="text"
              name="entityNameValue"
              label={`Employer ${++index} name`}
              variant="filled"
              size="small"
              value={allValues?.entityNameValue}
              style={{ marginBottom: "20px" }}
              onChange={(event) =>
                handle.onChangeField(event?.target?.value, "entityNameValue")
              }
              onBlur={() =>
                allValues?.entityNameValue
                  ? handle.commonEventHandle(
                    allValues?.entityNameValue,
                    "entityName"
                  )
                  : null
              }
            />
          </Grid>
        )}
        <Grid container spacing={1}>
          <Grid container item spacing={2} alignItems="center">
            <Grid item md={9} sm={9}>
              <Typography variant="subtitle2">Employment status</Typography>
              <RadioButtonGroup
                value={allValues?.employmentTypeValue}
                handleValue={(value) => {
                  handle.onChangeRadio(value, "employmentTypeValue");
                  handle.commonEventHandle(value, "employmentType");
                }}
                optionStyle={{
                  width: "80%",
                  padding: "8px",
                  minHeight: "48px",
                }}
                options={[
                  { icon: "House", label: "Full-time" },
                  { icon: "HomeWork", label: "Part-time" },
                  { icon: "Hotel", label: "Casual" },
                  { icon: "Hotel", label: "Contract" },
                  { icon: "House", label: "Self-employed" },
                  { icon: "Hotel", label: "Benefits" },
                  { icon: "Hotel", label: "Unemployed" },
                ]}
              />
            </Grid>

            <Grid item md={3} sm={3}>
              <Typography variant="subtitle2">
                Length of employment {allValues?.timeOfEmploymentValue || 0}{" "}
                months
              </Typography>
              <Box style={{ padding: "0 14px 0 7px" }}>
                <Slider
                  color="secondary"
                  size="large"
                  name="timeOfEmploymentValue"
                  marks={[
                    { value: 0, label: 0 },
                    // { value: 6, label: 6 },
                    // { value: 15, label: 15 },
                    { value: 12, label: 12 },
                    // { value: 25, label: 25 },
                    // { value: 18, label: 18 },
                    // { value: 35, label: 35 },
                    { value: 24, label: 24 },
                    { value: 36, label: 36 },
                    { value: 48, label: 48 },
                    { value: 60, label: "+60" },
                  ]}
                  step={1}
                  min={0}
                  max={60}
                  value={allValues?.timeOfEmploymentValue}
                  onChange={(event) =>
                    handle.onChangeField(
                      event?.target?.value,
                      "timeOfEmploymentValue"
                    )
                  }
                  onChangeCommitted={() =>
                    handle.commonEventHandle(
                      allValues?.timeOfEmploymentValue,
                      "timeOfEmployment"
                    )
                  }
                  aria-label="Default"
                  valueLabelDisplay="auto"
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container item spacing={1}>
            <Grid item md={4} sm={4}>
              <Autocomplete
                autoComplete
                loadingText={<AutoCompleteLoader />}
                value={allValues?.occupationValue}
                options={occupationListOptions}
                onChange={(event, newValue) => {
                  handle.onChangeField(newValue || "", "occupationValue");
                }}
                onBlur={() =>
                  allValues?.occupationValue
                    ? handle.commonEventHandle(
                      allValues?.occupationValue,
                      "occupation"
                    )
                    : null
                }
                size="small"
                getOptionLabel={(option) => option || ""}
                renderInput={(params) => (
                  <TextField
                    error={fieldErrors?.occupationValue}
                    helperText={fieldErrors?.occupationValue}
                    size="small"
                    {...params}
                    label="Occupation"
                    variant="filled"
                  />
                )}
              />
            </Grid>

            <Grid item md={4} sm={4}>
              <TextField
                fullWidth
                id="outlined-basic"
                type="text"
                name="managerValue"
                label="Manager"
                variant="filled"
                size="small"
                value={allValues?.managerValue}
                error={fieldErrors?.managerValue}
                helperText={fieldErrors?.managerValue}
                onChange={(event) =>
                  handle.onChangeField(event?.target?.value, "managerValue")
                }
                onBlur={() =>
                  allValues?.managerValue
                    ? handle.commonEventHandle(
                      allValues?.managerValue,
                      "manager"
                    )
                    : null
                }
              />
            </Grid>

            <Grid item md={4} sm={4}>
              <PhoneNumber
                label={"Contact number"}
                fieldKey="phoneNumberValue"
                guarantorFieldErrors={fieldErrors}
                setGuarantorFieldErrors={setFieldErrors}
                saveContactNum={(key, value) =>
                  handle.commonEventHandle(value, key)
                }
                phoneNumber={allValues?.phoneNumberValue}
              />
            </Grid>
          </Grid>
          <Grid container item sm={12} spacing={1}>
            <Grid item md={4} sm={4}>
              <Autocomplete
                autoComplete
                loadingText={<AutoCompleteLoader />}
                name="industryValue"
                value={allValues?.industryValue}
                options={employerIndustryListOptions}
                onChange={(event, newValue) => {
                  handle.onChangeField(newValue || "", "industryValue");
                }}
                onBlur={() =>
                  allValues?.industryValue
                    ? handle.commonEventHandle(
                      allValues?.industryValue,
                      "industry"
                    )
                    : null
                }
                size="small"
                getOptionLabel={(option) => option || ""}
                renderInput={(params) => (
                  <TextField
                    error={fieldErrors?.industryValue}
                    helperText={fieldErrors?.industryValue}
                    size="small"
                    {...params}
                    label="Industry"
                    variant="filled"
                  />
                )}
              />
            </Grid>
            <Grid item md={8} sm={8}>
              <GoogleAddressSearch
                customerId={customer?._id}
                singleCustomerId={customerId}
                guarantorFieldErrors={fieldErrors}
                setGuarantorFieldErrors={setFieldErrors}
                handleCreateAddress={handleAddAddress}
                handleUpdateAddress={handleUpdateAddress}
                handleRemoveAddress={handleDeleteAddress}
                address={employer?.address}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});

export default Employer;
