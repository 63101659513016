import { reusableFetch } from "../../utils/useAxios";

export const expirationMiddleware = (store) => (next) => async (action) => {
  if (action.type === "persist/REHYDRATE" && action.payload) {
    const currentTime = Date.now();
    const expirationTime = 2629440 * 1000; // 1 month

    // const { data } = await reusableFetch(`lender/log`, "GET", null);
    // if (data) {
    //   const lenderLog = data.data.lenderLog;
    //   const logTimestamp = Math.floor((new Date(lenderLog?.createdAt) || 0) / 1000) * 1000;

    //   Object.keys(action.payload).forEach((key) => {
    //     const persistedState = action.payload[key];
    //     if (persistedState && persistedState.timestamp) {
    //       if (currentTime - persistedState.timestamp > expirationTime) {
    //         delete action.payload[key];
    //       }
    //     }
    //     if (persistedState && persistedState.queries) {
    //       const queries = persistedState.queries;
    //       Object.keys(queries).forEach((query) => {
    //         const queryTimestamp = Math.floor(queries[query].fulfilledTimeStamp / 1000) * 1000;
    //         if (["pending", "rejected"].includes(queries[query].status) || logTimestamp > queryTimestamp) {
    //           delete queries[query];
    //         }
    //       });
    //     }
    //   });
    // }
  }
  return next(action);
};
