import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { reusableFetch, reusableFormDataFetch } from "src/utils/useAxios";

export const updateAsset = createAsyncThunk(
  "asset/updateAsset",
  async (data, { rejectWithValue }) => {
    const asset = await reusableFetch(`asset/`, "POST", data, rejectWithValue);
    return asset;
  }
);

export const uploadInvoice = createAsyncThunk(
  "asset/upload", 
  async ({ formData, assetId }, { rejectWithValue }) => {
    const asset = await reusableFormDataFetch(
      `asset/upload/${assetId}`,      
      "POST",
      formData,
      rejectWithValue
    );
    return asset;
  }
);

export const applicationsAssetSlice = createSlice({
  name: "asset",
  initialState: {
    liabilitiesListData: [
      {
        liabilitiesType: "",
        outstandingAmount: "",
        limit: "",
        financier: "",
        repaymentFrequency: "",
        repaymentAmount: "",
        description: "",
        application: "",
        customer: "",
      },
    ],
    assetListData: [
      {
        description: "",
        assetType: "",
        value: "",
        application: "",
        customer: "",
      },
    ],
    incomeListData: [
      {
        incomeType: "",
        frequency: "",
        amount: "",
        application: "",
        customer: "",
      },
    ],
    // expensesListData:,
    isLoading: false,
    application: {
      asset: {},
    },
  },
  reducers: {
    saveValue: (state, action) => {
      return _.merge(state, action.payload);
    },
  },
  extraReducers: {
    [uploadInvoice.fulfilled]: (state, { payload }) => {
      _.merge(state.application.asset, payload.data);
    },
    // [updateAssets.fulfilled]: (state, { payload }) => {
    // state.application.customers.filter(
    //   (customer) => customer._id === payload.data.customer
    // )[0] = _.merge(
    //   state.application.customers.filter(
    //     (customer) => customer._id === payload.data.customer
    //   )[0].customerAssets,
    //   payload.data
    // );
    // },
  },
});

export const { saveValue } = applicationsAssetSlice.actions;

export const assetSelector = (state) => state.asset;
