import React from "react";
import { useSelector } from "react-redux";
import { findIndex, isEqual, some, startCase } from "lodash";
import {
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { userSelector } from "src/store/slices/userSlice";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "75vh",
      width: 250,
    },
  },
};

const params = [
  ["fit", "asc"],
  ["repayments", "asc"],
  ["rate", "asc"],
  ["loanAmount", "asc"],
  ["fees", "asc"],
];

const ProductSelectorOrderBy = ({ handleOrderBy, value, screenSize }) => {
  const user = useSelector(userSelector);
  const isUserNew = user.status === "new";

  const handleMenuItemClick = (data) => () => {
    const currentIndex = findIndex(value, item => isEqual(item, data));
    const newSelected = [...value];

    if (currentIndex === -1) {
      newSelected.push(data);
    } else {
      newSelected.splice(currentIndex, 1);
    }
    handleOrderBy(newSelected);
  };

  return (
    <FormControl
      className="four-step"
      size="small"
      justifySelf="end"
      sx={{ m: 1, width: 120 }}
      style={{
        margin: "5px 0 10px 0",
        width: screenSize.dynamicWidth < 900 ? 120 : 160,
      }}
    >
      <InputLabel id="demo-multiple-chip-label">Order by</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple={!isUserNew}
        value={value}
        // onChange={handleOrderBy}
        style={{ marginTop: "10px", padding: "0 15px" }}
        input={<Input id="select-multiple-chip" label="Chip" />}
        renderValue={(selected) => `${selected.length} selected` || undefined}
        MenuProps={MenuProps}
      >
        {params.map((param) => {
          return (
            <MenuItem key={param[0]} value={param} onClick={handleMenuItemClick(param)}>
              <Checkbox checked={some(value, arr => isEqual(arr, param))} />
              {startCase(param[0])}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  );
};

export default ProductSelectorOrderBy;
