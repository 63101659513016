import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import _ from "lodash";
import InputMask from "react-input-mask";

import { Grid, Stack, TextField, Typography } from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCarTwoTone";

import {
  assetSelector,
  saveAsset,
  updateAsset,
} from "../../store/slices/applicationFormSlice";

import regex from "src/utils/regex";
import PhoneNumber from "../CustomerDetails/PhoneNumber";

export default function Supplier() {
  const dispatch = useDispatch();

  const asset = useSelector(assetSelector);

  const {
    _id,
    supplierName,
    supplierABN,
    supplierAddress,
    supplierPhone,
    supplierEmail,
  } = asset;

  const [fieldErrors, setFieldErrors] = useState({
    supplierName: "",
    supplierABN: "",
    supplierAddress: "",
    supplierPhone: "",
    supplierEmail: "",
  });

  const [allValues, setAllValue] = useState({
    supplierName: supplierName || "",
    supplierABN: supplierABN || "",
    supplierAddress: supplierAddress || "",
    supplierPhone: supplierPhone || "",
    supplierEmail: supplierEmail || "",
  });

  const validationSchema = Yup.object({
    supplierName: Yup.string()
      .optional()
      .matches(
        regex.alphaNumericAndSpecificChar,
        "Only letters, numbers and and . / ( ) - characters allowed."
      )
      .max(50, "Maximum of 50 characters"),
    supplierABN: Yup.string()
      .optional()
      .matches(regex.allowStrictNumber, "Only numbers is allowed.")
      .max(12, "Maximum of 12 characters"),
    supplierAddress: Yup.string()
      .required("address field is required")
      .matches(regex.addressRegex, "Please enter valid address")
      .min(3, "Maximum of 3 characters")
      .max(100, "Maximum of 100 characters"),
    supplierPhone: Yup.string()
      .optional()
      .matches(regex.phoneNumber, "Enter valid phone number."),
    supplierEmail: Yup.string()
      .required('Email is required')
      .matches(regex.email, "Please enter valid email address")
      .min(2, "Maximum of 5 characters")
      .max(50, "Maximum of 50 characters"),
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };

  const handle = {
    updateAsset: async (value) => {
      dispatch(saveAsset(value));
      dispatch(updateAsset({ _id, ...value }));
    },
    onChangeField: async (e, name) => {
      let isValid = true;

      // console.log(e)

      if (name === "supplierName") {
        isValid = regex.alphaNumericAndSpecificChar.test(e) && e?.length <= 50;
      } else if (name === "supplierABN") {
        isValid = e === "" || regex.allowStrictNumber.test(e) && e?.length <= 12;
      } else if (name === "supplierAddress") {
        isValid = e === "" || regex.addressRegex.test(e) && e?.length <= 100;
      } else if (name === 'supplierEmail') {
        isValid = e?.length <= 50;
      }

      if (!isValid) {
        await validateField(name, e);
        return;
      } else {
        setAllValue({ ...allValues, [name]: e });
        await validateField(name, e);
      }

      // console.log("slice", allValues?.supplierPhone?.substr(0, 2))
    },
    blurFn: (fieldName, value) => {
      try {
        if (fieldErrors[fieldName] === "")
          handle.updateAsset({ [fieldName]: value });
      } catch (error) {
        const newErrors = {};
        error.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
        setFieldErrors(newErrors);
      }
    },
  };

  return (
    <Grid container style={{ margin: "0 0 40px" }}>
      <Grid container xs={12} sm={12} md={2}>
        <DirectionsCarIcon style={{ marginRight: "10px" }} />
        <Typography>Supplier</Typography>
      </Grid>

      <Grid
        conatiner
        xs={12}
        sm={12}
        md={10}
        spacing={2}
        style={{
          padding: "0 0 0 10px",
        }}
      >
        <Stack spacing={3} direction="row" style={{ margin: "0 0 10px" }}>
          <Grid item md={7} sm={7}>
            <TextField
              fullWidth
              name="supplierName"
              style={{ background: "#ffffff" }}
              variant="filled"
              label="Supplier name"
              size="small"
              value={allValues?.supplierName}
              error={fieldErrors?.supplierName}
              helperText={fieldErrors?.supplierName}
              onChange={(event) =>
                handle.onChangeField(event?.target?.value, "supplierName")
              }
              onBlur={(e) => handle.blurFn("supplierName", e.target.value)}
            />
          </Grid>

          <Grid item md={5} sm={5}>
            <TextField
              fullWidth
              name="supplierABN"
              variant="filled"
              style={{ background: "#ffffff" }}
              value={allValues?.supplierABN}
              label="ABN"
              size="small"
              error={fieldErrors?.supplierABN}
              helperText={fieldErrors?.supplierABN}
              onChange={(event) =>
                handle.onChangeField(event?.target?.value, "supplierABN")
              }
              onBlur={(e) => handle.blurFn("supplierABN", e.target.value)}
            ></TextField>
          </Grid>
        </Stack>

        <Stack spacing={3} direction="row" style={{ margin: "0 0 10px" }}>
          <Grid item md={12} sm={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              type="text"
              name="supplierAddress"
              label="Address"
              value={allValues?.supplierAddress}
              error={fieldErrors?.supplierAddress}
              helperText={fieldErrors?.supplierAddress}
              onChange={(event) =>
                handle.onChangeField(event?.target?.value, "supplierAddress")
              }
              onBlur={(e) => handle.blurFn("supplierAddress", e.target.value)}
              variant="filled"
              size="small"
              InputProps={{ disableUnderline: true }}
            />
          </Grid>
        </Stack>

        <Stack spacing={3} direction="row" style={{ margin: "0 0 10px" }}>
          <Grid item md={4} sm={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              type="text"
              name="supplierEmail"
              label="Email"
              value={allValues?.supplierEmail}
              error={fieldErrors?.supplierEmail}
              helperText={fieldErrors?.supplierEmail}
              onChange={(event) =>
                handle.onChangeField(event?.target?.value, "supplierEmail")
              }
              onBlur={(e) => handle.blurFn("supplierEmail", e.target.value)}
              variant="filled"
              size="small"
              InputProps={{ disableUnderline: true }}
            // inputProps={{ maxLength: 50 }} 
            />
          </Grid>
          <Grid item md={3} sm={3}>
            <PhoneNumber
              label={"Phone number"}
              fieldKey="supplierPhone"
              guarantorFieldErrors={fieldErrors}
              setGuarantorFieldErrors={setFieldErrors}
              saveContactNum={(key, value) => handle.blurFn(key, value)}
              phoneNumber={allValues?.supplierPhone}
            />
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
}
