import { typeOfSaleList, employmentTypes } from "src/constants";
import criteria from "src/utils/criteria";
import platinum from "./platinum";
import gold from "./gold";
import silver from "./silver";
import bronze from "./bronze";

const { SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL, BENEFITS } =
  employmentTypes;

const {
  propertyOwner,
  nonPropertyOwner,
  nonGST,
  typeOfSale,
  hasDeposit,
  gstRegistrationBetween,
  gstRegistrationGreaterThan,
  termIsGreaterThan,
  assetValueBetween,
  assetType,
  ageOfAssetBetween,
  ageOfAssetLessThan,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  creditScoreGreaterThan,
  employmentType,
  timeOfEmploymentGreaterThan,
  livingArrangements,
  creditScoreBetween,
  compound,
} = criteria;

const platinumFinal = platinum.map((product) => {
  return {
    ...product,
    criteria: [...product.criteria, ageOfAssetAtEndLessThan(13)],
  };
});

const goldFinal = gold.map((product) => {
  return {
    ...product,
    criteria: [...product.criteria, ageOfAssetAtEndLessThan(13)],
  };
});

const silverFinal = silver.map((product) => {
  return {
    ...product,
    criteria: [...product.criteria, ageOfAssetAtEndLessThan(13)],
  };
});

const bronzeFinal = bronze.map((product) => {
  return {
    ...product,
    criteria: [...product.criteria, ageOfAssetAtEndLessThan(13)],
  };
});

export default {
  date: "4 Apr 2023",
  lender: "Money3",
  type: "consumer",
  logoURL: "https://assets.financeable.com.au/lender-logos%2Fmoney3-logo.svg",
  actions: [],
  lenderCredentials: [
    {
      label: "Email",
      attribute: "email",
    },
    {
      label: "User Name",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
    {
      label: "Merchant ID",
      attribute: "organisationId",
    },
    {
      label: "User ID",
      attribute: "brokerId",
    },
    {
      label: "Api Key",
      attribute: "apiKey",
    },
  ],
  requiredFields: {
    asset: [
      "assetType",
      "typeOfSale",
      "ageOfAsset",
      "glassesMake",
      "glassesModel",
      "glassesVariant",
      "assetValue",
    ],
    customer: [
      "firstName",
      "lastName",
      "dateOfBirth",
      "phoneNumbers[0].number",
      "emails[0].address",
      //   "addresses[0].street",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "IDNumber",
      "IDCardNumber",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "employers[0].entityName",
      "employers[0].timeOfEmployment",
      "employers[0].employmentType",
    ],
    agreement: ["introducerDeclarationAgree"],
  },
  fees: [
    {
      name: "Establishment fee",
      value: 995,
      capitalised: true,
      frequency: "upfront",
      criteria: [
        typeOfSale(typeOfSaleList.DEALER_SALE),
        assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      ],
    },
    {
      name: "Private sale",
      value: 995,
      capitalised: true,
      frequency: "upfront",
      criteria: [
        typeOfSale(typeOfSaleList.PRIVATE_SALE),
        assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      ],
    },
    {
      name: "Origination fee",
      value: 1320,
      capitalised: true,
      frequency: "upfront",
      criteria: [
        employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
      ],
    },
    {
      name: "Origination fee",
      value: 990,
      capitalised: true,
      frequency: "upfront",
      criteria: [employmentType([BENEFITS])],
    },
    {
      name: "Monthly account fee",
      value: 28.0,
      capitalised: false,
      frequency: "monthly",
    },
    {
      name: "Risk fee",
      value: 2,
      calc: "percent",
      capitalised: true,
      frequency: "upfront",
      criteria: [creditScoreGreaterThan(600)],
    },
    {
      name: "Risk fee",
      value: 3,
      calc: "percent",
      capitalised: true,
      frequency: "upfront",
      criteria: [creditScoreBetween(550, 600)],
    },
    {
      name: "Risk fee",
      value: 4,
      calc: "percent",
      capitalised: true,
      frequency: "upfront",
      criteria: [creditScoreBetween(500, 550)],
    },
    {
      name: "Risk fee",
      value: 4.5,
      calc: "percent",
      capitalised: true,
      frequency: "upfront",
      criteria: [creditScoreBetween(100, 499)],
    },
  ],
  brokerage: [],
  loading: [
    {
      name: "Micro product",
      value: 3,
      valueType: "percentage",
      criteria: [employmentType([BENEFITS])],
    },
  ],
  info: [
    `Payments to be made via direct debit`,
    `A benchmark monthly minimum living expense 
    (using location and income) will be compared 
    to those declared by the applicant during 
    assessment in order to calculate serviceability`,
  ],
  productTiers: [
    ...platinumFinal,
    ...goldFinal,
    ...silverFinal,
    ...bronzeFinal,
  ],
};
