import {
  typeOfSaleList,
  livingArrangementsTypes,
  employmentTypes,
} from "src/constants";
import criteria from "src/utils/criteria";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } =
  livingArrangementsTypes;

const { SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL, BENEFITS } =
  employmentTypes;

const {
  propertyOwner,
  nonPropertyOwner,
  nonGST,
  ageOfAssetNewOrDemo,
  typeOfSale,
  hasDeposit,
  dateOfBirthBetween,
  dateOfBirthGreaterThan,
  gstRegistrationBetween,
  gstRegistrationGreaterThan,
  termIsGreaterThan,
  assetValueBetween,
  assetType,
  ageOfAssetBetween,
  ageOfAssetLessThan,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  creditScoreGreaterThan,
  employmentType,
  timeOfEmploymentGreaterThan,
  livingArrangements,
  creditScoreBetween,
  compound,
} = criteria;

const base = [
  {
    name: "Auto <12 months",
    rate: 6.40,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      hasDeposit(20),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499),
    ],
  },
  {
    name: "Auto <12 months",
    rate: 7.10,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(12, 36),
      ageOfAssetAtEndLessThan(13),
      hasDeposit(20),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499),
    ],
  },
  // {
  //   name: "Auto <12 months",
  //   rate: 7.7,
  //   productType: "Standard",
  //   criteria: [
  //     ageOfAssetBetween(36, 155),
  //     ageOfAssetAtEndLessThan(13),
  //     hasDeposit(20),
  //     livingArrangements([PROPERTY_OWNER]),
  //     creditScoreGreaterThan(499),
  //   ],
  // },

  // {
  //   name: "Auto <12 months",
  //   rate: 7.15,
  //   productType: "Standard",
  //   criteria: [
  //     ageOfAssetLessThan(12),
  //     ageOfAssetAtEndLessThan(13),
  //     hasDeposit(10),
  //     livingArrangements([PROPERTY_OWNER]),
  //     creditScoreGreaterThan(499),
  //   ],
  // },
  // {
  //   name: "Auto <12 months",
  //   rate: 7.75,
  //   productType: "Standard",
  //   criteria: [
  //     ageOfAssetBetween(12, 36),
  //     ageOfAssetAtEndLessThan(13),
  //     hasDeposit(10),
  //     livingArrangements([PROPERTY_OWNER]),
  //     creditScoreGreaterThan(499),
  //   ],
  // },
  // {
  //   name: "Auto <12 months",
  //   rate: 8.3,
  //   productType: "Standard",
  //   criteria: [
  //     ageOfAssetBetween(36, 155),
  //     ageOfAssetAtEndLessThan(13),
  //     hasDeposit(10),
  //     livingArrangements([PROPERTY_OWNER]),
  //     creditScoreGreaterThan(499),
  //   ],
  // },

  {
    name: "Auto <12 months",
    rate: 6.70,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499),
    ],
  },
  {
    name: "Auto <12 months",
    rate: 7.40,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(12, 36),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499),
    ],
  },
  // {
  //   name: "Auto <12 months",
  //   rate: 9.05,
  //   productType: "Standard",
  //   criteria: [
  //     ageOfAssetBetween(36, 155),
  //     ageOfAssetAtEndLessThan(13),
  //     livingArrangements([PROPERTY_OWNER]),
  //     creditScoreGreaterThan(499),
  //   ],
  // },

  {
    name: "Auto <12 months",
    rate: 7.25,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      hasDeposit(20),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499),
    ],
  },
  {
    name: "Auto <12 months",
    rate: 9.25,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(12, 36),
      ageOfAssetAtEndLessThan(13),
      hasDeposit(20),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499),
    ],
  },
  // {
  //   name: "Auto <12 months",
  //   rate: 9.95,
  //   productType: "Standard",
  //   criteria: [
  //     ageOfAssetBetween(36, 155),
  //     ageOfAssetAtEndLessThan(13),
  //     hasDeposit(20),
  //     livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
  //     creditScoreGreaterThan(499),
  //   ],
  // },

  // {
  //   name: "Auto <12 months",
  //   rate: 8.6,
  //   productType: "Standard",
  //   criteria: [
  //     ageOfAssetLessThan(12),
  //     ageOfAssetAtEndLessThan(13),
  //     hasDeposit(10),
  //     livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
  //     creditScoreGreaterThan(499),
  //   ],
  // },
  // {
  //   name: "Auto <12 months",
  //   rate: 10.0,
  //   productType: "Standard",
  //   criteria: [
  //     ageOfAssetBetween(12, 36),
  //     ageOfAssetAtEndLessThan(13),
  //     hasDeposit(10),
  //     livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
  //     creditScoreGreaterThan(499),
  //   ],
  // },
  // {
  //   name: "Auto <12 months",
  //   rate: 10.55,
  //   productType: "Standard",
  //   criteria: [
  //     ageOfAssetBetween(36, 155),
  //     ageOfAssetAtEndLessThan(13),
  //     hasDeposit(10),
  //     livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
  //     creditScoreGreaterThan(499),
  //   ],
  // },

  {
    name: "Auto <12 months",
    rate: 7.70,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499),
    ],
  },
  {
    name: "Auto <12 months",
    rate: 8.40,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(12, 36),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499),
    ],
  },
  // {
  //   name: "Auto <12 months",
  //   rate: 11.3,
  //   productType: "Standard",
  //   criteria: [
  //     ageOfAssetBetween(36, 155),
  //     ageOfAssetAtEndLessThan(13),
  //     livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
  //     creditScoreGreaterThan(499),
  //   ],
  // },
];

const otherAssetTypes = [
  {
    name: "Auto <12 months",
    rate: 6.8,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499),
    ],
  },
  {
    name: "Auto <12 months",
    rate: 7.4,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(12, 36),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499),
    ],
  },
  {
    name: "Auto <12 months",
    rate: 7.95,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(36, 155),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499),
    ],
  },

  {
    name: "Auto <12 months",
    rate: 8.25,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499),
    ],
  },
  {
    name: "Auto <12 months",
    rate: 9.65,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(12, 36),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499),
    ],
  },
  {
    name: "Auto <12 months",
    rate: 10.2,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(36, 155),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499),
    ],
  },
];

const luxuryBase = [
  {
    name: "Auto <12 months",
    rate: 6.40,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      hasDeposit(20),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499),
    ],
  },

  // {
  //   name: "Auto <12 months",
  //   rate: 7.15,
  //   productType: "Standard",
  //   criteria: [
  //     ageOfAssetLessThan(12),
  //     ageOfAssetAtEndLessThan(13),
  //     hasDeposit(10),
  //     livingArrangements([PROPERTY_OWNER]),
  //     creditScoreGreaterThan(499),
  //   ],
  // },

  {
    name: "Auto <12 months",
    rate: 6.70,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499),
    ],
  },

  {
    name: "Auto <12 months",
    rate: 7.25,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      hasDeposit(20),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499),
    ],
  },

  // {
  //   name: "Auto <12 months",
  //   rate: 8.6,
  //   productType: "Standard",
  //   criteria: [
  //     ageOfAssetLessThan(12),
  //     ageOfAssetAtEndLessThan(13),
  //     hasDeposit(10),
  //     livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
  //     creditScoreGreaterThan(499),
  //   ],
  // },

  {
    name: "Auto <12 months",
    rate: 7.70,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499),
    ],
  },
];

const auto18_21 = base.map((product) => {
  return {
    ...product,
    criteria: [
      ...product.criteria,
      dateOfBirthBetween(18, 21),
      loanAmountBetween(10000, 40000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
    ],
  };
});

const auto21_24 = base.map((product) => {
  return {
    ...product,
    criteria: [
      ...product.criteria,
      dateOfBirthBetween(21, 24),
      loanAmountBetween(10000, 50000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
    ],
  };
});

const auto24Up = base.map((product) => {
  return {
    ...product,
    criteria: [
      ...product.criteria,
      dateOfBirthGreaterThan(24),
      loanAmountBetween(10000, 100000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
    ],
  };
});

const luxury = luxuryBase.map((product) => {
  return {
    ...product,
    criteria: [
      ...product.criteria,
      dateOfBirthGreaterThan(24),
      loanAmountBetween(10000, 250000),
      assetType(["Luxury Vehicle"]),
    ],
  };
});

const otherAssetTypes18_21 = otherAssetTypes.map((product) => {
  return {
    ...product,
    criteria: [
      ...product.criteria,
      dateOfBirthBetween(18, 21),
      loanAmountBetween(10000, 40000),
      assetType(["Trailer", "Caravan", "Motor home"]),
    ],
  };
});

const otherAssetTypes21_24 = otherAssetTypes.map((product) => {
  return {
    ...product,
    criteria: [
      ...product.criteria,
      dateOfBirthBetween(21, 24),
      loanAmountBetween(10000, 50000),
      assetType(["Trailer", "Caravan", "Motor home"]),
    ],
  };
});

const otherAssetTypes24Up = otherAssetTypes.map((product) => {
  return {
    ...product,
    criteria: [
      ...product.criteria,
      dateOfBirthGreaterThan(24),
      loanAmountBetween(10000, 100000),
      assetType(["Trailer", "Caravan", "Motor home"]),
    ],
  };
});

const fullTimePartTime = [
  ...auto18_21,
  ...auto21_24,
  ...auto24Up,
  ...luxury,
  // ...otherAssetTypes18_21,
  // ...otherAssetTypes21_24,
  // ...otherAssetTypes24Up
].map((product) => {
  return {
    ...product,
    criteria: [...product.criteria, employmentType([FULL_TIME, PART_TIME])],
  };
});

const casual = [
  ...auto18_21,
  ...auto21_24,
  ...auto24Up,
  ...luxury,
  // ...otherAssetTypes18_21,
  // ...otherAssetTypes21_24,
  // ...otherAssetTypes24Up
].map((product) => {
  return {
    ...product,
    criteria: [
      ...product.criteria,
      employmentType([CASUAL]),
      timeOfEmploymentGreaterThan(6),
    ],
  };
});

const SEContractor = [
  ...auto18_21,
  ...auto21_24,
  ...auto24Up,
  ...luxury,
  // ...otherAssetTypes18_21,
  // ...otherAssetTypes21_24,
  // ...otherAssetTypes24Up
].map((product) => {
  product.criteria.push();

  return {
    ...product,
    criteria: [
      ...product.criteria,
      employmentType([SELF_EMPLOYED, CONTRACT]),
      timeOfEmploymentGreaterThan(24),
    ],
  };
});

export default {
  date: "01st Feb 2024",
  lender: "Macquarie",
  type: "consumer",
  logoURL:
    "https://assets.financeable.com.au/lender-logos%2Fmacquarie_logo.gif",
  actions: [],
  lenderCredentials: [
    {
      label: "Email",
      attribute: "email",
    },
    {
      label: "User Name",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
    {
      label: "Merchant ID",
      attribute: "organisationId",
    },
    {
      label: "User ID",
      attribute: "brokerId",
    },
    {
      label: "Api Key",
      attribute: "apiKey",
    },
  ],
  requiredFields: {
    asset: [
      "assetType",
      "typeOfSale",
      "ageOfAsset",
      "glassesMake",
      "glassesModel",
      "glassesVariant",
      "assetValue",
    ],
    customer: [
      "firstName",
      "lastName",
      "dateOfBirth",
      "phoneNumbers[0].number",
      "emails[0].address",
      //   "addresses[0].street",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "IDNumber",
      "IDCardNumber",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "employers[0].entityName",
      "employers[0].timeOfEmployment",
      "employers[0].employmentType",
    ],
    agreement: ["introducerDeclarationAgree"],
  },
  fees: [
    {
      name: "Establishment fee",
      value: 250,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Monthly account fee",
      value: 8.25,
      capitalised: true,
      frequency: "monthly",
    },
  ],
  brokerage: [],
  loading: [],
  info: [
    `
     Maximum net amount financed within a 6 month period of the 
     settlement date (or approval date if not settled) - $100,000 
    `,
    ` Maximum net amount financed $200,000 for Existing customer 
      with current account including non property owner) to be eligible:
    `,
    `Must have a minimum of 24 months history, with gaps of no more than 6 months between contracts and; 
      must have current exposure or have finalised a contract no more than 6 months prior to submission `,
    `Luxury motor vehicles: new/demo Ferrari, Maserati, McLaren, Porsche, Bentley, 
Lamborghini, Tesla Motor Vehicles and Mercedes)`,
  ],
  productTiers: [...fullTimePartTime, ...casual, ...SEContractor],
};
