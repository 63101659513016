import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { approveUser, verifyEmail } from "../../store/slices/userSlice";

export default function VerifyEmail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { key } = useParams();

  useEffect(() => {
    try {
      if (key) {
          dispatch(verifyEmail(key)).then(({payload}) => {
          if (payload?.data?.user) {
            dispatch(approveUser(payload.data.user))
            navigate(`/login`);
          } else {
            navigate('/login');
          }
        });
      }
    } catch (error) {
      navigate('/');
    }
  }, [key]);

  return (
    <>
    </>
  )

}