import { useEffect } from "react";
import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

// redux
import { userSelector, validateToken } from "./store/slices/userSlice";
import { organisationSelector } from "./store/slices/organisationSlice";
// layouts
import PreAuthWrapper from "./containers/PreAuthWrapper";
import AuthWrapper from "./containers/AuthWrapper";
import AuthWrapperNoMenu from "./containers/AuthWrapperNoMenu";
// import NotFound from './components/User/Page404';
// pages
import Application from "./components/Application";
import ApplicationsList from "./components/Application/ApplicationsList";
import Organisation from "./components/Register/Organisation";
// import QuotesList from "./components/User/QuotesList";
import Login from "./components/User/Login";
import Enquiry from "./components/Register/Enquiry";
import Register from "./components/Register/RegistrationStepper";
import NotFound from "./components/User/404";
import CustomerList from "./pages/CustomerList";
// import AcceptRequestToJoin from "./components/User/AcceptRequestToJoin";
import InviteUsers from "./components/Register/InviteUsers";
import ForgotPassword from "./components/User/ForgotPassword";
import ResetPassword from "./components/User/ResetPassword";
import UserProfile from "./components/User/UserProfile";
// import UserSettings from "./components/User/UserSettings";
import Settings from "./components/Settings";
import OrganisationProfile from "./components/Organisation/OrganisationProfile";
import Privacy from "./pages/Privacy";
import UserList from "./components/User/UserList";
import VerifyEmail from "./components/Register/VerifyEmail";
import CalculatorList from "./components/Calculator/CalculatorList";
import Calculator from "./components/Calculator";
import QuotesList from "./components/Quote/QuoteList";
import Quote from "./components/Quote";
// import Billing from "./components/User/Billing";
// import Subscribe from "./components/Subscribe";
// import Payment from "./components/User/Payment";

// ----------------------------------------------------------------------

function handleDecode(token) {
  if (token && token.length > 12) {
    const now = Date.now() / 1000;
    const decoded = jwt_decode(token);
    // console.log("decoded", decoded.exp);
    // console.log("now", now);

    return decoded.exp < now;
  }

  return true;
}

export default function Router(props) {
  const token = localStorage.token;
  const isTokenExpired = handleDecode(token);
  // const isTokenExpired = false;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userFresh = useSelector(userSelector);
  const organisation = useSelector(organisationSelector);

  // const user = localStorage.user;
  const user = useSelector(userSelector);

  async function handleValidateToken(t) {
    if (t) await dispatch(validateToken(t)).unwrap();
  }

  function noToken() {
    if (isTokenExpired) return <PreAuthWrapper {...props} />;
  }

  function hasOrganisation(organisation, user) {
    return organisation._id || user.organisation;
  }

  useEffect(async () => {
    handleValidateToken(token);
  }, []);

  return useRoutes([
    {
      path: "/",
      element: <PreAuthWrapper {...props} />,
      children: [
        { path: "register", element: <Register /> },
        // { path: "confirm", element: <ConfirmEmail /> },
        { path: "verify-email/:key", element: <VerifyEmail /> },
        { path: "login", element: <Login /> },
        { path: "enquiry", element: <Enquiry /> },
        { path: "privacy", element: <Privacy /> },
        // { path: "forgotpassword", element: <Password /> },
        // { path: "reset-password/:resetToken", element: <UpdatePassword /> },
        { path: "404", element: <NotFound /> },
        { path: "/", element: <Navigate to="/login" /> },
        { path: "privacy", element: <Privacy /> },
      ],
    },
    {
      path: "/",
      element: !isTokenExpired ? (
        <AuthWrapperNoMenu {...props} />
      ) : (
        <Navigate to="/" />
      ),
      children: [
        { path: "organisation", element: <Organisation /> },
        { path: "invite-users", element: <InviteUsers /> },
      ],
    },
    {
      path: "/password",
      element: <PreAuthWrapper {...props} />,
      children: [
        { path: "forgot", element: <ForgotPassword /> },
        { path: "reset/:key", element: <ResetPassword user={user} /> },
      ],
    },
    {
      path: "/",
      element: !isTokenExpired && user.status !== "Suspended" ? (
        <AuthWrapper {...props} />
      ) : (
        <Navigate to="/" />
      ),
      // element: !isTokenExpired ? (
      //   hasOrganisation(organisation, userFresh) ?
      //     <AuthWrapper {...props} /> :
      //     <Navigate to="/organisation" replace  />
      // ) : (
      //   <Navigate to="/" />
      // ),
      children: [
        { path: "quotes", element: <QuotesList /> },
        { path: "quote/:applicationId", element: <Quote /> },
        { path: "applications", element: <ApplicationsList /> },
        // { path: "application/", element: <Application /> },
        { path: "application/:applicationId", element: <Application /> },
        // { path: "referrals", element: <ApplicationsList /> },
        // { path: "referral/:applicationId", element: <Application /> },
        { path: "calculator", element: <CalculatorList /> },
        { path: "calculator/:applicationId", element: <Calculator /> },
        { path: "customers", element: <CustomerList /> },
        { path: "users", element: <UserList /> },
        { path: "settings/:path", element: <Settings /> },
        // children: [
        //   { path: "general",  },

        // {
        //   path: "/dashboard",
        //   element: (
        //     <Navigate to={`/dashboard`} />
        //   ),
        // },
        //   ],
        // },
      ],
    },
    {
      path: "/account",
      element: !isTokenExpired ? (
        <AuthWrapper {...props} />
      ) : (
        <Navigate to="/" />
      ),
      children: [
        { path: "profile", element: <UserProfile /> },
        { path: "organisation", element: <OrganisationProfile /> },

        // {
        //   path: "/dashboard",
        //   element: (
        //     <Navigate to={`/dashboard`} />
        //   ),
        // },
      ],
    },

    // {
    //   path: "/request-success",
    //   element: <AcceptRequestToJoin />,
    // },
    // {
    //   path: "/request-fail",
    //   element: <AcceptRequestToJoin />,
    // },
    {
      path: "/subscribe",
      element: !isTokenExpired ? (
        <AuthWrapper {...props} />
      ) : (
        <Navigate to="/" />
      ),
    },
    // {
    //   path: "/setup",
    //   element: <Navigate to="/" />,
    // },

    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
