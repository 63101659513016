import React, { useCallback, useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import * as Yup from "yup";
import { debounce } from "lodash";
import { isValidInput } from "../../../components/Utils/helperFunction";
import {
  updateLoanDetails,
  loanDetailsSelector,
  saveLoanDetails,
} from "../../../store/slices/applicationFormSlice";
import regex from "../../../utils/regex";
import { NumericFormatCustom, currencyMaskedValue } from "../../../utils/currencyMaskFormat";

export default function Deposit({ quoteValues, setQuoteValue, fieldErrors, setFieldErrors }) {
  const dispatch = useDispatch();
  // const { applicationId } = useParams();
  const { deposit, _id } = useSelector(loanDetailsSelector);
  const [quoteDeposit, setQuoteDeposit] = useState(0)

  const validationSchema = Yup.object({
    deposit: Yup.string()
      .optional()
      .matches(regex.allowNumberWithZero, "Only numbers and and decimal points allowed.")
      .matches(regex.allowNumberAndDecimal, {
        message: "Number must be to up 7 digit and up to 2 decimal places",
      })
      .max(10, "Maximum of 7 digits"),
  });

  // const validateField = async (fieldName, value) => {
  //   try {
  //     await validationSchema.validateAt(fieldName, { [fieldName]: value });
  //     setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
  //   } catch (error) {
  //     setFieldErrors((prevErrors) => ({
  //       ...prevErrors,
  //       [fieldName]: error.message,
  //     }));
  //   }
  // };

  const debouncedValidation = useCallback(
    debounce(async (fieldName, value) => {
      try {
        await validationSchema.validateAt(fieldName, { [fieldName]: value });
        setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
      } catch (error) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: error.message,
        }));
      }
    }, 300),
    [],
  );

  const handle = {
    onChangeField: (e, name) => {
      let isValid = true;

      if (e === "") {
        setQuoteValue({ ...quoteValues, [name]: e });
        setFieldErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        return;
      }

      if (name === "deposit") {
        isValid = isValidInput(e);
      }

      if (!isValid) {
        // await validateField(name, e);
        debouncedValidation(name, e);
        return;
      } else {
        setQuoteValue({ ...quoteValues, [name]: e });
        // await validateField(name, e);
        debouncedValidation(name, e);
      }
    },
    blurFn: async (fieldName, value) => {
      try {
        if (fieldErrors[fieldName] === "")
          dispatch(saveLoanDetails({ [fieldName]: value }));
        if (Number(deposit) !== Number(value)) {
          if (_id) dispatch(updateLoanDetails({ _id, [fieldName]: value || 0 }));
        }
      } catch (error) {
        const newErrors = {};
        error.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
        setFieldErrors(newErrors);
      }
    },
  };

  useEffect(() => {
    setQuoteDeposit(deposit || 0)
  }, [deposit])

  return (
    <TextField
      fullWidth
      id="deposit"
      label="Deposit amount"
      variant="filled"
      type="text"
      size="small"
      name="deposit"
      value={quoteDeposit} // deposit
      error={fieldErrors?.deposit}
      helperText={fieldErrors?.deposit}
      onChange={(event) => handle.onChangeField(event?.target?.value, "deposit")}
      onBlur={(e) => handle.blurFn("deposit", currencyMaskedValue(e.target.value))}
      InputProps={{
        inputComponent: NumericFormatCustom,
        style: {
          borderTopRightRadius: 0,
        },
      }}
    />
  )
}