import { useStat } from "react";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import { userSelector } from "../../store/slices/userSlice";

import GoogleMaps from "../Utils/GoogleAddressSearch";
import { useState } from "react";
import { saveValue } from "src/store/slices/organisationSlice";
import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";

export default function Organisation() {
    const dispatch = useDispatch()
    const [bsb, setBsb] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [abn, setAbn] = useState("");
    const [contactName, setContactName] = useState("");
    const [email, setEmail] = useState("");

    return (
        <>
            <Grid
                container
                xs={11}
                sm={8}
                md={6}
                lg={6}
                justifyContent="center"
                alignItems="center"
                direction="column"
                textAlign="center"
                style={{ alignItems: "center", minHeight: "300px" }}
            >
                <Typography variant="h6">Your registration application has been submitted.</Typography>
                <br></br>
                <Typography>A member of the Tandemm team will be in touch soon.</Typography>
            </Grid>
        </>
    );
}