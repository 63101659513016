import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  uploadDocument,
  saveValue,
  userDetailsSelector,
  startVerification,
  getIdentityVerification,
} from "../../store/slices/applicationFormSlice";
import Document from "./Document";
import {
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Divider,
  Skeleton,
  Stack,
} from "@mui/material";
import usePoll from "src/utils/usePoll";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/styles";
import CustomCardSending from "./CustomCardSending";
import { useSnackbar } from "notistack";
import { ERRORS_MESSAGE, SUCCESS_MESSAGE } from "src/constants";
import { userSelector } from "src/store/slices/userSlice";

const Input = styled("input")({
  display: "none",
});

const Identity = React.memo(({ customer, applicationId }) => {
  // console.log("Identity")

  const dispatch = useDispatch();
  const ref = useRef(null);
  const fileInput = useRef();

  const {
    uploadIDLoading = "",
    sendingIDLink,
    idVerificationRequest,
  } = useSelector(userDetailsSelector);
  const user = useSelector(userSelector)
  const { enqueueSnackbar } = useSnackbar();
  const [startedCount, setStartedCount] = useState(0);
  const [finishedCount, setFinishedCount] = useState(0);

  const [delay, setDelay] = useState(null);

  const onStart = () => {
    setDelay(30000);
  };

  const onStop = () => {
    setDelay(null);
  };

  const handle = {
    startIdentityVerification: async (customerId, mobile) => {
      let res = await dispatch(
        startVerification({ customerId, applicationId, mobile })
      ).unwrap();

      if (res) {
        enqueueSnackbar(SUCCESS_MESSAGE.id_verification, {
          variant: "success",
          autoHideDuration: 5000,
        });
        dispatch(saveValue({ sendingIDLink: false }));

        handle.getIdentityVerificationFn(customer?._id);
      } else {
        enqueueSnackbar(ERRORS_MESSAGE.fetch_error_msg, {
          variant: "error",
          autoHideDuration: 5000,
        });
        dispatch(saveValue({ sendingIDLink: false }));
      }
    },
    getIdentityVerificationFn: (customerId) => {
      onStart();
    },
    uploadID: (event) => {
      dispatch(saveValue({ uploadIDLoading: true }));
      const formData = new FormData();
      console.log(event.target.files[0]);
      // Update the formData object
      formData.append("file", event.target.files[0]);
      formData.append("applicationId", applicationId);
      formData.append("customerId", customer._id);
      formData.append("name", "ID document");

      // Details of the uploaded file
      console.log(customer._id);
      console.log(customer.firstName);

      dispatch(uploadDocument(formData));
      event.target.input = null;
      // Request made to the backend api
      // Send formData object
    },
  };

  const updateMobile = customer?.phoneNumbers?.find(
    (ele) => ele.status === "current"
  );

  const [IDUploadButtonBackground, setIDUploadButtonBackground] =
    useState("primary");
  const [IDUploadButtonText, setIDUploadButtonText] = useState("Upload ID");

  const [sendIDLinkBackground, setSendIDLinkBackground] = useState("primary");
  const [sendIDLinkText, setSendIDLinkText] = useState("Send ID Capture Link");

  function handleIDButtonSuccessStyle() {
    setIDUploadButtonBackground("success");
    setIDUploadButtonText("Upload ID Successful");

    setTimeout(() => {
      setIDUploadButtonBackground("primary");
      setIDUploadButtonText("UPLOAD ID");
    }, 6000);
  }

  function handleSendIDLinkSuccessStyle() {
    setSendIDLinkBackground("success");
    setSendIDLinkText("Link sent");

    setTimeout(() => {
      setSendIDLinkBackground("primary");
      setSendIDLinkText("Send ID capture link");
    }, 6000);
  }

  usePoll(async () => {
    setStartedCount((count) => count + 1);
    dispatch(
      getIdentityVerification({
        customer: customer._id,
        // application: applicationId,
      })
    );
    setFinishedCount((count) => count + 1);
  }, delay);

  useEffect(() => {
    if (customer?.documents?.length > 0) setDelay(null);
  }, [customer]);

  const [imageWidth, setImageWidth] = useState();

  useEffect(() => {
    // console.log("width", ref.current ? ref.current.offsetWidth : 0);
    setImageWidth(ref.current ? ref.current.offsetWidth : 0);
  }, [ref.current]);

  useEffect(() => {
    if (uploadIDLoading === false) {
      handleIDButtonSuccessStyle();
      dispatch(saveValue({ uploadIDLoading: null }));
    }
  }, [uploadIDLoading]);

  useEffect(() => {
    if (sendingIDLink === false) {
      handleSendIDLinkSuccessStyle();
      dispatch(saveValue({ sendingIDLink: null }));
    }
  }, [sendingIDLink]);

  return (
    <>
      <Grid
        container
        item
        xs={12}
        spacing={0}
        style={{
          margin: "0 0 30px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          paddingBottom: "30px",
        }}
      >
        <Grid item xs={12}>
          <Typography fontWeight={600} style={{ marginBottom: "15px" }}>
            Identity
          </Typography>
        </Grid>

        <Stack spacing={1} direction="row" style={{ margin: "0 0 20px" }}>
          {customer?.documents?.length > 0 &&
            customer?.documents
              ?.filter((doc) =>
                [
                  "Driver licence (back)",
                  "Driver licence (front)",
                  "Customer photo",
                  "Verification certificate",
                ].includes(doc.name)
              )
              .map((doc) => (
                <Document document={doc} customer={customer} name="identity" />
              ))}
        </Stack>

        <Grid item xs={12} md={12} sm={12} spacing={2}>
          <Stack
            spacing={2}
            direction="row"
          // divider={<Divider orientation="vertical" flexItem />}
          >
            {user?.userType === "master" &&
              <Grid item xs={6}>
                <Card
                  onClick={() => {
                    if (
                      !customer?.phoneNumbers?.slice(-1)[0]?.number ? true : false
                    )
                      return;
                    handle.startIdentityVerification(
                      customer._id,
                      customer?.phoneNumbers?.slice(-1)[0]?.number
                    );
                    // dispatch(saveValue({ linkSent: true }));
                    dispatch(saveValue({ sendingIDLink: true }));
                  }}
                >
                  <CardActionArea
                    disableRipple={
                      !customer?.phoneNumbers?.slice(-1)[0]?.number ? true : false
                    }
                  >
                    <CustomCardSending
                      title={"Get ID documents"}
                      description={`Send a self service link to 
                      upload ID documents and verify identity.`}
                      imagePath={"/static/ocr-labs-logo-dark.png"}
                      loadingState={sendingIDLink}
                      financialState={false}
                      disabled={
                        !customer?.phoneNumbers?.slice(-1)[0]?.number
                          ? true
                          : false
                      }
                      icon={false}
                      lastTitle={"Sending SMS link..."}
                    />
                  </CardActionArea>
                </Card>

                {!customer?.phoneNumbers?.filter((a) => a.status === "current")[0]
                  ?.number && (
                    <Typography variant="caption">
                      Requires customer mobile phone number
                    </Typography>
                  )}
                {idVerificationRequest && (
                  <Typography variant="caption">
                    {` ID verification ${SUCCESS_MESSAGE.success_link_msg}`}
                  </Typography>
                )}
              </Grid>
            }

            <Grid item xs={6}>
              <Card style={{ height: "120px" }}>
                <Input
                  accept="*"
                  ref={fileInput}
                  id={`upload-id-${customer._id}`}
                  multiple
                  type="file"
                  value={null}
                  onChange={(event) => handle.uploadID(event)}
                />
                <CardActionArea onClick={() => fileInput.current.click()}>
                  <CustomCardSending
                    title={"Upload ID documents"}
                    description={`Upload ID documents from files stored locally on your
                    computer.`}
                    imagePath={""}
                    loadingState={uploadIDLoading}
                    financialState={false}
                    icon={false}
                    lastTitle={"Upload ID document..."}
                  />
                </CardActionArea>
              </Card>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
});

export default Identity;
