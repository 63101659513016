import { Autocomplete, Divider, CircularProgress, Grid, TextField, Typography, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userTypeOptions } from "src/constants";
import { updateUser } from "src/store/slices/userSlice";
import { updatePhoneNumber } from "src/store/slices/usersSlice";
import regex from "src/utils/regex";
import * as Yup from "yup";

const UserDetails = () => {
  const dispatch = useDispatch();
  const userState = useSelector(({ user }) => user);
  const { _id } = userState;

  const [fieldErrors, setFieldErrors] = useState({
    firstName: "",
    userType: "",
    lastName: "",
    phoneNumber: "",
    creditRepresentativeNumber: "",
    australianCreditLicenceNumber: "",
    organisationAddress: "",
    organisationPhoneNumber: "",
    organisationEmail: "",
  });

  const [userDetailState, setUserDetailState] = useState(null);

  useEffect(() => {
    if (userState?._id) {
      setUserDetailState({
        firstName: userState.firstName || "",
        userType: userState.userType || "",
        lastName: userState.lastName || "",
        userEmail: userState?.email?.address || "",
        phoneNumber: userState?.phoneNumber?.number || "",
        creditRepresentativeNumber: userState?.crn || "",
        organisationName: userState?.organisation?.name || "",
        organisationABN: userState?.organisation?.abn || "",
        organisationACN: userState?.organisation?.acn || "",
        australianCreditLicenceNumber: userState?.organisation?.acl || "",
        organisationAddress: userState?.organisation?.address || "",
        // organisationPhoneNumber: userState?.organisation?.phoneNumber || "",
        organisationEmail: userState?.organisation?.email || "",
      });
    }
  }, [userState]);

  const validationSchema = Yup.object({
    firstName: Yup.string().optional().matches(regex.name, "Only string allowed"),
    lastName: Yup.string().optional().matches(regex.name, "Only string allowed"),
    userType: Yup.string().optional().nullable().matches(regex.stringRegex, "Only string allowed"),
    phoneNumber: Yup.string().optional().matches(regex.phoneNumber, "Please enter mobile number properly"),
    creditRepresentativeNumber: Yup.string()
      .optional()
      .matches(regex.allowOnlyNumber, "Please enter credit representative number properly"),
    australianCreditLicenceNumber: Yup.string()
      .optional()
      .matches(regex.allowOnlyNumber, "Please enter australian credit licence number properly"),
    organisationAddress: Yup.string()
      .optional()
      .matches(regex.addressRegex, "Please enter organisation email properly"),
    organisationPhoneNumber: Yup.string()
      .optional()
      .matches(regex.phoneNumber, "Please enter organisation phone number properly"),
    organisationEmail: Yup.string().optional().matches(regex.emailOptional, "Please enter organisation email properly"),
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };

  const handle = {
    onChange: async (value, name) => {
      let valid = true;

      if (!valid) {
        await validateField(name, value);
        return;
      } else {
        setUserDetailState({
          ...userDetailState,
          [name]: value,
        });
        await validateField(name, value);
      }
    },
    blurFn: (value, name) => {
      try {
        let oldVal = "";
        if (name == "userEmail") {
          oldVal = userState?.email?.address;
        } else if (name == "phoneNumber") {
          oldVal = userState?.phoneNumber?.number;
        } else if (name == "creditRepresentativeNumber") {
          oldVal = userState?.crn;
        } else if (name == "organisationName") {
          oldVal == userState?.organisation?.name;
        } else if (name == "organisationABN") {
          oldVal == userState?.organisation?.abn;
        } else if (name == "organisationACN") {
          oldVal == userState?.organisation?.acn;
        } else if (name == "australianCreditLicenceNumber") {
          oldVal == userState?.organisation?.acl;
        } else if (name == "organisationAddress") {
          oldVal == userState?.organisation?.address;
        } else if (name == "organisationEmail") {
          oldVal == userState?.organisation?.email;
        } else {
          oldVal = userState[name];
        }
        console.log("oldVal", oldVal)
        console.log("value", value)
        if (oldVal === value) {
          return;
        }

        if (fieldErrors[name] === "" || name) {
          handle.updateProfile({ [name]: value });
        }
      } catch (error) {
        const newErrors = {};
        error?.inner?.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
        setFieldErrors(newErrors);
      }
    },
    updateProfile: (value) => {
      if ((value?.firstName || value?.lastName || value?.userType || value?.creditRepresentativeNumber) && _id) {
        if (value?.creditRepresentativeNumber) {
          dispatch(updateUser({ userId: _id, crn: value.creditRepresentativeNumber }));
          return;
        }
        dispatch(updateUser({ userId: _id, ...value }));
      }
      // this api is in progress
      if (value?.phoneNumber) {
        // console.log("phoneNumber", userState?.phoneNumber?._id);
        dispatch(updatePhoneNumber({ phoneNumber: value.phoneNumber }));
      }

      // if (value?.organisationPhoneNumber) {
      //   dispatch(
      //     updatePhoneNumberOrg({
      //       phoneNumber: value.organisationPhoneNumber,
      //       organisationId: userState.organisation._id,
      //     })
      //   );
      // }
    },
  };

  return (
    <Grid container spacing={1.5}>
      {userState?._id ? (
        <>
          <Grid item xs={8}>
            <Typography variant="h4" style={{ margin: "0 0 5px 0" }}>
              User Details
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <TextField
              label="First name"
              variant="filled"
              type="text"
              value={userDetailState?.firstName}
              error={fieldErrors?.firstName}
              helperText={fieldErrors?.firstName}
              onChange={(event) => handle.onChange(event.target.value, "firstName")}
              onBlur={(event) => handle.blurFn(event.target.value, "firstName")}
              size="small"
              style={{ margin: "0 0 5px 0" }}
              fullWidth
            />
          </Grid>

          <Grid item xs={8}>
            <TextField
              label="Last name"
              variant="filled"
              type="text"
              value={userDetailState?.lastName}
              error={fieldErrors?.lastName}
              helperText={fieldErrors?.lastName}
              onChange={(event) => handle.onChange(event.target.value, "lastName")}
              onBlur={(event) => handle.blurFn(event.target.value, "lastName")}
              size="small"
              style={{ margin: "0 0 5px 0" }}
              fullWidth
            />
          </Grid>

          <Grid item xs={8}>
            <TextField
              label="Email address"
              variant="filled"
              type="text"
              value={userDetailState?.userEmail}
              disabled
              size="small"
              style={{ margin: "0 0 5px 0" }}
              fullWidth
            />
          </Grid>

          <Grid item xs={8}>
            <TextField
              label="Mobile number"
              variant="filled"
              type="text"
              value={userDetailState?.phoneNumber}
              error={fieldErrors?.phoneNumber}
              helperText={fieldErrors?.phoneNumber}
              onChange={(event) => handle.onChange(event.target.value, "phoneNumber")}
              onBlur={(event) => handle.blurFn(event.target.value, "phoneNumber")}
              size="small"
              style={{ margin: "0 0 5px 0" }}
              fullWidth
            />
          </Grid>

          <Grid item xs={8}>
            <TextField
              label="Credit Representative Number"
              variant="filled"
              type="text"
              value={userDetailState?.creditRepresentativeNumber}
              error={fieldErrors?.creditRepresentativeNumber}
              helperText={fieldErrors?.creditRepresentativeNumber}
              onChange={(event) => handle.onChange(event.target.value, "creditRepresentativeNumber")}
              onBlur={(event) => handle.blurFn(event.target.value, "creditRepresentativeNumber")}
              size="small"
              style={{ margin: "0 0 5px 0" }}
              fullWidth
            />
          </Grid>

          <Grid item xs={8}>
            <Autocomplete
              options={userTypeOptions}
              variant="filled"
              size="small"
              fullWidth
              value={userDetailState?.userType}
              onChange={(event, newValue) => handle.onChange(newValue, "userType")}
              onBlur={(event) => handle.blurFn(event.target.value, "userType")}
              style={{ margin: "0 0 5px 0" }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  {...params}
                  label="User type"
                  variant="filled"
                  error={fieldErrors?.userType}
                  helperText={fieldErrors?.userType}
                />
              )}
            />
          </Grid>

          <Grid item xs={8}>
            <Divider style={{ margin: "20px 0 10px 0" }} />
          </Grid>

          <Grid item xs={8}>
            <Typography variant="h5" style={{ margin: "0 0 5px 0" }}>
              Organisation details
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <TextField
              label="Organisation name"
              variant="filled"
              value={userDetailState?.organisationName}
              type="text"
              disabled
              size="small"
              style={{ margin: "0 0 5px 0" }}
              fullWidth
            />
          </Grid>

          <Grid item xs={8}>
            <TextField
              label="ABN"
              variant="filled"
              value={userDetailState?.organisationABN}
              type="text"
              disabled
              size="small"
              style={{ margin: "0 0 5px 0" }}
              fullWidth
            />
          </Grid>

          <Grid item xs={8}>
            <TextField
              label="ACN"
              variant="filled"
              value={userDetailState?.organisationACN}
              type="text"
              disabled
              size="small"
              style={{ margin: "0 0 5px 0" }}
              fullWidth
            />
          </Grid>

          <Grid item xs={8}>
            <TextField
              label="Australian Credit Licence Number"
              variant="filled"
              type="text"
              value={userDetailState?.australianCreditLicenceNumber}
              error={fieldErrors?.australianCreditLicenceNumber}
              helperText={fieldErrors?.australianCreditLicenceNumber}
              onChange={(event) => handle.onChange(event.target.value, "australianCreditLicenceNumber")}
              onBlur={(event) => handle.blurFn(event.target.value, "australianCreditLicenceNumber")}
              size="small"
              style={{ margin: "0 0 5px 0" }}
              fullWidth
            />
          </Grid>

          <Grid item xs={8}>
            <TextField
              label="Address"
              variant="filled"
              type="text"
              value={userDetailState?.organisationAddress}
              error={fieldErrors?.organisationAddress}
              helperText={fieldErrors?.organisationAddress}
              onChange={(event) => handle.onChange(event.target.value, "organisationAddress")}
              onBlur={(event) => handle.blurFn(event.target.value, "organisationAddress")}
              size="small"
              style={{ margin: "0 0 5px 0" }}
              fullWidth
            />
          </Grid>

          {/* <Grid item xs={8}>
        <TextField
          label="Phone number"
          variant="filled"
          type="text"
          value={userDetailState?.organisationPhoneNumber}
          error={fieldErrors?.organisationPhoneNumber}
          helperText={fieldErrors?.organisationPhoneNumber}
          onChange={(event) =>
            handle.onChange(event.target.value, "organisationPhoneNumber")
          }
          onBlur={(event) =>
            handle.blurFn(event.target.value, "organisationPhoneNumber")
          }
          size="small"
          style={{ margin: "0 0 5px 0" }}
          fullWidth
        />
      </Grid> */}

          <Grid item xs={8}>
            <TextField
              label="Email"
              variant="filled"
              type="text"
              value={userDetailState?.organisationEmail}
              error={fieldErrors?.organisationEmail}
              helperText={fieldErrors?.organisationEmail}
              onChange={(event) => handle.onChange(event.target.value, "organisationEmail")}
              onBlur={(event) => handle.blurFn(event.target.value, "organisationEmail")}
              size="small"
              style={{ margin: "0 0 5px 0" }}
              fullWidth
            />
          </Grid>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            width: "100%",
            marginTop: "50px",
          }}
        >
          <CircularProgress size={40} />
        </Box>
      )}
    </Grid>
  );
};

export default UserDetails;