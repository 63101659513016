import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { Grid, Skeleton, Typography } from "@mui/material";

import {
  saveValue,
  userDetailsSelector,
  createEmployer,
} from "../../store/slices/applicationFormSlice";

import { getBusinessByABN, getBusinessByName } from "../../services/abr";

import { isDigitsOnly } from "src/utils/isDigitsOnly";
import AutoSearchHOC from "../HOC/AutoSearchHOC";

const Employer = React.memo(({ customerId, numberOfEmployers }) => {
  const dispatch = useDispatch();

  const { gettingEntityDetails } = useSelector(userDetailsSelector);

  const [loadingBusinessSearch, setLoadingBusinessSearch] = useState("");
  const [entityOptions, setEntityOptions] = useState([]);

  const [businessSearchValue, setBusinessSearchValue] = useState({
    searchValueBusiness: "",
    businessSearchInputText: "",
  });

  const [fieldErrors, setFieldErrors] = useState({
    business_search: "",
  });

  const debounced = useMemo(() =>
    debounce(async (inputValue) => {
      if (inputValue) {
        setLoadingBusinessSearch(true);
        if (isDigitsOnly.test(inputValue)) {
          const businessByABN = await getBusinessByABN(inputValue);
          setLoadingBusinessSearch(false);
          if (businessByABN.Abn.length > 1) {
            return setEntityOptions([businessByABN]);
          }
        }

        const businesses = await getBusinessByName(inputValue);
        if (businesses?.Names?.length > 0) {
          setLoadingBusinessSearch(false);
          return setEntityOptions(
            businesses.Names.map((i) => {
              return i;
            })
          );
        }
        setEntityOptions([
          {
            Name: "No results found",
          },
        ]);
      }
    }, 500)
  );

  const handleEntityDetails = async (details) => {
    dispatch(saveValue({ gettingEntityDetails: true }));
    const abrData = await getBusinessByABN(details.Abn);
    setFieldErrors({ business_search: "" });
    dispatch(createEmployer({ abrData, customerId: customerId }));
  };

  useEffect(() => {
    debounced(businessSearchValue?.businessSearchInputText);
    return () => debounced.cancel();
  }, [
    businessSearchValue?.searchValueBusiness,
    businessSearchValue?.businessSearchInputText,
  ]);

  useEffect(() => {
    if (!gettingEntityDetails) {
      setBusinessSearchValue({
        searchValueBusiness: "",
        businessSearchInputText: "",
      });
    }
  }, [gettingEntityDetails]);

  const numOfColumns = 3;
  const numOfRows = 2;

  const handle = {
    loadingGridFirst: (
      <Grid item container spacing={2} style={{ marginBottom: "15px" }}>
        {Array.from({ length: numOfColumns * numOfRows }).map((_, index) => (
          <Grid key={index} item sm={4}>
            <Skeleton variant="text" animation="wave" />
          </Grid>
        ))}
      </Grid>
    ),
  };


  return (
    <Grid
      container
      style={{
        margin: "0 0 30px 0",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        paddingBottom: "30px",
      }}
    >
      {gettingEntityDetails && (
        <Grid container item style={{}}>
          <Typography style={{ margin: "0 0 10px" }}>
            Getting employer details...
          </Typography>
          {handle.loadingGridFirst}
          {handle.loadingGridFirst}
        </Grid>
      )}
      <Grid container style={{ margin: "0 0 30px 0" }}>

        <Grid item md={9} sm={12}>
          <Typography style={{ marginBottom: "10px" }}>
            {numberOfEmployers > 0 ? "Add previous employer" : "Add employer"}
          </Typography>
          <AutoSearchHOC
            setBusinessSearchValue={setBusinessSearchValue}
            setEntityOptions={setEntityOptions}
            loadingBusinessSearch={loadingBusinessSearch}
            entityOptions={entityOptions}
            updatedSearch={handleEntityDetails}
            businessSearchValue={businessSearchValue}
            fieldErrors={fieldErrors}
            setFieldErrors={setFieldErrors}
          />
        </Grid>
      </Grid>
    </Grid>
  );
});

export default Employer;
