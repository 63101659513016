import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import UserDetails from "./User";
import Organisation from "./Organisation";
import BankDetails from "./BankDetails";
import Success from "./Success";
import { nanoid } from "@reduxjs/toolkit";
import { Fragment } from "react";
import { Grid, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  registerUser,
  saveValue,
  userSelector,
} from "src/store/slices/userSlice";
import {
  createBankDetails,
  createOrganisation,
  organisationSelector,
} from "src/store/slices/organisationSlice";
import AutoCompleteLoader from "../Application/AutoCompleteLoader";
import { useEffect } from "react";

const steps = [
  "Introducer Details",
  "Business Details",
  "Banking Details",
  "Submit",
];

export default function RegistrationStepper() {
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = React.useState(0);
  // const [skipped, setSkipped] = React.useState(new Set());
  let disableBtn = true;


  const user = useSelector(userSelector);

  const {
    name,
    address,
    email: businessEmail,
    phoneNumber: businessPhoneNumber,
    aggregationGroup,
    bsb,
    accountNumber,
    abn,
    contactName,
    contactEmail,
    _id: organisationId,
  } = useSelector(organisationSelector);

  const handleNext = async () => {
    if (activeStep === 0) await handleUserRegistration();
    if (activeStep === 1) await handleOrganisationRegistration();
    if (activeStep === 2) await handleBankDetails();

    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  async function handleUserRegistration() {
    const dataToSave = {
      ...user,
      previousAccreditation: user?.previousAccreditation === "Yes" ? true : false,
      representativeOfACLHolder: user?.representativeOfACLHolder === "Yes" ? true : false,
      employeeOfACLHolder: user?.employeeOfACLHolder === "Yes" ? true : false,
      directorOfACLHolder: user?.directorOfACLHolder === "Yes" ? true : false,
      yearsOfLendingExperience: Number(user?.yearsOfLendingExperience),
    }
    dispatch(saveValue({ isCreatingAccount: true }));

    await dispatch(
      registerUser(dataToSave)
    ).unwrap();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  async function handleOrganisationRegistration() {
    console.log({
      name,
      address,
      aggregationGroup,
      userId: user._id,
    });
    dispatch(saveValue({ isCreatingAccount: true }));
    await dispatch(
      createOrganisation({
        name,
        address,
        aggregationGroup,
        userId: user._id,
      })
    ).unwrap();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  async function handleBankDetails() {
    console.log({
      bsb,
      accountNumber,
      abn: abn,
      userId: user._id,
      organisationId,
    });
    dispatch(saveValue({ isCreatingAccount: true }));
    await dispatch(
      createBankDetails({
        bsb,
        accountNumber,
        abn,
        userId: user._id,
        organisationId,
      })
    ).unwrap();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const isIntroFieldsValid = () => {
    return user?.firstName &&
      user?.lastName &&
      user?.email &&
      user?.phoneNumber &&
      user?.password &&
      user?.previousAccreditation &&
      user?.representativeOfACLHolder &&
      user?.directorOfACLHolder &&
      user?.employeeOfACLHolder &&
      user?.other
  };

  const isBusinessFieldsValid = () => {
    return name &&
      address &&
      aggregationGroup
  };

  const isBankingFieldsValid = () => {
    return bsb &&
      accountNumber &&
      abn 
  };

  const isButtonDisabled = () => {
    switch (activeStep) {
      case 0:
        return !isIntroFieldsValid();
      case 1:
        return !isBusinessFieldsValid();
      case 2:
        return !isBankingFieldsValid();
      default:
        return false;
    }
  };

  return (
    <Fragment>
      <Grid
        container
        item
        // height="100%"
        // width="100%"
        style={{ padding: "30px 0 100px" }}
        justifyContent="center"
        alignItems="center"
        direction="row-reverse"
      >
        <Grid
          container
          item
          md={8}
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid
            item
            style={{
              textAlign: "center",
              margin: "0 0 30px 0",
            }}
          >
            <Typography
              variant="h4"
              fontWeight="800"
              style={{
                letterSpacing: "-1.8px",
                margin: "0 0 10px 0",
              }}
            >
              Tandemm
            </Typography>
            <Typography>Introducer Registration Form</Typography>
          </Grid>
          {/* <Grid conatiner item > */}

          <Grid container item xs={12}>
            <Stepper
              activeStep={activeStep}
              style={{
                margin: "0 0 40px 0",
                width: "100%",
              }}
            >
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};

                // console.log({ activeStep });
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <>
                <Grid
                  item
                  container
                  direction="column"
                  justifyContent="center"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0 0 10px 0",
                  }}
                >
                  {activeStep === 0 && <UserDetails />}
                  {activeStep === 1 && <Organisation />}
                  {activeStep === 2 && <BankDetails />}

                  {activeStep === 3 && <Success />}
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  direction="row"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={6} direction="row">
                    {/* {activeStep !== 0 &&
                                            <Button
                                                color="inherit"
                                                disabled={activeStep === 0}
                                                onClick={handleBack}

                                            // sx={{ mr: 1 }}
                                            >
                                                Back
                                            </Button>
                                        } */}
                    {/* <Box sx={{ flex: '1 1 auto' }} /> */}
                    {/* {isStepOptional(activeStep) && (
                            <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                Skip
                            </Button>
                        )} */}

                    <Stack
                      style={
                        {
                          // float: "right"
                        }
                      }
                    >
                      {activeStep !== 3 && (
                        <Button
                          onClick={handleNext}
                          color="primary"
                          variant="contained"
                          disabled={isButtonDisabled()}
                        >
                          {activeStep === 0 && user?.isCreatingAccount ? (
                            <AutoCompleteLoader />
                          ) : (
                            "Next step to Business Details"
                          )}
                          {activeStep === 1 && "Next step to Banking Details"}
                          {activeStep === 2 && "Submit"}
                        </Button>
                      )}

                      {/* {activeStep === 0 && <Typography variant='subtitle'>Only takes minutes</Typography>} */}
                    </Stack>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}
