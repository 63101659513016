import { List, ListItem, ListItemButton, ListItemText, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { userSelector } from "src/store/slices/userSlice";



export default function SettingsSidebar({ path }) {
    const navigate = useNavigate()
    const user = useSelector(userSelector);
    
    const primaryTypographyProps = {
        // color: 'primary',
        fontWeight: '400',
        fontSize: "13px",
        // variant: 'body2',
    }
    return (
        <>
            <Box
                sx={{ width: "100%", fontSize: "10px" }}
            >
                <List>
                    <ListItem disablePadding dense>
                        <ListItemButton selected={path === "general"} onClick={() => navigate("/settings/general")}>
                            <ListItemText
                                primary="Profile"
                                primaryTypographyProps={{
                                    fontSize: "13px",
                                    fontWeight: path === "general" ? "700" : 400
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                    {user?.userType === "master" &&
                        <ListItem disablePadding dense >
                            <ListItemButton selected={path === "lenders"} onClick={() => navigate("/settings/lenders")}>
                                <ListItemText
                                    primary="Lenders"
                                    primaryTypographyProps={{
                                        fontSize: "13px",
                                        fontWeight: path === "lenders" ? "700" : 400
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                    }
                    {/* <ListItem disablePadding dense>
                        <ListItemButton selected={path === "templates"} onClick={() => navigate("/settings/templates")}>
                            <ListItemText
                                primary="Templates"
                                primaryTypographyProps={{
                                    fontSize: "13px",
                                    fontWeight: path === "templates" ? "700" : 400
                                }}
                            />
                        </ListItemButton>
                    </ListItem> */}
                </List>
            </Box>


        </>
    );
}
