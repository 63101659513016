import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ERRORS_MESSAGE, SUCCESS_MESSAGE } from "src/constants";
import { socket } from "src/socket";
import {
  getCurrentProductLenderId,
  getProductSubmitDetail,
  saveValue,
  submitApplication,
  userDetailsSelector,
  verifiedDialogBox,
} from "src/store/slices/applicationFormSlice";

const VerifyDialog = ({ isOpen, title, message }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const applicantsSubmitData = useSelector(userDetailsSelector);
  const [code, setCode] = useState("");

  // useEffect(() => {
  //   return () => {
  //     socket.off("disconnect", () => {});
  //   };
  // }, [socket]);

  const handle = {
    closeDialog: () => {
      dispatch(verifiedDialogBox(false));
    },
    userVerified: async () => {
      if (applicantsSubmitData?.productSubmitDetail?.applicationId) {
        socket.emit("2fa-code", {
          code: code,
        });

        dispatch(
          submitApplication({
            applicationId:
              applicantsSubmitData?.productSubmitDetail?.applicationId,
            lenderType:
              applicantsSubmitData?.productSubmitDetail?.lenderName ===
              "Angle Finance"
                ? "Angle"
                : (applicantsSubmitData?.productSubmitDetail?.lenderName ===
                  "Dynamoney"
                    ? "Grow"
                    : applicantsSubmitData?.productSubmitDetail?.lenderName),
          })
        ).then((res) => {
          dispatch(saveValue({ applyApplication: true }));
          // setDisableApplicationBtn(false);
          dispatch(getCurrentProductLenderId(""));

          if (res.data) {
            enqueueSnackbar(SUCCESS_MESSAGE.link_msg, {
              variant: "success",
              autoHideDuration: 5000,
            });
          } else {
            enqueueSnackbar(ERRORS_MESSAGE.fetch_error_msg, {
              variant: "error",
              autoHideDuration: 5000,
            });
          }
        });
        setCode("");
        handle.closeDialog();

        dispatch(
          getProductSubmitDetail({
            applicationId: "",
            lenderName: "",
          })
        );

        return;
      }
      setCode("");
      dispatch(
        getProductSubmitDetail({
          applicationId: "",
          lenderName: "",
        })
      );
      handle.closeDialog();
      return;
    },
  };

  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={handle.closeDialog}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
          <TextField
            autoFocus
            label="Verify code"
            type="number"
            value={code}
            onChange={(event) => setCode(event.target.value)}
            fullWidth
            variant="filled"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handle.closeDialog} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handle.userVerified} variant="outlined">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default VerifyDialog;
