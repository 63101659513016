import { Box, Grid, Slider, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  saveCustomer,
  saveCustomerProfile,
  updateCustomer,
} from "src/store/slices/applicationFormSlice";
import RadioButtonGroup from "../Utils/RadioButtonGroup";
import PhoneNumber from "./PhoneNumber";
import * as Yup from "yup";
import regex from "src/utils/regex";
import { NumericFormatCustom, currencyMaskedValue } from "src/utils/currencyMaskFormat";

const CustomerPersonalProfile = React.memo(
  ({ customer, customerId, applicationId }) => {
    const dispatch = useDispatch();

    const [allValues, setAllValue] = useState({
      livingArrangementsValue: customer?.customerProfile?.livingArrangements,
      dependentsValue: customer?.customerProfile?.numberOfDependants,
      RentAmountMonthly: customer?.customerProfile?.rentAmount || "",
      contactNumber: customer?.customerProfile?.contactNumber || "",
    });
    const [errorField, setErrorField] = useState({
      contactNumber: "",
      RentAmountMonthly: "",
    });

    const validationSchema = Yup.object({
      RentAmountMonthly: Yup.string()
        .matches(
          regex.allowOnlyNumber,
          "Only numbers and decimal points allowed."
        )
        .min(3, "Minimum of 3 digits")
        .max(10, "Maximum of 7 digits"),
    });

    const validateField = async (fieldName, value) => {
      try {
        await validationSchema.validateAt(fieldName, { [fieldName]: value });
        setErrorField((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
      } catch (error) {
        setErrorField((prevErrors) => ({
          ...prevErrors,
          [fieldName]: error.message,
        }));
      }
    };

    const handle = {
      onChangeField: async (value, name) => {
        setAllValue({ ...allValues, [name]: value });
        await validateField(name, value);
      },
      saveCustomerProfile: (fieldName, value) => {
        const data = {
          [fieldName]: value,
          numberOfDependants: allValues?.dependentsValue || 0,
          customer: customer._id,
        }

        if (fieldName !== "rentAmount" && fieldName !== "contactNumber" && value !== "Renting") {
          data.RentAmountMonthly = ""
          data.contactNumber = ""
        }

        dispatch(saveCustomerProfile(data));
      },
      numberOfDependant: (event, value) => {
        dispatch(
          saveCustomerProfile({
            numberOfDependants: value,
            customer: customer._id,
          })
        );
      },
    };

    return (
      <React.Fragment>
        <Grid
          container
          item
          xs={12}
          style={{
            margin: "0 0 30px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            paddingBottom: "30px",
          }}
        >
          <Grid item md={12} sm={12} style={{ margin: "0 0 10px" }}>
            <Typography fontWeight={600}>Personal Profile</Typography>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item sm={7}>
              <Typography variant="subtitle2">
                Property/Living arrangements
              </Typography>
              <RadioButtonGroup
                value={allValues?.livingArrangementsValue}
                name={"livingArrangementsValue"}
                handleValue={(value) => {
                  handle.onChangeField(value, "livingArrangementsValue");
                  handle.saveCustomerProfile("livingArrangements", value);
                }}
                options={[
                  { icon: "House", label: "Property owner" },
                  { icon: "HomeWork", label: "Renting" },
                  { icon: "Hotel", label: "Boarding" },
                  { icon: "Living w/ parents", label: "Living w/ parents" },
                ]}
              />
            </Grid>

            {/* {allValues?.livingArrangementsValue === "Property owner" && ( */}
            <Grid item sm={5} style={{ margin: "0 0 10px" }}>
              <Typography
                variant="subtitle2"
                gutterBottom
              // style={{ marginBottom: "40px" }}
              >
                Number of dependents
              </Typography>
              <Box style={{ padding: "0 7px" }}>
                <Slider
                  required
                  color="secondary"
                  size="large"
                  marks={[
                    { value: 0, label: 0 },
                    { value: 1, label: 1 },
                    { value: 2, label: 2 },
                    { value: 3, label: 3 },
                    { value: 4, label: 4 },
                    { value: 5, label: 5 },
                    { value: 6, label: 6 },
                    { value: 7, label: 7 },
                    { value: 8, label: 8 },
                    { value: 9, label: 9 },
                    { value: 10, label: "10+" },
                  ]}
                  step={1}
                  min={0}
                  max={10}
                  aria-label="Default"
                  valueLabelDisplay="auto"
                  onChange={(event) =>
                    setAllValue({
                      ...allValues,
                      dependentsValue: event.target.value,
                    })
                  }
                  defaultValue={allValues?.dependentsValue}
                  onChangeCommitted={handle.numberOfDependant}
                />
              </Box>
            </Grid>
            {/* )} */}
          </Grid>
          {allValues?.livingArrangementsValue === "Renting" && (
            <Grid container item xs={12} sm={12} lg={12} spacing={2} sx={{ marginTop: "10px" }}>

              <Grid item sm={12}>
                <Typography variant="subtitle2">
                  Landlord/Agent details
                </Typography>
                <Grid item sm={12} style={{ display: 'flex'}}>
                  <Grid item sm={4} xs={4} lg={4}>
                    <PhoneNumber
                      label={"Contact number"}
                      singleCustomerId={customerId}
                      customerId={customer?._id}
                      guarantorFieldErrors={errorField}
                      setGuarantorFieldErrors={setErrorField}
                      saveContactNum={handle.saveCustomerProfile}
                      phoneNumber={allValues?.contactNumber}
                    // phoneNumber={
                    //   customer ? customer?.customerProfile?.contactNumber : ""
                    // }
                    />
                  </Grid>
                  <Grid item sm={4} xs={4} lg={4}>
                    <TextField
                      sx={{ marginLeft: "8px" }}
                      id="outlined-basic"
                      label={"Rent amount monthly"}
                      value={allValues?.RentAmountMonthly}
                      variant="filled"
                      size="small"
                      error={errorField.RentAmountMonthly}
                      helperText={errorField.RentAmountMonthly}
                      InputProps={{
                        inputComponent: NumericFormatCustom,
                      }}
                      onChange={(e) =>
                        handle.onChangeField(e.target.value, "RentAmountMonthly")
                      }
                      onBlur={(e) =>
                        handle.saveCustomerProfile("rentAmount",
                          currencyMaskedValue(e.target.value))
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    );
  }
);

export default CustomerPersonalProfile;
