import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import HandshakeTwoToneIcon from "@mui/icons-material/HandshakeTwoTone";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { agreementDetail, pointDetail } from "src/constants";
import { useDispatch } from "react-redux";
import { updateApplicationAgreement } from "src/store/slices/applicationFormSlice";

const IntroducerDeclaration = ({
  introducerDeclarationAgree,
  applicationId,
  errorField,
  setErrorField,
}) => {
  const dispatch = useDispatch();

  const handleCheckbox = () => {
    dispatch(
      updateApplicationAgreement({
        _id: applicationId,
        introducerDeclarationAgree: !introducerDeclarationAgree,
      })
    );
  };

  useEffect(() => {
    if (introducerDeclarationAgree === true) {
      setErrorField({
        ...errorField,
        introducerDeclarationAgree: "",
      });
    }
  }, [introducerDeclarationAgree]);

  return (
    <Grid container style={{ margin: "0 0 40px" }}>
      <Grid container xs={12} sm={12} md={2}>
        <HandshakeTwoToneIcon style={{ marginRight: "2px" }} />
        <Typography>Introducer Declaration</Typography>
      </Grid>

      <Grid
        xs={12}
        sm={12}
        md={10}
        spacing={2}
        style={{
          padding: "0 0 0 10px",
        }}
      >
        <Stack direction={"row"}>
          <Checkbox
            checked={introducerDeclarationAgree}
            onClick={handleCheckbox}
          />
          <Typography
            variant="subtitle2"
            fontWeight={"bold"}
            marginTop={"10px"}
            color={
              errorField?.introducerDeclarationAgree !== "" &&
              introducerDeclarationAgree === false
                ? "red"
                : ""
            }
          >
            I agree to the below terms *
          </Typography>
        </Stack>
        <Stack style={{ margin: "0 0 10px" }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="subtitle2" fontWeight={"bold"}>
                By completing and submitting the application on behalf of the
                applicant(s), you acknowledge that:
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ol style={{ marginLeft: "5px" }}>
                {agreementDetail.map((detailLine, i) => (
                  <li>
                    <Typography>{detailLine}</Typography>
                    {i === agreementDetail.length - 1 ? (
                      <ul style={{ marginLeft: "15px" }}>
                        {pointDetail.map((detailLine) => (
                          <li>
                            <Typography>{detailLine}</Typography>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </li>
                ))}
              </ol>
            </AccordionDetails>
          </Accordion>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default IntroducerDeclaration;
