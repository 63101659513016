
export const getLocalStorage = (key) => {
  const getData = localStorage.getItem(key);
  const parseData = JSON.parse(getData);

  return parseData;
};

export const setLocalStorage = (key, value) => {
  // removeLocalStorage(key);

  const stringifyValue = JSON.stringify(value);
  localStorage.setItem(key, stringifyValue);
};

export const removeLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const ClearLocalStorage = () => {
  localStorage.clear();
};