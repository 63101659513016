import { typeOfSaleList } from "src/constants";
import criteria from "src/utils/criteria";

const {
  typeOfSale,
  assetValueBetween,
  assetType,
  ageOfAssetLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  gstRegistrationGreaterThan,
  creditScoreGreaterThan,
} = criteria;

export default {
  date: "17 Apr 2023",
  lender: "GRENKE",
  type: "commercial",
  logoURL: "https://assets.financeable.com.au/lender-logos%2Fgrenke.gif",
  actions: ["email"],
  lenderCredentials: [
    {
      label: "Email",
      attribute: "email",
    },
    {
      label: "User Name",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
    {
      label: "Merchant ID",
      attribute: "organisationId",
    },
    {
      label: "User ID",
      attribute: "brokerId",
    },
    {
      label: "Api Key",
      attribute: "apiKey",
    },
  ],
  requiredFields: {
    entity: [
      "abn",
      // "addresses[0].streetAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
    ],
    customer: [
      "phoneNumbers[0].number",
      "emails[0].address",
      "firstName",
      "lastName",
      "dateOfBirth",
      // "addresses[0].streetAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "IDNumber",
      "IDCardNumber",
      // "emails[0].address",
      // "phoneNumbers[0].number",
    ],
    loanDetails: ["rate"],
    asset: [
      "assetType",
      "typeOfSale",
      "ageOfAsset",
      "glassesMake",
      "glassesModel",
      "glassesVariant",
      "assetValue",
    ],
  },
  fees: [
    {
      name: "Authorised supplier",
      value: 595,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(typeOfSaleList.DEALER_SALE)],
    },
  ],
  brokerage: [
    {
      value: 4,
      max: 10,
      valueType: "percentage",
      criteria: [assetValueBetween(500, 20000)],
    },
    {
      value: 4,
      max: 6,
      valueType: "percentage",
      criteria: [assetValueBetween(20000, 100000)],
    },
    {
      value: 4,
      max: 4,
      valueType: "percentage",
      criteria: [assetValueBetween(100000, 500000)],
    },
  ],
  loading: [],
  info: [
    `Rate loads are at the discretion of the lender`,
    `Property requirement is not mandatory however lender may condition for deposit`,
    `Bank Statements are not mandatory however applications >$25K may require at the discretion of lender`,
  ],
  productTiers: [
    {
      name: "Classic lease",
      rate: 12.9,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(500, 10000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(12),
        typeOfSale([typeOfSaleList.DEALER_SALE]),
        assetType([
          "Medical Equipment",
          "Manufacturing Equipment",
          "Industrial Plant & Printing",
          "Warehouse Equipment (wheeled or tracked)",
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Office Furniture",
          "Health & Beauty Equipment",
          "Solar",
        ]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Classic lease",
      rate: 11.9,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 20000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(12),
        typeOfSale([typeOfSaleList.DEALER_SALE]),
        assetType([
          "Medical Equipment",
          "Manufacturing Equipment",
          "Industrial Plant & Printing",
          "Warehouse Equipment (wheeled or tracked)",
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Office Furniture",
          "Health & Beauty Equipment",
          "Solar",
        ]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Classic lease",
      rate: 9.5,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20000, 35000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(12),
        typeOfSale([typeOfSaleList.DEALER_SALE]),
        assetType([
          "Medical Equipment",
          "Manufacturing Equipment",
          "Industrial Plant & Printing",
          "Warehouse Equipment (wheeled or tracked)",
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Office Furniture",
          "Health & Beauty Equipment",
          "Solar",
        ]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Classic lease",
      rate: 9.9,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(35000, 50000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(12),
        typeOfSale([typeOfSaleList.DEALER_SALE]),
        assetType([
          "Medical Equipment",
          "Manufacturing Equipment",
          "Industrial Plant & Printing",
          "Warehouse Equipment (wheeled or tracked)",
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Office Furniture",
          "Health & Beauty Equipment",
          "Solar",
        ]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Classic lease",
      rate: 9.5,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(50000, 75000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(12),
        typeOfSale([typeOfSaleList.DEALER_SALE]),
        assetType([
          "Medical Equipment",
          "Manufacturing Equipment",
          "Industrial Plant & Printing",
          "Warehouse Equipment (wheeled or tracked)",
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Office Furniture",
          "Health & Beauty Equipment",
          "Solar",
        ]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Classic lease",
      rate: 9.5,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(75000, 500000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(12),
        typeOfSale([typeOfSaleList.DEALER_SALE]),
        assetType([
          "Medical Equipment",
          "Manufacturing Equipment",
          "Industrial Plant & Printing",
          "Warehouse Equipment (wheeled or tracked)",
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Office Furniture",
          "Health & Beauty Equipment",
          "Solar",
        ]),
        creditScoreGreaterThan(550),
      ],
    },
  ],
};
