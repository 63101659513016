import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import regex from "src/utils/regex";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {
  createFinanceItem,
  deleteFinanceItem,
  createLiabilities,
  deleteLiabilities,
  updateLiabilities,
  updateLiabilitiesList,
} from "../../store/slices/applicationFormSlice";
import InputAdornment from "@mui/material/InputAdornment";
import _ from "lodash";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import FinanceItem from "./FinanceItem";
import { frequencyList, liabilitiesTypesList } from "src/constants";
import {
  NumericFormatCustom,
  currencyMaskedValue,
} from "src/utils/currencyMaskFormat";

export default function Liabilities({
  liabilities,
  customerId,
  applicationId,
}) {
  const dispatch = useDispatch();
  const [newLiabilityName, setNewLiabilityName] = useState("");
  const [liabilityList, setLiabilityList] = useState(liabilities || []);
  const [changeDetected, setChangeDetected] = useState(false);

  const [fieldErrors, setFieldErrors] = useState({
    liability: "",
    limit: "",
    outstandingAmount: "",
    repaymentAmount: "",
    financier: "",
  });
  const assetState = useSelector(({ assert }) => assert);
  const liabilitiesList = assetState?.liabilitiesListData || [];

  const validationSchema = Yup.object({
    liability: Yup.string()
      .optional()
      .matches(
        regex.fanciableItemNameRegex,
        "Only letters, dashes (-), forward slashes (/), commas (,), and spaces are allowed."
      )
      .max(25, "Maximum of 25 characters"),
    limit: Yup.string()
      .optional()
      .matches(regex.allowOnlyNumber, "Only numbers allowed.")
      .max(10, "Maximum of 7 digits"),
    outstandingAmount: Yup.string()
      .optional()
      .matches(regex.allowOnlyNumber, "Only numbers allowed.")
      .max(10, "Maximum of 7 digits"),
    repaymentAmount: Yup.string()
      .optional()
      .matches(regex.allowOnlyNumber, "Only numbers allowed.")
      .max(10, "Maximum of 7 digits"),
    financier: Yup.string()
      .optional()
      .matches(regex.alphaNumericAndSpecificChar, "Only letters, numbers and . ! / ( ) - + characters allowed.")
      .max(50, "Maximum of 50 characters"),
    description: Yup.string().optional().max(10, "Maximum of 100 characters"),
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };

  const onAddNewLiabilitiesItem = () => {
    const data = {
      liabilitiesType: "",
      outstandingAmount: "",
      limit: "",
      financier: "",
      repaymentFrequency: "",
      repaymentAmount: "",
      description: "",
      application: applicationId,
      customer: customerId,
    };

    dispatch(createLiabilities(data));
  };

  const removeLiabilities = (Element) => {
    const data = {
      liabilityId: Element._id,
      customer: Element.customer,
    };
    delete data._id;
    dispatch(deleteLiabilities(data));
  };

  useEffect(() => {
    if (liabilities?.length > 0) {
      setLiabilityList(liabilities)
    }
  }, [liabilities])

  const handle = {
    onBlur: (name, value, newID) => {
      const temp = [...liabilityList];

      temp.map((ele) => {
        if (ele._id === newID) {
          let updatedLiabilitiesData = {
            ...ele,
            [name]: value,
            liabilityId: newID,
            customer: customerId,
          };
          delete updatedLiabilitiesData._id;
          if (changeDetected) {
            dispatch(updateLiabilities(updatedLiabilitiesData));
            setChangeDetected(false);
          }
          return updatedLiabilitiesData;
        } else {
          return ele;
        }
      });
    },
    onChange: async (name, value, newID) => {

      const temp = [...liabilityList];

      let valid = true;
      let isChangeDetected = false;

      if(name === "financier"){
        valid = regex.alphaNumericAndSpecificChar.test(value)
      }

      if(!valid) return

      const updateAssetType = temp.map((ele) => {
        if (!ele._id) {
          const dataWithOutId = {
            ...ele,
            [name]: value,
            application: applicationId,
            customer: customerId,
          };
          isChangeDetected = true;
          dispatch(createLiabilities(dataWithOutId));
        }
        if (ele._id === newID) {
          const updatedLiabilitiesData = {
            ...ele,
            [name]: value,
          };
          if (ele[name] !== value) {
            isChangeDetected = true;
          }
          // validateField(name, value);
          return updatedLiabilitiesData;
        } else {
          return ele;
        }
      });
      setChangeDetected(isChangeDetected);
      if (isChangeDetected) {
        setLiabilityList(updateAssetType)
        // dispatch(updateLiabilitiesList(updateAssetType));
      }
      await validateField(name, value);
    },

    liabilities: (update) => {
      dispatch(updateLiabilities(update));
    },
    createLiability: async () => {
      try {
        if (fieldErrors["liability"] === "") {
          await dispatch(
            createFinanceItem({
              data: {
                name: _.camelCase(newLiabilityName),
                label: newLiabilityName,
                liabilitiesId: liabilities._id,
              },
              endpoint: "liabilities",
            })
          ).unwrap();
          setNewLiabilityName("");
        }
      } catch (error) {
        const newErrors = {};
        error.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
        setFieldErrors(newErrors);
      }
    },
    deleteLiability: async (data) => {
      await dispatch(
        deleteFinanceItem({
          data: { liabilityId: data.itemId, liabilitiesId: liabilities._id },
          endpoint: "liabilities",
        })
      ).unwrap();
    },
  };

  return (
    <>
      <Typography
        variant="subtitle2"
        sx={{ fontWeight: "bold" }}
        style={{ marginBottom: "5px", marginTop: "20px" }}
      >
        Liabilities
      </Typography>
      {liabilityList?.map((ele, index) => {
        return (
          <Grid
            container
            item
            spacing={2}
            style={{ marginBottom: "10px" }}
            alignItems="center"
            key={ele?._id}
          >
            <Grid container item xs={10} spacing={1}>
              <Grid item xs={3}>
                <FormControl fullWidth variant="filled">
                  <InputLabel id="years-at-label">Liabilities Type</InputLabel>
                  <Select
                    size="small"
                    labelId="years-at-label"
                    id="demo-simple-select"
                    value={ele?.liabilitiesType}
                    label="Liabilities Type"
                    onChange={(event) =>
                      handle.onChange(
                        "liabilitiesType",
                        event.target.value,
                        ele?._id
                      )
                    }
                    onBlur={() =>
                      handle.onBlur(
                        "liabilitiesType",
                        ele?.liabilitiesType,
                        ele?._id
                      )
                    }
                  >
                    {liabilitiesTypesList.map((i) => (
                      <MenuItem value={i.value}>{i.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  // type="tel"
                  type="text"
                  inputProps={{ min: 0 }}
                  label="Outstanding Amount"
                  variant="filled"
                  value={ele?.outstandingAmount}
                  // error={fieldErrors?.outstandingAmount}
                  // helperText={fieldErrors?.outstandingAmount}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                  onChange={(event) =>
                    handle.onChange(
                      "outstandingAmount",
                      currencyMaskedValue(event.target.value),
                      ele?._id
                    )
                  }
                  onBlur={() =>
                    handle.onBlur(
                      "outstandingAmount",
                      ele?.outstandingAmount,
                      ele?._id
                    )
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  // type="tel"
                  type="text"
                  inputProps={{ min: 0 }}
                  label="Limit"
                  variant="filled"
                  style={{ width: "100%" }}
                  value={ele?.limit}
                  // error={fieldErrors?.limit}
                  // helperText={fieldErrors?.limit}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                  onChange={(event) =>
                    handle.onChange("limit", event.target.value, ele?._id)
                  }
                  onBlur={() =>
                    handle.onBlur(
                      "limit",
                      currencyMaskedValue(ele?.limit),
                      ele?._id
                    )
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  type="text"
                  label="Financier"
                  variant="filled"
                  style={{ width: "100%" }}
                  value={ele?.financier}
                  onChange={(event) =>
                    handle.onChange("financier", event.target.value, ele?._id)
                  }
                  onBlur={() =>
                    handle.onBlur("financier", ele?.financier, ele?._id)
                  }
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>

              {/* <Grid container item spacing={1} alignItems="center" xs={12}> */}
              <Grid item xs={3}>
                <FormControl fullWidth variant="filled">
                  <InputLabel id="years-at-label">
                    Repayment Frequency
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="years-at-label"
                    id="demo-simple-select"
                    value={ele?.repaymentFrequency}
                    label="Repayment Frequency"
                    onChange={(event) =>
                      handle.onChange(
                        "repaymentFrequency",
                        event.target.value,
                        ele?._id
                      )
                    }
                    onBlur={() =>
                      handle.onBlur(
                        "repaymentFrequency",
                        ele?.repaymentFrequency,
                        ele?._id
                      )
                    }
                  >
                    {frequencyList.map((i) => (
                      <MenuItem value={i.value}>{i.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="outlined-basic"
                  // type="tel"
                  type="text"
                  inputProps={{ min: 0 }}
                  label="Repayment amount"
                  variant="filled"
                  size="small"
                  style={{ width: "100%" }}
                  value={ele?.repaymentAmount}
                  // error={fieldErrors?.repaymentAmount}
                  // helperText={fieldErrors?.repaymentAmount}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                  onChange={(event) =>
                    handle.onChange(
                      "repaymentAmount",
                      currencyMaskedValue(event.target.value),
                      ele?._id
                    )
                  }
                  onBlur={() =>
                    handle.onBlur(
                      "repaymentAmount",
                      ele?.repaymentAmount,
                      ele?._id
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  type="text"
                  label="Description"
                  variant="filled"
                  style={{ width: "100%", flex: "1" }}
                  value={ele?.description}
                  onChange={(event) =>
                    handle.onChange("description", event.target.value, ele?._id)
                  }
                  onBlur={() =>
                    handle.onBlur("description", ele?.description, ele?._id)
                  }
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              {/* </Grid> */}
            </Grid>
            <Grid item xs={1}>
              {liabilityList.length !== 0 && index !== 0 && (
                <Button
                  size="small"
                  // style={{ marginLeft: "10px" }}
                  onClick={() => removeLiabilities(ele)}
                  variant="outlined"
                >
                  Remove
                </Button>
              )}
            </Grid>
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <div>
          <Button
            onClick={() => onAddNewLiabilitiesItem()}
            variant="outlined"
            size="small"
            disabled={
              !liabilityList[0]?.liabilitiesType &&
                !liabilityList[0]?.description &&
                !liabilityList[0]?.repaymentAmount &&
                !liabilityList[0]?.repaymentFrequency &&
                !liabilityList[0]?.limit &&
                !liabilityList[0]?.outstandingAmount &&
                !liabilityList[0]?.financier
                ? true
                : false
            }
          >
            Add liability
          </Button>
        </div>
      </Grid>
    </>
  );
}

// <Grid
//   container
//   item
//   xs={3}
//   spacing={0}
//   style={{ alignContent: "flex-start" }}
// >
//   <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>
//     Liabilities (total)
//   </Typography>

//   {/* <Grid item sm={12}> */}
//   <Stack spacing={1}>
//     {liabilities?.items?.map((liability) => (
//       <FinanceItem
//         item={liability}
//         handleUpdate={handle.liabilities}
//         handleDelete={handle.deleteLiability}
//       />
//     ))}

//     <TextField
//       id="outlined-basic"
//       type="text"
//       size="small"
//       label="Add liability"
//       variant="outlined"
//       name="liability"
//       onChange={async (event) => {
//         let isValid = true;
//         isValid = regex.fanciableItemNameRegex.test(event.target.value) && event.target.value?.length <= 25;
//         if (!isValid) {
//           await validateField('liability', event.target.value);
//           return;
//         } else {
//           setNewLiabilityName(event.target.value);
//           await validateField("liability", event.target.value);
//         }
//       }}
//       value={newLiabilityName}
//       error={fieldErrors?.liability}
//       helperText={fieldErrors?.liability}
//       placeholder="Liability name"
//       InputProps={{
//         endAdornment: (
//           <InputAdornment position="end">
//             <IconButton
//               onClick={(event) => {
//                 if (newLiabilityName) {
//                   handle.createLiability(event);
//                 }
//               }}
//               style={{
//                 background: newLiabilityName ? "#616161" : "none",
//                 color: newLiabilityName ? "#fff" : "rgba(0, 0, 0, 0.6)",
//                 height: "30px",
//               }}
//               edge="end"
//             >
//               +
//             </IconButton>
//           </InputAdornment>
//         ),
//       }}
//     />
//   </Stack>
// </Grid>
