import { typeOfSaleList, livingArrangementsTypes } from "src/constants";
import aaa from "./aaa";
import criteria from "src/utils/criteria";
import aPlus from "./aPlus";
import a from "./a";
import aa from "./aa";
import bPlus from "./bPlus";
import b from "./b";
import c from "./c";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } =
  livingArrangementsTypes;

const {
  propertyOwner,
  nonPropertyOwner,
  nonGST,
  typeOfSale,
  hasDeposit,
  gstRegistrationBetween,
  gstRegistrationGreaterThan,
  termIsGreaterThan,
  assetValueBetween,
  assetType,
  ageOfAssetBetween,
  ageOfAssetLessThan,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  creditScoreGreaterThan,
  employmentType,
  timeOfEmploymentGreaterThan,
  livingArrangements,
  creditScoreBetween,
  compound,
} = criteria;

export default {
  date: "1 Jul 2023",
  lender: "Liberty",
  type: "consumer",
  logoURL: "https://assets.financeable.com.au/lender-logos%2Fliberty.gif",
  actions: [],
  lenderCredentials: [
    {
      label: "Email",
      attribute: "email",
    },
    {
      label: "User Name",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
    {
      label: "Merchant ID",
      attribute: "organisationId",
    },
    {
      label: "User ID",
      attribute: "brokerId",
    },
    {
      label: "Api Key",
      attribute: "apiKey",
    },
  ],
  requiredFields: {
    asset: [
      "assetType",
      "typeOfSale",
      "ageOfAsset",
      "glassesMake",
      "glassesModel",
      "glassesVariant",
      "assetValue",
    ],
    customer: [
      "firstName",
      "lastName",
      "dateOfBirth",
      "phoneNumbers[0].number",
      "emails[0].address",
      //   "addresses[0].street",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "IDNumber",
      "IDCardNumber",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "employers[0].entityName",
      "employers[0].timeOfEmployment",
      "employers[0].employmentType",
    ],
    agreement: ["introducerDeclarationAgree"],
  },
  fees: [
    {
      name: "Application fee",
      value: 1450,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Discharge fee",
      value: 125,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Equalisation fee",
      value: 1,
      capitalised: true,
      frequency: "upfront",
      calc: "percent",
      criteria: [creditScoreBetween(610, 700)],
    },
    {
      name: "Equalisation fee",
      value: 2,
      capitalised: true,
      frequency: "upfront",
      calc: "percent",
      criteria: [creditScoreBetween(510, 610)],
    },
    {
      name: "Equalisation fee",
      value: 3,
      capitalised: true,
      frequency: "upfront",
      calc: "percent",
      criteria: [creditScoreBetween(450, 510)],
    },
    {
      name: "Equalisation fee",
      value: 9,
      capitalised: true,
      frequency: "upfront",
      calc: "percent",
      criteria: [creditScoreBetween(410, 450)],
    },
    {
      name: "Equalisation fee",
      value: 10,
      capitalised: true,
      frequency: "upfront",
      calc: "percent",
      criteria: [creditScoreBetween(400, 410)],
    },
    {
      name: "Monthly account fee",
      value: 7.7,
      capitalised: false,
      frequency: "monthly",
      criteria: [
        livingArrangements(["Property owner"]),
        creditScoreGreaterThan(810),
      ],
    },
    {
      name: "Monthly account fee",
      value: 10,
      capitalised: false,
      frequency: "monthly",
      criteria: [creditScoreBetween(0, 810), loanAmountBetween(10000, 100000)],
    },
    {
      name: "Monthly account fee",
      value: 15,
      capitalised: false,
      frequency: "monthly",
      criteria: [creditScoreBetween(0, 810), loanAmountBetween(5000, 10000)],
    },
  ],
  brokerage: [],
  loading: [],
  info: [
    `
      Payments to be made via direct debit.
    `,
    `
      A benchmark monthly minimum living expense (using location and income) will be compared to those declared by the applicant during
      assessment in order to calculate serviceability
    `,
  ],
  productTiers: [...aaa, ...aa, ...aPlus, ...a, ...bPlus, ...b, ...c],
};
