import { livingArrangementsTypes, employmentTypes } from "src/constants";
import criteria from "src/utils/criteria";

import base from "./base";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } =
  livingArrangementsTypes;
const { FULL_TIME, PART_TIME, CASUAL, CONTRACT, SELF_EMPLOYED } =
  employmentTypes;

const {
  termIsGreaterThan,
  typeOfSale,
  assetValueBetween,
  assetType,
  ageOfAssetGreaterThan,
  ageOfAssetLessThan,
  loanAmountBetween,
  creditScoreGreaterThan,
  livingArrangements,
  employmentType,
  compound,
  creditScoreBetween,
  ageOfAssetBetween,
  timeOfEmploymentGreaterThan,
} = criteria;

function selfEmployed(l) {
  return l.map((product) => {
    return {
      ...product,
      criteria: [
        ...product.criteria,
        employmentType([SELF_EMPLOYED]),
        timeOfEmploymentGreaterThan(12),
      ],
    };
  });
}

function permanentEmployed(l) {
  return l.map((product) => {
    return {
      ...product,
      criteria: [
        ...product.criteria,
        employmentType([FULL_TIME, PART_TIME]),
        timeOfEmploymentGreaterThan(6),
      ],
    };
  });
}

function temporaryEmployed(l) {
  return l.map((product) => {
    return {
      ...product,
      criteria: [
        ...product.criteria,
        employmentType([CASUAL, CONTRACT]),
        timeOfEmploymentGreaterThan(4),
      ],
    };
  });
}

function propertyOwnerList(l) {
  return l.map((product) => {
    return {
      ...product,
      criteria: [...product.criteria, livingArrangements([PROPERTY_OWNER])],
    };
  });
}

function nonPropertyOwnerList(l) {
  return l.map((product) => {
    return {
      ...product,
      criteria: [
        ...product.criteria,
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      ],
    };
  });
}

const withEmploymentCriteriaAdded = [
  ...selfEmployed(base),
  ...permanentEmployed(base),
  ...temporaryEmployed(base),
];

export default {
  date: "13 Jul 2023",
  lender: "WISR",
  type: "consumer",
  logoURL: "https://assets.financeable.com.au/lender-logos%2FWisr-logo.png",
  actions: ["upload"],
  lenderCredentials: [
    {
      label: "Email",
      attribute: "email",
    },
    {
      label: "User Name",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
    {
      label: "Merchant ID",
      attribute: "organisationId",
    },
    {
      label: "User ID",
      attribute: "brokerId",
    },
    {
      label: "Api Key",
      attribute: "apiKey",
    },
  ],
  requiredFields: {
    asset: [
      "assetType",
      "typeOfSale",
      "ageOfAsset",
      "glassesMake",
      "glassesModel",
      "glassesVariant",
      "assetValue",
    ],
    customer: [
      "firstName",
      "lastName",
      "dateOfBirth",
      "phoneNumbers[0].number",
      "emails[0].address",
      // "addresses[0].street",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "IDNumber",
      "IDCardNumber",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "employers[0].entityName",
      "employers[0].timeOfEmployment",
      "employers[0].employmentType",
    ],
    agreement: ["introducerDeclarationAgree"],
  },
  fees: [
    {
      name: "Establishment fee",
      value: 525,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale("Authorised seller")],
    },
    {
      name: "Private sale fee",
      value: 575,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale("Private sale")],
    },
    {
      name: "PPSR",
      value: 10,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Account fee",
      value: 9.9,
      capitalised: false,
      frequency: "monthly",
    },
    {
      name: "Origination fee",
      value: 990,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(20000, 80000)],
    },
    {
      name: "Origination fee",
      value: 900,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(10000, 19999)],
    },
    {
      name: "Origination fee",
      value: 750,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(7500, 9999)],
    },
    {
      name: "Origination fee",
      value: 500,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(5000, 7499)],
    },
  ],
  brokerage: [],
  loading: [
    // {
    //   name: "Non property owner",
    //   value: 2,
    //   valueType: "percentage",
    //   criteria: [nonPropertyOwner],
    // },
    {
      name: "Private sale",
      value: 0.5,
      valueType: "percentage",
      criteria: [typeOfSale("Private sale")],
    },
    {
      name: "Term 60-84 months",
      value: 0.5,
      valueType: "percentage",
      criteria: [termIsGreaterThan(60)],
    },
  ],
  info: [
    `Multiple loans allowed up to $200,000 max loan size $80,000`,
    `Current bankrupts are not accepted. Ex-bankrupts must be discharged >2 years to be considered.`,
  ],
  productTiers: [
    ...propertyOwnerList(withEmploymentCriteriaAdded),
    ...nonPropertyOwnerList(withEmploymentCriteriaAdded),
  ],
};
