import { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import TextField from "@mui/material/TextField";
import { addPhoneNumber } from "../../store/slices/applicationFormSlice";
import regex from "src/utils/regex";

export default function PhoneNumber({
  phoneNumber,
  label,
  fieldKey,
  saveContactNum,
  customerId,
  singleCustomerId,
  guarantorFieldErrors,
  setGuarantorFieldErrors,
}) {
  const dispatch = useDispatch();

  const [allValues, setAllValue] = useState({
    number: phoneNumber?.number || phoneNumber,
  });

  const [fieldErrors, setFieldErrors] = useState({
    number: "",
  });

  const validationSchema = Yup.object({
    number: Yup.string()
      .required("number field is required")
      .matches(regex.phoneNumber, "Enter a valid Australian landline number."),
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setGuarantorFieldErrors((prevErrors) => ({
        ...prevErrors,
        Number: "",
      }));
      setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      setGuarantorFieldErrors((prevErrors) => ({
        ...prevErrors,
        Number: error.message,
      }));
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };

  const handle = {
    onChangeField: async (e, name) => {
      if (name === "number") {
        setAllValue({ ...allValues, [name]: e.replace(/\s+/g, '') });
      } else {
        setAllValue({ ...allValues, [name]: e });
      }
      await validateField(name, e);
    },
    blurFn: (fieldName, value) => {
      try {
        const inputValue = value.replace(/\s+/g, '') // trim() not work

        if (fieldErrors[fieldName] === "") {
          if(fieldKey === "supplierPhone"){
            saveContactNum("supplierPhone", inputValue);
            return
          }

          if(fieldKey === "phoneNumberValue"){
            saveContactNum("phoneNumber", inputValue);
            return
          }

          if (fieldKey !== "supplierPhone" && label) {
            saveContactNum("contactNumber", inputValue);
            return;
          }

          dispatch(
            addPhoneNumber({
              phoneNumberId: phoneNumber?._id,
              customerId,
              number: inputValue,
            })
          );
        }
      } catch (error) {
        const newErrors = {};
        error.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
        console.log("newErrors", newErrors);
        setFieldErrors(newErrors);
      }
    },
  };

  return (
    <InputMask
      mask={fieldKey === "phoneNumberValue" ? "0999999999" : "0999 999 999"}
      value={allValues?.number || ""}
      disabled={false}
      onChange={(event) => handle.onChangeField(event?.target?.value, "number")}
      onBlur={(e) => handle.blurFn("number", e?.target?.value)}
      maskChar=" "
    >
      {() => (
        <TextField
          id="outlined-basic"
          label={label || "Mobile number"}
          variant="filled"
          size="small"
          fullWidth
          error={
            (singleCustomerId === customerId && guarantorFieldErrors?.Number) ||
            fieldErrors?.number
          }
          helperText={
            (singleCustomerId === customerId && guarantorFieldErrors?.Number) ||
            fieldErrors?.number
          }
        />
      )}
    </InputMask>
  );
}
