import { typeOfSaleList, employmentTypes } from "src/constants";
import criteria from "src/utils/criteria";
import platinum from "./platinum";
import gold from "./gold";
import silver from "./silver";
import bronze from "./bronze";
import economy from "./economy";

const { SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL, BENEFITS } =
  employmentTypes;

const {
  propertyOwner,
  nonPropertyOwner,
  nonGST,
  typeOfSale,
  hasDeposit,
  gstRegistrationBetween,
  gstRegistrationGreaterThan,
  termIsGreaterThan,
  assetValueBetween,
  assetType,
  ageOfAssetBetween,
  ageOfAssetLessThan,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  creditScoreGreaterThan,
  employmentType,
  timeOfEmploymentGreaterThan,
  livingArrangements,
  creditScoreBetween,
  compound,
} = criteria;

export default {
  date: "05 Jun 2023",
  lender: "Finance One",
  type: "comsumer",
  logoURL: "https://assets.financeable.com.au/lender-logos/Finance-One.gif",
  actions: [],
  lenderCredentials: [
    {
      label: "Email",
      attribute: "email",
    },
    {
      label: "User Name",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
    {
      label: "Merchant ID",
      attribute: "organisationId",
    },
    {
      label: "User ID",
      attribute: "brokerId",
    },
    {
      label: "Api Key",
      attribute: "apiKey",
    },
  ],
  requiredFields: {
    asset: [
      "assetType",
      "typeOfSale",
      "ageOfAsset",
      "glassesMake",
      "glassesModel",
      "glassesVariant",
      "assetValue",
    ],
    customer: [
      "firstName",
      "lastName",
      "dateOfBirth",
      "phoneNumbers[0].number",
      "emails[0].address",
      //   "addresses[0].street",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "IDNumber",
      "IDCardNumber",
      "employers[0].entityName",
      "employers[0].timeOfEmployment",
      "employers[0].employmentType",
    ],
    agreement: ["introducerDeclarationAgree"],
  },
  fees: [
    {
      name: "Establishment fee",
      value: 695,
      capitalised: true,
      frequency: "upfront",
      criteria: [
        typeOfSale(typeOfSaleList.DEALER_SALE),
        employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CASUAL, CONTRACT]),
      ],
    },
    {
      name: "Establishment fee",
      value: 995,
      capitalised: true,
      frequency: "upfront",
      criteria: [
        typeOfSale(typeOfSaleList.DEALER_SALE),
        employmentType([BENEFITS]),
        loanAmountBetween(5000, 8000),
      ],
    },
    {
      name: "Establishment fee",
      value: 1495,
      capitalised: true,
      frequency: "upfront",
      criteria: [
        typeOfSale(typeOfSaleList.DEALER_SALE),
        employmentType([BENEFITS]),
        loanAmountBetween(8000, 10000),
      ],
    },
    {
      name: "Private sale",
      value: 995,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(typeOfSaleList.PRIVATE_SALE)],
    },
    {
      name: "Origination fee",
      value: 900,
      capitalised: true,
      frequency: "upfront",
      criteria: [
        loanAmountBetween(5000, 14000),
        employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
      ],
    },
    {
      name: "Origination fee",
      value: 1000,
      capitalised: true,
      frequency: "upfront",
      criteria: [
        loanAmountBetween(14000, 75000),
        employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
      ],
    },
    {
      name: "Origination fee",
      value: 500,
      capitalised: true,
      frequency: "upfront",
      criteria: [employmentType([BENEFITS])],
    },
    {
      name: "Monthly account fee",
      value: 27.0,
      capitalised: false,
      frequency: "monthly",
    },
  ],
  brokerage: [],
  loading: [],
  info: [
    `Defaults: Unpaid vehicle finance defaults >6mths (NO unpaid in <6months)`,
    `Unpaid court judgements >6mths`,
    `Paid court judgements to a financier >6mths`,
  ],
  productTiers: [...platinum, ...gold, ...silver, ...bronze, ...economy],
};
