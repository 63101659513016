import { useStat } from "react";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import { userSelector } from "../../store/slices/userSlice";

import GoogleMaps from "../Utils/GoogleAddressSearch";
import { useState } from "react";
import { saveValue } from "src/store/slices/organisationSlice";
import { useDispatch } from "react-redux";

export default function Organisation() {
  const dispatch = useDispatch()
  const [bsb, setBsb] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [abn, setAbn] = useState("");
  const [contactName, setContactName] = useState("");
  const [email, setEmail] = useState("");

  return (
    <>
      <Grid
        container
        xs={11}
        sm={8}
        md={6}
        lg={6}
        justifyContent="center"
        alignItems="center"
        direction="column"
        textAlign="center"
      // style={{ padding: "10vh 100px", marginTop: "10vh" }}
      >

        <TextField
          fullWidth
          // id="outlined-basic"
          type="text"
          name="bsb"
          label="BSB*"
          // autoComplete="first-name"
          variant="filled"
          // variant="outlined"
          size="small"
          value={bsb}
          inputProps={{ maxLength: 24 }}
          onChange={(event) => setBsb(event.target.value)}
          style={{ margin: "0 0 10px 0" }}
          // placeholder="John"
          // error={firstNameError}
          // helperText={firstNameErrorText}
          onBlur={() => dispatch(saveValue({ bsb }))}

        />

        <TextField
          fullWidth
          // id="outlined-basic"
          type="text"
          name="accountNumber"
          label="Account number*"
          // autoComplete="first-name"
          variant="filled"
          // variant="outlined"
          size="small"
          value={accountNumber}
          inputProps={{ maxLength: 24 }}
          onChange={(event) => setAccountNumber(event.target.value)}
          style={{ margin: "0 0 10px 0" }}
          // placeholder="John"
          // error={firstNameError}
          // helperText={firstNameErrorText}
          onBlur={() => dispatch(saveValue({ accountNumber }))}
        />

        <TextField
          fullWidth
          // id="outlined-basic"
          type="text"
          name="ABN"
          label="ABN*"
          // autoComplete="first-name"
          variant="filled"
          // variant="outlined"
          size="small"
          value={abn}
          inputProps={{ maxLength: 24 }}
          onChange={(event) => setAbn(event.target.value)}
          style={{ margin: "0 0 10px 0" }}
          // placeholder="John"
          // error={firstNameError}
          // helperText={firstNameErrorText}
          onBlur={() => dispatch(saveValue({ abn }))}

        />

        {/* <TextField
          fullWidth
          // id="outlined-basic"
          type="text"
          name="contactNameForRCTI"
          label="Contact Name For RCTI"
          // autoComplete="first-name"
          variant="filled"
          // variant="outlined"
          size="small"
          value={contactName}
          inputProps={{ maxLength: 100 }}
          onChange={(event) => setContactName(event.target.value)}
          style={{ margin: "0 0 10px 0" }}
          // placeholder="John"
          // error={firstNameError}
          // helperText={firstNameErrorText}
          onBlur={() => dispatch(saveValue({ contactName }))}

        />
        <TextField
          fullWidth
          // id="outlined-basic"
          type="text"
          name="emailForRCTI"
          label="Email Address For RCTI"
          // autoComplete="first-name"
          variant="filled"
          // variant="outlined"
          size="small"
          value={email}
          inputProps={{ maxLength: 100 }}
          onChange={(event) => setEmail(event.target.value)}
          style={{ margin: "0 0 10px 0" }}
          // placeholder="John"
          // error={firstNameError}
          // helperText={firstNameErrorText}
          onBlur={() => dispatch(saveValue({ contactEmail: email }))}

        /> */}
      </Grid>
    </>
  );
}