import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import regex from "src/utils/regex";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {
  createExpense,
  createFinanceItem,
  deleteExpense,
  deleteFinanceItem,
  updateExpense,
  updateExpenses,
  updateExpensesList,
} from "../../store/slices/applicationFormSlice";
import InputAdornment from "@mui/material/InputAdornment";
import _ from "lodash";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import FinanceItem from "./FinanceItem";
import { expensesTypeList, frequencyList } from "src/constants";
import { NumericFormatCustom, currencyMaskedValue } from "src/utils/currencyMaskFormat";

export default function Expenses({ expenses, customerId, applicationId }) {
  const dispatch = useDispatch();

  const [newExpenseName, setNewExpenseName] = useState("");

  const [fieldErrors, setFieldErrors] = useState({
    expenses: "",
    amount: "",
  });

  const assetState = useSelector(({ assert }) => assert);
  const expensesList = assetState?.expensesListData || [];

  const validationSchema = Yup.object({
    expenses: Yup.string()
      .optional()
      .matches(
        regex.fanciableItemNameRegex,
        "Only letters, dashes (-), forward slashes (/), commas (,), and spaces are allowed."
      )
      .max(25, "Maximum of 25 characters"),
    amount: Yup.string()
      .optional()
      .matches(regex.allowOnlyNumber, "Only numbers allowed.")
      .max(10, "Maximum of 7 digits"),
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };

  const onAddNewExpenses = () => {
    const data = {
      expensesType: "",
      frequency: "",
      amount: "",
      application: applicationId,
      customer: customerId,
    };

    dispatch(createExpense(data));
  };

  const removeExpenses = (Element) => {
    const data = {
      expenseId: Element._id,
      application: applicationId,
      customer: customerId,
    };
    delete data._id;

    dispatch(deleteExpense(data));
  };

  const handle = {
    onBlur: (name, value, newID) => {
      const temp = [...expenses];

      temp.map((ele) => {
        // if (!ele._id) {
        //   console.log("ele", ele);
        // }
        if (ele._id === newID) {
          let updatedAssetData = {
            ...ele,
            [name]: value,
            expenseId: newID,
            application: applicationId,
            customer: customerId,
          };

          delete updatedAssetData._id;

          dispatch(updateExpense(updatedAssetData));
          return updatedAssetData;
        } else {
          return ele;
        }
      });
    },
    onChange: (name, value, newID) => {
      const temp = [...expenses];

      const updateExpenses = temp.map((ele) => {
        if (!ele._id) {
          const dataWithOutId = {
            ...ele,
            [name]: value,
            application: applicationId,
            customer: customerId,
          };
          dispatch(createExpense(dataWithOutId));
        }

        if (ele._id === newID) {
          const updatedExpensesData = {
            ...ele,
            [name]: value,
          };
          // validateField(name, value);
          return updatedExpensesData;
        } else {
          return ele;
        }
      });
      dispatch(updateExpensesList(updateExpenses));
    },
    expenses: (update) => {
      dispatch(updateExpenses(update));
    },
    createExpense: async () => {
      try {
        if (fieldErrors["asset"] === "") {
          await dispatch(
            createFinanceItem({
              data: {
                name: _.camelCase(newExpenseName),
                label: newExpenseName,
                expensesId: expenses._id,
              },
              endpoint: "expenses",
            })
          ).unwrap();
          setNewExpenseName("");
        }
      } catch (error) {
        const newErrors = {};
        error.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
        setFieldErrors(newErrors);
      }
    },
    deleteExpense: async (del_data) => {
      console.log("deleting");
      await dispatch(
        deleteFinanceItem({
          data: { expenseId: del_data._id, expensesId: expenses._id },
          endpoint: "expenses",
        })
      ).unwrap();
    },
  };

  return (
    <>
      <Typography
        variant="subtitle2"
        sx={{ fontWeight: "bold" }}
        style={{ marginBottom: "5px", marginTop: "20px" }}
      >
        Expenses
      </Typography>
      {expenses?.map((ele, index) => {
        return (
          <Grid
            container
            spacing={2}
            style={{ marginBottom: "20px" }}
            key={ele?._id}
          >
            <Grid container item xs={10} spacing={1}>
              <Grid item xs={3}>
                <FormControl fullWidth variant="filled">
                  <InputLabel id="years-at-label">Expense type</InputLabel>
                  <Select
                    size="small"
                    labelId="years-at-label"
                    id="demo-simple-select"
                    value={ele?.expensesType}
                    label="Expense type"
                    onChange={(event) => {
                      handle.onChange(
                        "expensesType",
                        event.target.value,
                        ele?._id
                      );
                    }}
                    onBlur={() =>
                      handle.onBlur("expensesType", ele?.expensesType, ele?._id)
                    }
                  >
                    {expensesTypeList.map((i) => (
                      <MenuItem value={i.value}>{i.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  // type="tel"
                  type="text"
                  inputProps={{ min: 0 }}
                  label="Expense amount"
                  variant="filled"
                  style={{ width: "100%" }}
                  value={ele?.amount}
                  // error={fieldErrors?.amount}
                  // helperText={fieldErrors?.amount}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                  onChange={(event) =>
                    handle.onChange("amount", event.target.value, ele?._id)
                  }
                  onBlur={() => handle.onBlur("amount", currencyMaskedValue(ele?.amount), ele?._id)}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControl fullWidth variant="filled">
                  <InputLabel id="years-at-label">Expense frequency</InputLabel>
                  <Select
                    size="small"
                    labelId="years-at-label"
                    id="demo-simple-select"
                    value={ele?.frequency}
                    label="Expense frequency"
                    onChange={(event) => {
                      handle.onChange(
                        "frequency",
                        event.target.value,
                        ele?._id
                      );
                    }}
                    onBlur={() =>
                      handle.onBlur("frequency", ele?.frequency, ele?._id)
                    }
                  >
                    {frequencyList.map((i) => (
                      <MenuItem value={i.value}>{i.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {expenses?.length !== 0 && index !== 0 && (
                <Grid item xs={1}>
                  <Button
                    // style={{ marginLeft: "5px" }}
                    size="small"
                    style={{ marginTop: "10px" }}
                    onClick={() => removeExpenses(ele)}
                    variant="outlined"
                  >
                    Remove
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <div>
          <Button
            onClick={() => onAddNewExpenses()}
            variant="outlined"
            size="small"
            disabled={
              !expenses[0]?.expensesType &&
              !expenses[0]?.frequency &&
              !expenses[0]?.amount
                ? true
                : false
            }
          >
            Add expense
          </Button>
        </div>
      </Grid>
    </>
  );
}

{
  /* <Grid
container
item
xs={3}
spacing={0}
style={{ alignContent: "flex-start" }}
>
<Typography variant="subtitle2" style={{ marginBottom: "5px" }}>
  Expenses (monthly)
</Typography>

<Stack spacing={1}>
  {expenses?.items?.map((expense) => (
    <FinanceItem
      item={expense}
      handleUpdate={() => handle.expenses()}
      handleDelete={() => handle.deleteExpense(expense)}
    />
  ))}

  <TextField
    id="outlined-basic"
    type="text"
    size="small"
    label="Add expense"
    variant="outlined"
    name="expenses"
    placeholder="Expense name"
    onChange={async (event) => {
      let isValid = true;
      isValid =
        regex.fanciableItemNameRegex.test(event.target.value) &&
        event.target.value?.length <= 25;
      if (!isValid) {
        await validateField("expenses", event.target.value);
        return;
      } else {
        setNewExpenseName(event.target.value);
        await validateField("expenses", event.target.value);
      }
    }}
    error={fieldErrors?.expenses}
    helperText={fieldErrors?.expenses}
    value={newExpenseName}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            onClick={(event) => {
              if (newExpenseName) {
                handle.createExpense(event);
              }
            }}
            style={{
              background: newExpenseName ? "#616161" : "none",
              color: newExpenseName ? "#fff" : "rgba(0, 0, 0, 0.6)",
              height: "30px",
            }}
            edge="end"
          >
            +
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
</Stack>
</Grid> */
}
