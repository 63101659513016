import { Box, CircularProgress } from '@mui/material';
import * as React from 'react';

const DocumentLoader = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress size="2rem" />
    </Box>
  );
}

export default DocumentLoader