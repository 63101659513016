import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";

import { addEmail } from "../../store/slices/applicationFormSlice";
import regex from "src/utils/regex";
import * as Yup from "yup";
import { field_required_message } from "src/constants";

export default function Email({
  email,
  customerId,
  guarantorFieldErrors,
  setGuarantorFieldErrors,
  singleCustomerId,
}) {
  // console.log("Email")
  const dispatch = useDispatch();
  const [allValues, setAllValue] = useState({
    address: email?.address,
  });

  const [fieldErrors, setFieldErrors] = useState({
    address: "",
  });

  const validationSchema = Yup.object({
    address: Yup.string()
      .required(field_required_message.Address)
      .matches(regex.email, "Please enter valid email address")
      .min(2, "Maximum of 5 characters")
      .max(50, "Maximum of 50 characters"),
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setGuarantorFieldErrors((prevErrors) => ({
        ...prevErrors,
        Address: "",
      }));
      setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      console.log("error", error);
      setGuarantorFieldErrors((prevErrors) => ({
        ...prevErrors,
        Address: error.message,
      }));
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };

  const onChangeField = async (e, name) => {
    let isValid = true;
    isValid = e?.length <= 50;
    if (!isValid) {
      await validateField(name, e);
      return;
    } else {
      setAllValue({ ...allValues, [name]: e });
      await validateField(name, e);
    }
  };

  const blurFn = (fieldName, value) => {
    try {
      if (fieldErrors[fieldName] === "") {
        dispatch(
          addEmail({
            emailId: email?._id,
            customerId,
            address: value,
          })
        );
      }
    } catch (error) {
      const newErrors = {};
      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });
      setFieldErrors(newErrors);
    }
  };

  return (
    <TextField
      id="outlined-basic"
      type="text"
      label="Email"
      variant="filled"
      autoComplete="off"
      size="small"
      name="address"
      value={allValues?.address || ""}
      error={
        (singleCustomerId === customerId && guarantorFieldErrors?.Address) ||
        fieldErrors?.address
      }
      helperText={
        (singleCustomerId === customerId && guarantorFieldErrors?.Address) ||
        fieldErrors?.address
      }
      onChange={(event) => onChangeField(event?.target?.value, "address")}
      onBlur={(e) => blurFn("address", e?.target?.value)}
    />
  );
}
