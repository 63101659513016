import { useState, useEffect, useMemo } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import _ from "lodash";
import {
  Grid,
  Typography,
  TextField,
  Stack,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { useDispatch, useSelector } from "react-redux";
import {
  isEmailExist,
  registerUser,
  saveValue,
  userSelector,
  validateEmail,
} from "../../store/slices/userSlice";

import regex from "../../utils/regex";
import { useImperativeHandle } from "react";
import RadioButtonGroup from "../Utils/RadioButtonGroup";
import InputMask from "react-input-mask";
import { useSnackbar } from "notistack";
import { ERRORS_MESSAGE, SUCCESS_MESSAGE } from "src/constants";


function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function UserDetails() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const query = new URLSearchParams(window.location.search);
  const firstNameValue = query.get("firstName");
  const lastNameValue = query.get("lastName");
  const emailValue = query.get("email");
  const phoneNumberValue = query.get("phoneNumber");
  // console.log({ firstNameValue });

  const [firstName, setFirstName] = useState(firstNameValue || "");
  const [lastName, setLastName] = useState(lastNameValue || "");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [firstNameErrorText, setFirstNameErrorText] = useState(" ");
  const [lastNameErrortext, setLastNameErrorText] = useState(" ");
  const [email, setEmail] = useState(emailValue || "");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState(" ");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorText, setPasswordErrorText] = useState(" ");
  const [phoneNumber, setPhoneNumber] = useState(phoneNumberValue || "");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneNumberErrorText, setPhoneNumberErrorText] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);
  const [acceptedConditions, setAcceptedConditions] = useState(false);
  const [previousAccreditation, setPreviousAccreditation] = useState("");
  const [yearsOfLendingExperience, setYearsOfLendingExperience] = useState("");
  const [representativeOfACLHolder, setRepresentativeOfACLHolder] =
    useState("");
  const [employeeOfACLHolder, setEmployeeOfACLHolder] = useState("");
  const [directorOfACLHolder, setDirectorOfACLHolder] = useState("");
  const [other, setOther] = useState("");

  const { emailInUse, isCreatingAccount, createAccountSuccess } =
    useSelector(userSelector);

  async function handleRegistration() {
    // dispatch(saveValue({ isCreatingAccount: true }));
    // await dispatch(
    //   registerUser({
    //     firstName,
    //     lastName,
    //     email,
    //   })
    // ).unwrap();
  }

  // useImperativeHandle(ref, () => ({
  //   handleRegistration() {
  //     handleRegistration()
  //   }
  // }))

  useEffect(() => {
    if (firstNameValue)
      dispatch(
        saveValue({
          firstName: firstNameValue,
          lastName: lastNameValue,
          email: emailValue,
          phoneNumber: phoneNumber,
          password: password,
        })
      );
  }, []);

  function handleSaveValue(value) {
    dispatch(saveValue(value));
  }

  function handleFirstName(event) {
    setFirstName(event.target.value);
    dispatch(saveValue({ firstName: event.target.value }));

    if (regex.name.test(event.target.value)) {
      setFirstNameError(false);

      return setFirstNameErrorText(" ");
    }

    setFirstNameError(true);
    setFirstNameErrorText(
      "Name can contain only letters, dashes (-) or spaces."
    );
  }

  function handlePassword(event) {
    setPassword(event.target.value);
    dispatch(saveValue({ password: event.target.value }));

    if (regex.password.test(event.target.value)) {
      setPasswordError(false);

      return setPasswordErrorText("");
    }

    setPasswordError(true);
    setPasswordErrorText(
      "password must contain an uppercase letter, a number, a special character and be 8 or more characters in length."
    );
  }

  function handleLastName(event) {
    setLastName(event.target.value);
    dispatch(saveValue({ lastName: event.target.value }));

    if (regex.name.test(event.target.value)) {
      setLastNameError(false);

      return setLastNameErrorText(" ");
    }

    setLastNameError(true);
    setLastNameErrorText(
      "Name can contain only letters, dashes (-) or spaces."
    );
  }

  function handleEmail(event) {
    setEmail(event.target.value.trim());
    dispatch(saveValue({ email: event.target.value.trim() }));

    if (regex.email.test(event.target.value.toLowerCase())) {
      setEmailError(false);
      dispatch(saveValue({ emailInUse: false }));
      return setEmailErrorText("");
    }

    setEmailError(true);
    setEmailErrorText("Invalid email");
  }

  function handlePhoneNumber(event) {
    const inputValue = event.target.value.replace(/\s+/g, "");

    setPhoneNumber(inputValue);
    dispatch(saveValue({ phoneNumber: inputValue }));

    // if (regex.phoneNumber.test(event.target.value.toLowerCase())) {
    //   setPhoneNumberError(false);
    //   return setEmailErrorText(" ");
    // }

    // setPhoneNumberError(true);
    // setPhoneNumberErrorText("Invalid phone");
  }

  async function handleCheckEmail() {
    // await dispatch(isEmailExist(email)).unwrap();
    dispatch(isEmailExist(email)).then((res) => {
      if (res?.payload?.data) {
        setEmailError(false);
        setEmailErrorText("");
      } else {
        setEmailError(true);
        setEmailErrorText("Email address already in use.");

        enqueueSnackbar(ERRORS_MESSAGE.email_exist_msg, {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    });
  }

  useEffect(() => {
    if (emailInUse) {
      setEmailError(true);
      return setEmailErrorText("Email address already in use.");
    }

    setEmailError(false);
    return setEmailErrorText("");
  }, [emailInUse]);

  useEffect(() => {
    if (
      firstName &&
      lastName &&
      email &&
      password &&
      !passwordError &&
      !firstNameError &&
      !lastNameError &&
      !emailError
    )
      return setCanSubmit(true);

    setCanSubmit(false);
  }, [
    firstName,
    lastName,
    email,
    password,
    firstNameError,
    lastNameError,
    emailError,
    phoneNumberError,
    passwordError,
    acceptedConditions,
  ]);

  // useEffect(() => {
  //   console.log({ createAccountSuccess });
  //   if (hasOrganisation) return navigate("/applications");
  //   if (createAccountSuccess) navigate("/setup/organisation");
  // }, [createAccountSuccess]);

  // useEffect(() => {
  //   console.log("query.get(oid)", query.get("oid"))
  //   if (query.get("oid")) setHasOrganisation(query.get("oid"));
  // });

  useEffect(() => {
    async function handleKeyDown(e) {
      if (e.keyCode == 13 && canSubmit) await handleRegistration();
    }

    document.addEventListener("keydown", handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      <Grid
        container
        item
        xs={11}
        sm={8}
        md={6}
        xl={6}
        // height="100vh"
        justifyContent="center"
        alignItems="center"
        direction="column"
        textAlign="center"
      // spacing={3}
      >
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          height="100vh"
          justifyContent="start"
          alignItems="start"
          direction="column"
          textAlign="left"
        // style={{
        //   padding: "3vh 60px 50px",
        //   flexFlow: "column",
        //   overflow: "scroll",
        // }}
        >
          <a
            href="https://financeable.com.au"
            style={{
              textDecoration: "none",
              color: "rgba(0, 0, 0, 0.87)",
            }}
          >
            <Typography
              variant="h4"
              fontWeight="800"
              style={{
                letterSpacing: "-1.8px",
              }}
            >
              Financeable
            </Typography>
          </a>
          {/* <br />
          <br /> */}
          {/* <Typography variant="h1" fontWeight="500" style={{ textAlign: "left" }}>
            This is it.
          </Typography> */}
          <br />
          {/* <br /> */}
          <Typography variant="h4" fontWeight="300">
            Broker Registration
          </Typography>
          <br />
          <Typography variant="caption" fontWeight="300">
            All fields required.
          </Typography>
          <Grid
            container
            spacing={1}
            // sm={12}
            // lg={12}
            // md={12}
            // xl={12}
            style={{ margin: "0 0 10px", width: "100%" }}
          >
            <Grid item sm={6}>
              <TextField
                fullWidth
                // id="outlined-basic"
                type="text"
                name="firstName"
                label="First name*"
                autoComplete="First-name"
                variant="filled"
                // variant="outlined"
                size="small"
                value={firstName}
                inputProps={{ maxLength: 24 }}
                onChange={handleFirstName}
                margin="dense"
                error={firstNameError}
                // helperText={firstNameErrorText}
                placeholder="First"
              // onBlur={() => {
              //   const fullNameValue = fullName.split(" ");
              //   setFirstName(fullNameValue[0]);
              //   setLastName(fullNameValue[1]);
              // }}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                fullWidth
                // id="outlined-basic"
                type="text"
                name="lastName"
                label="Last name*"
                autoComplete="last-name"
                variant="filled"
                // variant="outlined"
                size="small"
                value={lastName}
                inputProps={{ maxLength: 24 }}
                onChange={handleLastName}
                margin="dense"
                // placeholder="Smith"
                // helperText={lastNameErrortext}
                error={lastNameError}
              // onBlur={() => {}}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                fullWidth
                type="text"
                label="Email*"
                variant="filled"
                autoComplete="off"
                size="small"
                name="address"
                margin="dense"
                value={email || ""}
                error={emailError}
                helperText={emailErrorText}
                onChange={handleEmail}
                onBlur={handleCheckEmail}
              />
            </Grid>
            <Grid item sm={6}>
              <InputMask
                mask={"0999 999 999"}
                value={phoneNumber || ""}
                onChange={handlePhoneNumber}
                // onBlur={(e) => handle.blurFn("number", e?.target?.value)}
                maskChar=" "
              >
                {() => (
                  <TextField
                    id="outlined-basic"
                    label="Phone number*"
                    variant="filled"
                    size="small"
                    name="Phone number"
                    fullWidth
                    autoComplete="new-password"
                    margin="dense"
                    error={phoneNumberError}
                    helperText={phoneNumberErrorText}
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item sm={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                type="password"
                label="Password*"
                name="password"
                variant="filled"
                size="small"
                margin="dense"
                autoComplete="new-password"
                value={password || ""}
                error={passwordError}
                helperText={passwordErrorText}
                onChange={handlePassword}
                onBlur={() => handleSaveValue({ password })}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                fullWidth
                // id="outlined-basic"
                // type="string"
                name="yearOfExperience"
                label="Years of Credit/Lending experience"
                // autoComplete="tel"
                variant="filled"
                // variant="outlined"
                type="number"
                size="small"
                value={yearsOfLendingExperience}
                onChange={(event) =>
                  setYearsOfLendingExperience(event.target.value)
                }
                margin="dense"
                onBlur={() => handleSaveValue({ yearsOfLendingExperience })}
                // helperText={phoneNumberErrorText}
                // error={phoneNumberError}
                inputProps={{ maxLength: 50 }}
                // placeholder="0412 345 678"
                // style={{ margin: "0 0 10px 0" }}
              />
            </Grid>
          </Grid>
          {/* <TextField
            fullWidth
            // id="outlined-basic"
            type="text"
            name="phoneNumber"
            label="Mobile number"
            autoComplete="tel"
            variant="filled"
            // variant="outlined"
            size="small"
            value={phoneNumber}
            onChange={handlePhoneNumber}
            margin="dense"
            helperText={phoneNumberErrorText}
            error={phoneNumberError}
            onBlur={() => {}}
            inputProps={{ maxLength: 12 }}
            placeholder="0400123123"
          /> */}

          {/* <TextField
          fullWidth
          // id="outlined-basic"
          type="email"
          name="email"
          label="Email"
          autoComplete="email"
          variant="filled"
          // variant="outlined"
          size="small"
          value={email}
          onChange={handleEmail}
          margin="dense"
          // helperText={emailErrorText}
          error={emailError}
          // onBlur={handleCheckEmail}
          inputProps={{ maxLength: 50 }}
          placeholder="name@email.com"
          style={{ margin: "0 0 10px 0" }}
        />

        <TextField
          fullWidth
          // id="outlined-basic"
          type="tel"
          name="phoneNumber"
          label="Phone number"
          autoComplete="tel"
          variant="filled"
          // variant="outlined"
          size="small"
          value={phoneNumber}
          onChange={handlePhoneNumber}
          margin="dense"
          // helperText={phoneNumberErrorText}
          error={phoneNumberError}
          inputProps={{ maxLength: 50 }}
          placeholder="0412 345 678"
          style={{ margin: "0 0 10px 0" }}
        /> */}

          <Grid
            container
            item
            direction="row"
            justifyContent="space-between"
            style={{ alignItems: "center", margin: "0 0 20px 0" }}
          >
            <Grid item xs={8}>
              <Typography textAlign="left">
                Have you ever held accreditation with AFS or Tandemm?*
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <RadioButtonGroup
                value={previousAccreditation}
                handleValue={(value) => {
                  setPreviousAccreditation(value);
                  handleSaveValue({ previousAccreditation: value });
                }}
                options={[
                  { icon: "House", label: "Yes" },
                  { icon: "HomeWork", label: "No" },
                ]}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            direction="row"
            justifyContent="space-between"
            style={{ alignItems: "center", margin: "0 0 20px 0" }}
          >
            <Grid item xs={8}>
              <Typography textAlign="left">
                I am an authorized Credit Representative of an Australian Credit
                Licence holder.*
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <RadioButtonGroup
                value={representativeOfACLHolder}
                handleValue={(value) => {
                  setRepresentativeOfACLHolder(value);
                  handleSaveValue({ representativeOfACLHolder: value });
                }}
                options={[
                  { icon: "House", label: "Yes" },
                  { icon: "HomeWork", label: "No" },
                ]}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            direction="row"
            justifyContent="space-between"
            style={{ alignItems: "center", margin: "0 0 20px 0" }}
          >
            <Grid item xs={8}>
              <Typography textAlign="left">
                I am a Director of an Australian Credit Licence holder.*
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <RadioButtonGroup
                value={directorOfACLHolder}
                handleValue={(value) => {
                  setDirectorOfACLHolder(value);
                  handleSaveValue({ directorOfACLHolder: value });
                }}
                options={[
                  { icon: "House", label: "Yes" },
                  { icon: "HomeWork", label: "No" },
                ]}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            direction="row"
            justifyContent="space-between"
            style={{ alignItems: "center", margin: "0 0 20px 0" }}
          >
            <Grid item xs={8}>
              <Typography textAlign="left">
                I am an Employee of an Australian Credit Licence holder.*
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <RadioButtonGroup
                value={employeeOfACLHolder}
                handleValue={(value) => {
                  setEmployeeOfACLHolder(value);
                  handleSaveValue({ employeeOfACLHolder: value });
                }}
                options={[
                  { icon: "House", label: "Yes" },
                  { icon: "HomeWork", label: "No" },
                ]}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justifyContent="space-between"
            style={{ alignItems: "center", margin: "0 0 20px 0" }}
          >
            <Grid item xs={8}>
              <Typography textAlign="left">
                I am an accountant/financial planner or other*
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <RadioButtonGroup
                value={other}
                handleValue={(value) => {
                  setOther(value);
                  handleSaveValue({ other: value });
                }}
                options={[
                  { icon: "House", label: "Yes" },
                  { icon: "HomeWork", label: "No" },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
