import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import _ from "lodash";

console.log(process.env.REACT_APP_API_URL);

function headers(token) {
  if (token) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": token,
    };
  }

  return {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
}

export const getAllApplications = createAsyncThunk(
  "applications/get",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/application/all`,
        method: "POST",
        headers: headers(token),
        data,
      }).catch((err) => {
        console.log("err", err);
      });
      // console.log("signUp response:", userResponse);
      if (response.status === "error") {
        console.log("response", response);
        return rejectWithValue(response);
      }
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const applicationsSlice = createSlice({
  name: "applications",
  initialState: {
    isLoading: false,
  },
  reducers: {
    saveValue: (state, action) => {
      return _.merge(state, action.payload);
    },
  },
  extraReducers: {
    [getAllApplications.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isLoading = false;
      state.isSuccess = true;
      state.applications = payload?.data?.data;
      // console.log(JSON.stringify(state, null, 4));
    },
    [getAllApplications.pending]: (state) => {
      state.isLoading = true;
      state.isFetching = true;
    },
    [getAllApplications.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isLoading = false;
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },
  },
});

export const { saveValue } = applicationsSlice.actions;

// export const store = configureStore({
//   reducer: {
//     applications: applicationsSlice.reducer,
//   },
// });

export const applicationsSelector = (state) => state.applications;
