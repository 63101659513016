import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "@reduxjs/toolkit";
import { useParams } from "react-router-dom";
import { flatMap, forEach, startCase } from "lodash";

import { Alert, Box, Card, Grid, Stack, Snackbar, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import PersonIcon from "@mui/icons-material/PersonTwoTone";
import EditNoteIcon from "@mui/icons-material/EditNote";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";
import EmailIcon from "@mui/icons-material/Email";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import {
  saveValue,
  getApplication,
  applicationSelector,
  userDetailsSelector,
  clearState,
  getApplicationDownloadUrl,
  submitApplication,
  createCustomer,
  getApplicationActivityLog,
  activityLogSelector,
  submitApplicationToEmail,
  setValidationRequireFieldError,
  setRequiredFieldsErrors,
} from "../../store/slices/applicationFormSlice";
import ProductSelector from "../ProductSelector";
import LoanSummary from "../LoanSummary";
import Asset from "src/components/Asset";
import LoanDetails from "src/components/LoanDetails";
import Entity from "../Entity/index.js";
import CustomerDetails from "../CustomerDetails";
import Supplier from "src/components/Supplier/index.js";
import PageLoader from "src/components/Application/PageLoader.js";
import convertToOrdinal from "src/utils/convertToOrdinal";
// import Notes from "./EditorNotes";
import ActivityLogSidebar from "./ActivityLogSidebar";
import Sidebar from "../HOC/Sidebar";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import {
  errorField,
  errorForAddress,
  ERRORS_MESSAGE,
  field_required_message,
  SUCCESS_MESSAGE,
} from "src/constants";
import AddedEntity from "../Entity/AddedEntity";
import IntroducerDeclaration from "../IntroducerDeclaration";
import NCCPDeclaration from "../NCCPDeclaration";
import { userSelector } from "src/store/slices/userSlice";
import Notes from "./Notes";
import EditorNotes from "./EditorNotes";
import { reusableFetch } from "src/utils/useAxios";
import {
  lenderApi,
  useGetCommercialLendersQuery,
  useGetConsumerLendersQuery,
  useGetPersonalLendersQuery,
} from "../../store/slices/apiSlice";
import { useSnackbar } from "notistack";

function openInNewTab(url) {
  var win = window?.open(url, "_blank");
  win?.focus();
}

const Application = React.memo(() => {
  const dispatch = useDispatch();
  const { applicationId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  // const [submitSuccess, setSubmitSuccess] = useState(false);
  const [openActivity, setOpenActivity] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [openSMS, setOpenSMS] = useState(false);

  const [openNotes, setOpenNotes] = useState(false);
  const [isApplicationComplete, setIsApplicationComplete] = useState(false);

  // const [submitButtonText, setSubmitButtonText] = useState("Submit");
  // const [submitButtonBackground, setSubmitButtonBackground] = useState("primary")

  const { linkSent, gotASICData, downloadApplicationLoader } = useSelector(userDetailsSelector);

  const application = useSelector(applicationSelector);
  const user = useSelector(userSelector);

  let { url, open, loadingSubmitApplication, gettingApplication } =
    useSelector(userDetailsSelector);

  const activityLog = useSelector(activityLogSelector);

  const [AssetFieldErrors, setAssetFieldErrors] = useState({
    assetValue: "",
    typeOfSale: "",
    condition: "",
    make: "",
    model: "",
    glassesMake: "",
    glassesModel: "",
    glassesVariant: "",
    glassesNVIC: "",
    glassesData: "",
  });

  const [guarantorFieldErrors, setGuarantorFieldErrors] = useState({
    customerTitle: "",
    firstName: "",
    lastName: "",
    IDNumber: "",
    dateOfBirth: "",
    IDCardNumber: "",
    phoneNumber: "",
    emailAddress: "",
    address: "",
    yearsAt: "",
    monthsAt: "",
  });

  const [agreementsFieldErrors, setAgreementsFieldErrors] = useState({
    introducerDeclarationAgree: "",
  });

  const [nccpFieldErrors, setNccpFieldErrors] = useState({
    whatIsThePurposeOfThisApplication: "", // Enum
    whyDoesThisLoanTermSuitYourRequirements: "", // Enum
    whatAreYouLookingForInYourLoan: "", // Enum
    anyForeseeableChangesImpactingLoanRepayment: "", // Enum
    howApplicantIntendToDoLoanRepaymentAfterChange: "", // Textarea
    increaseInLoanAmountForFinancingCostOfInsurance: "", // boolean
    balloonPaymentAtTheEndOfTheLoan: "", // boolean
    whatToDoWithBalloonPaymentAtTheEndOfTheLoanTerm: "", // Enum
  });

  const [entityFieldErrors, setEntityFieldErrors] = useState(false);

  const {
    assetValue,
    assetType,
    typeOfSale,
    ageOfAsset,
    make,
    model,
    glassesMake,
    glassesModel,
    glassesVariant,
    firstName,
    lastName,
    dateOfBirth,
    IDNumber,
    IDCardNumber,
    Number,
    Address,
    address,
    yearsAt,
    monthsAt,
    introducerDeclarationAgree,
  } = field_required_message;

  useEffect(() => {
    if (openActivity && applicationId) {
      dispatch(getApplicationActivityLog(applicationId));
    }
  }, [
    application?.customers,
    application?.asset,
    application?.entities,
    application?.loanDetails,
  ]);

  useEffect(() => {
    if (applicationId) {
      dispatch(getApplicationActivityLog(applicationId));
    }
  }, []);

  const handle = {
    handleApplicationComplete: (isComplete) => {
      setIsApplicationComplete(isComplete);
    },
    activityLog: () => {
      const extractKey = [
        "addresses",
        "customerAssets",
        "documents",
        "emails",
        "employers",
        "expenses",
        "income",
        "liabilities",
        "phoneNumbers",
      ];

      const list = flatMap(Object.keys(application), (item) => {
        if (["asset", "loanDetails"].includes(item)) {
          return [{ type: item, ...application[item] }];
        }

        if (["customers", "entities"].includes(item)) {
          return flatMap(application[item], (cusEnt) => {
            const result = [{ type: item, ...cusEnt }];
            forEach(cusEnt, (value, key) => {
              if (extractKey.includes(key) && value.createdAt) {
                result.push({ type: key, ...value });
              }
            });
            return result;
          });
        }
        return [];
      });

      return list;
    },
    applicationLoad: async () => {
      dispatch(saveValue({ gettingApplication: true }));
      if (applicationId) await dispatch(getApplication(applicationId)).unwrap();
    },
    addCustomer: () => {
      dispatch(
        createCustomer({
          applicationId: application._id,
          entities: application?.entities,
        })
      );
    },
    showRequired: (currentRequiredFields) => {
      // const isRequiredAsset = (field) => {
      //   return currentRequiredFields?.asset?.some((i) => i == [field]);
      // };
      // console.log(
      //   "- Application - handle.currentRequiredFields:",
      //   currentRequiredFields
      // );

      const requiredErrors = {
        address: "",
        introducerDeclarationAgree: "",
        // yearsAt: "",
        // monthsAt: "",
      };
      const assetErrors = {
        assetValue: assetValue,
        assetType: assetType,
        typeOfSale: typeOfSale,
        ageOfAsset: ageOfAsset,
        make: make,
        model: model,
        glassesMake: glassesMake,
        glassesModel: glassesModel,
        glassesVariant: glassesVariant,
      };
      const guarantorErrors = {
        firstName: firstName,
        lastName: lastName,
        dateOfBirth: dateOfBirth,
        IDNumber: IDNumber,
        IDCardNumber: IDCardNumber,
        Number: Number,
        Address: Address,
        address: address,
        yearsAt: yearsAt,
        monthsAt: monthsAt,
      };
      const agreementError = {
        introducerDeclarationAgree: introducerDeclarationAgree,
      };
      // const allRequiredFields = currentRequiredFields?.asset?.concat(
      //   currentRequiredFields?.entity || [],
      //   currentRequiredFields?.customer || [],
      //   currentRequiredFields?.loanDetails || []
      // )

      currentRequiredFields?.asset.forEach((field) => {
        if (assetErrors[field]) {
          requiredErrors[field] = assetErrors[field];
        }
      });

      currentRequiredFields?.customer.forEach((field) => {
        const filterField = startCase(field?.split(/\.(?=[^\.]+$)/)[1]);

        if (errorField.includes(field)) {
          requiredErrors[filterField] = guarantorErrors[filterField];
        }

        if (errorForAddress.includes(field)) {
          field === "addresses[0].yearsAt"
            ? (requiredErrors.yearsAt = guarantorErrors.yearsAt)
            : field === "addresses[0].monthsAt"
              ? (requiredErrors.monthsAt = guarantorErrors.monthsAt)
              : (requiredErrors.address = guarantorErrors.address);
        }

        if (guarantorErrors[field]) {
          requiredErrors[field] = guarantorErrors[field];
        }
      });

      currentRequiredFields?.agreement.forEach((field) => {
        requiredErrors[field] = agreementError[field];
      });

      setAssetFieldErrors({
        ...AssetFieldErrors,
        ...requiredErrors,
      });
      setGuarantorFieldErrors({
        ...guarantorFieldErrors,
        ...requiredErrors,
      });
      setAgreementsFieldErrors({
        ...agreementsFieldErrors,
        ...requiredErrors,
      });
      setEntityFieldErrors(true);
    },
    submitReferrerApplication: () => {
        // dispatch(saveValue({ loadingSubmitApplication: true }));
        dispatch(saveValue({ loadingSubmitApplication: true }));
        // await dispatch(submitApplicationToEmail({ applicationId: application._id })).unwrap();
        dispatch(submitApplicationToEmail({ applicationId: application._id })).then((res) => {
          if (res?.payload?.data?.data) {
            enqueueSnackbar(res?.payload?.data?.status_message || SUCCESS_MESSAGE.applicationSubmitted, {
              variant: "success",
              autoHideDuration: 5000,
            });
          } else {
            enqueueSnackbar(res?.payload?.status_message || ERRORS_MESSAGE.fetch_error_msg, {
              variant: "error",
              autoHideDuration: 5000,
            });
          }
          // setSubmitSuccess(true);
        });
    }
  };

  const activityList = handle.activityLog();

  useEffect(() => {
    handle.applicationLoad();
    return function cleanUp() {
      dispatch(clearState());
    };
  }, [applicationId]);
  
  // function handleApplicationSubmitSuccess() {
  //   setSubmitButtonBackground("success")
  //   setSubmitButtonText("Application submitted successfully")

  //   setTimeout(() => {
  //     setSubmitButtonBackground("primary")
  //     setSubmitButtonText("Submit")
  //   }, 6000)
  // }

  // useEffect(() => {
  //   if (loadingSubmitApplication === true) {
  //     handleApplicationSubmitSuccess()
  //     dispatch(saveValue({ loadingSubmitApplication: false }))
  //   }
  // }, [loadingSubmitApplication])

  const containerSize = 10;

  const applicationTypeString =
    application?.applicationType?.charAt(0).toUpperCase() +
    application?.applicationType?.slice(1);

    useEffect(() => {
      if (url) openInNewTab(url);
    }, [url]);
  
    const [skipConsumer, setSkipConsumer] = useState(true);
    const [skipPersonal, setSkipPersonal] = useState(true);
    const [skipCommercial, setSkipCommercial] = useState(true);
  
    const consumerResult = useGetConsumerLendersQuery({}, { skip: skipConsumer });
    const personalResult = useGetPersonalLendersQuery({}, { skip: skipPersonal });
    const commercialResult = useGetCommercialLendersQuery({}, { skip: skipCommercial });
  
    useEffect(() => {
      switch (application.applicationType) {
        case "consumer":
          setSkipConsumer(false);
          setSkipPersonal(true);
          setSkipCommercial(true);
          break;
        case "personal":
          setSkipConsumer(true);
          setSkipPersonal(false);
          setSkipCommercial(true);
          break;
        case "commercial":
          setSkipConsumer(true);
          setSkipPersonal(true);
          setSkipCommercial(false);
          break;
        default:
          setSkipConsumer(true);
          setSkipPersonal(true);
          setSkipCommercial(true);
          break;
      }
    }, [application.applicationType]);
  
    // Determine which result to use based on applicationType
    const result =
      application.applicationType === "consumer"
        ? consumerResult
        : application.applicationType === "personal"
          ? personalResult
          : application.applicationType === "commercial"
            ? commercialResult
            : { data: null, error: null, isLoading: false };
  
    const { data: lenderData, isFetching } = result;
  
    useEffect(() => {
      const request = window.indexedDB.open("financeableDB");
      let queries;
      request.onsuccess = async (event) => {
        const db = request.result;
        const objectStore = db.transaction(db.objectStoreNames[0], "readonly").objectStore("keyvaluepairs");
        const objectStoreData = objectStore.get("persist:root");
  
        objectStoreData.onsuccess = (e) => {
          if (objectStoreData.result) {
            const data = JSON.parse(objectStoreData.result ?? "");
            const lenderApiData = JSON.parse(data.lenderApi ?? "");
            queries = lenderApiData.queries;
            Object.keys(queries ?? {}).forEach(async (query) => {
              const queryTimestamp = Math.floor(queries[query].fulfilledTimeStamp / 1000) * 1000;
              // console.log((await logTimestamp()) > queryTimestamp);
              if (["pending", "rejected"].includes(queries[query].status) || (await logTimestamp()) > queryTimestamp) {
                switch (application.applicationType) {
                  case "consumer":
                    dispatch(
                      lenderApi.endpoints.getConsumerLenders.initiate({}, { subscribe: true, forceRefetch: true }),
                    );
                    break;
                  case "personal":
                    dispatch(
                      lenderApi.endpoints.getPersonalLenders.initiate({}, { subscribe: true, forceRefetch: true }),
                    );
                    break;
                  case "commercial":
                    dispatch(
                      lenderApi.endpoints.getCommercialLenders.initiate({}, { subscribe: true, forceRefetch: true }),
                    );
                    break;
                  default:
                    break;
                }
              }
            });
          }
        };
      };
  
      request.onerror = (e) => console.log("Error connecting to IndexDB");
  
      const logTimestamp = async () => {
        const { data } = await reusableFetch(`lender/log`, "GET", null);
        const lenderLog = data.data.lenderLog;
        const logTimestamp = Math.floor((new Date(lenderLog?.createdAt) || 0) / 1000) * 1000;
        return logTimestamp;
      };
    }, [application.applicationType]);
  

  return (
    <React.Fragment>
      {gettingApplication ? (
        <Grid
          container
          style={{
            marginTop: "64px",
            padding: "30px 0px 100px",
            height: "calc(100vh - 100px)",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PageLoader text="Appplication loading..." />
        </Grid>
      ) : (
        <Grid container item>
          <Grid
            container
            item
            sm={containerSize}
            md={containerSize}
            lg={containerSize}
            style={{ padding: "20px 40px 20px 40px" }}
          >
            <Typography
              variant="h3"
              style={{
                fontSize: "22px",
                margin: "0 0 50px",
              }}
            >
              {applicationTypeString} Asset Application{" "}
              {application?.humanId && `- ${application?.humanId}`}
            </Typography>

            <Asset
              applicationType={application.applicationType}
              fieldErrors={AssetFieldErrors}
              setFieldErrors={setAssetFieldErrors}
              applicationId={application._id}
            />

            <LoanDetails applicationType={application.applicationType} />
            {application?.applicationType === "commercial" ? (
              <React.Fragment>
                {application?.entities?.map((entity, i) => (
                  <Entity
                    application={application}
                    entitys={entity}
                    index={i}
                    fieldsErrors={entityFieldErrors}
                    setFieldsErrors={setEntityFieldErrors}
                    applicationId={application._id}
                  />
                ))}
                <AddedEntity
                  fieldsErrors={entityFieldErrors}
                  setFieldsErrors={setEntityFieldErrors}
                  applicationId={application._id}
                />
              </React.Fragment>
            ) : null}

            {/* {application?.applicationType === "commercial" &&
              !application?.entities?.[0] && (
                <Entity
                  application={application}
                  fieldsErrors={entityFieldErrors}
                  setFieldsErrors={setEntityFieldErrors}
                />
              )} */}

            {application?.customers?.length > 0 &&
              application?.customers?.map((customer, i) => (
                <CustomerDetails
                  key={application._id}
                  // numberOfCustomer={application?.customers?.length}
                  customer={customer}
                  fieldErrors={guarantorFieldErrors}
                  setFieldErrors={setGuarantorFieldErrors}
                  applicationType={application.applicationType}
                  applicationId={application._id}
                  index={i}
                />
              ))}

            {application?.customers?.length < 1 && (
              <Grid
                container
                item
                xs={12}
                style={{
                  margin: "0 0 30px",
                  padding: "0 0 20px",
                  borderBottom: "1px solid rgba(0,0,0,0.12)",
                }}
              >
                <Grid item xs={12} md={2}>
                  <Stack direction="row">
                    <PersonIcon style={{ marginRight: "10px" }} />
                    <Typography>Guarantor</Typography>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={10}
                  style={{
                    padding: "0 0 0 10px",
                  }}
                >
                  <LoadingButton
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      handle.addCustomer();
                      setGuarantorFieldErrors({
                        customerTitle: "",
                        firstName: "",
                        lastName: "",
                        IDNumber: "",
                        dateOfBirth: "",
                        IDCardNumber: "",
                        phoneNumber: "",
                        emailAddress: "",
                        address: "",
                      });
                    }}
                  >
                    Add guarantor
                  </LoadingButton>
                  {guarantorFieldErrors?.firstName !== "" &&
                    application?.customers?.length === 0 && (
                      <Typography
                        style={{
                          marginTop: "5px",
                          marginLeft: "14px",
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          fontWeight: "400",
                        }}
                      >
                        Please add guarantor
                      </Typography>
                    )}
                </Grid>
              </Grid>
            )}

            {application?.customers?.length > 0 && (
              <Grid
                container
                item
                xs={12}
                style={{
                  margin: "0 0 30px",
                  padding: "0 0 20px",
                  borderBottom: "1px solid rgba(0,0,0,0.12)",
                }}
              >
                <Grid item xs={12} md={2}>
                  <Stack direction="row">
                    <PersonIcon style={{ marginRight: "10px" }} />
                    <Typography>Add Guarantor</Typography>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={10}
                  style={{
                    padding: "0 0 0 10px",
                  }}
                >
                  <LoadingButton
                    color="primary"
                    variant="contained"
                    onClick={handle.addCustomer}
                  >
                    Add {convertToOrdinal(application?.customers?.length)}{" "}
                    guarantor
                  </LoadingButton>
                </Grid>
              </Grid>
            )}
            {application?.applicationType === "consumer" && (
              <>
                <IntroducerDeclaration
                  introducerDeclarationAgree={
                    application?.introducerDeclarationAgree
                  }
                  applicationId={application._id}
                  errorField={agreementsFieldErrors}
                  setErrorField={setAgreementsFieldErrors}
                />
                <NCCPDeclaration
                  whatIsThePurposeOfThisApplication={
                    application?.customers?.[0]?.customerDisclaimer
                      ?.whatIsThePurposeOfThisApplication
                  }
                  whyDoesThisLoanTermSuitYourRequirements={
                    application?.customers?.[0]?.customerDisclaimer
                      ?.whyDoesThisLoanTermSuitYourRequirements
                  }
                  whatAreYouLookingForInYourLoan={
                    application?.customers?.[0]?.customerDisclaimer
                      ?.whatAreYouLookingForInYourLoan
                  }
                  anyForeseeableChangesImpactingLoanRepayment={
                    application?.customers?.[0]?.customerDisclaimer
                      ?.anyForeseeableChangesImpactingLoanRepayment
                  }
                  howApplicantIntendToDoLoanRepaymentAfterChange={
                    application?.customers?.[0]?.customerDisclaimer
                      ?.howApplicantIntendToDoLoanRepaymentAfterChange
                  }
                  increaseInLoanAmountForFinancingCostOfInsurance={
                    application?.customers?.[0]?.customerDisclaimer
                      ?.increaseInLoanAmountForFinancingCostOfInsurance
                  }
                  balloonPaymentAtTheEndOfTheLoan={
                    application?.customers?.[0]?.customerDisclaimer
                      ?.balloonPaymentAtTheEndOfTheLoan
                  }
                  whatToDoWithBalloonPaymentAtTheEndOfTheLoanTerm={
                    application?.customers?.[0]?.customerDisclaimer
                      ?.whatToDoWithBalloonPaymentAtTheEndOfTheLoanTerm
                  }
                  applicationId={application._id}
                  customerId={application?.customers?.[0]?._id}
                  errorField={nccpFieldErrors}
                  setErrorField={setNccpFieldErrors}
                />
              </>
            )}
            <Supplier />

            <Grid container item xs={12} style={{ margin: "0 0 50px", justifyContent: "end" }} >
              {user?.userType !== "master" &&
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    margin: "0",
                    justifyContent: "end",
                    borderTop: "1px solid rgba(0,0,0,0.43)",
                    paddingTop: "30px"
                  }} >
                  <Stack spacing={3} direction="row" >
                    <LoadingButton
                      color="primary"
                      endIcon={<FileCopyOutlinedIcon />}
                      // loading={loadingDownloadUrl}
                      loadingPosition="end"
                      variant="outlined"
                      size="large"
                      // disabled={!isApplicationComplete}
                      onClick={async () => {
                        // setLoadingDownloadUrl(true);
                        if (!url) {
                          await dispatch(
                            getApplicationDownloadUrl({ applicationId: application._id })
                          ).unwrap();
                        }
                        // setLoadingDownloadUrl(false);
                      }}
                      href={url}
                    >
                      {downloadApplicationLoader
                        ? "Downloading..."
                        : "Download application file"}
                    </LoadingButton>

                    <LoadingButton
                      // color="secondary"
                      // color={submitButtonBackground}
                      endIcon={<SendOutlinedIcon />}
                      loading={loadingSubmitApplication}
                      loadingPosition="end"
                      variant="contained"
                      size="large"
                      disabled={!isApplicationComplete || loadingSubmitApplication}
                      onClick={handle.submitReferrerApplication}
                    >
                      {/* {submitButtonText} */}
                      {loadingSubmitApplication ? "Submitting..." : "Submit"}
                    </LoadingButton>
                  </Stack>
                </Grid>
              }
            </Grid>
          </Grid>

          {user?.userType === "master" && <Grid conatiner item sm={2} md={2} lg={2} style={{ padding: "20px" }}>
          <Sidebar
              open={open}
              title={"Expand lenders"}
              onClickFn={() => {
                dispatch(saveValue({ open: !open, openManualQuote: false }));
                if (!open) {
                  dispatch(setValidationRequireFieldError({}));
                  dispatch(setRequiredFieldsErrors({}));
                }
              }}
              bottom={""}
              right={true}
              left={false}
              disabled={false}
              forwardIcon={<ArrowForwardIosIcon fontSize={"large"} />}
              backwardIcon={<ChecklistRtlIcon fontSize="large" />}
              component={
                (!isFetching && lenderData) ? (
                  <ProductSelector onShowRequired={handle.showRequired} lenderData={lenderData} />
                ) : (
                  <PageLoader text={"Lenders Loading"} />
                )
              }
            />
          </Grid>}

          <Sidebar
            open={openNotes}
            title={"Notes"}
            onClickFn={() => setOpenNotes(!openNotes)}
            bottom={520}
            right={false}
            left={true}
            dispatch={false}
            icon={<EditNoteIcon fontSize="large" />}
            component={<EditorNotes handleApplicationComplete={handle.handleApplicationComplete} />}
          />
          <Sidebar
            open={openActivity}
            title={"Activity log"}
            onClickFn={() => setOpenActivity(!openActivity)}
            bottom={400}
            right={false}
            left={true}
            disabled={false}
            icon={<ViewTimelineIcon fontSize="large" />}
            component={
              <ActivityLogSidebar
                activityList={activityLog}
                openActivity={openActivity}
              />
            }
          />
          <Sidebar
            open={openSMS}
            title={"SMS (coming soon)"}
            onClickFn={() => setOpenSMS(!openSMS)}
            bottom={370}
            right={false}
            left={true}
            disabled={true}
            icon={<ChatBubbleIcon fontSize="large" />}
            component={""}
          />
          <Sidebar
            open={openEmail}
            title={"Emails (coming soon)"}
            onClickFn={() => setOpenEmail(!openEmail)}
            bottom={200}
            right={false}
            left={true}
            disabled={true}
            icon={<EmailIcon fontSize="large" />}
            component={""}
          />

          {/* <Sidebar
            open={openActivity}
            title={"Activity log"}
            onClickFn={() => setOpenActivity(!openActivity)}
            bottom={55}
            right={false}
            left={true}
            disabled={false}
            component={<ActivityLogSidebar activityList={activityLog} />}
          /> */}

          {user?.userType !== "master" && <Grid conatiner item sm={2} md={2} lg={2} style={{ padding: "20px" }}>
            <Box
              style={{
                // background: color.lightPink
                // padding: "20px",
                // width: "calc(25vw - 60px)",
                position: "fixed",
                overflowY: "wrap",
                overflowX: "wrap",
                height: "calc(100vh - 140px)",
                scrollbarColor: "white rebeccapurple",
                scrollbarWidth: "thin",
                paddingRight: "8px",
                marginTop: "70px",
              }}
              sx={{
                '&.MuiBox-root::-webkit-scrollbar': {
                  width: '7px', height: "7px"
                },
                '&.MuiBox-root::-webkit-scrollbar-track': {
                  background: '#f1f1f1',
                },
                '&.MuiBox-root::-webkit-scrollbar-thumb': {
                  backgroundColor: '#999',
                },
                '&.MuiBox-root::-webkit-scrollbar-thumb:hover': {
                  background: '#616161',
                },
              }}
            >
              <LoanSummary />
            </Box>
          </Grid>
          }

          {/* <Snackbar
            open={submitSuccess}
            autoHideDuration={8000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={() => setSubmitSuccess(false)}
          >
            <Alert
              onClose={() => setSubmitSuccess(false)}
              elevation={6}
              severity="success"
              sx={{ width: "100%" }}
            >
              Application submitted
            </Alert>
          </Snackbar> */}
          <Snackbar
            open={linkSent}
            autoHideDuration={8000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={() => dispatch(saveValue({ linkSent: false }))}
          >
            <Alert
              onClose={() => dispatch(saveValue({ linkSent: false }))}
              elevation={6}
              severity="success"
              sx={{ width: "100%" }}
            >
              Link sent
            </Alert>
          </Snackbar>
          <Snackbar
            open={gotASICData}
            autoHideDuration={8000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={() => dispatch(saveValue({ linkSent: false }))}
          >
            <Alert
              onClose={() => dispatch(saveValue({ linkSent: false }))}
              elevation={6}
              severity="success"
              sx={{ width: "100%" }}
            >
              ASIC Data retrieved
            </Alert>
          </Snackbar>
        </Grid>
      )}
    </React.Fragment>
  );
});

export default Application;
