import { useEffect, useState } from "react";
import _ from "lodash";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Box, Card, CardContent, Grid, Hidden, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  saveValue,
  saveAsset,
  startApplication,
  getApplication,
  updateAsset,
  updateLoanDetails,
  applicationSelector,
  assetSelector,
  loanDetailsSelector,
  saveLoanDetails,
  userDetailsSelector,
  createCustomer,
  getApplicationDownloadUrl,
  submitApplication,
  clearState,
  entitySelector,
  customersSelector,
} from "../../store/slices/applicationFormSlice";

import commercialProducts from "src/products/commercial";
import consumerProducts from "src/products/consumer";

import getBalloon from "src/utils/getBalloon";
import calculateRepayments from "src/utils/calculateRepayments";
import {
  evaluateCriteria,
  calculateLoading,
  calculateFees,
  calculateExtras,
} from "src/utils/evaluateCriteria";
import getMonthlyRate from "src/utils/getMonthlyRate";
import calculateComparisonRate from "src/utils/calculateComparisonRate";

import Product from "./Product";
import {
  consumerLenderList,
  commercialLenderList,
  productTypes,
  APPLICATION_TYPES
} from "src/constants";
import PageLoader from "../Application/PageLoader";
import calculateEffectiveRate from "src/utils/calculateEffectiveRate";

// console.log("products", products);
const dollarStringOptions = {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
}

export default function ProductSelector() {
  const dispatch = useDispatch();

  const asset = useSelector(assetSelector);
  const loanDetails = useSelector(loanDetailsSelector);
  const entity = useSelector(entitySelector);
  const customers = useSelector(customersSelector);
  // console.log("application", application);
  const { _id, applicationType } = useSelector(applicationSelector)
  const isCommercial = applicationType === "commercial"
  const products = isCommercial ? commercialProducts : consumerProducts
  const lenderList = isCommercial ? commercialLenderList : consumerLenderList

  const {
    assetValue = 0,
    ageOfAsset = "",
    assetType = "",
    typeOfSale,
    glassesMake,
    glassesModel,
    glassesVariant,
    make,
    model,
  } = useSelector(assetSelector);

  const {
    fees,
    balloon = 0,
    term = 0,
    tradeIn = 0,
    payout = 0,
    deposit = 0,
    brokerage = 0,
    rateAdjustment = 0,
    purpose,
    loanAmount: loanAmountSaved,
  } = useSelector(loanDetailsSelector);

  const [productsMap, setProductsMap] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [loanAmount, setLoanAmount] = useState(0);
  const [orderByParameters, setOrderByParameters] = useState([
    // ["fit", "desc"],
    // ["repayments", "asc"],
  ]);
  const [orderByParametersList, setOrderByParametersList] = useState([
    ["qualification", "repayments"],
    ["asc", "asc"],
  ]);

  const [filterByParameters, setFilterByParameters] = useState(lenderList);

  const [averageLoanAmount, setAverageLoanAmount] = useState(0);
  const [minRepayments, setMinRepayments] = useState(0);
  const [maxRepayments, setMaxRepayments] = useState(0);
  const [minRate, setMinRate] = useState(0);
  const [maxRate, setMaxRate] = useState(0);
  const [financeAmount, setFinanceAmount] = useState(0);
  const [brokerageAmount, setBrokerageAmount] = useState(0);

  // const handleLoanAmountCommercial = (fees, brokerage) => {
  //   // console.log({ assetValue, deposit, fees, brokerage }, (assetValue - deposit) + ((assetValue - deposit) * (brokerage || 0 / 100)) + fees)
  //   return (assetValue - deposit) + ((assetValue - deposit) * (brokerage / 100)) + fees;
  // };

  // const handleLoanAmountConsumer = (fees) => {
  //   // console.log({ deposit })
  //   return (
  //     assetValue - deposit + fees
  //   );
  // };

  const handle = {
    setDimension: () => {
      getDimension({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight,
      });
    },
    loanAmountCommercial: (fees, netAssetValue, brokerageAmount) => netAssetValue + brokerageAmount + fees,
    loanAmountConsumer: (fees, netAssetValue) => netAssetValue + fees,
    evaluateProducts: () => {

      const customerProfile = customers?.map(
        (customer) => customer.customerProfile
      )[0];

      const creditProfile = customers?.map((customer) => customer.creditProfile)[0];
      const employer = customers?.map((customer) => customer.employers[0])[0];

      const productList = products?.map((product) => {
        return product?.productTiers.map((tier) => {
          // console.log({ tier });
          const defaultBrokerage = calculateExtras(product.brokerage, {
            asset,
            loanDetails,
            entity: entity?.[0],
          })[0];

          // const FEES = fees?.[0] ? fees : product.fees

          const newLoanDetails = { loanAmount: assetValue - deposit, term, deposit, balloon }

          const FEESLIST = calculateFees(product.fees, {
            asset,
            loanDetails: newLoanDetails,
            entity: entity?.[0],
            customer: customers?.[0],
            customerProfile,
            creditProfile,
            employer,
          });

          const feesList = FEESLIST.map((fee) => {
            const feeValue = fee.value * 1;
            if (fee.calc === "percent") {
              const newFeeValue = (feeValue / 100) * newLoanDetails.loanAmount;
              return { ...fee, value: newFeeValue };
            }
            // if (fee.name === "Origination fee")
            //   return {
            //     ...fee,
            //     value: originationFee < feeValue ? parseFloat(originationFee) : feeValue,
            //   };
            return fee;
          });

          const financeFeesTotal = FEESLIST.reduce((accum, fee) => {
            // if (fee.percentage) {
            //   return (fee.value / 100) * newLoanDetails.loanAmount + accum;
            // }
            // if (fee.frequency === "monthly") return accum;
            // Upfront: false || Financed: true || Monthly: false
            if (fee.frequency === "Financed") return fee.value + accum;
            return accum;
          }, 0);

          const monthlyFees = feesList.find((fee) => fee.frequency === "monthly");
          const totalMonthlyFees = monthlyFees?.value ? monthlyFees?.value * term : 0

          const feesTotal = feesList.reduce((accum, fee) => {
            let feeValue = fee.value * 1;
            if (fee.percentage) {
              return (fee.value / 100) * newLoanDetails.loanAmount + accum;
            }
            if (fee.frequency === "monthly") return accum;
            if (fee.capitalised) return feeValue + accum;
            return accum;
          }, 0);

          const netAssetValue = (assetValue * 1) - (deposit * 1) - (tradeIn * 1) + (payout * 1);
          const adjustedBrokerage = brokerage * 1
          const finalBrokerage = adjustedBrokerage > defaultBrokerage?.max ? defaultBrokerage?.max : adjustedBrokerage
          const brokerageAmount = netAssetValue * (finalBrokerage / 100);

          let loanAmount = isCommercial
            ? handle.loanAmountCommercial(financeFeesTotal, netAssetValue, brokerageAmount)
            : handle.loanAmountConsumer(financeFeesTotal, netAssetValue);
          // console.log("loanAmount", loanAmount);

          // console.log("lender", product.lender);
          // console.log("product.loading", product.loading);

          const loadingList = calculateLoading(product.loading, {
            asset,
            loanDetails: {
              ...loanDetails,
              ...{
                loanAmount: loanAmount,
                brokerageRate: defaultBrokerage?.value,
                deposit: 100 - (assetValue - deposit) / assetValue * 100
              },
            },
            entity: entity?.[0],
            customerProfile,
          });

          const loadingTotal = loadingList.reduce((accum, item) => {
            return item.result === true ? item.value + accum : accum;
          }, 0);

          let RATE

          if (Array.isArray(tier.rate)) {
            // console.log(tier.criteria)
            const scoreCriteria =
              tier.criteria.find(criteria =>
                criteria.attribute === "score")

            // console.log({ scoreCriteria })

            if (scoreCriteria) {
              if (
                scoreCriteria.value.min &&
                creditProfile?.score >= scoreCriteria.value.min &&
                creditProfile?.score < scoreCriteria.value.max) {
                const percentage = (
                  ((creditProfile.score - scoreCriteria.value.max) /
                    (scoreCriteria.value.max - scoreCriteria.value.min) *
                    100) + 100
                )
                RATE = percentage / 100 * (tier.rate[1] - tier.rate[0]) + tier.rate[0]
              } else {
                RATE = tier.rate[0]
              }
            }
          } else {
            // console.log("not array", tier.rate)
            RATE = tier.rate
          }

          // const quoteDetails = calculateQuote(
          //   loanAmount,
          //   balloon,
          //   term,
          //   RATE + loadingTotal
          // );

          const quoteDetails = handle.calculateQuote(loanAmount, balloon, term, RATE, netAssetValue);
          const quoteWithNoBalloon = handle.calculateQuote(loanAmount, 0, term, RATE, netAssetValue);

          const balloonAmount = (balloon / 100) * netAssetValue;
          const presentValue = balloonAmount / Math.pow(1 + RATE, term);
          const trueLoanAmount = loanAmount - presentValue;

          const comparisonRateRaw = calculateEffectiveRate(
            term,
            quoteDetails.repayments,
            -(trueLoanAmount - feesTotal - (isCommercial ? brokerageAmount : 0)),
            balloonAmount,
            isCommercial ? 1 : 0,
            RATE / 100,
          );

          let effectiveRate = calculateEffectiveRate(
            term,
            quoteDetails.repayments,
            -(netAssetValue + financeFeesTotal),
            balloonAmount,
            isCommercial ? 1 : 0,
            RATE / 100,
          );

          if (effectiveRate < RATE) {
            effectiveRate = RATE;
          }

          let comparisonRate = comparisonRateRaw;

          if (comparisonRate < RATE) {
            comparisonRate = RATE;
          }

          const LOAN_DETAILS = {
            ...loanDetails,
            ...{
              loanAmount: loanAmount,
              repayments: quoteDetails.repayments,
              deposit: 100 - (assetValue - deposit) / assetValue * 100
            },
          };


          const productDetails = {
            lender: product.lender,
            productName: tier.name,
            name: tier.name,
            logo: product.logoURL,

          };

          const criteriaResults = evaluateCriteria(tier?.criteria, {
            asset,
            loanDetails: LOAN_DETAILS,
            entity: entity?.[0],
            customer: customers?.[0],
            customerProfile,
            creditProfile,
            employer,
          }).filter((result) => {
            // console.log({ result })
            if (!result.criteria) return result;

            if (result.criteria.reduce((boolean, criteria) => {
              return criteria.result ? boolean : false;
            }, true)) return result;

            if (!result.criteria.reduce((boolean, criteria) => {
              return criteria.result ? boolean : false;
            }, false) && result.criteria.some(
              (item) => result.primary === item.attribute
            )) {
              return result.criteria.find(
                (item) => result.primary === item.attribute
              );
            }

            // if (
            //   result.criteria.some(
            //     (item) => result.primary === item.attribute && item.result
            //   )

            // )
            //   return result;

            return result;
          });

          // console.log(criteriaResults);

          const overallResult = criteriaResults.reduce((count, item) => {

            return item?.result ? ++count : count;
          }, 0);

          function tierInfo() {
            if (tier?.info?.[0]) return tier?.info

            return []
          }

          const financeAmount = applicationType === APPLICATION_TYPES.PERSONAL ? loanDetails?.loanAmount : 1 * netAssetValue;

          const finalLoanAmount = applicationType === APPLICATION_TYPES.PERSONAL
            ? loanDetails?.loanAmount
            : quoteDetails.repayments * term

          return _.merge(productDetails, {
            effectiveRate: effectiveRate,
            comparisonRate: comparisonRate,
            financeAmount: financeAmount,
            loanAmount: finalLoanAmount,
            repayments: (quoteDetails.repayments || 0) + (monthlyFees?.value || 0),
            brokerage: finalBrokerage,
            brokerageAmount: brokerageAmount,
            monthlyFees,
            fees: feesList,
            feesTotal,
            financeFeesTotal,
            loading: { loadingList, loadingTotal },
            criteriaResults,
            fit: criteriaResults.length - overallResult,
            criteria: `${overallResult}/${criteriaResults.length}`,
            qualification: criteriaResults.length - overallResult,
            productType: tier.productType,
            labels: [tier.productType],
            // rate: (RATE + loadingTotal) * 1,
            rate: RATE,
            info: [...tierInfo(), ...(product?.info || [])],
            date: product.date,
            isCommercial,
            actions: product.actions,
          });
        });
      });

      const list = _.chain(productList)
        .flatten()
        // .filter((product) => product.repayments > 0)
        // .filter((product) => product.fit <= 3)
        // .filter((product) => filterByParameters.includes(product?.lender))
        // .filter((product) => filterByProductType.includes(product?.productType))
        // .filter((product) => filterByCriteria(product, criteriaFilterValue))
        // .filter((product) => filterByFit(product.fit, fitFilterValue))
        // .orderBy(...orderByParametersList)
        .value();

      const groupedList = _.mapValues(_.groupBy(list, 'lender'))
      const prioritisedList = Object.keys(groupedList).reduce((acc, products) => {
        // console.log(groupedList)
        const bestProduct = _.orderBy(groupedList[products],
          ["fit", "rate"],
          ["asc", "asc"])
        // console.log({ bestProduct })
        // console.log({bestProduct})
        // acc.push(groupedList[products][0])
        acc.push(bestProduct[0])
        return acc
      }, [])

      // mixpanel.track(`Quote: Filtered products`, {
      //   applicationId: _id,
      //   numberOfProducts: prioritisedList?.length,
      //   lenders: prioritisedList.map(i => i?.lender)
      // })

      setProductsMap(_.orderBy(prioritisedList,
        ...orderByParametersList
      ));
      setLoading(false)
    },
    calculateQuote: (loanAmount, balloon, term, rate, netAssetValue) => {
      const monthlyRate = getMonthlyRate(rate);
      const repayments = calculateRepayments(
        monthlyRate,
        term,
        -loanAmount,
        (balloon / 100) * netAssetValue,
        isCommercial ? 1 : 0,
      );

      return {
        repayments: Number(repayments.toFixed(2)),
      };
    },
  }

  function filterByCriteria(product, criteriaFilterValue) {

    const matches = (Array.isArray(criteriaFilterValue) ? criteriaFilterValue : [criteriaFilterValue]).map((criteria) => {
      return product.criteriaResults.find(result => {
        return criteria === result.attribute

      })?.result
    });

    return matches.every(i => i === true || i === undefined)
  }

  const calculateQuote = (loanAmount, balloon, term, rate) => {
    const monthlyRate = getMonthlyRate(rate);
    const rpmts = calculateRepayments(
      monthlyRate,
      term,
      -loanAmount,
      balloon / 100 * assetValue,
      isCommercial ? 1 : 0
    );
    const roundedRepayments = (Math.round(rpmts * 100) / 100).toFixed(2);



    return {
      repayments: roundedRepayments * 1,
    };
  };

  function handleMaxRepayments() {
    setMaxRepayments(Math.max.apply(Math, productsMap.map((p) => { return p.repayments; })) || 0)
  }

  function handleMinRepayments() {
    // console.log(Math.min.apply(Math, products.map((p) => { return p.repayments })))
    setMinRepayments(Math.min.apply(Math, productsMap.map((p) => { return p.repayments; })) || 0)
  }

  function handleAverageLoanAmount() {
    const sum = productsMap.reduce((a, b) => a + b.repayments, 0);
    setAverageLoanAmount((sum / productsMap.length) * term || 0)
  }

  function handleMaxRate() {
    setMaxRate(Math.max.apply(Math, productsMap.map((p) => { return p.rate; })).toFixed(2))
  }

  function handleMinRate() {
    setMinRate(Math.min.apply(Math, productsMap.map((p) => { return p.rate; })).toFixed(2))
  }

  function handleFinanceAmount() {
    const sum = productsMap.reduce((a, b) => a + b.financeAmount, 0);
    setFinanceAmount((sum / productsMap.length))
  }

  function handleBrokerageAmount() {
    const sum = productsMap.reduce((a, b) => {
      if (b?.brokerage?.value) return a + (b.brokerage.value / 100 * b.loanAmount)

      return a
    }, 0);
    setBrokerageAmount((sum / productsMap.length) / 2)
  }

  useEffect(() => {
    setLoading(true)
    handle.evaluateProducts();
  }, [
    customers,
    brokerage,
    assetType,
    ageOfAsset,
    balloon,
    term,
    entity,
    orderByParameters,
    filterByParameters,
    typeOfSale,
    assetValue,
    deposit,
    tradeIn,
    payout,
    glassesMake,
    glassesModel,
    glassesVariant,
    make,
    model,
    asset,
    loanAmountSaved,
    purpose,
    rateAdjustment
  ]);

  useEffect(() => {
    if (productsMap[0]) {
      handleFinanceAmount()
      handleAverageLoanAmount()
      handleMinRepayments()
      handleMaxRepayments()
      handleMinRate()
      handleMaxRate()
      if (isCommercial) handleBrokerageAmount()
    }
  }, [
    productsMap
  ]);

  return <>
    {/* <Grid item sm={12}> */}
    <Typography style={{ margin: "0 0 5px 0", fontSize: "18px" }}>Loan Summary</Typography>
    <Typography style={{ margin: "0 0 5px 0" }}>
      <strong style={{ fontSize: "14px" }}>Finance amount (avg)</strong> <br></br>
      {financeAmount.toLocaleString("en-US", dollarStringOptions)}
    </Typography>
    {/* <Typography style={{ margin: "0 0 5px 0" }}>
      <strong style={{ fontSize: "14px" }}>Total loan amount (avg)</strong> <br></br>
      {averageLoanAmount.toLocaleString("en-US", dollarStringOptions)}
    </Typography> */}
    <Typography style={{ margin: "0 0 5px 0" }}>
      <strong style={{ fontSize: "14px" }}>Repayments (mth)</strong> <br></br>
      {minRepayments.toLocaleString("en-US", dollarStringOptions)} - {maxRepayments.toLocaleString("en-US", dollarStringOptions)}
    </Typography>
    <Typography style={{ margin: "0 0 5px 0" }}>
      <strong style={{ fontSize: "14px" }}>Interest rate</strong><br></br>
      {minRate} - {maxRate}%
    </Typography>
    {/* <Typography style={{ margin: "0 0 40px 0" }}>
      <strong style={{ fontSize: "14px" }}>Brokerage (avg)</strong><br></br>
      {brokerageAmount.toLocaleString("en-US", dollarStringOptions)}
    </Typography> */}
    <Typography style={{ margin: "0 0 5px 0", fontSize: "12px" }}>
      <strong >Disclaimer:</strong><br></br>
      Figures above are indicative. Quotes are subject to lender approval.
    </Typography>
    {/* </Grid> */}
  </>
}
